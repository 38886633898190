/* eslint-disable import/no-default-export */
export default class DraftDestination {
  private name: string;

  isValid: boolean;

  constructor() {
    this.name = '';
    this.isValid = false;
  }

  getName() {
    return this.isValid ? this.name : '';
  }

  setName(name: string) {
    this.name = name;
  }

  clear() {
    this.name = '';
    this.isValid = false;
  }

  setValid() {
    this.isValid = true;
  }
}

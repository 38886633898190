import { apiAuthCaller } from '@services/apiCaller';
import { IRootStore } from '@stores/index';
import { AxiosResponse } from 'axios';
import { action, observable, makeObservable } from 'mobx';
import { ITransformationStore, TransformationStore } from './transformation';
import { getPermissions } from './util';
import { IDestinationTransformation, ITransformationConfig } from './destinationTransformation';

export type LanguageType = 'javascript' | 'python' | 'pythonfaas';

interface CreatePayload {
  name: string;
  description: string;
  code: string;
  events: unknown;
  language: LanguageType;
}

interface IStandardTransformation {
  id: string;
  name: string;
  description: string;
  code: string;
  language: LanguageType;
  codeVersion: string;
  category: string;
  icon: string;
}

type ServerTransformation = ITransformationStore & {
  destinationTransformations: IDestinationTransformation[];
  permissions?: { id: string; isLocked: boolean };
};

export interface ITransformationsListStore {
  transformations: ITransformationStore[];
  standardTransformations: IStandardTransformation[];
  rootStore: IRootStore;
  setTransformations(transformations: ITransformationStore[]): void;
  getTransformations(): void;
  getStandardTransformations(): void;
  getById(transformationId: string): ITransformationStore | undefined;
  createTransformation(transformation: CreatePayload): Promise<ITransformationStore>;
  updateTransformation(
    transformationId: string,
    transformation: Record<'name' | 'description' | 'code', string> & { events?: string },
    upgradeTransformation: boolean,
  ): void;
  connectTransformationToDestination(
    transformationId: string,
    destinationId: string,
    config?: ITransformationConfig,
  ): void;
  firstLoad: boolean;
}

export class TransformationsListStore implements ITransformationsListStore {
  @observable public transformations: ITransformationStore[] = [];

  @observable public standardTransformations: IStandardTransformation[] = [];

  @observable public rootStore: IRootStore;

  @observable public firstLoad = false;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  public setTransformations(transformations: ITransformationStore[]): void {
    this.transformations = transformations;
  }

  @action.bound
  public async getTransformations() {
    const { selectedWorkspaceId } = this.rootStore.userStore;
    try {
      const res: AxiosResponse<{ transformations: ServerTransformation[] }> =
        await apiAuthCaller().get(`/transformations?workspace_id=${selectedWorkspaceId}`);
      this.transformations = res.data.transformations.map(
        (transformation: ServerTransformation) =>
          new TransformationStore(transformation, this.rootStore),
      );
      this.rootStore.permissionsStore.registerResource(getPermissions(res.data.transformations));
      this.firstLoad = true;
    } catch (err) {}
  }

  @action.bound
  public async getStandardTransformations() {
    try {
      const res: AxiosResponse<{ rudderstackTransformations: IStandardTransformation[] }> =
        await apiAuthCaller().get('/rudderstackTransformations');
      this.standardTransformations = res.data.rudderstackTransformations.map(
        (transformation: IStandardTransformation) => ({
          id: transformation.id,
          name: transformation.name,
          description: transformation.description,
          code: transformation.code,
          category: transformation.category,
          icon: transformation.icon,
          language: transformation.language,
          codeVersion: transformation.codeVersion,
        }),
      );
    } catch (err) {}
  }

  @action.bound
  public async createTransformation(transformation: CreatePayload) {
    const { userStore, permissionsStore } = this.rootStore;
    const { selectedWorkspaceId } = userStore;
    const publish = true;
    const res = await apiAuthCaller().ignore400().post(`/transformations?publish=${publish}`, {
      name: transformation.name,
      description: transformation.description,
      code: transformation.code,
      events: transformation.events,
      upgradeTransformation: true,
      workspaceId: selectedWorkspaceId,
      language: transformation.language,
    });
    const savedTransformation = res.data;
    this.transformations.push(new TransformationStore(savedTransformation, this.rootStore));
    permissionsStore.addNewResource(savedTransformation.id);
    return savedTransformation;
  }

  @action.bound
  public async updateTransformation(
    transformationId: string,
    transformation: Record<'name' | 'description' | 'code' | 'events', string>,
    upgradeTransformation: boolean,
  ) {
    const publish = true;
    const res = await apiAuthCaller()
      .ignore400()
      .post(`/transformations/${transformationId}?publish=${publish}`, {
        name: transformation.name,
        description: transformation.description,
        code: transformation.code,
        events: transformation.events,
        upgradeTransformation,
      });

    const updatedTransformation = res.data;
    const prevTransformation = this.getById(transformationId);
    prevTransformation!.update(updatedTransformation);
  }

  @action.bound
  public getById(transformationId: string): ITransformationStore | undefined {
    return this.transformations.find((t) => t.id === transformationId);
  }

  @action.bound
  public async connectTransformationToDestination(
    transformationId: string,
    destinationId: string,
    config?: ITransformationConfig,
  ) {
    const transformation = this.transformations.find((t) => t.id === transformationId);
    transformation!.connectToDestination(destinationId, undefined, undefined, config);
  }
}

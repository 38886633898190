import { SourceTPConfig } from '@components/trackingPlans/stores/trackingPlanEventsList';
import { transformTPConfigToServer } from '@stores/sourcesList/util';

export const parseTrackingPlanConnections = (
  connections: { sourceId: string; config: SourceTPConfig }[],
) =>
  connections.map(({ sourceId, config }) => ({
    sourceId,
    config: parseTPConnectionConfig(config),
  }));

const parseTPConnectionConfig = (config: SourceTPConfig): Record<string, Record<string, string>> =>
  (Object.keys(config) as (keyof SourceTPConfig)[]).reduce(
    (acc, key) => {
      const currentConfig = config[key];
      if (currentConfig) {
        acc[key] = transformTPConfigToServer(currentConfig);
      }
      return acc;
    },
    {} as Record<string, Record<string, string>>,
  );

import { action, observable, makeObservable } from 'mobx';

export const enum AuthorizationScheme {
  Password = 'password',
  SmsOtp = 'smsotp',
}

export interface ISecurityStore {
  scheme: AuthorizationScheme | null;
  error: string | null;
  loading: boolean;
  getResponse(type: string): Promise<unknown>;
  submit(value: string): void;
  cancel(): void;
  handleError(error: string): Promise<unknown>;
  close(): void;
}

export class SecurityStore implements ISecurityStore {
  @observable scheme: AuthorizationScheme | null = null;

  @observable error: string | null = null;

  @observable loading = false;

  private promiseResolve: ((value: unknown) => void) | undefined;

  private promiseReject: ((reason: unknown) => void) | undefined;

  constructor() {
    makeObservable(this);
  }

  @action.bound
  getResponse(scheme: AuthorizationScheme) {
    this.scheme = scheme;
    this.error = null;
    this.loading = false;
    return this.getPromise();
  }

  @action.bound
  submit(value: string) {
    this.loading = true;
    this.promiseResolve!(value);
  }

  @action.bound
  cancel() {
    this.promiseReject!(new Error('Cancelled'));
    this.scheme = null;
  }

  @action.bound
  handleError(error: string) {
    this.error = error;
    this.loading = false;
    return this.getPromise();
  }

  @action.bound
  close() {
    this.scheme = null;
  }

  private getPromise() {
    const promise = new Promise((resolve, reject) => {
      this.promiseResolve = resolve;
      this.promiseReject = reject;
    });
    return promise;
  }
}

import React from 'react';
import { ISourceDefinition, UiConfig } from '@stores/sourceDefinitionsList';
import FormSchema, { Group } from '@components/common/formSchema';
import { AccountInfo } from '@stores/source/types';
import { FixMe } from '@utils/types';
import OAuth from './Oauth';
import AuthAccounts from './authAccounts';
import { CreateNewAccount } from './styles';
import { isOauthEnabledSource } from '@components/common/util/authUtil';
import useStores from '@stores/useStores';

interface ICloudAuthState {
  mode?: 'account' | 'form';
  hasAccounts?: boolean;
  modeChanged?: boolean;
}

type Settings = { role?: string };

type Schema = {
  auth: {
    type: string;
    secretFields: Array<string>;
    nameField: string;
    config?: Group[];
  };
  config?: Group[];
};

interface CloudSourceAuthProps {
  sourceDefinition: ISourceDefinition | undefined;
  onChange: (
    accountId: string,
    accountInfo: AccountInfo | undefined,
    isValid: boolean,
    goNext?: boolean,
  ) => void;
  initialSettings?: Settings;
  disabled?: boolean;
  selectedAccount?: string;
}
const CloudSourceAuth = ({
  onChange,
  disabled,
  initialSettings,
  sourceDefinition,
  selectedAccount,
}: CloudSourceAuthProps) => {
  const schema = sourceDefinition?.uiConfig as Schema;
  const authType = schema?.auth.type;
  const [OAuthSettings, setOauthSettings] = React.useState<{
    settings: Settings;
    isValid: boolean;
  }>({
    settings: {},
    isValid: false,
  });
  const { permissionsStore } = useStores();

  const [iCloudAuthState, setICloudAuthState] = React.useState<ICloudAuthState>({
    mode: 'account',
    modeChanged: false,
    hasAccounts: false,
  });
  const [newAccount, setNewAccount] = React.useState<string | null>(null);
  const handleOnClick = () => {
    if (iCloudAuthState.mode === 'form') {
      setICloudAuthState({
        ...iCloudAuthState,
        mode: 'account',
        modeChanged: true,
      });
    } else {
      setICloudAuthState({
        ...iCloudAuthState,
        mode: 'form',
        modeChanged: true,
      });
    }
    onChange('', undefined, false);
  };
  const onChangeOauthForm = React.useCallback(
    (settings: Settings, isValid: boolean) => {
      setOauthSettings({ settings, isValid });
      if (settings && Object.keys(settings).length > 0) {
        sessionStorage.setItem('OauthMetadata', JSON.stringify(settings));
      }
    },
    [onChange],
  );
  const onChangeForm = React.useCallback(
    (settings: Record<string, unknown>, isValid: boolean) => {
      const secretFields = schema.auth?.secretFields;
      const nameField = schema.auth?.nameField;
      let accountInfo = {
        options: {} as FixMe,
        secret: {} as FixMe,
        name: String(settings[nameField]),
        role: sourceDefinition?.name,
      };
      accountInfo = Object.keys(settings).reduce((accum, key) => {
        const currentValue = settings[key];
        if (secretFields && secretFields.includes(key)) {
          accum.secret[key] = currentValue;
        } else {
          accum.options[key] = currentValue;
        }
        return accum;
      }, accountInfo);
      onChange('', accountInfo, isValid);
    },
    [onChange],
  );
  const authConfig = schema.auth.config;
  return (
    <div data-testid="cloudsource-auth">
      {(iCloudAuthState.mode === 'account' && (
        <>
          <AuthAccounts
            key={newAccount}
            hasAccounts={(ifAccounts) =>
              setICloudAuthState({
                ...iCloudAuthState,
                hasAccounts: ifAccounts,
              })
            }
            selectedAccount={selectedAccount}
            role={sourceDefinition?.name || ''}
            onClick={(accountId) => {
              onChange(accountId, undefined, !!accountId);
            }}
          />
          {isOauthEnabledSource(authType) ? (
            <>
              {authConfig && (
                <FormSchema
                  schema={authConfig}
                  onChange={onChangeOauthForm}
                  initialSettings={initialSettings}
                />
              )}
              <OAuth
                provider={sourceDefinition?.options?.auth?.provider}
                disabled={!!authConfig && !OAuthSettings.isValid}
                params={OAuthSettings.settings}
                role={sourceDefinition?.name || ''}
                onComplete={(val, goNext) => {
                  setNewAccount(val);
                  onChange(val, undefined, !!val, goNext);
                }}
                title={sourceDefinition?.displayName || ''}
              />
            </>
          ) : null}
        </>
      )) || (
        <>
          {iCloudAuthState.mode === 'form' ? (
            <FormSchema
              schema={authConfig!}
              onChange={onChangeForm}
              initialSettings={initialSettings}
              disabled={!!disabled}
            />
          ) : null}
        </>
      )}
      {!isOauthEnabledSource(authType) && (
        <CreateNewAccount
          size="large"
          type="default"
          onClick={handleOnClick}
          disabled={!permissionsStore.hasPermission({ resourceType: 'workspace', action: 'edit' })}
        >
          {iCloudAuthState.mode === 'form'
            ? 'Use an existing account'
            : 'Create Credentials from Scratch'}
        </CreateNewAccount>
      )}
    </div>
  );
};

CloudSourceAuth.isOauth = (sourceDefinition: ISourceDefinition) => {
  const authType = (sourceDefinition.uiConfig as UiConfig)?.auth?.type;
  return authType !== undefined && isOauthEnabledSource(authType);
};

/* eslint-disable import/no-default-export */
export default CloudSourceAuth;

import { apiAuthCaller } from '@services/apiCaller';
import { action, observable, makeObservable } from 'mobx';
import { IUserStore } from './user';
import { IMessageStore } from './messages';
import { getApiErrorMessage } from '@components/common/util/util';
import isEqual from 'lodash/isEqual';

export const transformationDestinationDefaultConfig: ITransformationConfig = {
  enableForCloudMode: true,
  enableForDeviceMode: false,
  propagateEventsUntransformedOnErrorForDeviceMode: false,
  propagateEventsUntransformedOnErrorForCloudMode: false,
};

export const transformationDestinationOnlyDeviceModeDefaultConfig: ITransformationConfig = {
  enableForCloudMode: true,
  enableForDeviceMode: true,
  propagateEventsUntransformedOnErrorForDeviceMode: false,
  propagateEventsUntransformedOnErrorForCloudMode: false,
};

export interface ITransformationConfig {
  enableForCloudMode: boolean;
  enableForDeviceMode: boolean;
  propagateEventsUntransformedOnErrorForDeviceMode: boolean;
  propagateEventsUntransformedOnErrorForCloudMode: boolean;
}

export interface IDestinationTransformation {
  destinationId: string;
  transformationId: string;
  config?: ITransformationConfig;
}

export interface IDestinationTransformationStore extends IDestinationTransformation {
  update: (config: ITransformationConfig) => Promise<void>;
  resetConfig: () => Promise<void>;
}

type RootStore = {
  userStore: IUserStore;
  messagesStore: IMessageStore;
};

export class DestinationTransformationStore implements IDestinationTransformation {
  @observable destinationId: string;

  @observable transformationId: string;

  @observable config: ITransformationConfig;

  @observable rootStore: RootStore;

  constructor(destinationTransformation: IDestinationTransformation, rootStore: RootStore) {
    makeObservable(this);
    this.destinationId = destinationTransformation.destinationId;
    this.transformationId = destinationTransformation.transformationId;
    const {
      enableForCloudMode = transformationDestinationDefaultConfig.enableForCloudMode,
      enableForDeviceMode = transformationDestinationDefaultConfig.enableForDeviceMode,
      propagateEventsUntransformedOnErrorForDeviceMode = transformationDestinationDefaultConfig.propagateEventsUntransformedOnErrorForDeviceMode,
      propagateEventsUntransformedOnErrorForCloudMode = transformationDestinationDefaultConfig.propagateEventsUntransformedOnErrorForCloudMode,
    } = destinationTransformation.config || {};

    this.config = {
      ...transformationDestinationDefaultConfig,
      enableForCloudMode,
      enableForDeviceMode,
      propagateEventsUntransformedOnErrorForDeviceMode,
      propagateEventsUntransformedOnErrorForCloudMode,
    };

    this.rootStore = rootStore;
  }

  @action.bound
  public async update(config: ITransformationConfig, alert = true): Promise<void> {
    const { messagesStore } = this.rootStore;
    try {
      await apiAuthCaller().post(
        `/transformations/${this.transformationId}/updateDestinationConnectionConfig`,
        {
          destinationId: this.destinationId,
          config,
        },
      );
      this.config = config;
      if (alert) {
        messagesStore.showSuccessMessage('Transformation settings updated successfully');
      }
    } catch (error) {
      if (alert) {
        messagesStore.showErrorMessage(
          getApiErrorMessage(error, 'Failed to updated transformation settings'),
        );
      }
    }
  }

  @action.bound
  public async resetConfig(): Promise<void> {
    if (!isEqual(this.config, transformationDestinationDefaultConfig)) {
      this.update(transformationDestinationDefaultConfig, false);
    }
  }
}

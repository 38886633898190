import React from 'react';
import Svg from '@svg/index';
import { Subtext } from '@ui-library/typography';
import { Button } from '@ui-library/button';
import theme from '@css/theme';
import { PageContainer, CopyContainer, Hero } from './styles';

export const ErrorPage = () => (
  <PageContainer flexDirection="column" justifyContent="space-between" flexGrow alignItemsCenter>
    <CopyContainer
      gap=".5em"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Svg height="30" width="185" name="new-logo" />
      <Hero className="m-t-lg">Sorry about this...</Hero>
      <Subtext color={theme.token.colorTextDescription} className="m-t-sm m-b-lg">
        Something went wrong loading this page. Let’s try again.
      </Subtext>
      <Button onClick={() => window.location.reload()}>Reload</Button>
    </CopyContainer>
    <Svg name="error-page-bg-image" />
  </PageContainer>
);

import { ValidInvite } from '@auth/acceptInvite/hooks';
import { AwsUser } from '@auth/common/util';
import { Region } from '@components/common/constants';
import {
  apiAuthCaller,
  apiAuthCallerWithSkipMfaResponseInterceptor,
  apiCaller,
} from '@services/apiCaller';
import { FixMe } from '@utils/types';

interface RudderAnalytics {
  identify: (userId: string, traits: Record<string, unknown>) => void;
  track: (event: string, properties: Record<string, unknown>) => void;
  group: (groupId: string, traits: Record<string, unknown>) => void;
}
declare global {
  interface Window {
    rudderanalytics: RudderAnalytics | FixMe;
    LeaderLine: unknown;
  }
}

interface AuthSignInRequest {
  name: string;
  provider: string;
  freetrial?: boolean;
  invitation?: string;
}

interface AuthSignInResponse {
  firstLogin: boolean;
  email: string;
  name: string;
}

export interface CognitoTokens {
  idToken: string;
  refreshToken: string;
}

export interface ELoginResponse extends CognitoTokens {
  email: string;
}

interface IUserAttribute {
  name: string;
}

type ELoginCredentials = {
  email: string;
  password: string;
};

type SignupParams = {
  name: string;
  password: string;
  organization: string;
  token: string;
  freetrial: boolean;
};

type MfaType = 'NOMFA' | 'SMS';
const SendOtpRoute = '/v1/mfa/otp';

const Auth = {
  getTokens(userSession: AwsUser['signInUserSession']) {
    const idToken = userSession.getIdToken().getJwtToken();
    const refreshToken = userSession.getRefreshToken().getToken();

    return { idToken, refreshToken };
  },

  async signup({ name, password, organization, token, freetrial = false }: SignupParams) {
    await apiCaller().ignore400().post('/signup', {
      name,
      password,
      organization,
      token,
      verified: true,
      freetrial,
    });
  },

  async signupMail(
    email: string,
    freetrial: boolean,
    analytics?: { spz1?: string },
  ): Promise<{ token: string }> {
    const resp = await apiCaller().ignore400().post('/signupMail', {
      email,
      freetrial,
      analytics,
    });
    return resp.data;
  },

  async login(email: string, password: string) {
    const resp = await apiCaller()
      .ignore400()
      .post<CognitoTokens & { email?: string; status: string } & { scheme: 'smsotp' }>('/login', {
        email,
        password,
      });
    return resp.data;
  },

  async elogin(token: string, password: string): Promise<ELoginResponse> {
    const resp = await apiCaller().ignore400().post('/login', {
      token,
      password,
    });
    return resp.data;
  },

  async authSignin(idToken: string, body: AuthSignInRequest, analytics?: { spz1?: string }) {
    const { freetrial = true } = body;
    const resp = await apiAuthCaller({ idToken, nonWorkspaceRoute: true, region: Region.US })
      .ignore400()
      .post<AuthSignInResponse>('/authSignin', {
        ...body,
        freetrial,
        analytics,
      });
    return resp.data;
  },

  async forgotPassMail(email: string): Promise<{ statusCode: number }> {
    const resp = await apiCaller().post('/forgotPassMail', {
      email,
    });
    return resp.data;
  },

  async updateAttribute(idToken: string, attributeList: IUserAttribute): Promise<unknown> {
    const resp = await apiAuthCaller({ idToken, nonWorkspaceRoute: true, region: Region.US }).post(
      'updateAttribute',
      {
        attributeList,
      },
    );
    return resp.data;
  },

  async updatePassword(
    sessionData: CognitoTokens,
    oldPassword: string,
    newPassword: string,
  ): Promise<{ statusCode: number }> {
    const { idToken } = sessionData;
    const resp = await apiAuthCaller({ idToken, nonWorkspaceRoute: true, region: Region.US })
      .ignore400()
      .post('/changePassword', {
        oldPassword,
        newPassword,
      });
    return resp.data;
  },

  async confirmNewPassword(token: string, password: string) {
    await apiCaller().ignore400().post('/confirmNewPassword', {
      token,
      password,
    });
  },

  async currentSession(sessionData: CognitoTokens, email: string) {
    const { idToken, refreshToken } = sessionData;
    const resp = await apiAuthCaller({ idToken, nonWorkspaceRoute: true, region: Region.US }).post<
      { status: 'success' } & CognitoTokens
    >('/refreshToken', {
      refreshToken,
      email,
    });
    return resp.data;
  },

  /**
   * Send SMS OTP to a phone number, while the user doesn't have SMS MFA enabled already.
   */
  async sendOtpMfaDisabled(sessionData: CognitoTokens | undefined, phoneNumber: string) {
    if (!sessionData?.idToken) {
      return undefined;
    }

    return apiAuthCaller({
      idToken: sessionData.idToken,
      nonWorkspaceRoute: true,
      region: Region.US,
    }).post(SendOtpRoute, { phoneNumber });
  },

  /**
   * Send SMS OTP to a **new** phone number, while the user already has MFA set up.
   * This means user will have to submit MFA for their previous phone number in order to proceed
   */
  async sendOtpMfaEnabled(sessionData: CognitoTokens | undefined, phoneNumber: string) {
    if (!sessionData?.idToken) {
      return undefined;
    }

    return apiAuthCaller({
      idToken: sessionData.idToken,
      nonWorkspaceRoute: true,
      region: Region.US,
    }).put(SendOtpRoute, { phoneNumber });
  },

  /**
   * Send SMS OTP to a **new** phone number, while the user already has MFA set up.
   * This means user will have to submit MFA for their previous phone number in order to proceed
   */
  async resendOtpMfaEnabled(sessionData: CognitoTokens | undefined, phoneNumber: string) {
    if (!sessionData?.idToken) {
      return undefined;
    }

    return apiAuthCallerWithSkipMfaResponseInterceptor(sessionData.idToken).put(SendOtpRoute, {
      phoneNumber,
    });
  },

  async registerNewMfaPhone(
    sessionData: CognitoTokens | undefined,
    phoneNumber: string,
    code: string,
    credentials = {} as ELoginCredentials,
  ) {
    if (!sessionData?.idToken) {
      return undefined;
    }

    return apiAuthCaller({
      idToken: sessionData.idToken,
      nonWorkspaceRoute: true,
      region: Region.US,
    })
      .ignore400()
      .post('/v1/mfa/method', {
        phoneNumber,
        code,
        ...credentials,
      });
  },

  async disableMfa(sessionData: CognitoTokens | undefined) {
    if (!sessionData?.idToken) {
      return undefined;
    }

    return apiAuthCaller({
      idToken: sessionData.idToken,
      nonWorkspaceRoute: true,
      region: Region.US,
    }).put('/v1/mfa/method', { method: 'NOMFA' } as {
      method: MfaType;
    });
  },

  async getInviteDetails(token: string) {
    return apiCaller()
      .ignore400()
      .post<ValidInvite>('/validateInvite', { token })
      .then((res) => res.data);
  },

  async getIdpIdentifier(email: string) {
    return apiCaller().get('/v1/sso/idp', { params: { email } });
  },
};

/* eslint-disable import/no-default-export */
export default Auth;

import { Input as AntInput } from 'antd';
import React, { ReactNode, useState } from 'react';
import { InputProps } from 'antd/lib/input/Input';
import { TextAreaProps as TextAreaComponentProps } from 'antd/lib/input';
import { Flex } from '@ui-library/flex';
import { Subtext, LargeText } from '@ui-library/typography';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Tooltip } from '@ui-library/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '@css/theme';
import { RedAsterisk } from '@components/common/redAsterisk';

export { Search } from './search';
export { SearchV2 } from './search';

type Common = {
  isError?: boolean;
  errorMessage?: string;
  errorMessageClass?: string;
  customClass?: string;
  label?: string | ReactNode;
  hideIcon?: boolean;
  required?: boolean;
  block?: boolean;
};

export type InputComponentProps = InputProps & Common;

export const InputField = ({
  label,
  isError,
  errorMessageClass,
  customClass,
  hideIcon,
  errorMessage,
  required,
  block,
  size = 'large',
  ...rest
}: InputComponentProps) => (
  <Flex flexGrow={block} className={customClass} gap="0.25rem" flexDirection="column">
    {label && (
      <LargeText>
        {label}
        {required && <RedAsterisk />}
      </LargeText>
    )}
    <AntInput
      data-testid={typeof label === 'string' ? label : undefined}
      {...rest}
      size={size}
      status={isError ? 'error' : undefined}
    />

    {isError && (
      <Flex alignItemsCenter className={errorMessageClass}>
        {!hideIcon && (
          <FontAwesomeIcon
            icon={regular('circle-exclamation')}
            className="m-r-xs"
            color={theme.token.colorError}
          />
        )}
        <Subtext error={isError}>{errorMessage}</Subtext>
      </Flex>
    )}
  </Flex>
);

export type TextAreaProps = TextAreaComponentProps & Common;

export const TextArea = ({
  label,
  isError,
  errorMessageClass,
  hideIcon,
  required,
  errorMessage,
  size = 'large',
  ...rest
}: TextAreaProps) => (
  <Flex flexDirection="column" gap="0.25rem">
    {label && (
      <LargeText>
        {label}
        {required && <RedAsterisk />}
      </LargeText>
    )}
    <AntInput.TextArea
      data-testid={typeof label === 'string' ? label : undefined}
      {...rest}
      size={size}
      status={isError ? 'error' : undefined}
    />
    {isError && (
      <Flex alignItemsCenter className={errorMessageClass}>
        {!hideIcon && (
          <FontAwesomeIcon
            icon={regular('circle-exclamation')}
            className="m-r-xs"
            color={theme.token.colorError}
          />
        )}
        <Subtext error={isError}>{errorMessage}</Subtext>
      </Flex>
    )}
  </Flex>
);

export type PasswordInputProps = InputComponentProps & {
  isError?: boolean;
  errorMessage?: string;
  testId?: string;
};

export const PasswordInput = ({
  isError,
  errorMessage = '',
  testId,
  size = 'large',
  ...rest
}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputField
      {...rest}
      size={size}
      data-testid={testId}
      autoComplete="new-password"
      type={showPassword ? 'text' : 'password'}
      isError={isError || errorMessage.length > 0}
      errorMessage={errorMessage}
      suffix={
        <Tooltip title={showPassword ? 'Hide' : 'Show'} trigger="hover" placement="top">
          <FontAwesomeIcon
            data-testid="eye"
            onClick={() => setShowPassword((x) => !x)}
            icon={showPassword ? regular('eye-slash') : regular('eye')}
          />
        </Tooltip>
      }
    />
  );
};

export type { InputProps } from 'antd';
export { Input, InputNumber } from 'antd';

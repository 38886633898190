import { Region } from '@components/common/constants';
import { experiment, FeatureFlagKeys } from '@lib/experiment';
import { apiAuthCaller } from '@services/apiCaller';
import { IRootStore } from '@stores/index';
import { action, makeObservable, observable } from 'mobx';
import { isAfter } from '@utils/dateUtils';
import { getAllDestinationFeatureFlags } from '@lib/destinationExperiment';

export type ClientFeature =
  | 'AUDIT_LOGS'
  | 'DISABLE_REPORTING'
  | 'TEAMS'
  | 'ETL'
  | 'BILLING'
  | 'BILLING_PAGE'
  | 'USAGE'
  | 'USAGE_PAGE'
  | 'ENFORCE_MFA'
  | 'MFA'
  | 'PAT'
  | 'DATA_MANAGEMENT'
  | 'AUDIENCES'
  | 'LIVE_EVENTS_DISCLAIMER'
  | 'USER_DELETION'
  | 'SCIM'
  | 'DATA_RESIDENCY'
  | 'EVENT_REPLAY'
  | 'DATA_RETENTION_STORAGE'
  | 'SOURCE_GATEWAY_DUMPS'
  | 'DATA_RETENTION_GATEWAY_DUMPS'
  | 'UPGRADE_BUTTON'
  | 'WORKSPACE_TOKEN'
  | 'DATA_PLANE_RATE_LIMIT'
  | 'COMMUNITY_SLACK'
  | 'GRAFANA'
  | 'QUICKSTART'
  | 'NOTIFICATION_SUBSCRIPTIONS'
  | 'PERMISSIONS'
  | 'MODELS'
  | 'TRACKING_PLAN'
  | 'DEVICE_MODE_TRANSFORMATIONS'
  | 'WAREHOUSE_SSH_TUNNELLING'
  | 'PLANS_PAGE'
  | 'SAVE_TRANSFORMATION_LIVE_EVENTS'
  | 'SHOW_CONTACT_US'
  | 'PROFILES'
  | 'SHOW_QUALIFIED_EXPERIENCE'
  | 'CHAT_WITH_SPECIALIST_IN_ONBOARDING'
  | 'FREE_TIER_PLAN'
  | 'SHOW_UPGRADE_FOR_WORKSPACES'
  | 'NEW_ROLES_FLOW'
  | 'DATA_RETENTION'
  | 'GEOLOCATION_ENRICHMENT'
  | 'DATA_CATALOG'
  | 'DATA_CATALOG_METRICS'
  | 'RETL_SYNC_TREND_GRAPH'
  | 'EVENT_AUDIT_API'
  | 'HIDE_ETL'
  | 'CREDENTIALS'
  | 'MTU_USAGE_TAB'
  | 'MTU_BILLING_ENABLED'
  | 'SERVICE_ACCESS_TOKENS'
  | 'DATA_CATALOG_BUNDLES'
  | 'DATA_CATALOG_CUSTOM_TYPES';

type ServerFeatures = {
  BILLING_TAB: boolean;
  PERMISSIONS: boolean;
  MFA: boolean;
  SHOW_UPGRADE: boolean;
  MODELS: boolean;
  EVENT_REPLAY: boolean;
  SHOW_JOIN_SLACK: boolean;
  DATA_RETENTION_GATEWAY_DUMPS: boolean;
  REVERSE_ETL: boolean;
  SHOW_TRIAL_LIMIT: boolean;
  GRAFANA_LOGIN: boolean;
  DEVICE_MODE_TRANSFORMATIONS: boolean;
  NOTIFICATIONS: boolean;
  AUDIT_LOGS: boolean;
  SHOW_WORKSPACE_TOKEN: boolean;
  DATA_RETENTION_STORAGE: boolean;
  DATA_RETENTION: boolean;
  QUICKSTART: boolean;
  USAGE_TAB: boolean;
  PLANS_TAB: boolean;
  ENFORCE_MFA: boolean;
  SHOW_LIVE_EVENTS_DISCLAIMER: boolean;
  OAUTH_DELETE: boolean;
  SCIM_PROVISIONING: boolean;
  BAA_AGREEMENT: boolean;
  PYTHON_TRANSFORMATIONS: boolean;
  ETL: boolean;
  AUDIENCES: boolean;
  PROFILES: boolean | undefined;
  SHOW_LIVE_EVENTS_LIMIT: boolean;
  BLOCK_ACCESS_ON_OVERAGE: boolean;
  PAT: boolean;
  DISABLE_REPORTING: boolean;
  SOURCE_GATEWAY_DUMPS: boolean;
  ETL_SOURCES_LIMIT: number;
  EVENT_STREAM_LIMIT: number;
  MAX_TEAM_COUNT: number | undefined;
  TRANSFORMATIONS_LIMIT: number;
  REVERSE_ETL_SOURCES_LIMIT: number;
  WAREHOUSE_TUNNELLING: boolean;
  SHOW_CONTACT_US: boolean;
  WORKSPACE_ROLES: 'BASIC' | 'EXPANDED' | undefined;
  CHAT_WITH_SPECIALIST_IN_ONBOARDING: boolean;
  PLAN_TYPE: 'freeTier';
  WORKSPACES_LIMIT: number;
  MULTI_WORKSPACES: boolean;
  TP_SOURCE_CONNECTIONS_LIMIT: number;
  GEOLOCATION_ENRICHMENT: boolean;
  RETL_SYNC_TREND_GRAPH: boolean;
  EVENT_AUDIT_API: boolean;
  RETL_CONNECTIONS_LIMIT: number | undefined;
  CREDENTIALS: boolean | undefined;
  MTU_USAGE_TAB: boolean;
  BILLING_USAGE_TYPE: 'EVENTS' | 'MTU';
  TRACKING_PLANS_LIMIT: number;
  TRACKING_PLAN_EVENTS_LIMIT: number;
  TP_SOURCE_DROP_CONFIG: boolean;
  SERVICE_ACCESS_TOKENS: boolean | undefined;
  HIGH_GRANULARITY_WH_SYNC: boolean | undefined;
  DATA_CATALOG_BUNDLES: boolean;
  DATA_CATALOG_CUSTOM_TYPES: boolean;
};

type IFeatures = { [k in keyof ServerFeatures]?: ServerFeatures[k] };

type FeatureFlags = {
  [key: ClientFeature | FeatureFlagKeys | string]: boolean;
};

export interface IFeaturesStore {
  getFeatureConfiguration: (name: keyof ServerFeatures) => IFeatures[typeof name];
  getFeatures: () => void;
  clearFeatures: () => void;
  firstLoad: boolean;
  getCombinedFeatureFlagsList: (destinationType?: string) => FeatureFlags;
  has: (name: ClientFeature) => boolean;
}

export class FeaturesStore implements IFeaturesStore {
  @observable protected rootStore: IRootStore;

  @observable private features: IFeatures = {};

  @observable public firstLoad = false;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  public getFeatureConfiguration(name: keyof ServerFeatures) {
    return this.features[name];
  }

  @action.bound
  public clearFeatures() {
    this.features = {};
  }

  public async getFeatures() {
    const { workspaceStore } = this.rootStore;
    try {
      const response = await apiAuthCaller().get<IFeatures>('/featuresForPlan');
      this.features = response.data;
      workspaceStore.transformationsLimit = this.features.TRANSFORMATIONS_LIMIT as
        | number
        | undefined;
      if (this.features.ETL) {
        workspaceStore.cloudSourcesLimit = this.features.ETL_SOURCES_LIMIT as number | undefined;
      }
      if (this.features.REVERSE_ETL) {
        workspaceStore.warehouseActionsLimit = this.features.REVERSE_ETL_SOURCES_LIMIT as
          | undefined
          | number;
      }
    } catch (err) {}
    this.firstLoad = true;
  }

  // Get a list of all server, client and amplitude feature flags
  getCombinedFeatureFlagsList(destinationType?: string): FeatureFlags {
    const { userStore, workspaceStore, organizationStore, permissionsStore } = this.rootStore;
    const dataResidency = workspaceStore.dataplanes.length > 1;
    const trackingPlanEnabled = Boolean(this.features?.TP_SOURCE_CONNECTIONS_LIMIT);

    // TODO: this is recalculated every to has is invoked, this could be cached instead of getting generated everytime

    return {
      AUDIT_LOGS:
        permissionsStore.hasPermission({ resourceType: 'workspace', action: 'edit' }) &&
        !!this.features.AUDIT_LOGS,
      DISABLE_REPORTING: !!this.features.DISABLE_REPORTING,
      TEAMS: this.features.MAX_TEAM_COUNT !== 0,
      BILLING: !!this.features.BILLING_TAB,
      BILLING_PAGE: userStore.isOrgAdmin && !!this.features.BILLING_TAB,
      USAGE: !!this.features.USAGE_TAB,
      USAGE_PAGE: userStore.isOrgAdmin && !!this.features.USAGE_TAB,
      MFA: userStore.canSetupMfa,
      ENFORCE_MFA: !!this.features.ENFORCE_MFA && userStore.isOrgAdmin,
      PAT: !!this.features.PAT,
      PLANS_PAGE: !!this.features.PLANS_TAB && userStore.isOrgAdmin,
      DATA_MANAGEMENT:
        (!!this.features.DATA_RETENTION || trackingPlanEnabled) && userStore.isOrgAdmin,
      DATA_RETENTION: !!this.features.DATA_RETENTION,
      AUDIENCES: !!this.features.AUDIENCES,
      SCIM: !!this.features.SCIM_PROVISIONING,
      LIVE_EVENTS_DISCLAIMER: !this.features.SHOW_LIVE_EVENTS_DISCLAIMER, // Feature is named incorrectly in backend
      USER_DELETION: !!this.features.OAUTH_DELETE,
      DATA_RESIDENCY: dataResidency,
      EVENT_REPLAY: !!this.features.EVENT_REPLAY,
      DATA_RETENTION_STORAGE: !!this.features.DATA_RETENTION_STORAGE,
      SOURCE_GATEWAY_DUMPS: !!this.features.SOURCE_GATEWAY_DUMPS,
      DATA_RETENTION_GATEWAY_DUMPS: !!this.features.DATA_RETENTION_GATEWAY_DUMPS,
      UPGRADE_BUTTON: !!this.features.SHOW_UPGRADE,
      WORKSPACE_TOKEN: userStore.isOrgAdmin && !!this.features.SHOW_WORKSPACE_TOKEN,
      DATA_PLANE_RATE_LIMIT: !!this.features.SHOW_TRIAL_LIMIT,
      COMMUNITY_SLACK: !!this.features.SHOW_JOIN_SLACK,
      GRAFANA:
        !!this.features.GRAFANA_LOGIN && !!organizationStore.grafanaURL && userStore.isOrgAdmin,
      QUICKSTART:
        !!this.features.QUICKSTART && new Date(workspaceStore.createdAt) <= new Date('08/18/2022'),
      NOTIFICATION_SUBSCRIPTIONS: !!this.features.NOTIFICATIONS,
      SHOW_CONTACT_US: !!this.features.SHOW_CONTACT_US,
      PERMISSIONS: !!this.features.PERMISSIONS && userStore.isOrgAdmin,
      MODELS: !!this.features.MODELS,
      TRACKING_PLAN: trackingPlanEnabled,
      WAREHOUSE_SSH_TUNNELLING: !!this.features.WAREHOUSE_TUNNELLING,
      SAVE_TRANSFORMATION_LIVE_EVENTS: !dataResidency && workspaceStore.defaultRegion === Region.US,
      DEVICE_MODE_TRANSFORMATIONS:
        workspaceStore.enableDeviceModeTransformations ||
        (!!this.features.DEVICE_MODE_TRANSFORMATIONS &&
          experiment.isFeatureEnabled(FeatureFlagKeys.deviceModeTransformations)),
      PROFILES:
        !!this.features.PROFILES && experiment.isFeatureEnabled(FeatureFlagKeys.rs360EnableWht),
      SHOW_QUALIFIED_EXPERIENCE: !!this.features.BILLING_TAB,
      CHAT_WITH_SPECIALIST_IN_ONBOARDING: !!this.features.CHAT_WITH_SPECIALIST_IN_ONBOARDING,
      FREE_TIER_PLAN: this.features.PLAN_TYPE === 'freeTier',
      SHOW_UPGRADE_FOR_WORKSPACES: this.features.WORKSPACES_LIMIT === 1,
      NEW_ROLES_FLOW: !!this.features.MULTI_WORKSPACES,
      ETL: !!this.features.ETL,
      GEOLOCATION_ENRICHMENT:
        !!this.features.GEOLOCATION_ENRICHMENT &&
        experiment.isFeatureEnabled(FeatureFlagKeys.geoLocationEnrichment),
      DATA_CATALOG: trackingPlanEnabled,
      DATA_CATALOG_METRICS: experiment.isFeatureEnabled(FeatureFlagKeys.enableDataCatalogMetrics),
      RETL_SYNC_TREND_GRAPH: !!this.features.RETL_SYNC_TREND_GRAPH,
      EVENT_AUDIT_API: !!this.features.EVENT_AUDIT_API,
      HIDE_ETL:
        !this.features.ETL ||
        (experiment.isFeatureEnabled(FeatureFlagKeys.hideETL) &&
          isAfter(
            workspaceStore.createdAt,
            experiment.getFeatureFlag(FeatureFlagKeys.hideETL).payload.date,
          )),
      CREDENTIALS: !!this.features.CREDENTIALS,
      MTU_USAGE_TAB: !!this.features.MTU_USAGE_TAB,
      MTU_BILLING_ENABLED: this.features.BILLING_USAGE_TYPE === 'MTU',
      SERVICE_ACCESS_TOKENS: !!this.features.SERVICE_ACCESS_TOKENS,
      HIGH_GRANULARITY_WH_SYNC: !!this.features.HIGH_GRANULARITY_WH_SYNC,
      DATA_CATALOG_CUSTOM_TYPES: experiment.isFeatureEnabled(
        FeatureFlagKeys.enableDataCatalogCustomTypes,
      ),
      DATA_CATALOG_BUNDLES: experiment.isFeatureEnabled(FeatureFlagKeys.enableDataCatalogBundles),

      // Used by our form builders prerequisites to conditionally show/hide form fields
      ...experiment.getAllFeatureFlags(),
      ...(destinationType && getAllDestinationFeatureFlags(destinationType)),
    };
  }

  has(name: ClientFeature): boolean {
    const clientFeatureAndExperiments = this.getCombinedFeatureFlagsList();
    return Boolean(clientFeatureAndExperiments[name]);
  }
}

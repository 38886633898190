import { apiAuthCaller } from '@services/apiCaller';
import { IRootStore } from '@stores/index';
import { action, observable, makeObservable } from 'mobx';
import { ILibraryStore, LibraryStore } from '@stores/library';
import { AxiosResponse } from 'axios';

export type LanguageType = 'javascript' | 'pythonfaas';

interface CreatePayload {
  name: string;
  description: string;
  code: string;
  language: LanguageType;
}

export interface ILibrariesListStore {
  libraries: ILibraryStore[];
  standardLibraries: ILibraryStore[];
  rootStore: IRootStore;
  getLibraries(): Promise<void>;
  getStandardLibraries(): Promise<void>;
  getById(libraryId: string): ILibraryStore | undefined;
  getStandardLibraryById(libraryId: string): ILibraryStore | undefined;
  createLibrary(library: CreatePayload): Promise<ILibraryStore>;
  updateLibrary(libraryId: string, library: CreatePayload): void;
}

export class LibrariesListStore implements ILibrariesListStore {
  @observable public libraries: ILibraryStore[] = [];

  @observable public standardLibraries: ILibraryStore[] = [];

  @observable public rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  public async getLibraries() {
    try {
      const res = await apiAuthCaller().get('/transformationLibraries');
      this.libraries = res.data.libraries.map(
        (library: ILibraryStore) => new LibraryStore(library, this.rootStore),
      );
    } catch (err) {}
  }

  @action.bound
  public async getStandardLibraries() {
    try {
      const res: AxiosResponse<{ rudderstackLibraries: ILibraryStore[] }> =
        await apiAuthCaller().get('/rudderstackTransformationLibraries');
      this.standardLibraries = res.data.rudderstackLibraries.map(
        (library: ILibraryStore) => new LibraryStore(library, this.rootStore),
      );
    } catch (err) {}
  }

  @action.bound
  public async createLibrary(library: CreatePayload) {
    const { userStore } = this.rootStore;
    const { selectedWorkspaceId } = userStore;
    const publish = true;
    const res = await apiAuthCaller()
      .ignore400()
      .post(`/transformationLibraries?publish=${publish}`, {
        name: library.name,
        description: library.description,
        code: library.code,
        language: library.language,
        workspaceId: selectedWorkspaceId,
      });
    const savedLibrary = res.data;
    this.libraries.push(new LibraryStore(savedLibrary, this.rootStore));
    return savedLibrary;
  }

  @action.bound
  public async updateLibrary(libraryId: string, library: CreatePayload) {
    const existingLibrary = this.getById(libraryId);
    return existingLibrary?.update(
      library.name,
      library.description,
      library.code,
      library.language,
    );
  }

  @action.bound
  public getById(libraryId: string): ILibraryStore | undefined {
    return this.libraries.find((t) => t.id === libraryId);
  }

  @action.bound
  public getStandardLibraryById(libraryId: string): ILibraryStore | undefined {
    return this.standardLibraries.find((t) => t.id === libraryId);
  }
}

import React, { useCallback, useEffect, useState } from 'react';
import SourceSettingsRow from '@components/sources/source-view/source-settings/source-settings-row';
import { ISourceDataStore } from '@stores/sourceDataStore';
import { Button } from '@ui-library/button';
import CredentialPopup from './credential-popup';
import { observer } from 'mobx-react';

// TODO: fix below implementation to exclude redundant config, sourceDef props.
interface ICredentialsProps {
  config: unknown;
  sourceDef?: { name: string };
  source?: ISourceDataStore;
  isAdmin: boolean;
}

function Credentials(props: ICredentialsProps) {
  const [showCredentialPopup, setShowCredentialPopup] = useState(false);
  const [username, setUsername] = useState('');

  const getCredentialInfo = useCallback(async () => {
    const { accountInfo } = await props.source!.getCredentialInfo(true);
    setUsername(accountInfo?.name || '');
  }, [setUsername]);

  useEffect(() => {
    getCredentialInfo();
  }, [props.config, getCredentialInfo]);

  const description = `You are currently connected to ${props.sourceDef?.name} account ${username}`;
  let modal = null;
  if (showCredentialPopup) {
    modal = <CredentialPopup onCancel={() => setShowCredentialPopup(false)} />;
  }
  return (
    <>
      {modal}
      <SourceSettingsRow
        name="Credentials"
        description={description}
        cta={
          props.isAdmin ? (
            <Button
              type="default"
              onClick={() => setShowCredentialPopup(true)}
              name="Edit Credentials"
            />
          ) : null
        }
      />
    </>
  );
}

/* eslint-disable import/no-default-export */
export default observer(Credentials);

import styled from 'styled-components';

export const Container = styled.div`
  width: 475px;
  ${({ className, theme }) =>
    className?.includes('single-select_container-variant-badge') &&
    `
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: flex-end;
          flex-wrap: wrap;
          gap: ${theme.token.controlPaddingHorizontalSM}px;
          
          .label {
            font-weight: normal;
          }
    `};
`;

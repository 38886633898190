import * as React from 'react';
import { FormConfig, FormSecrets } from '@components/common/formSchema/types';
import FormSchema, { Group } from '@components/common/formSchema';
import { ISourceDefinition, UiConfig } from '@stores/sourceDefinitionsList';
import { MetaData } from '@components/common/dynamicSelect';
import { SourceConfig } from '@stores/source/types';

interface ICloudSettingsProps {
  sourceDefinition: ISourceDefinition;
  onSettingsChange: (value: FormConfig, isValid: boolean) => void;
  initialSettings?: SourceConfig;
  secretConfig?: FormSecrets;
  disabled?: boolean;
  metadata?: MetaData;
}

export type SingerResourceConfig = { destination_sync_mode?: string; sync_mode?: string };
export type SingerResource = { id: string; config: SingerResourceConfig };
export type Resource = SingerResource | { id: string; role: string } | string;

type Schema = {
  auth?: {
    type: string;
    secretFields: Array<string>;
    nameField: string;
    config?: Group[];
  };
  config?: Group[];
};

/* eslint-disable import/no-default-export */
export default class CloudSourceSettings extends React.Component<ICloudSettingsProps> {
  static configExists = (sourceDef: Pick<ISourceDefinition, 'name' | 'uiConfig'>) =>
    !!(sourceDef.uiConfig as UiConfig)?.config?.length;

  render() {
    const {
      sourceDefinition,
      onSettingsChange,
      initialSettings,
      disabled,
      secretConfig,
      metadata,
    } = this.props;

    const schema = sourceDefinition.uiConfig as Schema;
    return (
      <div className="p-t-lg p-b-lg configure-data__container">
        <FormSchema
          metadata={metadata}
          schema={schema.config || []}
          onChange={onSettingsChange}
          initialSettings={initialSettings as FormConfig}
          disabled={!!disabled}
          secretConfig={secretConfig}
        />
      </div>
    );
  }
}

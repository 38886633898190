import dayjs from 'dayjs';
import { EventStatParams } from '@components/sources/source-view/source-events/hooks';
import { IDestinationStore } from '@stores/destination';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import { areAllSourcesInDeviceMode } from '../utils';
import { DestinationConfig } from '@stores/destination/index';
import { Source } from '@stores/source/types';
import { getSourceTypeForConnectionMode } from '../configurationV2/formComponents/util';
import { LatencyStatParams } from './store/types';

export const TIME_FILTERS = [
  {
    value: 'day',
    label: 'Past 1 day',
  },
  {
    value: 'week',
    label: 'Past 7 days',
  },
  {
    value: 'month',
    label: 'Past 30 days',
  },
];

export const ALL_SOURCES = { value: 'all', label: 'All sources' };

const map: Record<number, string> = {
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  410: '410 Gone',
  422: 'Unprocessable Entity',
  429: 'Too Many Requests',
  500: 'Internal Server Error',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
};

export const getErrorMessage = (statusCode: number) => map[statusCode] || statusCode;

export const getFilterParams = (sourceFilter: string, timeFilter: string) => ({
  sourceId: sourceFilter === ALL_SOURCES.value ? undefined : sourceFilter,
  ...getTimeParams(timeFilter),
});

export const getTimeParams = (timeFilter: string) => {
  let start = '';
  let aggregationMinutes: EventStatParams['aggregationMinutes'] = 10;
  let step: LatencyStatParams['step'] = '1h';

  if (timeFilter === TIME_FILTERS[0].value) {
    start = dayjs.utc().subtract(1, 'days').startOf('hour').toISOString();
    aggregationMinutes = 10;
    step = '1h';
  } else if (timeFilter === TIME_FILTERS[1]?.value) {
    start = dayjs.utc().subtract(7, 'days').startOf('hour').toISOString();
    aggregationMinutes = 60;
    step = '1h';
  } else if (timeFilter === TIME_FILTERS[2]?.value) {
    start = dayjs.utc().subtract(30, 'days').startOf('day').toISOString();
    aggregationMinutes = 1440; // 60 * 24
    step = '1d';
  }
  return {
    start,
    aggregationMinutes,
    step,
  };
};

export const formatGraphLabel = (timeFilter: string) => {
  if (timeFilter === TIME_FILTERS[0].value) {
    return 'HH:mm';
  }
  if (timeFilter === TIME_FILTERS[1]?.value) {
    return 'MMM D, HH:mm';
  }
  return 'MMM D';
};

export const truncate = (event: unknown) => {
  const str = isString(event) ? event : JSON.stringify(event);
  if (str.length > 30) {
    return `${str.substring(0, 30)}...`;
  }
  return str;
};

type ConnectionMode = 'cloud' | 'device' | 'hybrid';

interface ConnectionModes {
  overall: 'allDevice' | 'mixed' | 'allCloud';
  sources: Record<string, ConnectionMode>;
}

export const calloutMessageTexts = {
  hybrid:
    'This connection is in hybrid mode. The graph only shows events that are passing in cloud mode.',
  device:
    'Event graph data is unavailable since this connection is in device mode. The graph is only available in cloud mode.',
  allDevice:
    'Event graph data is unavailable since all connections are in device mode. The graph is only available in cloud mode.',
  mixed:
    'Few connections are in hybrid or device mode. The graph only shows events that are passing in cloud mode.',
  cloud: '',
  allCloud: '',
};

export const getConnectionMode = (sourceId: string, connectionModes: ConnectionModes) => {
  if (sourceId === ALL_SOURCES.value) {
    return connectionModes.overall;
  }
  return connectionModes.sources[sourceId];
};

const getAllConnectionModes = (
  config: DestinationConfig,
  sources: Source[],
): Record<string, ConnectionMode> => {
  const { connectionMode } = config;

  const allConnectionModes = sources.reduce(
    (acc, source) => {
      const sourceType = getSourceTypeForConnectionMode(source.sourceDef);
      const key = source.id;
      if (isObject(connectionMode) && connectionMode?.[sourceType]) {
        acc[key] = connectionMode[sourceType] as ConnectionMode;
      } else {
        acc[key] = (config?.[`${sourceType}-connectionMode`] as ConnectionMode) || 'cloud';
      }
      return acc;
    },
    {} as Record<string, ConnectionMode>,
  );

  return allConnectionModes;
};

export const getSourcesConnectionMode = (destination: IDestinationStore): ConnectionModes => {
  const { config, sources } = destination;
  const allConnectionModes = getAllConnectionModes(config, sources);
  const isAllInDeviceMode = areAllSourcesInDeviceMode(config, sources);
  const isAllInCloudMode = Object.values(allConnectionModes).every((mode) => mode === 'cloud');

  let overallMode: ConnectionModes['overall'] = 'mixed';
  if (isAllInDeviceMode) {
    overallMode = 'allDevice';
  } else if (isAllInCloudMode) {
    overallMode = 'allCloud';
  }

  const connectionModes: ConnectionModes = {
    overall: overallMode,
    sources: allConnectionModes,
  };

  return connectionModes;
};

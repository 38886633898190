import { Region } from '@components/common/constants';

export const BACKEND_BASE_URL = {
  [Region.US]:
    process.env.REACT_APP_BACKEND_URL ||
    process.env.REACT_APP_BACKEND_URL_US ||
    'http://localhost:5050',
  [Region.EU]: process.env.REACT_APP_BACKEND_URL_EU || 'http://localhost:5051',
};
export const WEB_APP_URL = process.env.REACT_APP_WEB_URL || 'http://localhost:3000';

export const ACTIVATION_HOST_URL = {
  [Region.US]: process.env.REACT_APP_US_ACTIVATION_API || 'http://localhost:5110',
  [Region.EU]:
    process.env.REACT_APP_EU_ACTIVATION_API ||
    process.env.REACT_APP_US_ACTIVATION_API ||
    'http://localhost:5110',
};

export const disableCognito = process.env.REACT_APP_DISABLE_COGNITO === 'true';
export const disableAnalytics = process.env.REACT_APP_DISABLE_ANALYTICS === 'true' || false;
export const disableSSO = process.env.REACT_APP_DISABLE_SSO === 'true';

export const DATADOG_APP_ID = process.env.REACT_APP_DATADOG_APP_ID || '';
export const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_VERSION || '';
export const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';
export const AMPLITUDE_DEPLOYMENT_KEY = process.env.REACT_APP_AMPLITUDE_DEPLOYMENT_KEY || '';
export const AMPLITUDE_PROXY_URL = process.env.REACT_APP_AMPLITUDE_PROXY_URL;

export const TABLE_VIRTUALIZATION_THRESHOLD = parseInt(
  process.env.REACT_APP_TABLE_VIRTUALIZATION_THRESHOLD || '80',
  10,
);

export const awsConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION || '',
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID || '',
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID || '',
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN_ENDPOINT || '',
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: `${WEB_APP_URL}/signup`,
      redirectSignOut: `${WEB_APP_URL}/signup`,
      responseType: 'code',
    },
  },
};

export const SLACK_APP_CLIENT_ID =
  process.env.REACT_APP_SLACK_APP_CLIENT_ID || '611722233910.6013277261767';

export const LIVE_EVENT_CAPTURE_TIME_IN_MIN = parseInt(
  process.env.REACT_APP_LIVE_EVENT_CAPTURE_TIME_IN_MIN || '5',
  10,
);

export const ASYNC_API_MAX_POLL_INTERVAL = parseInt(
  process.env.REACT_APP_ASYNC_API_MAX_POLL_INTERVAL || '10000',
  10,
);

export const ASYNC_API_TIMEOUT = parseInt(process.env.REACT_APP_ASYNC_API_TIMEOUT || '660000', 10);

export const LOCAL_FEATURE_FLAGS = {
  VDM_NEXT: process.env.REACT_APP_VDM_NEXT || 'false',
  RS360_ENABLE_WHT: process.env.REACT_APP_RS360_ENABLE_WHT || 'false',
  ENABLE_PROFILES_UIV1: process.env.REACT_APP_ENABLE_PROFILES_UIV1 || 'false',
  ENABLE_COHORT_AUDIENCE: process.env.REACT_APP_ENABLE_COHORT_AUDIENCE || 'false',
};

import { Flex } from '@ui-library/flex';
import React from 'react';
import classes from './modal.module.scss';

interface IModalProps {
  show: boolean;
  children: React.ReactNode;
  modalClosed: ((e: React.SyntheticEvent) => void) | undefined;
}

/* eslint-disable import/no-default-export */
export default ({ show, children, modalClosed }: IModalProps) => (
  <Flex
    centered
    className={classes.Modal}
    style={{
      transform: show ? 'translateY(0)' : 'translateY(-100vh)',
      opacity: show ? 1 : 0,
    }}
    data-testid="skeleton"
    onClick={modalClosed}
  >
    {show ? children : null}
  </Flex>
);

enum HttpStatusCode {
  Ok = 200,

  NoContent = 204,

  BadRequest = 400,

  Unauthorized = 401,

  RequestForbidden = 403,

  NotFound = 404,

  InternalServerError = 500,
}

/* eslint-disable import/no-default-export */
export default HttpStatusCode;

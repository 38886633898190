import moment from 'moment';

type DurationType = 'years' | 'months' | 'days' | 'hours' | 'minutes' | 'seconds';

const readableDurationSeconds = (
  seconds: number,
  type: 'default' | 'compact' = 'default',
): string => {
  const momentData = (moment.duration(seconds, 'seconds') as { _data?: Record<string, unknown> })
    ._data;
  let readableDuration = '';
  let durationtypesDisplayed = 0;
  const isCompact = type === 'compact';

  const compactMap: Record<DurationType, string> = {
    years: 'yr',
    months: 'mo',
    days: 'd',
    hours: 'h',
    minutes: 'm',
    seconds: 's',
  };

  Object.keys(compactMap).every((durationType): boolean => {
    const z = momentData![durationType];
    if (z) {
      if (isCompact) {
        readableDuration += `${z}${compactMap[durationType as DurationType]} `;
      } else {
        readableDuration += `${z} ${
          z === 1 ? durationType.substring(0, durationType.length - 1) : durationType
        } `;
      }
      durationtypesDisplayed++;
      // restrict to 2 two types of duration shown
      // eg 1 day 13 minutes or 5 minutes 12 seconds
      if (durationtypesDisplayed >= 2) {
        return false;
      }
    }
    return true;
  });
  if (readableDuration === '') {
    return isCompact ? '0s' : '0 seconds';
  }

  return readableDuration.trim();
};

/* eslint-disable import/no-default-export */
export default readableDurationSeconds;

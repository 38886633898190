import React from 'react';
import { apiAuthCaller } from '@services/apiCaller';
import { inject, observer } from 'mobx-react';
import { UserStore } from '@stores/user';
import { MessagesStore } from '@stores/messages';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CatchErr } from '@utils/types';
import { RESOURCE_TYPES, SESSION_STORAGE_KEYS } from '@components/common/constants';
import { RudderCategory } from '@components/common/types';
import { GoogleButton, XeroButton } from './button';
import { AuthOptions } from '@stores/sourceDefinitionsList';
import { CreateAccountButton } from '@components/common';
import { IPermissions } from '@stores/permissions';

const failedMessage = 'Authentication failed. Please try again';
const windowClosed = 'window_closed';

interface QLQueryVars {
  role: string;
}

interface OAuthProps extends QLQueryVars {
  onComplete?: (secretId: string, goNext?: boolean) => void;
  onError?: (errorMessage: string) => void;
  title: string;
  disabled?: boolean;
  params: {
    role?: string;
  };
  rudderCategory?: RudderCategory;
  provider?: AuthOptions['provider'];
}

interface OAuthState {
  secretId?: string;
  loading?: boolean;
}

interface StoreProps {
  userStore?: UserStore;
  messagesStore?: MessagesStore;
  permissionsStore?: IPermissions;
}

@inject('userStore', 'messagesStore', 'permissionsStore')
@observer
class OAuth extends React.PureComponent<OAuthProps & StoreProps, OAuthState> {
  /*
   * Exports the account id from the redirect url (/success/${accountID})
   */
  static exportAccountIdFromUrl = (url: string) => url.replace(/.*success\/([^#]+)(#.*)?/, '$1');

  constructor(props: OAuthProps & StoreProps) {
    super(props);
    this.state = {
      secretId: '',
    };
  }

  onError = (message: string) => {
    if (this.props.onError) {
      this.props.onError(message);
    }
    this.setState({
      loading: false,
    });
  };

  startOAuth = () => {
    const { messagesStore, role, params, rudderCategory } = this.props;
    const { role: roleFromParams, ...restParams } = params || {};
    this.setState({
      loading: true,
    });
    const oAuthWindow = window.open('', '_blank') as Window;
    oAuthWindow.document.write('Loading...');

    const rudderCatPath = rudderCategory ? `/${rudderCategory}` : '';
    apiAuthCaller({ nonWorkspaceRoute: true })
      .get(`/oauth${rudderCatPath}/${(roleFromParams || role).toLowerCase()}/url`, {
        params: { ...restParams, rudderCategory },
      })
      .then((resp) => {
        oAuthWindow.location.href = resp.data.url;
      })
      .catch((err) => {
        oAuthWindow.close();
        messagesStore?.showErrorMessage(err.message);
      });
    const timer = setInterval(() => {
      const oAuthMetaKey = SESSION_STORAGE_KEYS[rudderCategory || RESOURCE_TYPES.SOURCE];
      let errorMessage = '';
      try {
        if ((!oAuthWindow.opener || oAuthWindow.opener.closed === true) && !this.state.secretId) {
          throw new Error(windowClosed);
        }
        if (oAuthWindow.document.URL.includes('success')) {
          window.clearInterval(timer);
          sessionStorage.removeItem(oAuthMetaKey);
          oAuthWindow.close();
          const secretId = OAuth.exportAccountIdFromUrl(oAuthWindow.document.URL);
          this.setState({
            secretId,
            loading: false,
          });
          return;
        }
        if (oAuthWindow.document.URL.includes('error')) {
          const url = new URL(oAuthWindow.document.URL);
          errorMessage = url.searchParams.get('message') as string;
          sessionStorage.removeItem(oAuthMetaKey);
          oAuthWindow.close();
          throw new Error(errorMessage);
        }
      } catch (err: CatchErr) {
        if (err.message === windowClosed || !!errorMessage) {
          sessionStorage.removeItem(oAuthMetaKey);
          this.onError(failedMessage);
          messagesStore!.showErrorMessage(errorMessage || failedMessage);
          window.clearInterval(timer);
        }
      }
    }, 600);
  };

  onComplete = async (secretId: string) => {
    const { onComplete } = this.props;
    if (onComplete) {
      onComplete(secretId, true);
    }
  };

  componentDidUpdate(_prevProps: OAuthProps, prevState: OAuthState) {
    if (prevState.secretId !== this.state.secretId) {
      this.onComplete(this.state.secretId as string);
    }
  }

  getButton() {
    const { title, disabled, provider, permissionsStore } = this.props;
    const { loading } = this.state;
    const buttonProps = {
      onClick: this.startOAuth,
      disabled:
        disabled ||
        loading ||
        !permissionsStore?.hasPermission({ resourceType: 'workspace', action: 'edit' }),
      name: `Connect with ${title}`,
    };
    if (provider === 'Google') {
      return <GoogleButton {...buttonProps} />;
    }
    if (provider === 'Xero') {
      return <XeroButton {...buttonProps} />;
    }
    return (
      <CreateAccountButton
        type="default"
        icon={<FontAwesomeIcon icon={regular('plus')} />}
        {...buttonProps}
      />
    );
  }

  render() {
    return this.getButton();
  }
}

/* eslint-disable import/no-default-export */
export default OAuth as React.ComponentType<OAuthProps>;

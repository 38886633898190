/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { lazy, MutableRefObject, Suspense } from 'react';
import { ISourceDefinition } from '@stores/sourceDefinitionsList';
import { Skeleton } from 'antd';
import { ISource } from '@stores/source';
import { AccountInfo, SourceConfig } from '@stores/source/types';
import { ValidationState } from '@components/common/types';
import { ISourceDataStore } from '@stores/sourceDataStore';
import { DIRECTORY_TABS } from '@components/common/constants';
import removeNullValues from '@utils/removeNullValues';
import { apiAuthCaller } from '@services/apiCaller';
import CloudSourceSettings, { Resource } from '../source/cloudSource/cloudSourceSettings';

const SelectResources = lazy(
  () =>
    import(
      /* webpackChunkName: "sourceFactory.selectResources" */ '../source/cloudSource/cloudSourceResources'
    ),
);

const CloudSourceSyncDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "sources.cloud-source-details" */ '@components/sources/sourceDetails/cloudSourceDetails/syncDetails'
    ),
);

export type DirectoryTab = (typeof DIRECTORY_TABS)[keyof typeof DIRECTORY_TABS];

export interface CredentialsAuth {
  handleChange: (
    accountId: string,
    accountInfo: AccountInfo | undefined,
    isValid?: boolean | undefined,
    submit?: boolean | undefined,
  ) => Promise<void>;
  source?: ISource;
  isValidationDisabled: boolean;
  isOldFlow?: boolean;
  isValidationLoading: ValidationState;
  checkValid: (result?: ValidationState, isAccountDeleted?: boolean) => void;
  ref: MutableRefObject<HTMLDivElement | null>;
  credentials: {
    accountId?: string;
    accountInfo?: { options?: Record<string, unknown>; secret?: Record<string, unknown> };
  };
}

export interface AccountValidator {
  source: ISourceDataStore | undefined;
  isNewAccountName: (name: string) => Promise<boolean>;
  isOauthSource: (sourceDef: ISourceDefinition) => boolean;
  accountInfo: AccountInfo;
  setCredentials: (credentials: {
    accountId: string;
    accountInfo: unknown;
    valid: boolean;
    updated: boolean;
  }) => void;
  showErrorMessage: ((msg: string) => void) | undefined;
  accountId?: string;
  valid?: boolean;
}

export interface ResourcesComponentProps {
  source: ISourceDataStore;
  disabled?: boolean;
  onChange: (config: SourceConfig) => void;
  onSingleResourceOrLess: () => void;
}

export interface ConfigurationComponentProps {
  source: ISourceDataStore;
  disabled?: boolean;
  onChange: (config: SourceConfig, isValid: boolean) => void;
}

interface CronSyncSchedule {
  validate: (secondDiff: number, thirdDiff: number) => boolean;
  minFreq: number;
}

export abstract class SourceCategory {
  abstract getSourceType(sourceDef: { name: string }): string;

  abstract isNewConnectionAllowed(source: { destinations: unknown[] }): boolean;

  abstract getTabs(source: ISource): string[];

  abstract getAccountsEndpoint(sourceDef?: string): string;

  abstract handleSetup(
    canCreateCloud: boolean,
    canCreateWarehouse: boolean,
    create: () => void,
  ): boolean;

  abstract getAuthComponent(props: CredentialsAuth): JSX.Element;

  abstract validateAccount(props: AccountValidator): Promise<string | undefined>;

  abstract isPartOf(tab: DirectoryTab): boolean;

  hideFromAllowedDestConnections(): boolean {
    return false;
  }

  getCredentialSettings(sourceDataStore: ISourceDataStore, isAdmin: boolean): JSX.Element | null {
    return null;
  }

  isWarehouse(): boolean {
    return false;
  }

  showTablePrefixSettings(): boolean {
    return false;
  }

  showSyncScheduleSettings(): boolean {
    return false;
  }

  showGeolocationSettings(): boolean {
    return false;
  }

  enableCredentialValidations(): boolean {
    return false;
  }

  isReportsViewEnabled(): boolean {
    return false;
  }

  isSyncDelayEnabled(): boolean {
    return false;
  }

  isDestinationAllowedForConnection(destination: { sources: unknown[] }) {
    return true;
  }

  transformSourceConfig(config: SourceConfig): Record<string, unknown> {
    const {
      accountInfo,
      customResources,
      credentials,
      accessToken,
      refreshToken,
      rudderAccountId,
      ...updatedConfig
    } = config;
    delete updatedConfig?.role;
    return removeNullValues({
      ...updatedConfig,
    });
  }

  transformResourcesConfig(config: SourceConfig): Record<string, unknown> {
    const {
      accountInfo,
      customResources,
      credentials,
      accessToken,
      refreshToken,
      rudderAccountId,
      ...updatedConfig
    } = config;
    const newResources = (customResources || []).reduce((accum: string[], rsrc: { id: string }) => {
      if (!updatedConfig.resources!.includes(rsrc.id)) {
        accum.push(rsrc.id);
      }
      return accum;
    }, []);
    delete updatedConfig?.role;
    return removeNullValues({
      ...updatedConfig,
      resources: [...updatedConfig.resources!, ...newResources],
    });
  }

  getResourcesComponent({
    source,
    disabled,
    onChange,
    onSingleResourceOrLess,
  }: ResourcesComponentProps): JSX.Element {
    return (
      <Suspense fallback={<Skeleton active />}>
        <SelectResources
          metadata={{
            params: {
              accountId: source?.config?.accountId || source?.config?.rudderAccountId,
            },
          }}
          // when editing resources will be in form of string only.
          selectedResources={source?.config?.resources?.map((elem: Resource) => {
            if (typeof elem === 'string') {
              return elem;
            }
            return elem.id;
          })}
          selectedColumns={source?.config?.resourcesColumns}
          customResources={source?.config?.customResources}
          onSingleResourceOrLess={onSingleResourceOrLess}
          sourceId={source?.id}
          sourceDefinition={source!.sourceDef}
          onChange={(resources, customResources, resourcesColumns) =>
            onChange({ resources, customResources, resourcesColumns })
          }
          disabled={disabled}
        />
      </Suspense>
    );
  }

  getConfigurationComponent(props: ConfigurationComponentProps): JSX.Element {
    const { source, onChange, disabled } = props;
    const { sourceDef, config } = source;
    return (
      <CloudSourceSettings
        metadata={{
          params: {
            accountId: config?.accountId || config?.rudderAccountId,
          },
        }}
        initialSettings={config}
        onSettingsChange={onChange}
        sourceDefinition={sourceDef}
        disabled={disabled}
      />
    );
  }

  hasIncompatibleSyncMode(srcSyncBehaviours?: string[], destSyncBehaviours?: string[]): boolean {
    return false;
  }

  async validateSource(
    config: SourceConfig,
    source: { id?: string; config?: SourceConfig; sourceDef: ISourceDefinition },
  ): Promise<{ valid: boolean; reason: string }> {
    if (!source.config) {
      throw new Error('Source config is Required');
    }
    const url = `/cloudSources/info/roles/${source.sourceDef.name}/source/validate`;
    const response = await apiAuthCaller({ timeout: 3000 }).post(url, {
      ...source.config,
      ...config,
    });
    return response.data;
  }

  getSyncs(source: ISource, hasSourcePermission: boolean): JSX.Element | null {
    return (
      <Suspense fallback={<Skeleton loading />}>
        <CloudSourceSyncDetails source={source} hasSourcePermission={hasSourcePermission} />
      </Suspense>
    );
  }

  getBasicSyncScheduleOptions(): { name: string; value: number }[] {
    const options = [
      {
        name: 'Every 24 hours',
        value: 1440,
      },
      {
        name: 'Every 12 hours',
        value: 720,
      },
      {
        name: 'Every 6 hours',
        value: 360,
      },
      {
        name: 'Every 3 hours',
        value: 180,
      },
      {
        name: 'Every 1 hour',
        value: 60,
      },
      {
        name: 'Every 30 minutes',
        value: 30,
      },
      {
        name: 'Every 15 minutes',
        value: 15,
      },
    ];
    return options;
  }

  getCronSyncSchedule(): CronSyncSchedule {
    const minFreq = 15;
    return {
      validate: (secondDiff: number, thirdDiff: number) =>
        secondDiff < minFreq || thirdDiff < minFreq,
      minFreq,
    };
  }
}

import { getApiErrorMessage } from '@components/common/util/util';
import { apiAuthCaller } from '@services/apiCaller';
import { action, observable, makeObservable } from 'mobx';

import { IRootStore } from '@stores/index';
import { isDestNameUnique } from '@components/destinations/destination-view/utils';
import { DESTINATION_DEFINITION_CATEGORY } from '@components/common/constants';
import { DestinationStore, IDestination, IDestinationStore } from './destination';
import DraftDestination from './draftDestination';
import { getPermissions } from './util';
import { Analytics } from '@lib/analytics';
import { PendingTask } from './user';

interface CreatePayload {
  name: string;
  allowDuplicateNames?: boolean;
  definitionName: string;
  destinationDefinitionId: string;
  config: {
    webhookUrl?: string;
  };
  enabled?: boolean;
}

export type ServerDestination = Pick<
  IDestination,
  | 'id'
  | 'name'
  | 'enabled'
  | 'config'
  | 'secretConfig'
  | 'destinationDefinition'
  | 'regions'
  | 'destinationTransformations'
> & {
  permissions?: { id: string; isLocked: boolean };
};

export interface IDestinationsListStore {
  destinations: IDestinationStore[];
  firstLoad: boolean;
  draftDestination: DraftDestination;
  rootStore: IRootStore;
  setDestinations(destinations: IDestinationStore[]): void;
  getDestinations(): void;
  createDestination(
    dest: CreatePayload,
    onSuccess?: (dest: Pick<IDestinationStore, 'id' | 'enabled' | 'toggleEnabled'>) => void,
    onError?: () => void,
  ): Promise<Pick<IDestination, 'id' | 'destinationDefinition'> | undefined>;
  cloneDestination(
    name: string,
    id: string,
    onSuccess: (dest: Pick<IDestination, 'id'>) => void,
    onError: () => void,
  ): void;
  createDestinationConnections(
    dest: Pick<IDestination, 'id'>,
    sourceIds: string[],
    onSuccess?: () => void,
    sqlModelId?: string,
    onError?: () => void,
  ): Promise<boolean>;
  deleteDestination(dest: IDestination, onSuccess: () => void, onError: () => void): void;
  destinationById(destId: string): IDestinationStore | undefined;
  hasWareHouseDestinations(): boolean;
  isDestNameUnique: (newName: string, currentName?: string) => boolean;
  createDatabricksDestination(workspaceId: string): Promise<string | undefined>;
  reset: () => void;
}

export class DestinationsListStore implements IDestinationsListStore {
  @observable public destinations: IDestinationStore[] = [];

  @observable public rootStore: IRootStore;

  @observable public firstLoad = false;

  @observable public draftDestination: DraftDestination;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.draftDestination = new DraftDestination();
  }

  @action.bound
  public setDestinations(destinations: IDestinationStore[]): void {
    this.destinations = destinations;
  }

  @action.bound
  public async getDestinations() {
    const { selectedWorkspaceId } = this.rootStore.userStore;
    try {
      const res = await apiAuthCaller().get<{ destinations: ServerDestination[] }>(
        `/destinations?workspace_id=${selectedWorkspaceId}`,
      );
      this.destinations = res.data.destinations.map(
        (destination) => new DestinationStore(destination, this.rootStore),
      );
      this.rootStore.permissionsStore.registerResource(getPermissions(res.data.destinations));
      this.firstLoad = true;
    } catch (err) {}
  }

  @action.bound
  public async createDestination(
    dest: CreatePayload,
    onSuccess?: (dest: Pick<IDestinationStore, 'id' | 'enabled' | 'toggleEnabled'>) => void,
    onError?: () => void,
  ) {
    const { messagesStore, workspaceStore } = this.rootStore;
    try {
      const { data } = await apiAuthCaller().post<ServerDestination>('/destinations/', {
        name: dest.name,
        destinationDefinitionId: dest.destinationDefinitionId,
        config: dest.config,
        allowDuplicateNames: dest.allowDuplicateNames,
        enabled: dest.enabled ?? true,
      });
      const res = this.postDestinationCreation(data, workspaceStore.id);
      if (onSuccess) {
        onSuccess(res);
      } else {
        messagesStore.showSuccessMessage('Destination created successfully');
      }
      return res;
    } catch (err) {
      messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to create destination'));
      onError?.();
      return undefined;
    }
  }

  private postDestinationCreation(response: ServerDestination, workspaceId: string) {
    const orgId = this.rootStore.userStore.workspaces.find((ws) => ws.id === workspaceId)
      ?.organizationId;
    const savedDest = new DestinationStore(response, this.rootStore);
    Analytics.track({
      event: 'destinationCreated',
      properties: {
        destinationId: savedDest.id,
        destinationType: savedDest.destinationDefinition.name,
        workspaceId,
        organizationId: orgId,
      },
    });
    if (workspaceId === this.rootStore.workspaceStore.id) {
      this.destinations.push(savedDest);
      this.rootStore.permissionsStore.addNewResource(savedDest.id);
    }
    return savedDest;
  }

  createDatabricksDestination(workspaceId: string) {
    const { userStore, messagesStore } = this.rootStore;
    return apiAuthCaller({ nonWorkspaceRoute: true })
      .post<ServerDestination>(`/workspaces/${workspaceId}/partners/databricks`)
      .then((res) => {
        messagesStore.showSuccessMessage(
          'Congrats. Your Databricks destination has been setup. Double-check your configurations, and add a source to complete your connection',
        );
        this.postDestinationCreation(res.data, workspaceId);
        userStore.removePendingTask(PendingTask.databricks);
        return res.data.id;
      })
      .catch((err) => {
        messagesStore.showErrorMessage(
          getApiErrorMessage(err, 'Failed to provision Databricks destination'),
        );
        return undefined;
      });
  }

  @action.bound
  public async cloneDestination(
    name: string,
    id: string,
    onSuccess: (dest: Pick<IDestination, 'id'>) => void,
    onError: () => void,
  ) {
    const { messagesStore, permissionsStore } = this.rootStore;

    try {
      const r = await apiAuthCaller().post<ServerDestination>(`/destinations/${id}/clone`, {
        name,
      });
      this.destinations.push(new DestinationStore(r.data, this.rootStore));
      Analytics.track({
        event: 'destinationCreated',
        properties: {
          destinationId: r.data.id,
          destinationType: r.data.destinationDefinition.name,
        },
      });
      permissionsStore.addNewResource(r.data.id);
      messagesStore.showSuccessMessage('Destination cloned successfully');
      onSuccess(r.data);
    } catch (e) {
      messagesStore.showErrorMessage('Failed to clone destination');
      onError();
    }
  }

  @action.bound
  public async createDestinationConnections(
    dest: IDestination,
    sourceIds: string[],
    onSuccess: () => void,
    sqlModelId?: string,
    onError?: () => void,
  ) {
    const { messagesStore, connectionsStore, modelListStore, organizationStore } = this.rootStore;
    try {
      await apiAuthCaller().post(`/destinations/${dest.id}/connect`, {
        sourceIds,
        sqlModelId,
      });

      if (sqlModelId) {
        const sqlModel = modelListStore.getModelById(sqlModelId);
        sqlModel?.setSourceIds([...sqlModel.sourceIds, sourceIds[0]]);
      }
      // update connections store
      sourceIds.forEach((_, key) => {
        if (!connectionsStore.connections[sourceIds[key]]) {
          connectionsStore.connections[sourceIds[key]] = [];
        }
        connectionsStore.connections[sourceIds[key]].push(dest.id);
        Analytics.group(organizationStore.id, { orgConnectionCreated: true });
      });
      onSuccess?.();
      return true;
    } catch (err) {
      onError?.();
      messagesStore.showErrorMessage(
        getApiErrorMessage(err, 'Failed to connect source to destination'),
      );
      return false;
    }
  }

  @action.bound
  public async deleteDestination(
    destination: IDestinationStore,
    onSuccess: () => void,
    onError: () => void,
  ) {
    const { messagesStore, transformationsListStore } = this.rootStore;
    try {
      await apiAuthCaller().delete(`/destinations/${destination.id}`);
      onSuccess();
      messagesStore.showSuccessMessage('Destination deleted successfully');
      this.destinations = this.destinations.filter(
        (existingDest) => existingDest.id !== destination.id,
      );
      transformationsListStore.getTransformations();
      Analytics.track({
        event: 'destinationDeleted',
        properties: {
          destinationId: destination.id,
          destinationType: destination.destinationDefinition.name,
        },
      });
    } catch (err) {
      onError();
      messagesStore.showErrorMessage('Failed to delete destination');
    }
  }

  @action.bound
  public destinationById(destId: string): IDestinationStore | undefined {
    return this.destinations.find((dest) => dest.id === destId);
  }

  public hasWareHouseDestinations() {
    return this.destinations.some(
      (destination: IDestinationStore) =>
        destination?.destinationDefinition.category === DESTINATION_DEFINITION_CATEGORY.WAREHOUSE,
    );
  }

  @action.bound
  public isDestNameUnique(newName: string, currentName = '') {
    return isDestNameUnique(newName, currentName, this.destinations);
  }

  @action.bound
  public reset() {
    this.setDestinations([]);
    this.firstLoad = false;
  }
}

import { FixMe } from './types';

const removeNullValues = (obj: { [i: string]: FixMe }) => {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  return Object.keys(obj).reduce((newObject: FixMe, keyName: string) => {
    const currentValue = obj[keyName];
    if (currentValue || currentValue === 0 || currentValue === false) {
      if (Array.isArray(currentValue)) {
        newObject[keyName] = currentValue.map((value) => {
          if (value && typeof value === 'object') {
            return removeNullValues(value);
          }
          return value;
        });
      } else if (typeof currentValue === 'object') {
        newObject[keyName] = removeNullValues(currentValue);
      } else {
        newObject[keyName] = currentValue;
      }
    }
    return newObject;
  }, {});
};

/* eslint-disable import/no-default-export */
export default removeNullValues;

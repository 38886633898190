import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useStores from '@stores/useStores';

export const with404Redirect =
  <T extends object>(
    Component: React.ComponentType<T>,
    { exists, redirectPath }: { exists: (id: string) => boolean; redirectPath: string },
  ) =>
  (props: T) => {
    const { messagesStore } = useStores();
    const history = useHistory();
    const { id } = useParams<{ id?: string }>() ?? {};

    if (id && !exists(id)) {
      history.replace(redirectPath);
      messagesStore.showInfoMessage('Resource not found');
      return null;
    }

    return <Component {...props} />;
  };

import styled, { css, DefaultTheme } from 'styled-components';
import { Alert } from 'antd';

const tipStyles = css`
  border-color: ${({ theme }) => theme.token.colorPrimaryBorder};

  .ant-alert-icon {
    color: ${({ theme }) => theme.token.colorPrimaryBorder};
  }
`;

const tokenMap: Record<string, keyof DefaultTheme['token']> = {
  error: 'colorErrorBg',
  warning: 'colorWarningBg',
  success: 'colorSuccessBg',
  info: 'colorInfoBg',
  type: 'colorPrimaryBg',
};

export const StyledAlert = styled(Alert)<{
  customVariant?: 'tip';
}>`
  ${(props) => props.customVariant === 'tip' && tipStyles}

  border-width: 0 0 0 3px;
  background: ${({ type = 'info', theme }) => theme.token[tokenMap[type]]};
`;

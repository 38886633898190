import {
  DESTINATION_DEFINITION_CATEGORY,
  SOURCE_DEFINITION_CATEGORY,
  RUDDER_SCOPES,
} from '@components/common/constants';
import { FilterSpecProps } from './audiences/types';

export enum WarehouseSourceOrigin {
  TABLE = 'table',
  MODEL = 'model',
  AUDIENCE = 'audience',
  PROFILES_TABLE = 'profiles-table',
  PROFILES_AUDIENCE = 'profiles-audience',
}

export type SourceDefinitionCategory =
  | (typeof SOURCE_DEFINITION_CATEGORY)[keyof typeof SOURCE_DEFINITION_CATEGORY]
  | 'webhook'
  | null;

export type DestinationDefinitionCategory =
  | (typeof DESTINATION_DEFINITION_CATEGORY)[keyof typeof DESTINATION_DEFINITION_CATEGORY]
  | null;

export type RudderScope = keyof typeof RUDDER_SCOPES;

export interface WarehouseConfigConstant {
  key: string;
  value: string;
}

export interface ISourceInfo {
  accountId?: string;
  table?: string;
  schema?: string;
  bucketName?: string;
  objectPrefix?: string;
  sqlModelId?: string;
  sql?: string;
  filterSpec?: FilterSpecProps;
  audienceId?: string;
}

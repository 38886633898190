import * as React from 'react';
import styled from 'styled-components';
import { TimePicker } from '@ui-library/datepicker';
import { ITimePickerOptions } from './timePicker';
import { FixMe } from '@utils/types';
import { Flex } from '@ui-library/flex';
import dayjs, { Dayjs } from 'dayjs';
import { LargeText } from '@ui-library/typography';

const Row = styled(Flex)`
  width: 100%;
  justify-content: 'flex-start';
`;

const Column = styled.div`
  flex: 1;
  input {
    width: 100%;
  }
`;

interface ITimeRangePickerProps {
  field: FixMe;
  options?: ITimePickerOptions;
  onChange: (label: string, value: FixMe) => void;
  disabled: boolean;
}

/* eslint-disable import/no-default-export */
export default class TimeRangePicker extends React.Component<ITimeRangePickerProps, FixMe> {
  constructor(props: FixMe) {
    super(props);
    const time = props.field.value;
    this.state = {
      startTime: time.startTime,
      endTime: time.endTime,
      startTimeString: '',
      endTimeString: '',
    };
  }

  componentDidMount() {
    const { field } = this.props;
    const startTime = field.default ? field.default[field.startTime.value] : '';
    const endTime = field.default ? field.default[field.endTime.value] : '';
    this.setState({ startTime, endTime });
  }

  startTimechange = (time: Dayjs | null, timeString: string) => {
    const { field, onChange } = this.props;
    const startTimeString = timeString;
    const { endTimeString } = this.state;
    this.setState({ startTime: time, startTimeString: timeString });
    onChange(field.value, {
      [field.startTime.value]: startTimeString,
      [field.endTime.value]: endTimeString,
    });
  };

  endTimechange = (time: Dayjs | null, timeString: string) => {
    const { field, onChange } = this.props;
    const { startTimeString } = this.state;
    const endTimeString = timeString;
    this.setState({ endTime: time, endTimeString: timeString });
    onChange(field.value, {
      [field.startTime.value]: startTimeString,
      [field.endTime.value]: endTimeString,
    });
  };

  public render() {
    const { field, options, disabled } = this.props;
    return (
      <div className="p-t-sm" data-testid="timeRangePicker">
        <LargeText className="m-b-xs">
          {field.label}
          {field.required && ' *'}
        </LargeText>
        <Row>
          <Column className="p-r-sm">{field.startTime.label}</Column>
          <Column className="p-l-sm">{field.endTime.label}</Column>
        </Row>
        <Row>
          <Column className="p-r-sm">
            <TimePicker
              className="full-width"
              disabled={disabled}
              onChange={this.startTimechange}
              value={this.state.startTime ? dayjs(this.state.startTime, 'HH:mm') : undefined}
              minuteStep={options?.minuteStep || 1}
              format="HH:mm"
              size="large"
              inputReadOnly
            />
          </Column>
          <Column className="p-l-sm">
            <TimePicker
              data-testid={field.label}
              className="full-width"
              disabled={disabled}
              onChange={this.endTimechange}
              value={this.state.endTime ? dayjs(this.state.endTime, 'HH:mm') : undefined}
              minuteStep={options?.minuteStep || 1}
              format="HH:mm"
              size="large"
              inputReadOnly
            />
          </Column>
        </Row>
      </div>
    );
  }
}

import theme from '@css/theme';
import { Flex } from '@ui-library/flex';
import { BaseText } from '@ui-library/typography';
import styled from 'styled-components';

export const Container = styled(Flex)`
  margin-bottom: ${theme.token.margin}px;
`;

export const CredentialsRow = styled(BaseText)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const AccountCredentialsOption = styled(Flex)<{
  isSelected: boolean;
  disabled?: boolean;
  isFullWidth?: boolean;
  isEditFlow?: boolean;
}>`
  ${({ disabled, isSelected }) =>
    disabled
      ? `
    pointer-events: none;
    background: ${theme.token.colorBgContainerDisabled};
    border: ${theme.token.borderRadiusXS}px solid ${theme.token.colorBorderTertiary};
    `
      : `
    background: ${isSelected ? theme.token.colorPrimaryBg : 'white'};
    border:  ${
      isSelected
        ? `${theme.token.borderRadiusXS}px solid ${theme.token.colorPrimaryBorder}`
        : `1px solid ${theme.token.colorBorder}`
    };
    `}
  padding: ${theme.token.paddingLG}px ${theme.token.padding}px ${theme.token.paddingLG}px ${theme
    .token.padding}px;
  border-radius: ${theme.token.borderRadiusSM}px;
  cursor: pointer;
  & svg {
    color: ${({ isSelected, disabled }) => {
      if (disabled) {
        return theme.token.colorTextDisabled;
      }
      return isSelected ? theme.token.colorPrimaryBorder : theme.token.colorTextDescription;
    }};
  }
  &:hover {
    border: ${({ isSelected, theme }) =>
      isSelected
        ? `${theme.token.borderRadiusXS}px solid ${theme.token.colorPrimaryBorder}`
        : `1px solid ${theme.token.colorPrimaryBorder}`};
  }
`;

import { ITPConfig, ITPSettingsType, TPGlobalConfig } from '@stores/source';
import { ServerSource, ServerSourceTPConfig } from './types';

export const serverSourceToStore = (server: ServerSource) => {
  const { dgSourceTrackingPlanConfig, geoEnrichmentEnabled, ...rest } = server;
  if (!dgSourceTrackingPlanConfig) {
    return {
      ...rest,
      isGeoEnrichmentEnabled: geoEnrichmentEnabled!,
      trackingPlanConfig: undefined,
    };
  }
  const { config, trackingPlanId = '' } = dgSourceTrackingPlanConfig;
  return {
    ...rest,
    isGeoEnrichmentEnabled: geoEnrichmentEnabled!,
    trackingPlanConfig: getTrackingPlanConfig(config, trackingPlanId),
  };
};

const getTrackingPlanConfig = (config: ServerSourceTPConfig, trackingPlanId: string) => {
  const configuration = (Object.keys(ITPSettingsType) as ITPSettingsType[]).reduce((acc, key) => {
    const globalClonf = config.global;
    const currentConfig = config[key];
    const allowUnplannedEvents =
      currentConfig?.allowUnplannedEvents || globalClonf?.allowUnplannedEvents;
    const unplannedProperties =
      currentConfig?.unplannedProperties || globalClonf?.unplannedProperties;
    const anyOtherViolation = currentConfig?.anyOtherViolation || globalClonf?.anyOtherViolation;
    const propagateValidationErrors =
      currentConfig?.propagateValidationErrors || globalClonf?.propagateValidationErrors;
    const sendViolatedEventsTo =
      currentConfig?.sendViolatedEventsTo || globalClonf?.sendViolatedEventsTo;

    acc[key] = {
      dropUnplannedEvents: allowUnplannedEvents === 'false',
      dropUnplannedProperties: unplannedProperties === 'drop',
      dropOtherViolation: anyOtherViolation === 'drop',
      propagateValidationErrors: propagateValidationErrors === 'true',
      sendViolatedEventsTo: sendViolatedEventsTo || '',
    };
    return acc;
  }, {} as ITPConfig);

  return {
    config: configuration,
    trackingPlanId,
  };
};

export const transformTPConfigToServer = (config: TPGlobalConfig) => {
  const {
    dropUnplannedProperties,
    dropOtherViolation,
    propagateValidationErrors,
    dropUnplannedEvents,
  } = config;
  const mappedConfig = {} as Record<string, string>;

  if (typeof propagateValidationErrors !== 'undefined') {
    mappedConfig.propagateValidationErrors = propagateValidationErrors ? 'true' : 'false';
  }
  if (typeof dropUnplannedEvents !== 'undefined') {
    mappedConfig.allowUnplannedEvents = dropUnplannedEvents ? 'false' : 'true';
  }
  if (typeof dropUnplannedProperties !== 'undefined') {
    mappedConfig.unplannedProperties = dropUnplannedProperties ? 'drop' : 'forward';
  }
  if (typeof dropOtherViolation !== 'undefined') {
    mappedConfig.anyOtherViolation = dropOtherViolation ? 'drop' : 'forward';
  }
  return mappedConfig;
};

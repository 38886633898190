import { Path } from '@components/routes';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const paths = ['/sso', '/forgotPassword', Path.LOGIN, '/elogin'];
const id = 'rs-meta-robots';

/* eslint-disable import/no-default-export */
export default () => {
  const location = useLocation();

  useEffect(() => {
    /*
      This code will work only if the web crawler supports client side JS code
      and not just the HTML.
      https://developers.google.com/search/docs/advanced/javascript/javascript-seo-basics
    */
    if (paths.includes(location.pathname)) {
      const meta = document.createElement('meta');
      meta.id = id;
      meta.name = 'robots';
      meta.content = 'noindex';
      document.head.appendChild(meta);
    }
    return () => {
      const meta = document.getElementById(id);
      if (meta && meta.parentNode) {
        meta.parentNode.removeChild(meta);
      }
    };
  });

  return null;
};

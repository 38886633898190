import { apiAuthCaller } from '@services/apiCaller';
import { ASYNC_API_MAX_POLL_INTERVAL, ASYNC_API_TIMEOUT } from '@config';
import {
  ApiErrorResponse,
  ApiResponse,
  ApiSuccessResponse,
  formatError,
  getRespData,
} from '@components/sources/sourceResponse';

type SubmitResult = {
  requestId: string;
};

enum AsyncApiState {
  Processing = 'Processing',
  Completed = 'Completed',
}

type ApiResponseDataTypes<T> = {
  Processing: {
    state: AsyncApiState.Processing;
  };
  CompletedWithData: {
    state: AsyncApiState.Completed;
    result: ApiSuccessResponse<T>;
  };
  CompletedWithError: {
    state: AsyncApiState.Completed;
    result: ApiErrorResponse;
  };
};

type ResultResponse<T> = ApiResponse<
  | ApiResponseDataTypes<T>['Processing']
  | ApiResponseDataTypes<T>['CompletedWithData']
  | ApiResponseDataTypes<T>['CompletedWithError']
>;

type AsyncApiConfig = {
  submitUrl: string;
  submitRequestData: object;
  resultUrl: string;
};

const INITIAL_POLL_INTERVAL = 1000;
const EXPONENTIAL_POLL_MULTIPLIER = 2;

export const asyncApiHandler = <T>(config: AsyncApiConfig) => {
  const { submitUrl, submitRequestData, resultUrl } = config;
  let timer: NodeJS.Timeout | null = null;
  let canStartPolling = false;
  let delay = INITIAL_POLL_INTERVAL;
  let timeElapsed = 0;

  const throwTimeoutError = () => {
    throw {
      response: {
        data: {
          errorDetails: {
            code: 'timeout',
            message: 'query took too long to execute',
            reason: 'exceeded timeout',
          },
        },
      },
    };
  };

  const pollForResult = async <T>(requestId: string, resultUrl: string) => {
    if (timeElapsed > ASYNC_API_TIMEOUT) {
      throwTimeoutError();
    }
    timeElapsed += delay;

    const { data } = await apiAuthCaller().get<ResultResponse<T>>(`${resultUrl}/${requestId}`);

    const resp = getRespData(data);
    const { state } = resp;
    if (state === AsyncApiState.Completed) {
      const { result } = resp;
      return getRespData(result);
    }
    return new Promise<T>((resolve, reject) => {
      delay = Math.min(delay * EXPONENTIAL_POLL_MULTIPLIER, ASYNC_API_MAX_POLL_INTERVAL);
      timer = setTimeout(async () => {
        try {
          const resp = await pollForResult<T>(requestId, resultUrl);
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      }, delay);
    });
  };

  const fetchData = async () => {
    canStartPolling = true;
    const { data } = await apiAuthCaller().post<ApiResponse<SubmitResult>>(
      submitUrl,
      submitRequestData,
    );
    if (data.success) {
      if (canStartPolling) {
        // If this is false, it means polling should not start as stopResultPolling has been called.
        canStartPolling = false;
        return pollForResult<T>(data.data.requestId, resultUrl);
      }
      return null as T;
    }
    throw formatError(data);
  };

  const requestPromise = new Promise<T>((resolve, reject) => {
    fetchData()
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });

  const stopResultPolling = () => {
    if (timer) {
      // when polling is in progress
      clearTimeout(timer);
      timer = null;
    } else if (canStartPolling) {
      // when polling has not started yet but submit request is not fulfilled.
      canStartPolling = false;
    }
  };

  return { requestPromise, stopResultPolling };
};

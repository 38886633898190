import { ISecurityStore, SecurityStore } from '@stores/security';
import {
  ITrackingPlanListStore,
  TrackingPlansListStore,
} from '@stores/trackingPlans/trackingPlansList';
import { ConnectionsStore, IConnectionsStore } from './connections';
import { DestinationsListStore, IDestinationsListStore } from './destinationsList';
import { ISourceDefinitionsListStore, SourceDefinitionsListStore } from './sourceDefinitionsList';

import { DestinationDefsListStore, IDestinationDefsListStore } from './destinationDefsList';
import { ISourcesListStore, SourcesListStore } from './sourcesList';
import { ITransformationsListStore, TransformationsListStore } from './transformationsList';
import { ISyncsListStore, SyncsListStore } from './syncsList';
import { IUserStore, UserStore } from './user';
import { IMessageStore, MessagesStore } from './messages';
import { IWorkspaceStore, WorkspaceStore } from './workspace';
import { ILibrariesListStore, LibrariesListStore } from './librariesList';
import { IBillingStore, BillingStore } from './billing';
import { AuditLogsStore, IAuditLogsStore } from './auditLogs';
import { ISourceDataStore, SourceDataStore } from './sourceDataStore';
import { ISettingsStore, SettingsStore } from './settings';
import { ITeamStore, TeamStore } from './teammates';
import { OrganizationStore, IOrganization } from './organization';
import ModelListStore, { IModelListStore } from './modelsList';
import { IAudienceListStore, AudienceListStore } from './audiences';
import { IPermissions, PermissionsStore } from './permissions';
import { IAccountsStore, AccountsStore } from './accounts';
import { FeaturesStore, IFeaturesStore } from './features/index';
import {
  DestinationTransformationListStore,
  IDestinationTransformationList,
} from './destinationTransformationList';
import { HealthDashboardStore, IHealthDashboardStore } from './healthDashboard/healthDashboard';
import { RetlConnectionListStore } from './retlConnections';
import { RetlSourceListStore } from './retlSources';
import { CredentialStore } from './credentialStore';
import { AlertDefinitionsListStore, IAlertDefinitionsListStore } from './alertDefinitionsList';
import { ProfilesListStore, IProfilesListStore } from './profilesList';
import { ServiceAccessTokenStore, IServiceAccessToken } from './serviceAccessToken';

export interface IRootStore {
  userStore: IUserStore;
  sourcesListStore: ISourcesListStore;
  destinationsListStore: IDestinationsListStore;
  sourceDefinitionsListStore: ISourceDefinitionsListStore;
  destinationDefsListStore: IDestinationDefsListStore;
  connectionsStore: IConnectionsStore;
  transformationsListStore: ITransformationsListStore;
  librariesListStore: ILibrariesListStore;
  messagesStore: IMessageStore;
  workspaceStore: IWorkspaceStore;
  organizationStore: IOrganization;
  billingStore: IBillingStore;
  sourceDataStore: ISourceDataStore;
  settingsStore: ISettingsStore;
  auditLogsStore: IAuditLogsStore;
  syncsListStore: ISyncsListStore;
  teamStore: ITeamStore;
  modelListStore: IModelListStore;
  securityStore: ISecurityStore;
  trackingPlanListStore: ITrackingPlanListStore;
  permissionsStore: IPermissions;
  audienceListStore: IAudienceListStore;
  accountStore: IAccountsStore;
  featuresStore: IFeaturesStore;
  destinationTransformationsListStore: IDestinationTransformationList;
  healthDashboardStore: IHealthDashboardStore;
  retlConnectionListStore: RetlConnectionListStore;
  retlSourceListStore: RetlSourceListStore;
  credentialStore: CredentialStore;
  alertDefinitionsListStore: IAlertDefinitionsListStore;
  profilesListStore: IProfilesListStore;
  serviceAccessTokenStore: IServiceAccessToken;
}

class RootStore implements IRootStore {
  public userStore: IUserStore;

  public sourcesListStore: ISourcesListStore;

  public destinationsListStore: IDestinationsListStore;

  public sourceDefinitionsListStore: ISourceDefinitionsListStore;

  public destinationDefsListStore: IDestinationDefsListStore;

  public connectionsStore: IConnectionsStore;

  public transformationsListStore: ITransformationsListStore;

  public librariesListStore: ILibrariesListStore;

  public messagesStore: IMessageStore;

  public organizationStore: IOrganization;

  public workspaceStore: IWorkspaceStore;

  public syncsListStore: ISyncsListStore;

  public billingStore: IBillingStore;

  public auditLogsStore: IAuditLogsStore;

  public sourceDataStore: ISourceDataStore;

  public settingsStore: ISettingsStore;

  public teamStore: ITeamStore;

  public modelListStore: IModelListStore;

  public securityStore: ISecurityStore;

  public trackingPlanListStore: ITrackingPlanListStore;

  public permissionsStore: IPermissions;

  public audienceListStore: IAudienceListStore;

  public accountStore: IAccountsStore;

  public featuresStore: IFeaturesStore;

  public destinationTransformationsListStore: IDestinationTransformationList;

  public healthDashboardStore: IHealthDashboardStore;

  public retlConnectionListStore: RetlConnectionListStore;

  public retlSourceListStore: RetlSourceListStore;

  public credentialStore: CredentialStore;

  public alertDefinitionsListStore: IAlertDefinitionsListStore;

  public profilesListStore: IProfilesListStore;

  public serviceAccessTokenStore: ServiceAccessTokenStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.sourcesListStore = new SourcesListStore(this);
    this.destinationsListStore = new DestinationsListStore(this);
    this.sourceDefinitionsListStore = new SourceDefinitionsListStore(this);
    this.destinationDefsListStore = new DestinationDefsListStore(this);
    this.connectionsStore = new ConnectionsStore(this);
    this.transformationsListStore = new TransformationsListStore(this);
    this.librariesListStore = new LibrariesListStore(this);
    this.messagesStore = new MessagesStore();
    this.organizationStore = new OrganizationStore(this);
    this.workspaceStore = new WorkspaceStore(this);
    this.syncsListStore = new SyncsListStore(this);
    this.billingStore = new BillingStore(this);
    this.auditLogsStore = new AuditLogsStore(this);
    this.sourceDataStore = new SourceDataStore(this);
    this.settingsStore = new SettingsStore(this);
    this.teamStore = new TeamStore(this);
    this.modelListStore = new ModelListStore(this);
    this.securityStore = new SecurityStore();
    this.trackingPlanListStore = new TrackingPlansListStore(this);
    this.permissionsStore = new PermissionsStore(this);
    this.audienceListStore = new AudienceListStore(this);
    this.accountStore = new AccountsStore(this);
    this.featuresStore = new FeaturesStore(this);
    this.destinationTransformationsListStore = new DestinationTransformationListStore(this);
    this.healthDashboardStore = new HealthDashboardStore(this);
    this.retlConnectionListStore = new RetlConnectionListStore(this);
    this.retlSourceListStore = new RetlSourceListStore(this);
    this.credentialStore = new CredentialStore(this);
    this.alertDefinitionsListStore = new AlertDefinitionsListStore(this);
    this.profilesListStore = new ProfilesListStore(this);
    this.serviceAccessTokenStore = new ServiceAccessTokenStore(this);
  }
}

export const stores = new RootStore();

import styled from 'styled-components';
import { Radio } from 'antd';
import theme from '@css/theme';

export const radioButtonStyles = `
  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background-color: ${theme.token.colorBgContainer};
  }
  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
    background: ${theme.token.colorPrimaryBase};
    transform: scale(0.5);
  }
  .ant-radio-wrapper .ant-radio-disabled .ant-radio-inner::after {
    background: ${theme.token.colorTextDisabled};
  }
`;

export const StyledRadioButtonGroup = styled(Radio.Group)`
  text-wrap: nowrap;

  .ant-radio-button-wrapper:first-child {
    border-start-start-radius: 100px;
    border-end-start-radius: 100px;
  }

  .ant-radio-button-wrapper:last-child {
    border-start-end-radius: 100px;
    border-end-end-radius: 100px;
  }

  .ant-radio-button-wrapper {
    border-color: ${theme.token.colorBorder};
    &:hover {
      border-color: ${theme.token.colorPrimaryBorder};
    }
  }

  .ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled {
    background: ${theme.token.colorTextDisabled};
  }

  .ant-radio-button-wrapper-disabled {
    &:hover {
      border-color: ${theme.token.colorBorder};
    }
  }
  ${radioButtonStyles}
`;

export const StyledRadio = styled(Radio)`
  .ant-radio-inner {
    background-color: ${theme.token.colorBgContainer} !important;

    &::after {
      background: ${theme.token.colorPrimaryBase};
    }
  }
`;

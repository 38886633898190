import { observable, makeObservable } from 'mobx';

type Status = 'pending' | 'expired' | undefined;
export type ServerStatus = Status | 'accepted';
export type LegacyInviteRole = 'member' | 'read-write' | 'admin';

export enum OrganizationRole {
  admin = 'admin',
  member = 'member',
}

export enum WorkspaceRole {
  workspaceRead = 'workspace_read',
  connectionsAdmin = 'connections_admin',
  connectionsEdit = 'connections_edit',
  transformationsAdmin = 'transformations_admin',
  modelsAdmin = 'models_admin',
}

export type UserRoles =
  | {
      organization: OrganizationRole.admin;
    }
  | {
      organization: OrganizationRole.member;
      workspaces?: Record<string, WorkspaceRole[]>;
    };

export interface Member {
  id: string;
  name: string;
  email: string;
  status?: Status;
  roles: UserRoles;
  invitedAt?: string;
}

type RootStore = {
  permissionsStore: {
    getAllowedWorkspaceRoles: () => WorkspaceRole[];
  };
};

export class MemberStore implements Member {
  @observable public id = '';

  @observable public name = '';

  @observable public email = '';

  @observable public roles: UserRoles;

  @observable public status: Status;

  @observable public invitedAt? = '';

  @observable public rootStore: RootStore;

  constructor(
    {
      id,
      name,
      email,
      status: serverStatus,
      role,
      invitedAt,
      roles,
      workspaceId,
    }: Omit<Member, 'status'> & {
      workspaceId: string;
      status?: ServerStatus;
      role?: LegacyInviteRole;
    },
    rootStore: RootStore,
  ) {
    makeObservable(this);
    this.id = id;
    this.name = name;
    this.email = email;
    this.roles = roles;
    this.invitedAt = invitedAt;
    this.rootStore = rootStore;
    if (serverStatus) {
      this.status = serverStatus === 'accepted' ? 'pending' : serverStatus;
      if (this.roles && Object.keys(this.roles).length === 0) {
        if (role === 'admin') {
          this.roles = { organization: OrganizationRole.admin };
        } else if (role === 'read-write') {
          const allowedRoles = this.rootStore.permissionsStore.getAllowedWorkspaceRoles();
          this.roles = {
            organization: OrganizationRole.member,
            workspaces: { [workspaceId]: allowedRoles },
          };
        } else {
          this.roles = { organization: OrganizationRole.member, workspaces: { [workspaceId]: [] } };
        }
      }
    }
  }
}

import readableDurationSeconds from '@utils/readableDurationSeconds';
import {
  ResourceType,
  ResourceCategory,
  ThresholdSliderUnit,
} from '@stores/alertDefinitionsList/types';

export const readableThreshold = (value?: number, unit?: ThresholdSliderUnit) =>
  unit === 'time_seconds' ? readableDurationSeconds(value ?? 0, 'compact') : `${value}%`;

export const getResourceCategoryDisplayName = (resourceCategory: ResourceCategory): string => {
  switch (resourceCategory) {
    case ResourceCategory.EVENT_STREAM:
      return 'Event Stream';
    case ResourceCategory.RETL:
      return 'Reverse ETL';
    case ResourceCategory.PROFILES:
      return 'Profiles';
    default:
      return resourceCategory;
  }
};

export const getResourceTypeDisplayName = (resourceType: ResourceType) => {
  switch (resourceType) {
    case ResourceType.SOURCE:
      return 'source';
    case ResourceType.CLOUD_DESTINATION:
    case ResourceType.WAREHOUSE_DESTINATION:
      return 'destination';
    case ResourceType.PROFILE:
      return 'profile';
    default:
      return '';
  }
};

export const getResourceIdProp = (resourceId: string, resourceType: ResourceType) => {
  switch (resourceType) {
    case ResourceType.SOURCE:
      return { sourceId: resourceId };
    case ResourceType.CLOUD_DESTINATION:
    case ResourceType.WAREHOUSE_DESTINATION:
      return { destinationId: resourceId };
    case ResourceType.PROFILE:
      return { profileId: resourceId };
    default:
      return {};
  }
};

export const getResourceNameProp = (resourceName: string, resourceType: ResourceType) => {
  switch (resourceType) {
    case ResourceType.SOURCE:
      return { sourceName: resourceName };
    case ResourceType.CLOUD_DESTINATION:
    case ResourceType.WAREHOUSE_DESTINATION:
      return { destinationName: resourceName };
    case ResourceType.PROFILE:
      return { profileName: resourceName };
    default:
      return {};
  }
};

export const getNoActiveAlertsBannerDescription = (resourceType: ResourceType) => {
  const suffix = 'is functioning as expected and there are no active alerts to report.';

  switch (resourceType) {
    case ResourceType.SOURCE:
      return `$sourceName source ${suffix}`;
    case ResourceType.CLOUD_DESTINATION:
    case ResourceType.WAREHOUSE_DESTINATION:
      return `$destinationName destination ${suffix}`;
    case ResourceType.PROFILE:
      return `$profileName profile ${suffix}`;
    default:
      return '';
  }
};

import { ISource, ISourceTPConfig, SecretConfig, ITPConfig, ITPSettingsType } from '@stores/source';
import { IResourceConfig, SourceConfig, SourceCategory } from '@stores/source/types';
import { action, computed, observable, makeObservable } from 'mobx';
import { IRootStore } from '@stores/index';
import { apiAuthCaller } from '@services/apiCaller';
import { getApiErrorMessage } from '@components/common/util/util';
import { IDestinationStore } from '@stores/destination';
import SourceFactory from '@components/sources/sourceFactory';
import { CatchErr } from '@utils/types';
import { ISourceDefinition } from './sourceDefinitionsList';
import {
  ApiResponse,
  getApiErrorMessageDetails,
  getRespData,
  isErrorFromNewSourceResponseFramework,
  isFollowingNewSourcesResponseFramework,
} from '@components/sources/sourceResponse';
import { SOURCE_DEFINITION_CATEGORY } from '@components/common/constants';
import removeNullValues from '@utils/removeNullValues';
import { coerceRetlSubcategory, getOrigin } from './util';
import { ISourceInfo, WarehouseConfigConstant } from './types';
import { IAccount } from './accounts';
import { IColumn } from '@components/sources/source/warehouseSource/configureData/sourceSpecificConfig/types';
import {
  ErrorProps,
  fetchErrorAssets,
} from '@components/sources/visualizer/mappingScreens/errorsView';
import { transformTPConfigToServer } from './sourcesList/util';
import { Analytics } from '@lib/analytics';
import { asyncApiHandler } from '@utils/asyncApi';
import { FeatureFlagKeys, experiment } from '@lib/experiment';

interface PreviewProps {
  rows: Array<Record<string, unknown>>;
  columns?: IColumn[];
  rowCount?: number;
}

interface Resource {
  default?: boolean;
  name: string;
  id?: string;
  resource: string;
  children?: Resource[];
  columns?: boolean;
}

export interface IResource extends Resource {
  multiple: boolean;
}

interface CredentialsInfo {
  accounts: IAccount[];
  accountInfo: undefined | null | IAccount;
}

export interface IPreviewParams {
  rowLimit?: number;
  fetchRowCount?: boolean;
  fetchRowsColumns?: boolean;
}

export interface ISourceDataStore extends ISource {
  isWarehouse(): boolean;
  setSource(source: ISource): void;
  flipEnabled(): void;
  setName(name: string): void;
  isEventStream(): boolean;
  getCredentialInfo(
    force?: boolean,
    useAccountId?: string,
    sourceDef?: ISourceDefinition,
  ): Promise<CredentialsInfo>;
  accountInfo: undefined | null | IAccount;
  createAccount(accountData: unknown, sourceDef?: ISourceDefinition): Promise<IAccount>;
  deleteConnection(destination: IDestinationStore): Promise<void>;
  getColumns<T = string>(
    sourceConfig: ISourceInfo,
    sourceDefName?: string,
    updateSchemaErrors?: (errorItems: ErrorProps[]) => void,
  ): Promise<IColumn<T>[] | null>;
  reset(): void;
  validateSource(config: SourceConfig): Promise<{ valid: boolean; reason: string }>;
  getPreview(
    accountId: string,
    sourceDefName: string,
    originResources: ISourceInfo,
    previewParams?: IPreviewParams,
  ): Promise<PreviewProps>;
  isSourceAvailableToSync(sourceId?: string): Promise<void>;
  getSourceDefResources(sourceDefName: string, params: unknown): Promise<IResource[] | null>;
  getSourceConfigResources(sourceId: string): Promise<IResourceConfig[]>;
  deleteCustomResource(sourceId: string, resourceId: string): void;
  deleteAccount(id: string): Promise<void>;
  unlinkTrackingPlan(): Promise<void>;
  updateTPSettings(config: Omit<ITPConfig, 'global'>, onSuccess?: () => void): Promise<void>;
  updateName(val: string): void;
  enableSource(): void;
  disableSource(): void;
  toggleGeolocationEnrichment(toggle: boolean): void;
  toggleGatewayDumps(val: boolean): void;
  usesVisualDataMapper(constants?: WarehouseConfigConstant[]): boolean;
  stopPreviewPolling: (() => void) | null;
  stopColumnsPolling: (() => void) | null;
  setAccount: (accountId: string) => void;
}

export class SourceDataStore implements ISourceDataStore {
  @observable config: SourceConfig = {};

  @observable secretConfig: SecretConfig = {};

  @observable createdAt = '';

  @observable enabled = false;

  @observable isGeoEnrichmentEnabled = false;

  @observable id = '';

  @observable name = '';

  @observable sourceDefinitionId = '';

  @observable transient = false;

  @observable writeKey = '';

  @observable rootStore: IRootStore;

  @observable sourceDefAccounts = [];

  @observable accountInfo: undefined | null | IAccount = null;

  @observable trackingPlanConfig: ISourceTPConfig | undefined;

  stopPreviewPolling: (() => void) | null = null;

  stopColumnsPolling: (() => void) | null = null;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  setAccount(accountId: string) {
    this.config.accountId = accountId;
    this.getCredentialInfo(true);
  }

  @action.bound
  setSource(source: ISource) {
    this.id = source.id;
    this.name = source.name;
    this.createdAt = source.createdAt;
    this.writeKey = source.writeKey;
    this.enabled = source.enabled;
    this.isGeoEnrichmentEnabled = source.isGeoEnrichmentEnabled;
    this.config = source.config;
    this.secretConfig = source.secretConfig;
    this.sourceDefinitionId = source.sourceDefinitionId;
    this.accountInfo = undefined;
    this.sourceDefAccounts = [];
    this.trackingPlanConfig = source.trackingPlanConfig;
    this.transient = source.transient;
  }

  @action.bound
  isEventStream() {
    return !this?.sourceDef?.category || this?.sourceDef?.category === 'webhook';
  }

  @action.bound
  flipEnabled() {
    this.enabled = !this.enabled;
  }

  @action.bound
  reset() {
    this.config = {};
    this.secretConfig = {};
    this.createdAt = '';
    this.enabled = false;
    this.isGeoEnrichmentEnabled = false;
    this.id = '';
    this.name = '';
    this.sourceDefinitionId = '';
    this.writeKey = '';
    this.sourceDefAccounts = [];
    this.accountInfo = null;
  }

  @action.bound
  public setName(name: string): void {
    this.name = name;
  }

  @computed get sourceDef() {
    return this.rootStore.sourceDefinitionsListStore.getById(this.sourceDefinitionId)!;
  }

  @computed get destinations() {
    const destIds = this.rootStore.connectionsStore.connections[this.id] || [];
    return this.rootStore.destinationsListStore.destinations.filter((dest) =>
      destIds.includes(dest.id),
    );
  }

  @computed get category() {
    return this.sourceDef.category;
  }

  updateSourceInList() {
    const { sourcesListStore } = this.rootStore;
    sourcesListStore.updateSourceInList(this);
  }

  /**
   * updateName
   */

  @action.bound
  public async updateName(name: string) {
    name = name && name.trim();
    if (name === this.name) {
      return;
    }
    const { messagesStore } = this.rootStore;

    try {
      await apiAuthCaller().patch(`/sources/${this.id}`, { name });
      this.name = name;
      this.updateSourceInList();
      messagesStore.showSuccessMessage('Name updated successfully');
    } catch (error) {
      messagesStore.showErrorMessage(getApiErrorMessage(error, 'Failed to update source name'));
    }
  }

  @action.bound
  public async updateConfig(config: SourceConfig, hardUpdate = false) {
    const { messagesStore } = this.rootStore;
    try {
      const {
        accountInfo,
        customResources,
        credentials,
        accessToken,
        refreshToken,
        rudderAccountId,
        accountId,
        ...configToUpdate
      } = this.config;
      const updatedConfig = {
        ...configToUpdate,
        accountId: rudderAccountId || accountId,
        ...config,
      };
      if (updatedConfig.resources) {
        updatedConfig.resources = (updatedConfig.resources || []).map(
          (resource: { id: string; config: object } | { id: string; role: string } | string) => {
            if (
              typeof resource === 'string' ||
              (typeof resource === 'object' &&
                this.sourceDef.category === SOURCE_DEFINITION_CATEGORY.SINGER)
            ) {
              return resource;
            }
            return resource?.id;
          },
        );
      }
      const updateUrl = `/sources/${this.id}${hardUpdate ? '?hardUpdate=true' : ''}`;
      const res = await apiAuthCaller().post(updateUrl, {
        config: updatedConfig,
      });
      this.config = res.data.config;
      this.secretConfig = res.data.secretConfig;
      this.updateSourceInList();
      messagesStore.showSuccessMessage('Successfully updated');
      return res.status === 200;
    } catch (error) {
      messagesStore.showErrorMessage(getApiErrorMessage(error, 'Failed to update source'));
      return false;
    }
  }

  /**
   * Enable Source
   */
  @action.bound
  public async enableSource() {
    try {
      await apiAuthCaller().post(`/sources/${this.id}`, {
        enabled: true,
      });
      this.enabled = true;
      this.updateSourceInList();
    } catch (error) {}
  }

  /**
   * Disable Source
   */
  @action.bound
  public async disableSource() {
    try {
      await apiAuthCaller().post(`/sources/${this.id}`, {
        enabled: false,
      });
      this.enabled = false;
      this.updateSourceInList();
      Analytics.track({
        event: 'sourceDisabled',
        properties: { sourceId: this.id, sourceType: this.sourceDef.name },
      });
    } catch (error) {}
  }

  @action.bound
  public async toggleGeolocationEnrichment(toggle: boolean) {
    const { messagesStore } = this.rootStore;
    try {
      await apiAuthCaller().post(`/sources/${this.id}/geo-enrichment`, {
        enabled: toggle,
      });
      this.isGeoEnrichmentEnabled = toggle;
    } catch (error) {
      messagesStore.showErrorMessage(
        getApiErrorMessage(error, 'Failed to update Geolocation Enrichment'),
      );
    }
  }

  @action.bound
  public async getCredentialInfo(force = false) {
    if (this.sourceDefAccounts.length === 0 || force) {
      try {
        this.sourceDefAccounts = [];
        this.accountInfo = undefined;
        const url = SourceFactory(this.sourceDef.category).getAccountsEndpoint(this.sourceDef.name);
        const accounts = await apiAuthCaller().get(url);
        this.sourceDefAccounts = accounts.data;
        const { rudderAccountId, accountId } = this.config;
        this.accountInfo =
          rudderAccountId || accountId
            ? this.sourceDefAccounts.find(
                (currAcc: IAccount) => currAcc.id === rudderAccountId || currAcc.id === accountId,
              )
            : null;
      } catch (e) {}
    }
    return { accountInfo: this.accountInfo, accounts: this.sourceDefAccounts };
  }

  isWarehouse() {
    return SourceFactory(this.sourceDef.category).isWarehouse();
  }

  async createAccount(accountData: unknown, sourceDef?: ISourceDefinition) {
    const { messagesStore } = this.rootStore;
    try {
      const url = SourceFactory(
        sourceDef?.category || this.sourceDef.category,
      ).getAccountsEndpoint();
      const response = await apiAuthCaller().post(url, accountData);
      return response.data;
    } catch (err: CatchErr) {
      messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to save credentials'));
      throw err;
    }
  }

  async deleteConnection(destination: IDestinationStore) {
    const { connectionsStore } = this.rootStore;
    await connectionsStore.removeConnections(this, destination);
  }

  async getColumns<T = string>(
    sourceConfig: ISourceInfo,
    sourceDefName?: string,
    updateSchemaErrors?: (errorItems: ErrorProps[]) => void,
  ): Promise<IColumn<T>[] | null> {
    const { userStore, messagesStore } = this.rootStore;
    const timeout = 120000;
    const useAsyncApi = experiment.isFeatureEnabled(FeatureFlagKeys.retlAsyncApi);

    try {
      const filteredOptions: Record<string, string> = removeNullValues(sourceConfig);
      const baseUrl = `cloudSources/sources/info/${sourceDefName || this.sourceDef.name}`;
      const requestData = {
        ...filteredOptions,
        workspaceId: userStore.selectedWorkspaceId,
      };
      if (useAsyncApi) {
        const { requestPromise, stopResultPolling } = asyncApiHandler<{ columns: IColumn<T>[] }>({
          submitUrl: `${baseUrl}/columns/submit`,
          submitRequestData: requestData,
          resultUrl: `${baseUrl}/columns/result`,
        });
        this.stopColumnsPolling = stopResultPolling;
        const data = await requestPromise;

        return data?.columns || [];
      }
      const { data } = await apiAuthCaller({ timeout }).post(`${baseUrl}/columns`, requestData);

      if (isFollowingNewSourcesResponseFramework(data)) {
        const { columns } = getRespData(data as ApiResponse<{ columns: IColumn<T>[] }>);
        return columns;
      }
      return data.columns || [];
    } catch (err) {
      if (isErrorFromNewSourceResponseFramework(err)) {
        const errDetails = getApiErrorMessageDetails(err, 'Failed to fetch columns');

        if (updateSchemaErrors) {
          updateSchemaErrors([fetchErrorAssets('columns', errDetails.message)]);
        }
        messagesStore.showErrorMessage(errDetails.messageWithFallback);
      } else {
        const errorMsg = getApiErrorMessage(err, 'Failed to fetch columns');
        messagesStore.showErrorMessage(errorMsg);
        if (updateSchemaErrors) {
          updateSchemaErrors([fetchErrorAssets('columns', errorMsg)]);
        }
      }
    }
    return null;
  }

  validateSource = async (
    config: SourceConfig,
  ): Promise<{
    valid: boolean;
    reason: string;
  }> => SourceFactory(this.sourceDef.category).validateSource(config, this);

  async getPreview(
    accountId: string,
    sourceDefName: string,
    originResources: ISourceInfo,
    previewParams?: IPreviewParams,
  ): Promise<PreviewProps> {
    const {
      userStore: { selectedWorkspaceId },
    } = this.rootStore;
    const useAsyncApi = experiment.isFeatureEnabled(FeatureFlagKeys.retlAsyncApi);

    const nonNullOriginResources: Record<string, string> = removeNullValues(originResources);
    const baseUrl = `cloudSources/sources/info/${sourceDefName || this.sourceDef.name}`;
    const requestData = {
      accountId,
      fetchRows: previewParams?.fetchRowsColumns,
      fetchColumns: previewParams?.fetchRowsColumns,
      fetchRowCount: previewParams?.fetchRowCount,
      rowLimit: previewParams?.rowLimit,
      ...nonNullOriginResources,
      workspaceId: selectedWorkspaceId,
    };

    if (useAsyncApi) {
      const { requestPromise, stopResultPolling } = asyncApiHandler<PreviewProps>({
        submitUrl: `${baseUrl}/preview/submit`,
        submitRequestData: requestData,
        resultUrl: `${baseUrl}/preview/result`,
      });
      this.stopPreviewPolling = stopResultPolling;
      const data = await requestPromise;

      return data || { rows: [] };
    }
    const response = await apiAuthCaller().post<ApiResponse<PreviewProps>>(
      `${baseUrl}/preview`,
      requestData,
    );
    return getRespData(response.data);
  }

  public isSourceAvailableToSync = async (sourceId?: string): Promise<void> => {
    await apiAuthCaller().get(`/cloudSources/sources/${sourceId || this.id}/status`);
  };

  public getSourceDefResources = async (sourceDefName: string, params: unknown) => {
    const { messagesStore } = this.rootStore;
    try {
      const resp = await apiAuthCaller().post(
        `/cloudSources/info/roles/${sourceDefName}/resources`,
        params,
      );
      return resp.data;
    } catch (error: CatchErr) {
      messagesStore.showErrorMessage(getApiErrorMessage(error, 'Failed to create roles resource'));
      return null;
    }
  };

  public getSourceConfigResources = async (sourceId: string) => {
    const { messagesStore } = this.rootStore;
    try {
      const resp = await apiAuthCaller().get(`/cloudSources/source/${sourceId}/resources`);
      return resp.data;
    } catch (error: CatchErr) {
      messagesStore.showErrorMessage(getApiErrorMessage(error, 'Failed to get source resource'));
      return null;
    }
  };

  public deleteCustomResource = async (sourceId: string, resourceId: string) => {
    await apiAuthCaller().delete(`/cloudSources/source/${sourceId}/resources/${resourceId}`);
  };

  deleteAccount = async (id: string) => {
    await apiAuthCaller().delete(`/warehouseSources/accounts/${id}`);
  };

  toggleGatewayDumps = (val: boolean) => {
    const {
      messagesStore: { showErrorMessage },
    } = this.rootStore;
    return apiAuthCaller()
      .patch(`/sources/${this.id}`, { transient: val })
      .then(() => {
        this.transient = val;
      })
      .catch(() => showErrorMessage('Failed to update source settings'));
  };

  @action.bound
  async unlinkTrackingPlan() {
    const { trackingPlanListStore } = this.rootStore;
    await trackingPlanListStore.unlinkTrackingPlan(
      this.trackingPlanConfig?.trackingPlanId!,
      this.id,
      () => {
        this.trackingPlanConfig = undefined;
      },
    );
  }

  @action.bound
  async updateTPSettings(config: ITPConfig, onSuccess?: () => void) {
    const { messagesStore } = this.rootStore;

    try {
      const updatedConfig = (Object.keys(config) as ITPSettingsType[]).reduce(
        (acc, key) => {
          const currentConfig = config[key];
          if (currentConfig) {
            acc[key] = transformTPConfigToServer(currentConfig);
          }
          return acc;
        },
        {} as Record<string, Record<string, string>>,
      );

      await apiAuthCaller().put(`/trackingPlans/sources/${this.id}`, {
        config: updatedConfig,
      });

      this.trackingPlanConfig!.config = {
        ...this.trackingPlanConfig?.config,
        ...config,
      };
      onSuccess?.();
    } catch (error: CatchErr) {
      messagesStore.showErrorMessage(
        getApiErrorMessage(error, 'Failed to update tracking plan settings'),
      );
    }
  }

  @computed get origin() {
    return getOrigin(this.config);
  }

  usesVisualDataMapper(constants?: WarehouseConfigConstant[]) {
    return Boolean(
      (constants || this.config?.constants)?.find(
        ({ key }) => key === 'context.mappedToDestination',
      ),
    );
  }

  /**
   * Calculate the source type based on the source definition category and
   * source config origin
   */

  @computed get sourceCategory(): SourceCategory {
    // eslint-disable-next-line no-nested-ternary
    return SourceFactory(this.sourceDef.category).isWarehouse()
      ? {
          category: 'retl' as const,
          subcategory: coerceRetlSubcategory(this.origin),
        }
      : ['singer-protocol', 'cloud'].includes(this.sourceDef.category ?? '')
      ? { category: 'extract' as const }
      : {
          category: 'event' as const,
        };
  }
}

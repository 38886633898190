import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import useStores from '@stores/useStores';

const HOME_PATH = '/';
const DEFAULT_MESSAGE = 'Resource not found or insufficient privileges. Redirecting to homepage.';

export const RedirectRoute = ({
  to = HOME_PATH,
  message = DEFAULT_MESSAGE,
}: {
  to?: string;
  message?: string;
}) => {
  const { messagesStore } = useStores();

  useEffect(() => {
    messagesStore.showInfoMessage(message);
  }, []);

  return <Redirect to={to} />;
};

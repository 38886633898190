import { IDestDefinition } from '@stores/destinationDefsList';
import { RUDDER_SCOPES } from '../constants';
import { RudderScope } from '@stores/types';

// #region auth utils
const OAuth = 'OAuth';

const validDestAuthTypes = [OAuth];
const validSourceAuthTypes = [OAuth];

const isValidAuthType = (authType: string) => validDestAuthTypes.includes(authType);
const isOauthEnabledSource = (authType: string) => validSourceAuthTypes.includes(authType);

const getAuthType = (destinationDefinition: IDestDefinition | undefined) =>
  destinationDefinition?.config?.auth?.type;

const getRudderScopes = (destinationDefinition: IDestDefinition | undefined) =>
  destinationDefinition?.config?.auth?.rudderScopes;

/**
 *
 * @param destinationDefinition
 * @param scope
 * @returns if destination is a valid oauth destination and has scope
 */
const isOauthEnabledDest = (
  destinationDefinition: IDestDefinition | undefined,
  scope: RudderScope = RUDDER_SCOPES.delivery,
) => {
  const authType = getAuthType(destinationDefinition);
  const rudderScopes = getRudderScopes(destinationDefinition);

  // rudderScopes config needs to be updated to include delivery and delete scopes
  const isOldConfig = rudderScopes === undefined;

  return (
    authType &&
    isValidAuthType(authType) &&
    (isOldConfig ? scope === RUDDER_SCOPES.delivery : rudderScopes.includes(scope))
  );
};
// #endregion

export { isOauthEnabledSource, isOauthEnabledDest };

import { Region } from '@components/common/constants';
import { getApiErrorMessage } from '@components/common/util/util';
import { apiAuthCaller } from '@services/apiCaller';
import { IRootStore } from '@stores/index';
import { CatchErr } from '@utils/types';
import cloneDeep from 'lodash/cloneDeep';
import { action, makeObservable, observable } from 'mobx';
import { OrganizationRole, UserRoles, WorkspaceRole } from './member';

type Access = { userRoles: UserRoles };
type UserInvite = { email: string } & Access;

export interface ITeamStore {
  rootStore: IRootStore;
  inviteUsers: (invites: UserInvite[]) => void;
  deleteUserInvitation: (id: string, email: string) => void;
  resendUserInvitation: (email: string) => void;
  removeUser: (id: string, email: string) => void;
  addUserToWorkspaces: (id: string, workspaceIds: string[]) => void;
  updateTeamMemberRole: (
    memberId: string,
    userRoles: UserRoles,
    onSuccess: () => void,
    onError: (error: CatchErr) => void,
  ) => void;
  removeUserFromWorkspace: (
    id: string,
    workspaceId: string,
    onSuccess: () => void,
    onError: (error: CatchErr) => void,
  ) => void;
  addUsersToWorkspace: (
    memberIds: string[],
    workspaceId: string,
    onSuccess: () => void,
    onError: (error: CatchErr) => void,
  ) => void;
}

export class TeamStore implements ITeamStore {
  @observable public rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  public async inviteUsers(invites: UserInvite[]) {
    const { id: organizationId } = this.rootStore.organizationStore;
    const { messagesStore } = this.rootStore;
    try {
      await apiAuthCaller({ region: Region.US }).post('/userInvitations', {
        invites,
        organizationId,
      });
      messagesStore!.showSuccessMessage(`Invite(s) sent`);
    } catch (err) {
      messagesStore!.showErrorMessage(getApiErrorMessage(err, 'Failed to invite'));
      throw err;
    }
  }

  @action.bound
  public async deleteUserInvitation(id: string, email: string) {
    const { messagesStore } = this.rootStore;
    try {
      await apiAuthCaller({ region: Region.US }).delete(`/userInvitations/${id}`);
      messagesStore!.showSuccessMessage(`Removed ${email} successfully`);
    } catch (error: CatchErr) {
      const errorMessage = getApiErrorMessage(error, 'Unable to remove the user');
      messagesStore!.showErrorMessage(errorMessage);
    }
  }

  @action.bound
  public async updateUserInvitation(email: string, id: string, access: Access) {
    const { id: organizationId } = this.rootStore.organizationStore;
    await apiAuthCaller({ region: Region.US }).post(`/updateInvitation/${id}`, {
      organizationId,
      email,
      ...access,
    });
  }

  @action.bound
  public async resendUserInvitation(email: string) {
    const { messagesStore, organizationStore } = this.rootStore;
    try {
      await apiAuthCaller({ region: Region.US }).post(`/resendInvitation`, { email });
      organizationStore.setMemberStatus(email);
      messagesStore!.showSuccessMessage(`Invitation has been resent to ${email} successfully`);
    } catch (error: CatchErr) {
      const errorMessage = getApiErrorMessage(error, 'Unable to resend the invitation');
      messagesStore!.showErrorMessage(errorMessage);
    }
  }

  @action.bound
  public async removeUser(id: string, email: string) {
    const { id: organizationId } = this.rootStore.organizationStore;
    const { messagesStore } = this.rootStore;
    try {
      await apiAuthCaller({ region: Region.US }).delete(`/users/${id}`, {
        data: { organizationId },
      });
      messagesStore!.showSuccessMessage(`Removed ${email} successfully`);
    } catch (error: CatchErr) {
      const errorMessage = getApiErrorMessage(error, 'Unable to remove the user');
      messagesStore!.showErrorMessage(errorMessage);
    }
  }

  @action.bound
  public async updateUserRole(id: string, access: Access) {
    await apiAuthCaller({ region: Region.US }).post(`/updateUser/${id}`, access);
  }

  @action.bound
  public async addUserToWorkspaces(id: string, workspaceIds: string[]) {
    const member = this.rootStore.organizationStore.members.find((member) => member.id === id)!;
    const userRoles = cloneDeep(member.roles);
    if (userRoles.organization === OrganizationRole.admin) {
      return;
    }
    if (!userRoles.workspaces) {
      userRoles.workspaces = {};
    }
    workspaceIds.forEach((workspace) => {
      userRoles.workspaces![workspace] = [WorkspaceRole.workspaceRead];
    });
    await this.updateTeamMemberRole(
      id,
      userRoles,
      () => null,
      (error) => {
        throw error;
      },
    );
  }

  @action.bound
  public async updateTeamMemberRole(
    memberId: string,
    userRoles: UserRoles,
    onSuccess: () => void,
    onError: (error: CatchErr) => void,
  ) {
    const { workspaceStore, organizationStore } = this.rootStore;
    const member = organizationStore.members.find((member) => member.id === memberId)!;
    const { email, id, status } = member;
    try {
      if (status === 'pending') {
        await this.updateUserInvitation(email, id, { userRoles });
      } else {
        await this.updateUserRole(id, { userRoles });
      }
      await workspaceStore.getSettings();
      onSuccess();
    } catch (error: CatchErr) {
      onError(error);
    }
  }

  @action.bound
  public async removeUserFromWorkspace(
    id: string,
    workspaceId: string,
    onSuccess: () => void,
    onError: (error: CatchErr) => void,
  ) {
    const member = this.rootStore.organizationStore.members.find((member) => member.id === id)!;
    const userRoles = cloneDeep(member.roles);
    if (userRoles.organization === OrganizationRole.admin) {
      return;
    }
    if (!userRoles.workspaces) {
      userRoles.workspaces = {};
    }
    userRoles.workspaces[workspaceId] = [];
    await this.updateTeamMemberRole(id, userRoles, onSuccess, onError);
  }

  @action.bound
  public async addUsersToWorkspace(
    memberIds: string[],
    workspaceId: string,
    onSuccess: () => void,
    onError: (error: CatchErr) => void,
  ) {
    const { organizationStore, workspaceStore } = this.rootStore;
    const { messagesStore } = this.rootStore;
    const data: { userIds: string[]; inviteIds: string[] } = { userIds: [], inviteIds: [] };
    memberIds.forEach((id) => {
      const member = organizationStore.members.find((member) => member.id === id)!;
      if (member.status === 'pending') {
        data.inviteIds.push(id);
      } else {
        data.userIds.push(id);
      }
    });
    try {
      await apiAuthCaller({ nonWorkspaceRoute: true, region: Region.US }).post(
        `/organizations/${organizationStore.id}/workspaces/${workspaceId}/members`,
        {
          ...data,
        },
      );
      await workspaceStore.getSettings();
      onSuccess();
    } catch (error: CatchErr) {
      const errorMessage = getApiErrorMessage(error, 'Unable to add the users');
      messagesStore!.showErrorMessage(errorMessage);
      onError(error);
    }
  }
}

import React from 'react';
import { Tooltip } from '@ui-library/tooltip';
import {
  DefaultButton,
  IconFix,
  LinkButton,
  PrimaryButton,
  SecondaryButton,
  ButtonProps,
} from './styles';

export type { ButtonProps } from './styles';

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    icon,
    type = 'primary',
    variant = 'default',
    tooltip: { title = '', placement = 'top', trigger = 'hover', show = true } = {},
    size = 'large',
    noPadding = false,
    ...others
  } = props;

  const comp = (() => {
    const danger = variant === 'danger';
    const iconProp = icon ? <IconFix>{icon}</IconFix> : undefined;

    if (type === 'secondary') {
      return (
        <SecondaryButton {...others} variant={variant} size={size} icon={iconProp}>
          {props.name ?? others.children}
        </SecondaryButton>
      );
    }

    if (type === 'text' || type === 'link') {
      return (
        <LinkButton
          {...others}
          noPadding={noPadding}
          type={type}
          danger={danger}
          size={size}
          icon={iconProp}
        >
          {props.name ?? others.children}
        </LinkButton>
      );
    }

    if (type === 'primary') {
      return (
        <PrimaryButton
          {...others}
          type="primary"
          variant={variant}
          danger={danger}
          size={size}
          icon={iconProp}
        >
          {props.name ?? others.children}
        </PrimaryButton>
      );
    }

    return (
      <DefaultButton {...others} type="default" variant={variant} size={size} icon={iconProp}>
        {props.name ?? others.children}
      </DefaultButton>
    );
  })();

  if (title && show) {
    return (
      <Tooltip title={title} placement={placement} trigger={trigger}>
        {comp}
      </Tooltip>
    );
  }

  return comp;
};

import { action, observable, makeObservable } from 'mobx';
import { IRootStore } from '@stores/index';
import { apiAuthCaller } from '@services/apiCaller';
import HttpStatusCode from '@utils/httpStatusCodes';
import { AxiosResponse } from 'axios';
import omit from 'lodash/omit';
import { Region } from '@components/common/constants';

export interface ISettingsStore {
  rootStore: IRootStore;
  tokens: ITokenInfo[] | undefined;
  getTokens: () => Promise<void>;
  createToken: (at: ITokenInfo) => Promise<string>;
  deleteToken: (name: string, workspaceId: string) => Promise<boolean>;
  reset(): void;
}

export interface ITokenInfo {
  name: string;
  role: string;
  description?: string;
  workspaceId: string;
}

interface IGetTokensResp extends ITokenInfo {
  userId: string;
}

interface ICreateTokenResp {
  success: boolean;
  data: { token: string };
}

export class SettingsStore implements ISettingsStore {
  @observable public rootStore: IRootStore;

  @observable public tokens: ITokenInfo[] | undefined;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  public async getTokens(): Promise<void> {
    const { organizationStore } = this.rootStore;
    try {
      const resp: AxiosResponse<IGetTokensResp[]> = await apiAuthCaller({
        nonWorkspaceRoute: true,
        region: Region.US,
      }).get(`/organizations/${organizationStore.id}/accessTokens`);
      if (resp.status === HttpStatusCode.Ok) {
        this.tokens = await resp.data.map((token: IGetTokensResp) => omit(token, 'userId'));
      }
    } catch (error) {}
  }

  @action.bound
  public async createToken(tokenInfo: ITokenInfo): Promise<string> {
    try {
      const resp: AxiosResponse<ICreateTokenResp> = await apiAuthCaller({
        nonWorkspaceRoute: true,
        region: Region.US,
      }).post(`/workspaces/${tokenInfo.workspaceId}/accessTokens`, {
        role: tokenInfo.role,
        name: tokenInfo.name,
      });
      if (resp.status === HttpStatusCode.Ok) {
        const { token } = resp.data.data;
        return token;
      }
      return '';
    } catch (error) {
      return '';
    }
  }

  @action.bound
  public async deleteToken(tokenName: string, workspaceId: string): Promise<boolean> {
    try {
      await apiAuthCaller({ nonWorkspaceRoute: true, region: Region.US }).delete(
        `/workspaces/${workspaceId}/accessTokens?name=${tokenName}`,
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  public async reset() {
    this.tokens = undefined;
  }
}

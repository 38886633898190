/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable prefer-rest-params */

export const loadQualifiedDocsChat = () => {
  ((w, d) => {
    if (w.QualifiedObject) {
      return;
    }
    const head = d.getElementsByTagName('head')[0];
    const script = d.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://js.qualified.com/qualified.js?token=gfwXVGcrnd6984yB';
    head.appendChild(script);
    (function (w, q) {
      w.QualifiedObject = q;
      w[q] =
        w[q] ||
        function () {
          (w[q].q = w[q].q || []).push(arguments);
        };
    })(w, 'qualified');
  })(window, document);

  if (typeof qualified !== 'undefined') {
    qualified('handleEvents', (name, data) => {
      window.rudderanalytics.track(name, data);
    });
  }

  document.addEventListener('DOMContentLoaded', () => {
    function handleQualifiedIframeAddition(iframe) {
      const attributeObserver = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.attributeName === 'style') {
            mutation.target.style.setProperty('right', '-12px', 'important');
          }
        });
      });

      // Observe qualified iframe style attribute changes
      attributeObserver.observe(iframe, { attributes: true });
    }

    // Observe first level DOM node mutations
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node.tagName === 'IFRAME' && node.id === 'q-messenger-frame') {
            handleQualifiedIframeAddition(node);
            observer.disconnect();
          }
        });
      });
    });

    // Start observing the document body for changes at the first level only
    observer.observe(document.body, { childList: true });
  });
};

import { useEffect, useRef } from 'react';

export const useScrollToRef = ({ predicate }: { predicate: boolean }) => {
  const ref = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (predicate) {
      setTimeout(() => {
        scrollToRef();
      }, 0);
    }
  }, [predicate]);

  const scrollToRef = () => {
    if (ref.current) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return {
    ref,
  };
};

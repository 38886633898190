import { apiAuthCaller } from '@services/apiCaller';

import { IRootStore } from '@stores/index';

import { action, computed, observable, makeObservable } from 'mobx';
import { IModel, IModelStore, IModelConfig, ModelStore } from './model';
import { ISourceDefinition } from './sourceDefinitionsList';
import { AxiosResponse } from 'axios';
import { getPermissions } from './util';

export interface CreatePayload {
  name: string;
  accountId: string;
  config: IModelConfig;
  sourceDefinition: ISourceDefinition;
}

type SqlModelConnection = {
  sqlModelId: string;
  connection: { sourceId: string };
};

type ServerModel = IModelStore & {
  sqlModelConnections: SqlModelConnection[];
  permissions?: { id: string; isLocked: boolean };
};

export interface IModelListStore {
  models: IModelStore[];
  rootStore: IRootStore;
  sourceModelMap: Record<string, string>;
  firstLoad: boolean;
  createModel: (model: CreatePayload) => Promise<IModel>;
  setModels: (models: IModelStore[]) => void;
  getModels: (throwError: boolean) => Promise<void>;
  deleteModel: (model: IModelStore) => Promise<void>;
  getModelById: (id: string) => IModelStore | undefined;
  reset: () => void;
}

/* eslint-disable import/no-default-export */
export default class ModelListStore implements IModelListStore {
  @observable models: IModelStore[] = [];

  @observable rootStore: IRootStore;

  @observable firstLoad = false;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  setModels(models: IModelStore[]) {
    this.models = models;
    this.firstLoad = true;
  }

  @action.bound
  async getModels(throwError: boolean) {
    const { permissionsStore } = this.rootStore;
    try {
      const resp: AxiosResponse<{ sqlModels: ServerModel[] }> =
        await apiAuthCaller().get('/sqlModels');
      permissionsStore.registerResource(getPermissions(resp.data.sqlModels));
      this.setModels(
        resp.data.sqlModels.map(
          (modelDetails: IModelStore & { sqlModelConnections: SqlModelConnection[] }) => {
            const sqlModel = new ModelStore(modelDetails, this.rootStore);
            sqlModel.setSourceIds(
              modelDetails.sqlModelConnections.map(({ connection }) => connection.sourceId),
            );
            return sqlModel;
          },
        ),
      );
    } catch (err) {
      if (throwError) {
        throw err;
      }
    }
  }

  @computed get sourceModelMap() {
    return this.models.reduce((accum: Record<string, string>, { id, sourceIds }) => {
      sourceIds.forEach((sourceId) => {
        accum[sourceId] = id;
      });
      return accum;
    }, {});
  }

  @action.bound
  async createModel(model: CreatePayload): Promise<IModel> {
    const { permissionsStore } = this.rootStore;
    const resp = await apiAuthCaller().post('/sqlModels', {
      ...model,
      sourceDefinitionId: model.sourceDefinition.id,
    });
    const savedModel: IModelStore = new ModelStore(resp.data, this.rootStore);
    this.setModels([savedModel, ...this.models]);
    permissionsStore.addNewResource(savedModel.id);
    return savedModel;
  }

  @action.bound
  public getModelById = (id: string): IModelStore | undefined =>
    this.models.find((model) => model.id === id);

  @action.bound
  async deleteModel(model: IModelStore) {
    const resp = await apiAuthCaller().delete(`/sqlModels/${model.id}`);
    const deletedModel: IModel = resp.data;
    this.setModels(this.models.filter((currModel) => currModel.id !== deletedModel.id));
  }

  @action.bound
  reset() {
    this.setModels([]);
    this.firstLoad = false;
  }
}

import React from 'react';
import { Link } from 'react-router-dom';
import classes from './source-destinations.module.scss';

interface TabViewProps {
  tabName: string;
  sourceID?: string;
}

const NoDestinationTabView = ({ tabName, sourceID }: TabViewProps) => (
  <div className={classes.empty_list_container}>
    <div>{`TO VIEW ${tabName.toUpperCase()}, PLEASE CONNECT TO A DESTINATION.`}</div>
    <div>
      Connect a new or existing destination by clicking Add destination in
      <Link to={`/sources/${sourceID}`}> Overview</Link>
    </div>
  </div>
);

/* eslint-disable import/no-default-export */
export default NoDestinationTabView;

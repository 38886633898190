import React from 'react';

import { Subtext } from '@ui-library/typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Flex } from '@ui-library/flex';
import theme from '@css/theme';

interface IErrorProps {
  error: boolean;
  errorMessage: string;
  showIcon?: boolean;
  color?: string;
}

export const ErrorLabel = ({
  error,
  errorMessage,
  showIcon = true,
  color = theme.token.colorError,
}: IErrorProps) => {
  if (errorMessage.length > 0 && error) {
    return (
      <Flex gap=".5em" alignItemsCenter className="m-v-sm">
        {showIcon && <FontAwesomeIcon color={color} icon={regular('circle-exclamation')} />}
        <Subtext color={color}>{errorMessage}</Subtext>
      </Flex>
    );
  }

  return <div />;
};

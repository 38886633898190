import { getApiErrorMessage } from '@components/common/util/util';
import { apiAuthCaller } from '@services/apiCaller';
import { action, makeObservable, observable } from 'mobx';
import { ConnectionConfig, RETLConnection, UpdateRETLConnectionParams } from './types';
import { IConnectionsStore } from '@stores/connections';
import { IDestinationsListStore } from '@stores/destinationsList';
import { IUserStore } from '@stores/user';
import { IMessageStore } from '@stores/messages';

type RootStore = {
  connectionsStore: IConnectionsStore;
  destinationsListStore: IDestinationsListStore;
  userStore: IUserStore;
  messagesStore: IMessageStore;
};

export class RetlConnectionStore {
  rootStore: RootStore;

  @observable id: string;

  @observable sourceId: string;

  @observable destinationId: string;

  @observable enabled: boolean;

  @observable config: ConnectionConfig;

  createdAt: string;

  constructor(connection: RETLConnection, rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.id = connection.id;
    this.sourceId = connection.sourceId;
    this.destinationId = connection.destinationId;
    this.config = connection.config;
    this.enabled = connection.enabled;
    this.createdAt = connection.createdAt;
  }

  @action.bound
  public async update(updates: UpdateRETLConnectionParams): Promise<boolean> {
    const { messagesStore } = this.rootStore;
    try {
      const { config, enabled } = updates;

      const payload = {
        ...(enabled !== undefined && { enabled }),
        ...(config !== undefined && {
          config: { ...this.config, ...config },
        }),
      };

      const { data } = await apiAuthCaller().put<RETLConnection>(
        `/retl-connections/${this.id}`,
        payload,
      );

      this.config = data.config;
      this.enabled = data.enabled;

      return true;
    } catch (err) {
      messagesStore.showErrorMessage(
        getApiErrorMessage(err, 'Failed to update the Retl-connection'),
      );
    }
    return false;
  }

  usesVisualDataMapper() {
    return this.config.source.constants.some(({ key }) => key === 'context.mappedToDestination');
  }

  isAvailableToSync() {
    const url = `/retl-connections/${this.id}/sync-status`;
    return apiAuthCaller().get(url);
  }
}

import { getSourceType, isValidInput } from '@components/common/util/util';
import { REGEX_STRING } from '@components/common/constants';
import { IDestination } from '@stores/destination';
import { IDestDefinition } from '@stores/destinationDefsList';
import setupDocs from './destinationSetupDocs.json';
import { Source } from '@stores/source/types';

export const getDestGuideLink = (destDef: IDestDefinition) =>
  setupDocs.find(
    (setup: { name: string }) => setup.name.toLowerCase() === destDef.name?.toLowerCase(),
  )?.docs;

export const isDestNameUnique = (
  newName: string,
  currentName: string,
  destinations: IDestination[],
) => {
  const input = newName.trim();
  if (input === currentName) {
    return true;
  }
  return !destinations.some(
    (dest: IDestination) => dest.name.toLowerCase() === input.toLowerCase(),
  );
};

export const validateDestName = (name: string) => {
  if (!isValidInput(REGEX_STRING.DESTINATION_NAME, name.trim())) {
    return 'Please enter a valid destination name';
  }
  return '';
};

export const getDisabledLiveEventMsg = (
  destination: IDestination,
  isDeviceMode: boolean,
  isLocked: boolean,
) => {
  if (destination.destinationDefinition.name.toLowerCase() === 'marketo_bulk_upload') {
    return 'Live events view is not available for this destination';
  }
  if (!destination.enabled) {
    return 'Destination is disabled';
  }
  if (destination.sources.length === 0) {
    return 'There are no connected sources';
  }
  if (isDeviceMode) {
    return 'Destination is being used only in device mode';
  }
  const allSourcesDisabled = destination.sources
    .map((s) => s.enabled)
    .every((isEnabled) => !isEnabled);
  if (allSourcesDisabled) {
    return 'All connected sources are disabled';
  }
  if (isLocked) {
    return "You don't have permissions to view live events";
  }
  return '';
};

interface IConfig {
  useNativeSDK?: Record<string, boolean>;
  useNativeSDKToSend?: Record<string, boolean>;
  connectionMode?: Record<string, string>;
}
export const areAllSourcesInDeviceMode = (
  config: IConfig | undefined,
  sources: Source[] | undefined,
) => {
  if (!sources || sources.length === 0) {
    return false;
  }
  const hasDeviceModeDisabledSource = Object.values(config?.useNativeSDK || {})
    .concat(Object.values(config?.useNativeSDKToSend || {}))
    .some((x) => !x);
  if (hasDeviceModeDisabledSource) {
    return false;
  }

  const isHybridMode = Object.values(config?.connectionMode || {}).some((val) =>
    val.toLowerCase().match('hybrid'),
  );

  if (isHybridMode) {
    return false;
  }

  const hasNoDeviceModeSupportSource = sources
    ?.map((x) => getSourceType(x.sourceDef))
    .some((category) => !Object.keys(config?.useNativeSDK || {}).includes(category));
  return !hasNoDeviceModeSupportSource;
};

import light from '@ui-library/tokens/light.json';

/** @deprecated - Use theme.token */
export const palette = {
  primary: {
    base: '#005CE6',
    light25: '#4085EC',
    light75: '#BFD6F9',
    light95: '#F2F7FE',
    dark10: '#0053CF',
  },
  grey: {
    base: '#424B59',
    light25: '#6F7273',
    light50: '#C4C6C8',
    dark50: '#232D54',
  },
  silver: {
    base: '#E9EFF4',
    light25: '#F2F2F2',
    light75: '#FAFAFA',
    light95: '#FFFFFF',
    dark10: '#CDD4DA',
  },
  error: {
    base: '#E10000',
    light25: '#CA5252',
    light75: '#EDC5C5',
    light95: '#FBF3F3',
    dark10: '#A61616',
  },
  warning: {
    base: '#D27E00',
    light25: '#DD9E40',
    light75: '#F4DFBF',
    light90: '#FAF2E5',
    dark10: '#BD7100',
  },
  success: {
    base: '#038A00',
    light25: '#42A740',
    light75: '#C0E2BF',
    light95: '#F2F9F2',
    dark10: '#037C00',
  },
} as const;

const theme = {
  color: {
    rsPrimary: palette.primary.base,
  },
  iconSize: {
    xs: 16,
    small: 20,
    medium: 28,
    large: 40,
  },
  text: {
    fontFamily: { primary: 'Inter', secondary: 'PolySans', tertiary: 'PolySans Wide' },
    color: {
      primary: '#2E2E2E',
      secondary: palette.grey.base,
      disabled: palette.grey.light50,
    },
  },
  input: {
    fontSize: '14px',
    height: '40px',
    borderRadius: '4px',
    borderColor: palette.grey.light50,
    focused: {
      borderColor: palette.primary.base,
    },
    error: {
      borderColor: palette.error.base,
    },
    disabled: {
      color: palette.grey.light50,
      backgroundColor: palette.silver.light75,
    },
  },
  breakpoint: {
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
  token: light,
};

export type LightTokenType = typeof light;
export type ThemeType = typeof theme;

/* eslint-disable import/no-default-export */
export default theme;

import React, { useEffect } from 'react';
import type { NotificationPlacement } from 'antd/es/notification/interface';
import { notification } from 'antd';

interface Props {
  type: NotificationType;
  onClose: () => void;
  message: string;
}
type NotificationType = 'success' | 'error' | 'info';

export const Toast = ({ type, onClose, message }: Props) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (
    type: NotificationType,
    placement: NotificationPlacement = 'top',
  ) => {
    api[type]({
      message,
      duration: 4.5,
      placement,
      className: `notification ${type}-notification`,
      onClose,
    });
  };

  useEffect(() => {
    if (message) {
      openNotificationWithIcon(type);
    }
  }, [message]);

  return <div>{contextHolder}</div>;
};

import { IDestination } from '@stores/destination';
import isString from 'lodash/isString';
import { IRespData, LiveEvent } from './interface';

export const isNotWarehouseEvent = (event: LiveEvent) => !event.payload?.uploadID;

export const filterByTab = (events: LiveEvent[], selectedTab: string) => {
  if (selectedTab !== 'all') {
    return events.filter((event: LiveEvent) => event.jobState === selectedTab);
  }
  return events;
};

interface ISourceEvent {
  sourceId: string | string[];
}

export const filterBySources = <T extends ISourceEvent>(events: T[], sourceIds: string[]) => {
  if (sourceIds.length === 0) {
    return events;
  }
  return events.filter((event) => sourceIds.some((sourceId) => event.sourceId.includes(sourceId)));
};

const getDestination = (destination: IRespData) => {
  const jsonPayload = destination.payload?.body?.JSON;
  let eventName = isString(jsonPayload?.event) ? jsonPayload.event : '';
  const eventType = isString(jsonPayload?.type) ? jsonPayload.type : '';
  if (!eventName && !eventType) {
    eventName = 'Event';
  }
  return {
    eventName,
    eventType,
    sentAt: jsonPayload?.receivedAt,
  };
};

export const constructLiveEvents = (data: IRespData[]): LiveEvent[] =>
  data.map((event) => {
    let jobState: LiveEvent['jobState'] = 'failed';
    if (event.jobState === 'succeeded' || event.jobState === 'generated_staging_file') {
      jobState = 'success';
    } else if (event.jobState === 'filtered') {
      jobState = 'dropped';
    }
    return {
      ...event,
      jobState,
      ...(event.version !== 'v2' && getDestination(event)),
    };
  });

export const getDropdownSources = (destination: IDestination) =>
  destination?.sources?.map((source) => ({
    value: source.id,
    label: source.name,
  })) || [];

import { apiAuthCaller } from '@services/apiCaller';
import { IMessageStore } from '@stores/messages';
import { Option, Select } from '@ui-library/select';
import { LargeText } from '@ui-library/typography';
import exportResponseError from '@utils/exportResponseError';
import retry from '@utils/retry';
import { CatchErr, FixMe } from '@utils/types';
// eslint-disable-next-line custom/no-antd-imports
import { OptionProps } from 'antd/lib/mentions';
// eslint-disable-next-line custom/no-antd-imports
import { SelectProps, SelectValue } from 'antd/lib/select';
import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import { inject } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import { CommonFieldProps } from '../formGroup';

interface SelectInputProps extends SelectProps<SelectValue> {
  optionsProps?: OptionProps;
  options?: { value: string; label: string }[];
}

type DynamicSelectTransform = (data: FixMe) => SelectInputProps['options'];

const Container = styled.div`
  width: 475px;
  input {
    width: 100%;
  }
`;

interface NormalizeOptionsParams {
  labelKey?: string;
  valueKey?: string;
  preRequisiteValue?: FixMe;
  optionAsValue?: boolean;
  responseKey?: string;
  transformResponse?: DynamicSelectTransform;
}

interface RequestParams {
  url: string;
  timeout?: number;
  params?: { [i: string]: FixMe };
  method?: 'get' | 'post';
}

interface IFieldProps {
  // sets as default the first option
  autoDefault?: boolean;
  // return the option object as value
  optionAsValue?: boolean;
  // if true workspacetoken will be included to
  useToken?: boolean;
}

export type MetaData = { params: Record<string, unknown> };

interface DynamicSelectProps extends Omit<SelectInputProps, 'onChange'> {
  preRequisiteObjectValue?: FixMe;
  preRequisiteValue?: FixMe;
  onError?: (error: CatchErr) => void;
  field: IFieldProps &
    CommonFieldProps &
    NormalizeOptionsParams &
    RequestParams &
    Omit<SelectInputProps, 'onChange'>;
  onChange: (label: string, value: string) => void;
  onFetch?: (data: FixMe) => void;
  metadata?: MetaData;
  hasError?: boolean;
  refresh?: boolean;
  setRefresh?: (val: boolean, failed?: boolean) => void;
}

type State = {
  options: SelectInputProps['options'] | null;
  value: string;
  loadingState: boolean;
  errorState?: boolean;
};

interface StoreProps {
  messagesStore: IMessageStore;
}

class DynamicSelect extends React.PureComponent<DynamicSelectProps & StoreProps, State> {
  constructor(props: DynamicSelectProps & StoreProps) {
    super(props);
    this.state = {
      options: null,
      value: this.props.field.default || this.props.field.defaultValue,
      loadingState: false,
      errorState: false,
    };
  }

  componentDidMount() {
    const { field, onChange } = this.props;
    onChange(field.value, field.default || field.defaultValue);
    this.setOptions();
  }

  static getOptions = async (
    { method = 'get', url, params, timeout }: RequestParams,
    nonWorkspaceRoute = false,
  ) => {
    let response: AxiosResponse;
    if (method === 'get') {
      response = await apiAuthCaller({ nonWorkspaceRoute, timeout }).get(url, {
        params,
      });
    } else {
      response = await apiAuthCaller({ nonWorkspaceRoute, timeout }).post(url, params);
    }
    return response.data;
  };

  /** Normalize integration info response. Assumes that response is either
   * object or array. If will first check for transformResponse prop and
   * delegate normalization to that function. Otherwise, it will assume the
   * response is an array or look for an array in responseKey prop (if present)
   * and map each array item to an text/value object by using fields defined by
   * labelKey/valueKey props respectively.
   */
  static normalizeOptions = (response: FixMe, options: NormalizeOptionsParams) => {
    if (!response) {
      return [];
    }

    const { responseKey, labelKey, valueKey, transformResponse, optionAsValue } = options;

    const data = responseKey ? get(response, responseKey) : response;
    if (!data || (!transformResponse && !Array.isArray(data))) {
      return [];
    }
    if (transformResponse) {
      return transformResponse(data);
    }
    return data?.map((option: FixMe) => {
      let value =
        (valueKey && get(option, valueKey)) || (typeof option === 'string' ? option : option.value);
      if (optionAsValue) {
        value = JSON.stringify(option);
      }
      return {
        label:
          (labelKey && get(option, labelKey)) ||
          (typeof option === 'string' ? option : option.label),
        value,
      };
    });
  };

  setOptions = async (callback?: () => void) => {
    const {
      field,
      onError,
      preRequisiteValue,
      metadata,
      disabled,
      preRequisiteObjectValue,
      onFetch,
      setRefresh,
      messagesStore,
    } = this.props;
    try {
      if (disabled) {
        return;
      }
      const { url, params, method, timeout, useToken } = field;
      const { options, value } = this.state;

      let optionList = options;
      this.setState({ errorState: false, loadingState: true });
      let urlPath = url;
      if (preRequisiteValue) {
        // eslint-disable-next-line no-template-curly-in-string
        urlPath = urlPath.replace('${preRequisiteValue}', preRequisiteValue);
      }
      let requestParams = { ...(params || {}), ...(metadata?.params || {}) };

      if (preRequisiteObjectValue) {
        requestParams = { ...requestParams, ...preRequisiteObjectValue };
      }
      const responseOptions = await retry(() =>
        DynamicSelect.getOptions(
          { url: urlPath, params: requestParams, method, timeout },
          useToken,
        ),
      );

      if (onFetch) {
        onFetch(responseOptions);
      }
      optionList = DynamicSelect.normalizeOptions(responseOptions, field);

      this.setState(
        {
          loadingState: false,
          options: optionList,
          value,
        },
        callback,
      );
    } catch (err: CatchErr) {
      if (onError) {
        onError(err);
      } else {
        const errorMessage = exportResponseError(err)?.message;
        if (errorMessage) {
          messagesStore.showErrorMessage(errorMessage);
        }
      }
      this.setState({ errorState: true });
      if (setRefresh) {
        setRefresh(false);
      }
    }
  };

  componentDidUpdate(prevProps: FixMe) {
    const { field, preRequisiteValue, refresh, setRefresh } = this.props;
    const { value, options } = this.state;
    // check if the selected value has the corresponding option
    let optionValueExists = true;
    let newValue: FixMe = null;
    if (value && options) {
      // In case of multiple selection we should remove
      // the value that doesn't have corresponding options
      if (Array.isArray(value)) {
        const filteredValue = (value as string[]).filter((val) =>
          options.some((option) => option.value === val),
        );
        if (value.length !== filteredValue.length) {
          optionValueExists = false;
          newValue = (filteredValue.length > 0 && filteredValue) || undefined;
        }
      } else {
        const selectedOptions = options.find((option) => option.value === value);
        if (!selectedOptions) {
          optionValueExists = false;
        }
      }
    }
    if (
      preRequisiteValue !== prevProps.preRequisiteValue ||
      prevProps?.field.url !== field.url ||
      JSON.stringify(field.params || {}) !== JSON.stringify(prevProps?.field?.params || {})
    ) {
      this.onChange(undefined, this.setOptions);
    } else if (!optionValueExists && newValue !== value) {
      this.onChange(newValue);
    } else if (!prevProps.refresh && refresh) {
      this.setOptions(() => {
        let valueIsInvalid = false;
        if (value && !options?.find((curr) => value === curr.value)) {
          this.onChange(undefined, undefined, true);
          valueIsInvalid = true;
        }
        if (setRefresh) {
          setRefresh(false, valueIsInvalid);
        }
      });
    }
  }

  onChange = (value: FixMe, cl?: () => FixMe, errorState?: boolean) => {
    const { onChange, field } = this.props;
    this.setState({ value, errorState }, cl);
    if (onChange) {
      let val: FixMe = value;
      if (Array.isArray(val) && val.length === 0) {
        val = undefined;
      } else if (Array.isArray(val) && field.optionAsValue && val) {
        val = value.map((v: string) => JSON.parse(v));
      } else if (field.optionAsValue && val) {
        val = JSON.parse(value);
      }
      onChange(field.value, val);
    }
  };

  render() {
    const { field, disabled, hasError, loading, className } = this.props;
    const {
      url,
      method,
      params,
      labelKey,
      valueKey,
      defaultValue,
      autoDefault,
      responseKey,
      value,
      ...restProps
    } = field;
    const { errorState, loadingState, options } = this.state;
    const nVal = field.optionAsValue
      ? {
          label:
            labelKey && this.state.value
              ? get(JSON.parse(this.state.value), labelKey)
              : this.state.value,
          value: this.state.value,
        }
      : this.state.value;
    const defVal = field.default || defaultValue;
    const nDefault = field.optionAsValue
      ? {
          label: labelKey && !!defVal ? get(JSON.parse(defVal), labelKey) : defVal,
          value: defVal,
        }
      : defVal;
    const isLoading = loadingState || loading;
    const foundError = errorState || hasError;

    return (
      <Container className={`dynamic-select_container ${className}`} data-testid="dynamicSelect">
        <LargeText className="m-b-xs select_label">
          {field.label}
          {field.required && (
            <span style={{ color: 'red' }} data-testid="required">
              {' '}
              *
            </span>
          )}
        </LargeText>
        <Select
          labelInValue={field.optionAsValue}
          hasError={foundError}
          disabled={disabled || isLoading}
          loading={isLoading}
          value={nVal}
          {...restProps}
          defaultValue={nDefault}
          onChange={(v: FixMe) => {
            this.onChange(field.optionAsValue ? v.value : v);
          }}
          data-testid="dynamicSelectDropdown"
          filterOption={(input, option) =>
            !!option?.children?.toString()?.toLowerCase().includes(input.toLowerCase())
          }
        >
          {options?.map((opt) => (
            <Option value={opt.value} key={`single-select-opt-key-${opt.label}-${opt.value}`}>
              {opt.label}
            </Option>
          ))}
        </Select>
      </Container>
    );
  }
}

/* eslint-disable import/no-default-export */
export default inject(
  'userStore',
  'messagesStore',
  'workspaceStore',
)(DynamicSelect as FixMe) as React.ComponentType<DynamicSelectProps>;

import dayjs from 'dayjs';
import { observable, makeObservable, action } from 'mobx';
import { apiAuthCaller } from '@services/apiCaller';
import { IRootStore } from '@stores/index';
import { getApiErrorMessage } from '@components/common/util/util';
import { ErrorType, ISampleErrorsStore, SampleError, SampleErrorsConfig } from './types';

export class SampleErrorsStore implements ISampleErrorsStore {
  private rootStore: IRootStore;

  @observable public config: SampleErrorsConfig;

  @observable public sampleErrors: SampleError[] = [];

  @observable public loading = false;

  constructor(config: SampleErrorsConfig, rootStore: IRootStore) {
    makeObservable(this);
    this.config = config;
    this.rootStore = rootStore;
  }

  @action
  private setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  @action.bound
  public fetchSyncErrors = async () => {
    const { messagesStore, workspaceStore } = this.rootStore;
    const { errorType, runId, startedAt, sourceId } = this.config;

    if (errorType === ErrorType.ABORTED) {
      return;
    }

    try {
      this.setLoading(true);
      const params = {
        sourceId,
        jobRunId: runId,
        type: errorType,
        from: dayjs.utc(startedAt).startOf('hour').toISOString(),
        workspaceId: workspaceStore.id,
        realTime: true,
        region: workspaceStore.defaultRegion,
      };

      const { data } = await apiAuthCaller().get<{ errors: SampleError[] }>('/reports/metrics', {
        params,
      });
      this.sampleErrors = data.errors;
    } catch (error) {
      messagesStore.showErrorMessage(getApiErrorMessage(error, 'Failed to load samples'));
    } finally {
      this.setLoading(false);
    }
  };
}

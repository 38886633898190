import { action, makeObservable, computed } from 'mobx';
import {
  ActiveAlert,
  ActiveAlertsStore as RootActiveAlertsStore,
  IActiveAlertsStore as IRootActiveAlertsStore,
} from '@components/alertNotifications/stores/activeAlerts';
import { IRootStore } from '@stores/index';
import { ResourceCategory, ResourceType } from '@stores/alertDefinitionsList/types';
import { constructAlertsOverviewData, filterAndUpdateRetlAlertsByConnections } from './utils';
import { Category, ActiveAlertsOverview } from './types';

export interface IActiveAlertsStore extends IRootActiveAlertsStore {
  activeAlertsByCategory: Record<Category, ActiveAlert[]>;
  activeAlertsOverview: Record<Category, ActiveAlertsOverview | null>;
  alertsConfigured: Record<Category, boolean>;
  getActiveAlertsByResource: (
    category: Category,
    resourceDetails: {
      sourceId?: string;
      destinationId?: string;
      trackingPlanId?: string;
      connectionId?: string;
    },
  ) => ActiveAlert[];
}

export class ActiveAlertsStore extends RootActiveAlertsStore implements IActiveAlertsStore {
  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @computed
  public get alertsConfigured() {
    if (
      this.alertConfigurationStatus &&
      typeof this.alertConfigurationStatus === 'object' &&
      !('hasAlerts' in this.alertConfigurationStatus)
    ) {
      return {
        [Category.EVENT_STREAM]: this.alertConfigurationStatus.hasEventStreamDestinationAlerts,
        [Category.RETL]: this.alertConfigurationStatus.hasRETLAlerts,
        [Category.TRACKING_PLAN]: this.alertConfigurationStatus.hasTrackingPlanAlerts,
      };
    }
    return {
      [Category.EVENT_STREAM]: false,
      [Category.RETL]: false,
      [Category.TRACKING_PLAN]: false,
    };
  }

  @computed
  public get activeAlertsByCategory() {
    const {
      healthDashboardStore: {
        retlHealthStore: { syncsData },
      },
    } = this.rootStore;

    return {
      [Category.EVENT_STREAM]: this.activeAlerts.filter(
        (alert) =>
          alert.resourceCategory === ResourceCategory.EVENT_STREAM &&
          (alert.resourceType === ResourceType.CLOUD_DESTINATION ||
            alert.resourceType === ResourceType.WAREHOUSE_DESTINATION),
      ),
      [Category.RETL]: filterAndUpdateRetlAlertsByConnections(
        this.activeAlerts.filter((alert) => alert.resourceCategory === ResourceCategory.RETL),
        syncsData,
      ),
      [Category.TRACKING_PLAN]: this.activeAlerts.filter((alert) => !!alert.trackingPlanId),
    };
  }

  @computed
  public get activeAlertsOverview() {
    if (!this.showActiveAlerts) {
      return {
        [Category.EVENT_STREAM]: null,
        [Category.RETL]: null,
        [Category.TRACKING_PLAN]: null,
      };
    }

    return constructAlertsOverviewData(
      this.alertsConfigured,
      this.activeAlertsByCategory,
      this.activeAlertsLoading,
    );
  }

  @action.bound
  public getActiveAlertsByResource = (
    category: Category,
    resourceDetails: {
      sourceId?: string;
      destinationId?: string;
      trackingPlanId?: string;
      connectionId?: string;
    },
  ) => {
    const { sourceId, destinationId, trackingPlanId, connectionId } = resourceDetails;
    const alerts = this.activeAlertsByCategory[category];
    return alerts.filter(
      (alert) =>
        (!alert.sourceId || !sourceId || alert.sourceId === sourceId) &&
        (!alert.destinationId || !destinationId || alert.destinationId === destinationId) &&
        (!alert.trackingPlanId || !trackingPlanId || alert.trackingPlanId === trackingPlanId) &&
        (!alert.jobId || !connectionId || alert.jobId === connectionId),
    );
  };
}

type GetFlagKeys =
  | 'live_events_intro_disable'
  | `mfashown${string}`
  | 'askAiCoachMarkShown'
  | 'hasAcknowledgedGovernSection'
  | 'showEditorProfilesPopup';

type GetKeys =
  | GetFlagKeys
  | 'visitedSources'
  | 'authError'
  | 'userStore'
  | 'lastVisitedWorkspace'
  | 'transformationEditor'
  | 'trackingPlanMigration'
  | 'lastVisitedOrgToWorkspaceMap'
  | 'profilesProjectsInfo'
  | 'lockDiffMode';

type SetFlagKeys =
  | 'mfashown'
  | 'askAiCoachMarkShown'
  | 'showEditorProfilesPopup'
  | 'hasAcknowledgedGovernSection';

type SetKeys =
  | SetFlagKeys
  | 'visitedSources'
  | 'userStore'
  | 'authError'
  | 'live_events_intro_disable'
  | 'userStore'
  | 'lastVisitedWorkspace'
  | 'transformationEditor'
  | 'trackingPlanMigration'
  | 'profilesProjectsInfo'
  | 'lastVisitedOrgToWorkspaceMap'
  | 'lockDiffMode';

const LocalStorage = {
  setFlag(key: SetFlagKeys) {
    this.set(key, 'true');
  },

  isFlagSet(key: GetFlagKeys) {
    return this.get(key) === 'true';
  },

  get(key: GetKeys) {
    try {
      return localStorage.getItem(key);
    } catch (err) {
      return null;
    }
  },

  set(key: SetKeys, value: string) {
    try {
      localStorage.setItem(key, value);
    } catch (err) {}
  },

  remove(key: 'authError') {
    try {
      localStorage.removeItem(key);
    } catch (err) {}
  },
};

/* eslint-disable import/no-default-export */
export default LocalStorage;

import { Region } from '@components/common/constants';
import { getApiErrorMessage } from '@components/common/util/util';
import { hasExhaustedLimit } from '@components/directory/utils';
import { Analytics } from '@lib/analytics';
import { apiAuthCaller } from '@services/apiCaller';
import { PlanNameType } from '@stores/billing';
import { IRootStore } from '@stores/index';
import {
  LegacyInviteRole,
  Member,
  OrganizationRole,
  ServerStatus,
  UserRoles,
} from '@stores/member';
import HttpStatusCode from '@utils/httpStatusCodes';
import { AxiosResponse } from 'axios';
import { action, computed, makeObservable, observable } from 'mobx';

export type WorkspaceEnvironment = 'DEVELOPMENT' | 'PRODUCTION';

type ServerOrgFeature = Record<
  'enableDeviceModeTransformations' | `enable_${string}_source`,
  boolean
>;

type ServerWorkspace = {
  id: string;
  name: string;
  createdAt: string;
  dataPlaneURL: string;
  ownerId: string;
  userRoles: {
    user: { id: string; email: string; name: string };
    roles: UserRoles;
  }[];
  invitedUsers: {
    id: string;
    name: string;
    email: string;
    status: ServerStatus;
    role: LegacyInviteRole;
    userRoles: UserRoles;
    createdAt: string;
  }[];
  organizationId: string;
  orgSettings: {
    name: string;
    enforcedMfa: boolean;
    grafanaURL?: string;
  } & ServerOrgFeature;
  piiPermissions: { isLocked: boolean; id: string };
  defaultRegion: Region;
  dataplanes: { region: Region; url: string }[];
  sshConfig: { publicKey: string; id: string } | undefined;
  planName: PlanNameType;
  environment: WorkspaceEnvironment;
  eventAuditEnabled: boolean;
};

type Onboarding = 'addSource' | 'liveEvents';

export interface IWorkspace {
  id: string;
  name: string;
  createdAt: string;
  dataPlane: string;
  isLoading: boolean;
  hasExhaustedCloudLimit: boolean;
  cloudSourcesLimit?: number;
  hasExhaustedWHSourcesLimit: boolean;
  warehouseActionsLimit?: number;
  hasExhaustedTransformationsLimit: boolean;
  transformationsLimit?: number;
  firstLoad: boolean;
  isEventsCountsLoading: boolean;
  eventStreamLimit: number;
  eventStreamCount: number;
  enableDeviceModeTransformations: boolean;
  enabledSources: string[];
  features: Map<string, string[]>;
  tasks: undefined | string[];
  showOnboarding: Record<Onboarding, boolean>;
  setShowOnboarding: (key: Onboarding, val: boolean) => void;
  getTasks: () => void;
  markTaskComplete: (task: string) => void;
  getEventUsageStats: () => void;
  dataResidencyDefaultRegion: Region;
  dataplanes: { region: Region; url: string }[];
  sshConfig: { publicKey: string; id: string };
  dataplaneRegions: Region[];
  reportingRegions: Region[];
  defaultRegion: Region;
  environment: WorkspaceEnvironment;
  eventAuditEnabled: boolean;
}

export type Bucket = {
  type: 'S3' | 'GCS' | 'AZURE_BLOB' | 'MINIO';
  config: Record<string, unknown>;
};

export interface RetentionData {
  enableReportingPii: boolean;
  useRudderServerStorage: boolean;
  bucket: Bucket | null;
  retentionPeriod?: 'full' | 'default';
}

type ReportingPiiStatus = 'enabled' | 'disabled' | 'noAccess' | 'idle';

// If a new module/feature comes up, add it over here:
type IFeatureModule = 'sources' | 'warehouse';
type IFeature = 'configurationTests';

export interface IWorkspaceStore extends IWorkspace {
  rootStore: IRootStore;
  getWorkspace(): void;
  getSettings: () => Promise<void>;
  requestAdminToUpgrade: (type: 'upgradeTouchPoint' | 'eventLimit') => Promise<boolean>;
  getGrafanaUrl: () => Promise<string | false>;
  updateName: (e: string) => Promise<boolean>;
  reset: () => void;
  isFeatureEnabled: (module: IFeatureModule, feature: IFeature) => boolean;
  getFeatures: () => Promise<void>;
  members: Member[];
  reportingPiiStatus: ReportingPiiStatus;
  updateReportingPiiStatus: (enableReportingPii: boolean) => void;
  getReportingPiiStatus: () => Promise<RetentionData | null>;
  updateEventAudit: (enabled: boolean) => void;
}

export class WorkspaceStore implements IWorkspaceStore {
  @observable public id = '';

  @observable public createdAt = '';

  @observable public name = '';

  @observable public isLoading = false;

  @observable public cloudSourcesLimit = 0;

  @observable public warehouseActionsLimit = 0;

  @observable public rootStore: IRootStore;

  @observable public dataPlaneURL = '';

  @observable public firstLoad = false;

  @observable public isEventsCountsLoading = false;

  @observable public eventStreamLimit = 0;

  @observable public eventStreamCount = 0;

  @observable public transformationsLimit = 0;

  @observable public enableDeviceModeTransformations = false;

  @observable public enabledSources: string[] = [];

  @observable public features: Map<string, string[]> = new Map();

  @observable public showOnboarding = {} as Record<Onboarding, boolean>;

  @observable public tasks: IWorkspace['tasks'];

  @observable public defaultRegion: Region = Region.US;

  @observable public dataResidencyDefaultRegion: Region = Region.US;

  @observable public dataplanes: IWorkspace['dataplanes'] = [];

  @observable public sshConfig: IWorkspace['sshConfig'] = { publicKey: '', id: '' };

  @observable public environment: WorkspaceEnvironment = 'PRODUCTION';

  @observable public reportingPiiStatus: ReportingPiiStatus = 'idle';

  @observable public eventAuditEnabled = false;

  /** Add variables in {@link reset} method as well */

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  public async getWorkspace() {
    const { userStore } = this.rootStore;
    const { selectedWorkspaceId } = userStore;
    if (!selectedWorkspaceId) {
      userStore.signOutUser();
      return;
    }
    await this.getSettings();
    this.firstLoad = true;
  }

  @action.bound
  public isFeatureEnabled(module: IFeatureModule, feature: IFeature) {
    const moduleFeatures = this.features.get(module);
    return !!(moduleFeatures && moduleFeatures.includes(feature));
  }

  @action.bound
  public async getFeatures() {
    apiAuthCaller()
      .get('/features')
      .then((res: AxiosResponse<{ features: Map<string, string[]> }>) => {
        this.features = new Map(Object.entries(res.data.features));
      });
  }

  getEnabledSources(settings: ServerOrgFeature) {
    const { sourceDefinitionsListStore } = this.rootStore;
    return sourceDefinitionsListStore.sourceDefinitions
      .filter(({ name }) => settings[`enable_${name.toLowerCase()}_source`])
      .map(({ name }) => name);
  }

  public async getSettings() {
    const {
      userStore: { id: userId },
      permissionsStore,
      organizationStore,
    } = this.rootStore;
    try {
      const resp = await apiAuthCaller().get<{ workspace: ServerWorkspace }>('/settings');
      const workspaceData = resp.data.workspace;
      this.id = workspaceData.id;
      this.createdAt = workspaceData.createdAt;
      this.name = workspaceData.name;
      this.isLoading = false;
      this.dataPlaneURL = workspaceData.dataPlaneURL;
      if (workspaceData.sshConfig) {
        this.sshConfig = workspaceData.sshConfig;
      }
      this.enableDeviceModeTransformations =
        workspaceData.orgSettings.enableDeviceModeTransformations;
      this.enabledSources = this.getEnabledSources(workspaceData.orgSettings);
      this.dataResidencyDefaultRegion =
        workspaceData.dataplanes.length > 1 ? workspaceData.defaultRegion : Region.US;
      this.defaultRegion = workspaceData.defaultRegion;
      this.setDataplanes(workspaceData);
      this.environment = workspaceData.environment;
      this.eventAuditEnabled = workspaceData.eventAuditEnabled || false;
      permissionsStore.setDataPermissions(workspaceData.piiPermissions);
      organizationStore.seed({
        id: workspaceData.organizationId,
        name: workspaceData.orgSettings.name,
        planName: workspaceData.planName,
        grafanaURL: workspaceData.orgSettings.grafanaURL,
        ownerId: workspaceData.ownerId,
        userRoles: workspaceData.userRoles,
        invitedUsers: workspaceData.invitedUsers,
        workspaceId: workspaceData.id,
        enforcedMfa: workspaceData.orgSettings.enforcedMfa,
      });
      Analytics.identify(userId, {
        plan: workspaceData.planName,
        workspacesId: workspaceData.id,
        organizationId: workspaceData.organizationId,
      });
    } catch (err) {}
  }

  private setDataplanes = (workspaceData: ServerWorkspace) => {
    const { dataPlaneURL, dataplanes } = workspaceData;
    if (dataplanes.length > 0) {
      this.dataplanes = dataplanes;
    } else if (dataPlaneURL) {
      this.dataplanes = [{ region: this.dataResidencyDefaultRegion, url: dataPlaneURL }];
    } else {
      this.dataplanes = [];
    }
  };

  @computed get hasExhaustedCloudLimit() {
    return hasExhaustedLimit({
      limit: this.cloudSourcesLimit,
      count: this.rootStore.sourcesListStore.cloudSourcesCount,
    });
  }

  @computed get hasExhaustedWHSourcesLimit() {
    return hasExhaustedLimit({
      limit: this.warehouseActionsLimit,
      count: this.rootStore.sourcesListStore.warehouseSourcesCount,
    });
  }

  @action.bound
  async getEventUsageStats() {
    interface IResp {
      eventStreamLimit: number;
      eventStreamCount: number;
    }
    try {
      this.isEventsCountsLoading = true;
      const resp = await apiAuthCaller().ignore400().get<IResp>('/eventUsageStats');
      this.isEventsCountsLoading = false;
      const { eventStreamLimit, eventStreamCount } = resp.data;
      this.eventStreamLimit = eventStreamLimit;
      this.eventStreamCount = eventStreamCount;
    } catch (err) {}
  }

  @action.bound
  public async requestAdminToUpgrade(type: 'upgradeTouchPoint' | 'eventLimit') {
    try {
      const resp = await apiAuthCaller({ region: Region.US }).post('/requestAdminToUpgrade', {
        type,
      });
      return resp.status === 200;
    } catch (error) {
      // Handle error
      return false;
    }
  }

  @action.bound
  public async getGrafanaUrl() {
    try {
      const response = await apiAuthCaller({ nonWorkspaceRoute: true }).get<string>(
        `/grafana/${this.id}/authenticate`,
      );
      return response.data;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  public async updateName(name: string) {
    const {
      messagesStore: { showErrorMessage },
    } = this.rootStore;
    try {
      const resp = await apiAuthCaller().post('/updateName', { name });
      if (resp.status === HttpStatusCode.Ok) {
        this.name = name;
        return true;
      }
      return false;
    } catch (error) {
      showErrorMessage(getApiErrorMessage(error, 'Failed to update workspace name'));
      return false;
    }
  }

  @action.bound
  setShowOnboarding(key: Onboarding, val: boolean) {
    this.showOnboarding[key] = val;
  }

  @action.bound
  public updateReportingPiiStatus(enableReportingPii: boolean) {
    this.reportingPiiStatus = enableReportingPii ? 'enabled' : 'disabled';
  }

  @action.bound
  public async updateEventAudit(enabled: boolean) {
    this.eventAuditEnabled = enabled;
  }

  @action.bound
  public async getReportingPiiStatus() {
    const {
      userStore,
      messagesStore: { showErrorMessage },
    } = this.rootStore;

    if (!userStore.isOrgAdmin) {
      this.reportingPiiStatus = 'noAccess';
      showErrorMessage(
        getApiErrorMessage({}, 'Only organization admins can access data retention config'),
      );
      return null;
    }

    try {
      const res = await apiAuthCaller().get<RetentionData>('/settings/dataRetention');
      const { enableReportingPii } = res.data;
      this.reportingPiiStatus = enableReportingPii ? 'enabled' : 'disabled';
      return res.data;
    } catch (error) {
      showErrorMessage(getApiErrorMessage(error, 'Failed to get data retention config'));
      return null;
    }
  }

  @computed get dataPlane() {
    return this.dataPlaneURL || '';
  }

  @computed get hasExhaustedTransformationsLimit() {
    const { transformations } = this.rootStore.transformationsListStore;
    return !!this.transformationsLimit && transformations.length >= this.transformationsLimit;
  }

  getTasks() {
    const { messagesStore } = this.rootStore;
    apiAuthCaller()
      .get<{ completed: string[] }>(`/tasks`)
      .then((res) => {
        this.tasks = res.data.completed;
      })
      .catch((err) => {
        messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to get tasks'));
      });
  }

  async markTaskComplete(task: string) {
    await apiAuthCaller()
      .post('/tasks', { task })
      .then(() => {
        this.tasks = (this.tasks || []).concat(task);
      })
      .catch(() => null);
  }

  @computed get dataplaneRegions() {
    return this.dataplanes.length > 0
      ? this.dataplanes.map((dp) => dp.region)
      : [this.dataResidencyDefaultRegion];
  }

  @computed get reportingRegions() {
    return this.dataplanes.length > 1
      ? this.dataplanes.map((dp) => dp.region)
      : [this.defaultRegion];
  }

  @computed get members() {
    const {
      organizationStore: { members },
    } = this.rootStore;
    return members.filter(
      (member) =>
        member.roles.organization === OrganizationRole.admin ||
        (member.roles.organization === OrganizationRole.member &&
          member.roles.workspaces?.[this.id] &&
          member.roles.workspaces[this.id].length > 0),
    );
  }

  reset() {
    this.id = '';
    this.name = '';
    this.isLoading = true;
    this.createdAt = '';
    this.cloudSourcesLimit = 0;
    this.warehouseActionsLimit = 0;
    this.dataPlaneURL = '';
    this.transformationsLimit = 0;
    this.enableDeviceModeTransformations = false;
    this.enabledSources = [];
    this.features = new Map();
    this.showOnboarding = {} as Record<Onboarding, boolean>;
    this.eventStreamCount = 0;
    this.eventStreamLimit = 0;
    this.tasks = undefined;
    this.dataResidencyDefaultRegion = Region.US;
    this.defaultRegion = Region.US;
    this.dataplanes = [];
    this.sshConfig = { publicKey: '', id: '' };
    this.eventAuditEnabled = false;
    this.reportingPiiStatus = 'idle';
  }
}

import * as React from 'react';
import { FormConfig, FormSecrets } from '@components/common/formSchema/types';
import FormSchema, { Group } from '@components/common/formSchema';
import { ISourceDefinition } from '@stores/sourceDefinitionsList';
import { SourceConfig } from '@stores/source/types';
import classes from './webhookConfigure.module.scss';

interface IWebhookConfigureProps {
  sourceDefinition: ISourceDefinition;
  onSettingsChange: (value: FormConfig, isValid: boolean) => void;
  initialSettings?: SourceConfig;
  secretConfig?: FormSecrets;
  disabled?: boolean;
}

export const WebhookConfigure = ({
  sourceDefinition,
  onSettingsChange,
  initialSettings,
  disabled,
  secretConfig,
}: IWebhookConfigureProps) => {
  const schema = sourceDefinition.uiConfig as Group[];
  return (
    <div className={`p-t-lg p-b-lg ${classes.min_width_form}`} data-testid="webhook-configure-test">
      <FormSchema
        schema={schema || []}
        onChange={onSettingsChange}
        initialSettings={initialSettings as FormConfig}
        disabled={!!disabled}
        secretConfig={secretConfig}
      />
    </div>
  );
};

import { ErrorDetailsData, Module, ModuleInfo, RouterSubModule } from './types';

const payloadStageMap: Record<string, string> = {
  gateway: 'Gateway',
  router_input: 'Router Input',
};

const moduleMap: Record<Module, ModuleInfo> = {
  destination_filter: {
    block: 'processor',
    module: Module.destination_filter,
    moduleName: 'Destination Filter',
    errorStage: '',
    description: '',
    payloadStage: payloadStageMap.gateway,
  },
  user_transformer: {
    block: 'processor',
    module: Module.user_transformer,
    moduleName: 'User Transformer',
    errorStage: 'User Transformation',
    description: '',
    payloadStage: payloadStageMap.gateway,
  },
  event_filter: {
    block: 'processor',
    module: Module.event_filter,
    moduleName: 'Event Filter',
    errorStage: 'Delivery',
    description: 'Message type not supported',
    payloadStage: payloadStageMap.gateway,
  },
  dest_transformer: {
    block: 'processor',
    module: Module.dest_transformer,
    moduleName: 'Destination Transformer',
    errorStage: 'Delivery',
    description: '',
    payloadStage: payloadStageMap.gateway,
  },
  router: {
    block: 'delivery',
    module: Module.router,
    moduleName: 'Router',
    subModules: {
      router_dest_transformer: {
        name: 'Router Destination Transformer',
      },
      router_dest_delivery: {
        name: 'Router Destination Delivery',
      },
    },
    errorStage: 'Delivery',
    description: 'Error from cloud destination',
    payloadStage: payloadStageMap.router_input,
  },
  batch_router: {
    block: 'delivery',
    module: Module.batch_router,
    moduleName: 'Batch Router',
    errorStage: 'Delivery',
    description: 'Error from object storage destination',
    payloadStage: payloadStageMap.router_input,
  },
  warehouse: {
    block: 'delivery',
    module: Module.warehouse,
    moduleName: 'Warehouse',
    errorStage: 'Delivery',
    description: 'Error from warehouse destination',
    payloadStage: '',
  },
};

const parseMultipleLevels = (d: string): object | string => {
  let data = d.replace(/\r?\n|\r/g, ''); // replacing new line characters
  data = data.replace(/\s+/g, ' ').trim(); // replacing multiple spaces with single space
  try {
    const parsedData = JSON.parse(data);
    if (typeof parsedData === 'object') {
      const keys = Object.keys(parsedData);
      keys.forEach((key) => {
        if (typeof parsedData[key] === 'string') {
          parsedData[key] = parseMultipleLevels(parsedData[key]);
        }
      });
    }
    return parsedData;
  } catch (error) {
    return data;
  }
};

export const getFormattedSample = (data: string | object, removeStageDetails = false) => {
  const parsedData = typeof data === 'string' ? parseMultipleLevels(data) : data;
  if (typeof parsedData === 'object') {
    return {
      type: 'object',
      sample: JSON.stringify(
        removeStageDetails ? removeStageDetailsFromSampleResponse(parsedData) : parsedData,
        null,
        2,
      ),
    };
  }
  return {
    type: 'string',
    sample: parsedData,
  };
};

const removeStageDetailsFromSampleResponse = (response: object) => {
  const keysToRemove = ['routerSubStage', 'payloadStage'];
  const cleanedResponse = { ...response } as Record<string, unknown>;
  keysToRemove.forEach((key) => {
    delete cleanedResponse[key];
  });

  return cleanedResponse;
};

export const getSampleErrorDetails = ({
  statusCode,
  reportedBy,
  response,
}: {
  statusCode: number;
  reportedBy: string;
  response: string;
}): ErrorDetailsData => {
  const parsedData = parseMultipleLevels(response);
  const moduleDetails = moduleMap[reportedBy as keyof typeof moduleMap];
  let errorStage = moduleDetails?.errorStage || '-';
  let payloadStage = moduleDetails?.payloadStage || '-';

  if (typeof parsedData === 'object' && reportedBy === Module.router) {
    const { routerSubStage, payloadStage: receivedPayloadStage } = parsedData as Record<
      string,
      string
    >;
    errorStage =
      moduleDetails.subModules?.[(routerSubStage as RouterSubModule) || '']?.name ?? errorStage;

    payloadStage =
      receivedPayloadStage && receivedPayloadStage in payloadStageMap
        ? payloadStageMap[receivedPayloadStage]
        : payloadStage;
  }

  return {
    code: statusCode,
    module: moduleDetails?.moduleName || reportedBy,
    errorStage,
    payloadStage,
    description: moduleDetails?.description || '',
    response:
      typeof parsedData === 'object'
        ? JSON.stringify(removeStageDetailsFromSampleResponse(parsedData), null, 2)
        : parsedData,
  };
};

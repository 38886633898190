import { action, observable, makeObservable, computed } from 'mobx';
import { IRootStore } from '@stores/index';
import { apiAuthCaller } from '@services/apiCaller';
import { getApiErrorMessage } from '@components/common/util/util';
import { IDestinationStore } from '@stores/destination';
import {
  DestinationHealth,
  ESDestinationHealthStore,
} from '@stores/healthDashboard/esDestinationHealth';
import { areAllSourcesInDeviceMode } from '@components/destinations/destination-view/utils';
import { convertToPercentage } from './utils';

interface EventReport {
  successSum: number;
  abortSum: number;
}

export interface DestinationDetails {
  destination: IDestinationStore;
  abortSum: number;
  prevAbortSum: number;
  successSum: number;
  prevSuccessSum: number;
  percentage: number;
  prevPercentage: number;
  enabled: boolean;
  errors: number[];
  latency: number | null;
}

export interface EventStreamHealth {
  fetchedData: Record<string, Record<string, EventReport> | null>;
  esDestData: Record<string, DestinationDetails>;
  eventStreamOverview: { total: number; cloudCount: number; warehouseCount: number };
  selectedDestId: string;
  eventStreamDestHealthStore: Record<string, DestinationHealth>;
  selectedDest: DestinationDetails;
  selectedDestHealth: DestinationHealth;
  loading: boolean;
  previousDataLoading: boolean;
  setSelectedDestId(destId: string): void;
  load(type: 'current' | 'previous'): void;
  refresh(): void;
}

const API = {
  eventStream: '/eventStreamHealth',
  latency: '/eventStream/latencyByDestination',
};

export class EventStreamHealthStore implements EventStreamHealth {
  private rootStore: IRootStore;

  @observable public fetchedData: Record<string, Record<string, EventReport> | null> = {
    currentES: null,
    previousES: null,
  };

  @observable public fetchedLatencies: Record<string, number> = {};

  @observable public selectedDestId = '';

  @observable public eventStreamDestHealthStore: Record<string, DestinationHealth> = {};

  @observable public loading = false;

  @observable public previousDataLoading = false;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @computed
  public get selectedDest(): DestinationDetails {
    return this.esDestData[this.selectedDestId];
  }

  @computed
  public get selectedDestHealth(): DestinationHealth {
    return this.eventStreamDestHealthStore[this.selectedDestId];
  }

  @computed
  public get esDestData(): Record<string, DestinationDetails> {
    return this.constructEventStreamTableData(
      this.fetchedData.currentES || {},
      this.fetchedData.previousES || {},
    );
  }

  @computed
  public get eventStreamOverview() {
    const failingDestinations = Object.values(this.esDestData).filter((v) => v.abortSum > 0);
    const total = failingDestinations.length;
    const warehouseCount = failingDestinations.filter(
      (v) => v.destination.destinationDefinition.category === 'warehouse',
    ).length;

    return {
      total,
      cloudCount: total - warehouseCount,
      warehouseCount,
    };
  }

  @action.bound
  public setSelectedDestId(destId: string) {
    this.createDestHealthStore(destId);
    this.selectedDestId = destId;
  }

  @action.bound
  private createDestHealthStore(destId: string) {
    if (this.selectedDestId && this.eventStreamDestHealthStore[this.selectedDestId]) {
      this.eventStreamDestHealthStore[this.selectedDestId].reset();
    }
    if (!destId) {
      return;
    }
    if (!this.eventStreamDestHealthStore[destId]) {
      this.eventStreamDestHealthStore[destId] = new ESDestinationHealthStore(
        this.esDestData[destId],
        this.rootStore,
      );
    }
  }

  private getHealthData = (period: 'currentPeriod' | 'prevPeriod') => {
    const {
      healthDashboardStore,
      workspaceStore: { defaultRegion: region },
    } = this.rootStore;
    const { periodFilter } = healthDashboardStore;
    return apiAuthCaller().get<Record<string, EventReport>>(API.eventStream, {
      params: {
        ...periodFilter[period],
        region,
      },
    });
  };

  private getLatencyData = () => {
    const { healthDashboardStore } = this.rootStore;
    const { periodFilter, showLatencyColumn } = healthDashboardStore;
    if (!showLatencyColumn) {
      return { data: {} };
    }

    return apiAuthCaller().get<Record<string, number>>(API.latency, {
      params: {
        start: periodFilter.currentPeriod.start,
      },
    });
  };

  private constructEventStreamTableData = (
    currentData: Record<string, EventReport>,
    previousData: Record<string, EventReport>,
  ) => {
    const { destinationsListStore } = this.rootStore;
    const { destinationById } = destinationsListStore;
    let list: Record<string, DestinationDetails> = {};
    Object.entries(currentData).forEach(([id, v]) => {
      const res = destinationById(id);
      if (!res) {
        return;
      }
      const { config, sources, enabled } = res;
      if (areAllSourcesInDeviceMode(config, sources)) {
        return;
      }
      const prevAbortSum = previousData?.[id]?.abortSum || 0;
      const prevSuccessSum = previousData?.[id]?.successSum || 0;
      const result = {
        destination: res,
        abortSum: v.abortSum,
        prevAbortSum,
        successSum: v.successSum,
        prevSuccessSum,
        percentage: convertToPercentage(v.successSum, v.abortSum),
        prevPercentage: convertToPercentage(prevSuccessSum, prevAbortSum),
        enabled,
        errors: [],
        latency: this.fetchedLatencies[id] || null,
      };

      list = { [id]: result, ...list };
    });
    return list;
  };

  public load = (type: 'current' | 'previous') => {
    if (type === 'current' && !this.fetchedData.currentES) {
      this.getCurrPeriodData();
    } else if (type === 'previous' && !this.fetchedData.previousES) {
      this.getPrevPeriodData();
    }
  };

  @action.bound
  public refresh = () => {
    this.setSelectedDestId('');
    this.fetchedData = {
      currentES: null,
      previousES: null,
      currentRETL: null,
      previousRETL: null,
    };
    this.eventStreamDestHealthStore = {};
    this.getCurrPeriodData();
  };

  private getCurrPeriodData = async () => {
    // fetching current time period data
    const { messagesStore } = this.rootStore;

    this.loading = true;
    try {
      const currentES = await this.getHealthData('currentPeriod');
      this.fetchedData.currentES = currentES.data;
    } catch (error) {
      messagesStore.showErrorMessage(getApiErrorMessage(error, 'Failed to load data'));
    } finally {
      this.loading = false;
    }
  };

  private getPrevPeriodData = async () => {
    // fetching previous time period data
    // fetching latency data as previous period data to ensure we make the API call only when the event stream tab is active.
    const { messagesStore } = this.rootStore;

    this.previousDataLoading = true;
    try {
      const [healthRes, latencyRes] = await Promise.all([
        this.getHealthData('prevPeriod'),
        this.getLatencyData(),
      ]);
      this.fetchedData.previousES = healthRes.data;
      this.fetchedLatencies = latencyRes.data;
    } catch (error) {
      messagesStore.showErrorMessage(getApiErrorMessage(error, 'Failed to load data'));
    } finally {
      this.previousDataLoading = false;
    }
  };
}

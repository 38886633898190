import { apiAuthCaller } from '@services/apiCaller';
import useStores from '@stores/useStores';
import exportResponseError from '@utils/exportResponseError';
import * as React from 'react';
import { FixMe } from '@utils/types';
import { SelectFieldV1 } from '@components/common/selectField';
import { RedAsterisk } from '@components/common/redAsterisk';
import { LargeText } from '@ui-library/typography';

type FieldType = {
  name: string;
  exclusiveFields: string[];
};

interface InfoResponse {
  attributes: FieldType[];
  segments: FieldType[];
  metrics: FieldType[];
}

interface State {
  excludedFields: string[];
  prevProps: unknown;
  valuesPerField: InfoResponse;
  options: {
    attributes: { value: FixMe; label: string; disabled: boolean }[];
    segments: { value: FixMe; label: string; disabled: boolean }[];
    metrics: { value: FixMe; label: string; disabled: boolean }[];
  } | null;
}

interface Props {
  onChange?: (value: string[]) => void;
  value: string[];
  disabled?: boolean;
  data: InfoResponse;
}

class AdWordsFieldsSelectComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      valuesPerField: {
        attributes: [],
        segments: [],
        metrics: [],
      },
      excludedFields: [],
      prevProps: {},
      options: null,
    };
  }

  static transformFields = (fields: FieldType[], excludedFields: string[]) =>
    fields.map((field) => ({
      label: field.name,
      value: field.name,
      disabled: excludedFields.includes(field.name),
    }));

  /*
   * Transforms the info response into options fittable for the DropDown
   */
  static normalizeOptions = (response: InfoResponse, excludedFields: string[]) => {
    if (!response) {
      return {};
    }
    return {
      attributes: AdWordsFieldsSelectComponent.transformFields(response.attributes, excludedFields),
      segments: AdWordsFieldsSelectComponent.transformFields(response.segments, excludedFields),
      metrics: AdWordsFieldsSelectComponent.transformFields(response.metrics, excludedFields),
    };
  };

  static getFieldValues = (fields: FieldType[], values: string[]): string[] =>
    values.reduce((accum: FixMe[], value) => {
      const fieldValue = fields.find((field) => field.name === value);
      if (fieldValue) {
        accum.push(value);
      }
      return accum;
    }, []);

  /* Takes an array of fields and the response of info
  and returns the data categorized in the correct field type */
  static getDefaultValues = (response: InfoResponse, values: string[]) => ({
    attributes: AdWordsFieldsSelectComponent.getFieldValues(response.attributes, values),
    segments: AdWordsFieldsSelectComponent.getFieldValues(response.segments, values),
    metrics: AdWordsFieldsSelectComponent.getFieldValues(response.metrics, values),
  });

  /*
   * Returns a list of all excluded fields according to the value
   */
  static getExcludedFields = (response: InfoResponse, value: string[]) => {
    if (!response || !value) {
      return [];
    }
    return Object.keys(response).reduce((accum: FixMe[], key: FixMe) => {
      const currentFieldType = response[key as keyof InfoResponse];
      currentFieldType.forEach((field) => {
        if (value.includes(field.name)) {
          accum = accum.concat(field.exclusiveFields);
        }
      });
      return accum;
    }, []);
  };

  static getDerivedStateFromProps(props: Props, state: FixMe) {
    const { data, value, onChange } = props;

    const { options, prevProps = {}, excludedFields, valuesPerField } = state;

    const prevData = prevProps.data || ({} as FixMe);

    const changedIntegrationInfo = prevData && data !== prevData;

    let optionList = options;
    let newExcludedFields = excludedFields;
    const valueHasChange =
      (value && value.length > 0 ? value.length : 0) !==
      (prevProps.value && prevProps.value.length > 0 ? prevProps.value.length : 0);
    // if value changed or integrationInfo response has change recalculate excluded fields
    if (
      (valueHasChange && data) ||
      (!prevData && data && value && value.length > 0) ||
      changedIntegrationInfo
    ) {
      newExcludedFields = AdWordsFieldsSelectComponent.getExcludedFields(data, value);
    }
    let optionValueExists = true;
    let fieldValues = valuesPerField;
    let newValue = value;
    // make sure that all selected values has the corresponding option
    if (value && data) {
      fieldValues = AdWordsFieldsSelectComponent.getDefaultValues(data, value);
      const newFieldValues = [
        ...fieldValues.attributes,
        ...fieldValues.metrics,
        ...fieldValues.segments,
      ];
      if (value.length !== newFieldValues.length) {
        optionValueExists = false;
        newValue = newFieldValues;
      }
    }
    if ((changedIntegrationInfo || !optionValueExists) && onChange) {
      onChange(newValue);
    }
    // calculate optionList
    if (data && (!optionList || changedIntegrationInfo || valueHasChange)) {
      optionList = AdWordsFieldsSelectComponent.normalizeOptions(data, newExcludedFields);
    }
    return {
      // save props as prevProps to be able to check if the integrationInfo has change
      prevProps: props,
      options: optionList,
      excludedFields: newExcludedFields,
      valuesPerField: fieldValues,
    };
  }

  onChange = (fieldType: keyof InfoResponse) => (value: FieldType[]) => {
    const { valuesPerField } = this.state;
    const data = { ...valuesPerField };
    data[fieldType] = value || [];
    const { onChange } = this.props;
    if (onChange) {
      onChange([...(data.attributes as FixMe[]), ...(data.segments as FixMe[]), ...data.metrics]);
    }
  };

  render() {
    const { options } = this.state;
    const { disabled } = this.props;
    const { valuesPerField } = this.state;
    return (
      <div>
        <SelectFieldV1
          disabled={disabled}
          options={(options && options.attributes) || []}
          mode="multiple"
          label="Attributes"
          value={valuesPerField.attributes}
          onChange={this.onChange('attributes')}
        />
        <SelectFieldV1
          disabled={disabled}
          options={(options && options.segments) || []}
          mode="multiple"
          label="Segments"
          value={valuesPerField.segments}
          onChange={this.onChange('segments')}
        />
        <SelectFieldV1
          disabled={disabled}
          options={(options && options.metrics) || []}
          mode="multiple"
          label="Metrics"
          value={valuesPerField.metrics}
          onChange={this.onChange('metrics')}
        />
      </div>
    );
  }
}

export const AdWordsFieldsSelect = (props: {
  onChange: (name: string, value: FixMe) => void;
  preRequisiteValue: string | undefined;
  disabled?: boolean;
  valueName: string;
  label: string;
  required: boolean;
  defaultValue?: string[];
}) => {
  const { onChange, required, label, preRequisiteValue, valueName, disabled, defaultValue } = props;
  const { messagesStore } = useStores();
  const [fieldData, setFieldData] = React.useState<FixMe>();
  const [values, setValues] = React.useState<string[]>(defaultValue || []);
  React.useEffect(() => {
    const path = `/cloudSources/info/roles/google_adwords/info/reportTypes/${
      preRequisiteValue || ''
    }/fields`;
    apiAuthCaller()
      .get(path)
      .then((resp) => {
        setFieldData(resp.data);
      })
      .catch((err) => {
        const errorMessage = exportResponseError(err)?.message;
        if (errorMessage) {
          messagesStore!.showErrorMessage(errorMessage);
        }
      });
  }, [preRequisiteValue]);

  if (!fieldData || Object.keys(fieldData).length === 0) {
    return null;
  }
  return (
    <div>
      <LargeText className="m-b-xs">
        {label}
        {required && <RedAsterisk />}
      </LargeText>
      <AdWordsFieldsSelectComponent
        data={fieldData}
        value={values}
        disabled={disabled}
        onChange={(v) => {
          setValues(v);
          onChange(valueName, !(v && v.length > 0) ? undefined : v);
        }}
      />
    </div>
  );
};

import React from 'react';
import { Tooltip as AntTooltip, TooltipProps } from 'antd';

// ts-prune-ignore-next
export type { TooltipProps } from 'antd';

export const Tooltip = ({
  title = '',
  trigger = 'hover',
  placement = 'top',
  open,
  arrow = true,
  children,
  ...rest
}: TooltipProps) => (
  <AntTooltip
    arrow={arrow}
    title={title}
    open={open}
    trigger={trigger}
    placement={placement}
    overlayClassName="custom_tooltip"
    {...rest}
  >
    {children}
  </AntTooltip>
);

module.exports = {
  "project_64c0dc71ffddf09e95b831f3": {
    "base": require('./Custom Stripe Billing UI.json')
  },
  "project_651469d044134222fe8dcbf7": {
    "base": require('./Data Catalog.json')
  },
  "project_638523fc63f559803763cec0": {
    "base": require('./Data Residency UI.json')
  },
  "project_63062f550e89ed6a0dfbe143": {
    "base": require('./Data Retention UI.json')
  },
  "project_630fc9c45c0e7caa5f6fde15": {
    "base": require('./In App Email Support.json')
  },
  "project_635944cdd3f2b3c9c3a38b2b": {
    "base": require('./Tracking Plans.json')
  }
}
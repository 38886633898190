import { CatchErr } from '@utils/types';

interface IError {
  code: string;
  message: string;
  reason?: string;
}

export type ApiErrorResponse = {
  success: false;
  errorDetails: IError;
};

export type ApiSuccessResponse<T> = {
  success: true;
  data: T;
};

export type ApiResponse<T> = ApiErrorResponse | ApiSuccessResponse<T>;

function isFollowingNewSourcesResponseFramework(response: object): boolean {
  return response && (response as ApiResponse<object>).success !== undefined;
}

function isErrorFromNewSourceResponseFramework(err: CatchErr): boolean {
  return err?.response?.data?.errorDetails !== undefined;
}

const formatError = (error: ApiErrorResponse) => ({
  response: { data: { errorDetails: error.errorDetails } },
});

function getRespData<T>(dataInResp: ApiResponse<T>): T {
  if (!dataInResp.success) {
    throw formatError(dataInResp);
  }
  return dataInResp.data;
}

const convertErrorToHumanReadable = ({ message, reason }: IError): string =>
  message + (reason ? ` : ${reason}` : '');

const getApiErrorMessageDetails = (
  err: CatchErr,
  fallback: string,
): {
  messageWithFallback: string;
  message: string;
} => {
  const message = err?.response?.data?.errorDetails
    ? convertErrorToHumanReadable(err.response.data.errorDetails)
    : 'Unexpected error!';

  return { messageWithFallback: `${fallback} - ${message}`, message };
};

export {
  getRespData,
  getApiErrorMessageDetails,
  isFollowingNewSourcesResponseFramework,
  isErrorFromNewSourceResponseFramework,
  formatError,
};

import { isValidJson } from '@components/common/util/util';
import { webappClient, sourcesClient } from '@lib/bugsnag';
import { stores } from '@stores/index';

const xRequestIdKey = 'x-request-id';

type Headers = {
  [xRequestIdKey]: string;
};

export type RequestConfig = {
  url?: string;
  baseURL?: string;
  method?: string;
  startTime?: Date;
  timeout?: number;
  startVisibility?: string;
  ignore?: boolean;
  ignore400?: boolean;
  data: string;
};

interface ApiError extends Error {
  response:
    | {
        status: number;
        data: Record<string, unknown> | string;
        headers: Headers;
      }
    | undefined;
  config: RequestConfig;
  code?: string;
}

const Logger = {
  logApi(error: ApiError) {
    if (error.config.ignore) {
      return;
    }
    const request = getRequestObject(error.config, error.response?.headers[xRequestIdKey] || '');
    error.response ? logBackendError(error, request) : logTimeouts(error, request);
  },

  log(
    error: Error,
    description: string,
    metaData?: Record<string, unknown>,
    client = webappClient,
  ) {
    client.addMetadata('user', { tier: stores.billingStore.tenantInfo?.planName || 'N/A OR OS' });
    client.leaveBreadcrumb(description, metaData);
    error.message = `${description} - ${error.message}`;
    client.notify(error);
  },
};

/* eslint-disable import/no-default-export */
export default Logger;

const getRequestObject = (config: ApiError['config'], requestId: string) => ({
  baseURL: config?.baseURL,
  url: config?.url,
  method: config?.method,
  responseTime: (new Date().valueOf() - config.startTime!.valueOf() || 0) / 1000,
  timeout: config.timeout! / 1000,
  visibility: {
    start: config.startVisibility,
    end: document.visibilityState,
  },
  requestId,
  ...(isValidJson(config.data) && { body: JSON.parse(config.data) }),
});

const logTimeouts = (error: ApiError, request: ReturnType<typeof getRequestObject>) => {
  if (request.url?.includes('v1/session')) {
    return;
  }
  /*
    CloudFront timeout is 60sec. So APIs getting logged with response time of 61 sec
    or more have the timing logic incorrect and so can be ignored
  */
  if (
    request.responseTime > request.timeout &&
    request.visibility.start !== 'hidden' &&
    request.responseTime < 70 &&
    stores.userStore.loggedIn
  ) {
    Logger.log(error, error.code || 'No error code', request, getClient(request.url || ''));
  }
};

const logBackendError = (error: ApiError, request: ReturnType<typeof getRequestObject>) => {
  const { status, data } = error.response!;
  if (status === 400 && error.config.ignore400) {
    return;
  }
  const metaData = {
    request,
    response: {
      status,
      data,
    },
  };
  Logger.log(error, request.url!, metaData, getClient(request.url || ''));
};

const getClient = (url: string) =>
  [
    'cloudSources/',
    'integrationsInfo/',
    'warehouseSources/',
    'reports/',
    'sqlModels',
    'audiences',
    'retl',
  ].some((x) => url.includes(x))
    ? sourcesClient
    : webappClient;

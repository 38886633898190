import { apiAuthCaller } from '@services/apiCaller';
import { action, observable, makeObservable } from 'mobx';
import { IAccount } from './accounts';
import { ISourceDefinition } from './sourceDefinitionsList';
import { IUserStore } from './user';

export interface IModelConfig {
  description?: string;
  sql: string;
}

export interface IModel {
  id: string;
  name: string;
  account: IAccount;
  config: IModelConfig;
  sourceDefinition: ISourceDefinition;
  createdAt: string;
  updatedAt: string;
  sourceIds: string[];
}

export interface IModelStore extends IModel {
  update: (values: {
    name?: string;
    config?: Partial<IModelConfig>;
    accountId?: string;
  }) => Promise<void>;
  setSourceIds: (sourceIds: string[]) => void;
}

type RootStore = {
  userStore: IUserStore;
};

export class ModelStore implements IModelStore {
  @observable id: string;

  @observable name: string;

  @observable config: IModelConfig;

  @observable rootStore: RootStore;

  @observable sourceDefinition: ISourceDefinition;

  @observable createdAt: string;

  @observable updatedAt: string;

  @observable account: IAccount;

  @observable sourceIds: string[];

  constructor(model: IModelStore, rootStore: RootStore) {
    makeObservable(this);
    this.id = model.id;
    this.name = model.name || '';
    this.config = model.config || {};
    this.sourceDefinition = model.sourceDefinition || {};
    this.createdAt = model.createdAt;
    this.updatedAt = model.updatedAt;
    this.account = model.account || {};
    this.sourceIds = model.sourceIds || [];
    this.rootStore = rootStore;
  }

  @action.bound
  public async update(values: {
    name?: string;
    config?: Partial<IModelConfig>;
    accountId?: string;
  }): Promise<void> {
    let newConfig;
    if (values.config) {
      newConfig = { ...this.config, ...values.config };
    }

    const resp = await apiAuthCaller().put(`/sqlModels/${this.id}`, {
      name: values.name,
      config: newConfig,
      accountId: values.accountId,
    });
    const updatedModel: IModel = resp.data;
    this.name = updatedModel.name;
    this.config = updatedModel.config;
    this.account = updatedModel.account;
  }

  @action.bound
  public setSourceIds(sourceIds: string[]) {
    this.sourceIds = sourceIds;
  }
}

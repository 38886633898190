import { action, observable, computed, makeObservable } from 'mobx';

import { getApiErrorMessage } from '@components/common/util/util';
import { apiAuthCaller } from '@services/apiCaller';
import { IConfig, IAudience, UpdatePayload } from './types';
import { IAccount } from '@stores/accounts';
import { IConnectionsStore } from '@stores/connections';
import { IDestinationsListStore } from '@stores/destinationsList';
import { IUserStore } from '@stores/user';
import { IMessageStore } from '@stores/messages';

type RootStore = {
  connectionsStore: IConnectionsStore;
  destinationsListStore: IDestinationsListStore;
  userStore: IUserStore;
  messagesStore: IMessageStore;
};

export class AudienceStore {
  rootStore: RootStore;

  @observable id: string;

  @observable name: string;

  @observable config: IConfig;

  @observable account: IAccount;

  @observable sourceIds: string[];

  @observable createdAt: string;

  @observable updatedAt: string;

  constructor(audience: IAudience, rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.id = audience.id;
    this.name = audience.name || '';
    this.config = audience.config || {};
    this.account = audience.account || {};
    this.sourceIds = audience.sourceIds || [];
    this.createdAt = audience.createdAt || '';
    this.updatedAt = audience.updatedAt || '';
  }

  @action.bound
  public async update(audience: UpdatePayload): Promise<boolean> {
    const { messagesStore } = this.rootStore;

    try {
      const newConfig = {
        filterSpec: {
          ...this.config.filterSpec,
          ...audience.config?.filterSpec,
        },
        description: audience.config?.description || this.config.description,
      };

      const payload = {
        name: audience.name || this.name,
        ...(audience.config && { config: newConfig }),
      };

      const { data } = await apiAuthCaller().put<IAudience>(`/audiences/${this.id}`, payload);

      this.name = data.name;
      this.config.filterSpec = data.config.filterSpec;
      this.config.description = data.config.description;

      return true;
    } catch (err) {
      messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to update the audience'));
    }
    return false;
  }

  @computed get destinations() {
    const destinationIds: string[] = [];
    this.sourceIds.forEach((sourceId) => {
      const destIds = this.rootStore.connectionsStore.connections[sourceId] ?? [];
      destinationIds.push(...destIds);
    });

    return this.rootStore.destinationsListStore.destinations.filter((destination) =>
      destinationIds.includes(destination.id),
    );
  }

  @action.bound
  public disconnect(sourceId: string) {
    this.sourceIds = this.sourceIds.filter((id) => id !== sourceId);
  }

  @action.bound
  public connect(sourceId: string) {
    this.sourceIds = [...this.sourceIds, sourceId];
  }
}

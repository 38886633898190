import styled, { css } from 'styled-components';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '@css/theme';

export const CloseIcon = styled(FontAwesomeIcon).attrs({
  icon: solid('circle-xmark'),
})`
  position: absolute;
  top: -7px;
  right: -7px;
  font-size: ${theme.token.fontSizeLG}px;
`;

const arrowFix = css`
  .ant-popover-placement-topLeft .ant-popover-arrow,
  .ant-popover-placement-topRight .ant-popover-arrow,
  .ant-popover-placement-top .ant-popover-arrow {
    bottom: 1.3px !important;
  }

  .ant-popover-placement-bottomLeft .ant-popover-arrow,
  .ant-popover-placement-bottomRight .ant-popover-arrow,
  .ant-popover-placement-bottom .ant-popover-arrow {
    top: 1.3px !important;
  }

  .ant-popover-placement-leftBottom .ant-popover-arrow,
  .ant-popover-placement-leftTop .ant-popover-arrow,
  .ant-popover-placement-left .ant-popover-arrow {
    right: 1.3px !important;
  }

  .ant-popover-placement-rightBottom .ant-popover-arrow,
  .ant-popover-placement-rightTop .ant-popover-arrow,
  .ant-popover-placement-right .ant-popover-arrow {
    left: 1.3px !important;
  }
`;

const createPopoverStyle = (
  className: string,
  { colorToken = 'colorBorder' }: { colorToken?: keyof typeof theme.token } = {},
) => css`
  .${className} {
    .ant-popover-arrow::after {
      border: 1px solid ${({ theme }) => theme.token[colorToken]};
    }

    .ant-popover-content {
      max-width: 450px;
      word-wrap: break-word;

      .ant-popover-inner {
        border: 1px solid ${({ theme }) => theme.token[colorToken]};

        .ant-popover-inner-content {
          cursor: pointer;
        }
      }
    }
  }
`;

export const popoverDefaultStyle = createPopoverStyle('ui_library_popover');
export const popoverPromotionalStyle = createPopoverStyle('ui_library_popover_promotional', {
  colorToken: 'colorPrimary',
});

export const popoverCommonStyle = css`
  ${arrowFix}

  .ui-library-popover-no-padding .ant-popover-inner {
    padding: 0;
  }
`;

import { makeObservable, observable, action } from 'mobx';
import { getApiErrorMessage } from '@components/common/util/util';
import { IRootStore } from '@stores/index';
import { WorkspaceRole } from './member';
import { apiAuthCaller } from '@services/apiCaller';

export interface ServiceAccessToken {
  id: string;
  name: string;
  description?: string;
  type: ServiceAccessTokenType;
  organizationId: string;
  workspaceId?: string;
  workspaceRoles?: WorkspaceRole[];
}

export interface CreateTokenRequest {
  name: string;
  description?: string;
  type: ServiceAccessTokenType;
  organizationId: string;
  workspaceId?: string;
  workspaceRoles?: WorkspaceRole[];
}

export type ICreateTokenResp = ServiceAccessToken & { accessToken: string };

export interface IServiceAccessToken {
  firstLoad: boolean;
  serviceAccessTokens: ServiceAccessToken[];
  setServiceAccessTokens: (_: ServiceAccessToken[]) => void;
  getServiceAccessTokens: () => Promise<void>;
  createServiceAccessToken: (token: CreateTokenRequest) => Promise<ICreateTokenResp | undefined>;
  deleteServiceAccessToken: (token: string) => Promise<boolean>;
  getServiceTokenById: (tokenId: string) => ServiceAccessToken | undefined;
  readonly currentWorkSpaceTokens: ServiceAccessToken[];
  reset: () => void;
}

enum ServiceAccessTokenType {
  ORGANIZATION = 'organization',
  WORKSPACE = 'workspace',
}

export class ServiceAccessTokenStore implements IServiceAccessToken {
  rootStore: IRootStore;

  @observable firstLoad = false;

  @observable serviceAccessTokens: ServiceAccessToken[] = [];

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  reset() {
    this.serviceAccessTokens = [];
    this.firstLoad = false;
  }

  @action.bound
  setServiceAccessTokens(tokens: ServiceAccessToken[]) {
    this.serviceAccessTokens = tokens;
  }

  @action.bound
  async getServiceAccessTokens(): Promise<void> {
    const { messagesStore, organizationStore } = this.rootStore;
    try {
      const resp = await apiAuthCaller({ nonWorkspaceRoute: true }).get<ServiceAccessToken[]>(
        `organizations/${organizationStore.id}/service-access-tokens`,
      );
      this.setServiceAccessTokens(resp.data);
    } catch (error) {
      messagesStore.showErrorMessage(
        getApiErrorMessage(error, 'Failed to get Service Access Tokens'),
      );
    } finally {
      this.firstLoad = true;
    }
  }

  @action.bound
  async createServiceAccessToken(token: CreateTokenRequest): Promise<ICreateTokenResp | undefined> {
    const { messagesStore, organizationStore } = this.rootStore;
    try {
      const resp = await apiAuthCaller({ nonWorkspaceRoute: true }).post<ICreateTokenResp>(
        `organizations/${organizationStore.id}/service-access-tokens`,
        token,
      );
      const serviceAccessToken = resp.data;
      const { accessToken, ...serviceAccessTokenResponse } = serviceAccessToken;
      this.serviceAccessTokens.push(serviceAccessTokenResponse);
      return serviceAccessToken;
    } catch (error) {
      messagesStore.showErrorMessage(
        getApiErrorMessage(error, 'Failed to create Service Access Token'),
      );
      return undefined;
    }
  }

  @action.bound
  async deleteServiceAccessToken(id: string): Promise<boolean> {
    const { messagesStore, organizationStore } = this.rootStore;
    const token = this.serviceAccessTokens.find((accessToken) => accessToken.id === id);
    if (!token) {
      messagesStore.showErrorMessage('Token not found');
      return false;
    }
    try {
      await apiAuthCaller({ nonWorkspaceRoute: true }).delete<void>(
        `organizations/${organizationStore.id}/service-access-tokens/${id}`,
      );

      this.serviceAccessTokens = this.serviceAccessTokens.filter(
        (accessToken) => accessToken.id !== id,
      );
      messagesStore.showSuccessMessage(`Token - ${token.name} deleted successfully`);
      return true;
    } catch (error) {
      messagesStore.showErrorMessage(
        getApiErrorMessage(error, 'Failed to delete Service Access Token'),
      );
      return false;
    }
  }

  getServiceTokenById(tokenId: string) {
    return this.serviceAccessTokens.find((serviceAccessToken) => serviceAccessToken.id === tokenId);
  }

  get currentWorkSpaceTokens() {
    const { workspaceStore } = this.rootStore;

    return this.serviceAccessTokens.filter(
      (token) =>
        token.type === ServiceAccessTokenType.WORKSPACE && token.workspaceId === workspaceStore.id,
    );
  }
}

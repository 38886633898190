import { Region } from '@components/common/constants';
import { getApiErrorMessage } from '@components/common/util/util';
import { IRespData } from '@components/sources/liveEvents/types';
import merge from 'lodash/merge';
import {
  EventStatParams,
  TrackEventParams,
} from '@components/sources/source-view/source-events/hooks';
import {
  EventsWithViolationParams,
  EventsWithViolationResponse,
  TrackingPlanAggregatedData,
  TrackingPlanStats,
  TrackingPlanStatsParams,
} from '@components/sources/source-view/source-events/trackingPlan/hooks';
import { BATCH_SIZE } from '@components/sources/source-view/source-events/utils';
import { apiAuthCaller } from '@services/apiCaller';
import { IConnectionsStore } from '@stores/connections';
import { IDestinationsListStore } from '@stores/destinationsList';
import { IMessageStore } from '@stores/messages';
import { ISourceDefinitionsListStore } from '@stores/sourceDefinitionsList';
import { ISourcesListStore } from '@stores/sourcesList';
import { ITrackingPlanListStore } from '@stores/trackingPlans/trackingPlansList';
import { IUserStore } from '@stores/user';
import { IWorkspaceStore } from '@stores/workspace';
import SourceFactory from '@components/sources/sourceFactory';
import { action, computed, makeObservable, observable } from 'mobx';
import { IDestinationStore } from '../destination';
import { WarehouseSourceOrigin } from '../types';
import { coerceRetlSubcategory, getOrigin } from '../util';
import { AggregatedData, EventStatsResponse, Source, SourceConfig, SourceCategory } from './types';
import { mergeAggregatedEventsStats } from './utils';

export type SecretConfig = Record<string, unknown>;

export interface EventStats {
  graph: AggregatedData[];
  totalSent: number;
}
export interface TrackEventsResponse {
  data?: TrackEvent[];
  hasMore: boolean;
}

interface TrackEvent {
  name: string;
  type: string;
  count: number;
  lastSent: number;
}

export interface TPGlobalConfig {
  dropUnplannedEvents?: boolean;
  dropUnplannedProperties?: boolean;
  dropOtherViolation?: boolean;
  propagateValidationErrors?: boolean;
}
interface TPGlobalConfigExtended extends TPGlobalConfig {
  sendViolatedEventsTo?: string;
}

export enum ITPSettingsType {
  identify = 'identify',
  group = 'group',
  page = 'page',
  screen = 'screen',
  track = 'track',
  global = 'global',
}

export type ITPSettingsEventType = Exclude<ITPSettingsType, ITPSettingsType.global>;

export interface ITPConfig extends Record<ITPSettingsType, TPGlobalConfig> {
  global: TPGlobalConfigExtended;
}

export interface ISourceTPConfig {
  config: ITPConfig;
  trackingPlanId: string;
}

export interface ISource extends Source {
  writeKey: string;
  createdAt: string;
  config: SourceConfig;
  secretConfig: SecretConfig;
  sourceDefinitionId: string;
  destinations: IDestinationStore[];
  transient: boolean;
  isGeoEnrichmentEnabled: boolean;
  trackingPlanConfig: ISourceTPConfig | undefined;
  origin: WarehouseSourceOrigin;
  updateConfig(config?: SourceConfig, hardUpdate?: boolean): Promise<boolean>;
}

type RootStore = {
  sourceDefinitionsListStore: ISourceDefinitionsListStore;
  sourcesListStore: ISourcesListStore;
  connectionsStore: IConnectionsStore;
  destinationsListStore: IDestinationsListStore;
  userStore: IUserStore;
  messagesStore: IMessageStore;
  workspaceStore: IWorkspaceStore;
  trackingPlanListStore: ITrackingPlanListStore;
};

interface TrackingPlan {
  id: string;
  name: string;
  createdAt: string;
  version?: string;
}

export interface TrackingPlanWithVersions extends TrackingPlan {
  versions?: string[];
  activeVersion?: string;
}

type TrackingPlanVersions = Record<string, number[]>;

export interface ISourceStore extends ISource {
  eventStats: EventStats | undefined;
  trackEvents: TrackEventsResponse | undefined;
  rootStore: RootStore;
  setName(name: string): void;
  getLiveEvents(latestId: number, from: number): Promise<IRespData[]>;
  getEventStats: (params: EventStatParams, regions: Region[], callback: () => void) => void;
  getTotalEvents: (params: EventStatParams, callback: (count: number | undefined) => void) => void;
  getTrackEvents: (params: TrackEventParams, callback?: () => void) => void;
  getHistoricalTrackingPlans: (
    params: {
      start?: string;
      regions: Region[];
    },
    trackingPlanId?: string,
  ) => Promise<TrackingPlanWithVersions[] | undefined>;
  getTrackingPlanStats: (params: TrackingPlanStatsParams) => Promise<TrackingPlanStats | undefined>;
  getTrackingPlanVersions: (params: {
    start?: string;
    regions: Region[];
  }) => Promise<TrackingPlanVersions | undefined>;
  getEventsWithViolations: (
    params: EventsWithViolationParams,
  ) => Promise<EventsWithViolationResponse | undefined>;
  isEventStream: () => boolean;
}

export type SourceConstructor = {
  id: string;
  name: string;
  createdAt: string;
  writeKey: string;
  enabled: boolean;
  isGeoEnrichmentEnabled: boolean;
  config: SourceConfig;
  secretConfig: SecretConfig;
  sourceDefinitionId: string;
  transient: boolean;
  trackingPlanConfig: ISourceTPConfig | undefined;
};

export class SourceStore implements ISourceStore {
  @observable public id: string;

  @observable public name: string;

  @observable public createdAt: string;

  @observable public writeKey: string;

  @observable public enabled: boolean;

  @observable public isGeoEnrichmentEnabled: boolean;

  @observable public config: SourceConfig;

  @observable public transient: boolean;

  @observable public secretConfig: SecretConfig;

  @observable public sourceDefinitionId: string;

  @observable public trackingPlanConfig: ISourceTPConfig | undefined;

  @observable public rootStore: RootStore;

  @observable eventStats: EventStats | undefined;

  @observable trackEvents: TrackEventsResponse | undefined;

  constructor(source: SourceConstructor, rootStore: RootStore) {
    makeObservable(this);
    this.id = source.id;
    this.name = source.name;
    this.createdAt = source.createdAt;
    this.writeKey = source.writeKey;
    this.enabled = source.enabled;
    this.isGeoEnrichmentEnabled = source.isGeoEnrichmentEnabled;
    this.config = source.config;
    this.secretConfig = source.secretConfig;
    this.sourceDefinitionId = source.sourceDefinitionId;
    this.transient = source.transient;
    const prevState = rootStore.sourcesListStore.sourceById(this.id);
    this.eventStats = prevState?.eventStats;
    this.trackEvents = prevState?.trackEvents;
    this.trackingPlanConfig = source.trackingPlanConfig;
    this.rootStore = rootStore;
  }

  @action.bound
  public setName(name: string): void {
    this.name = name;
  }

  @computed get sourceDef() {
    return this.rootStore.sourceDefinitionsListStore.getById(this.sourceDefinitionId)!;
  }

  @computed get destinations() {
    const destIds = this.rootStore.connectionsStore.connections[this.id] || [];
    return this.rootStore.destinationsListStore.destinations.filter((dest) =>
      destIds.includes(dest.id),
    );
  }

  @computed get category() {
    return this.sourceDef.category;
  }

  @action.bound
  isEventStream() {
    return !this.category || this.category === 'webhook';
  }

  @action.bound
  public async updateConfig(config: SourceConfig, hardUpdate = false) {
    const { messagesStore } = this.rootStore;
    try {
      const updateUrl = `/sources/${this.id}${hardUpdate ? '?hardUpdate=true' : ''}`;
      const res = await apiAuthCaller().post(updateUrl, {
        config,
      });
      this.config = res.data.config;
      return (await res.status) === 200;
    } catch (error) {
      messagesStore.showErrorMessage(
        getApiErrorMessage(error, 'Failed to update source configuration.'),
      );
      return false;
    }
  }

  public async getLiveEvents(latestId: number, timestamp: number) {
    try {
      const resp = await apiAuthCaller().get(`/eventUploads`, {
        headers: { 'X-WRITE-KEY': this.writeKey },
        params: { id: latestId, from: timestamp },
      });
      return resp.data.map((e: { event: unknown }) => ({
        ...e,
        payload: e.event,
      }));
    } catch (err) {
      return [];
    }
  }

  private getTrackingPlanStatsForRegion(
    region: Region,
    params: TrackingPlanStatsParams,
  ): Promise<{ data: TrackingPlanStats }> {
    const { start, aggregationMinutes, trackingPlanId, trackingPlanVersion } = params;
    return apiAuthCaller().get(`/sources/${this.id}/trackingPlans/eventFlowGraph`, {
      params: {
        start,
        aggregationMinutes,
        trackingPlanId,
        region,
        trackingPlanVersion,
      },
    });
  }

  private getEventsStatsForRegion(
    region: Region,
    params: EventStatParams,
  ): Promise<{ data: EventStatsResponse }> {
    const { start, aggregationMinutes } = params;
    const useRealtimeApi = aggregationMinutes === 10;
    return apiAuthCaller().get(`/sources/${this.id}/eventMetrics`, {
      params: {
        start,
        aggregationMinutes: useRealtimeApi ? 60 : aggregationMinutes,
        useRealtimeApi,
        region,
      },
    });
  }

  async getTrackingPlanStats(
    params: TrackingPlanStatsParams,
  ): Promise<TrackingPlanStats | undefined> {
    const initialValue = {
      aggregates: [] as TrackingPlanAggregatedData[],
      totalEventsDropped: 0,
      totalEventsValidated: 0,
      totalEventsWithViolations: 0,
    };
    try {
      const response = await Promise.all(
        params.regions.map((region) => this.getTrackingPlanStatsForRegion(region, params)),
      );

      return response.reduce((acc, r) => {
        const { aggregates, totalEventsDropped, totalEventsValidated, totalEventsWithViolations } =
          r.data;

        const newGraph = mergeAggregatedEventsStats<TrackingPlanAggregatedData>(
          acc.aggregates,
          aggregates,
          'eventsDropped',
          'eventsWithViolations',
        );

        return {
          aggregates: newGraph,
          totalEventsDropped: acc.totalEventsDropped + (totalEventsDropped || 0),
          totalEventsValidated: acc.totalEventsValidated + (totalEventsValidated || 0),
          totalEventsWithViolations:
            acc.totalEventsWithViolations + (totalEventsWithViolations || 0),
        };
      }, initialValue);
    } catch (err) {
      this.rootStore.messagesStore.showErrorMessage(
        getApiErrorMessage(err, 'Failed to get source events violations'),
      );
    }
    return undefined;
  }

  getEventStats(params: EventStatParams, regions: Region[], callback: () => void) {
    Promise.all(regions.map((region) => this.getEventsStatsForRegion(region, params)))
      .then((res) => {
        this.eventStats = { graph: [], totalSent: 0 };
        res.forEach((r) => {
          const { aggregates, totalSent } = r.data;
          this.eventStats = {
            graph: mergeAggregatedEventsStats<AggregatedData>(this.eventStats!.graph, aggregates),
            totalSent: this.eventStats!.totalSent + totalSent ?? 0,
          };
        });
      })
      .catch((err) => {
        this.rootStore.messagesStore.showErrorMessage(
          getApiErrorMessage(err, 'Failed to get source events data'),
        );
      })
      .finally(() => {
        callback();
      });
  }

  // Returns the trackings plans applied to the source after the given start date
  async getHistoricalTrackingPlans(
    {
      start,
      regions,
    }: {
      start?: string;
      regions: Region[];
    },
    // if trackingPlanId is provided, only the versions of the specified tracking plan will be returned
    trackingPlanId?: string,
  ): Promise<TrackingPlanWithVersions[] | undefined> {
    let trackingPlanList: TrackingPlan[] = [];
    if (trackingPlanId) {
      const defaultTrackingPlan = this.rootStore.trackingPlanListStore.getById(trackingPlanId);
      if (defaultTrackingPlan) {
        trackingPlanList = [
          {
            ...defaultTrackingPlan,
          },
        ];
      }
    } else {
      // get the previous applied tracking plans
      const { data = [] as TrackingPlan[] } = await apiAuthCaller().get<TrackingPlan[]>(
        `/sources/${this.id}/trackingPlans`,
        {
          params: {
            start,
          },
        },
      );
      trackingPlanList = data;
    }

    let currentTrackingPlan = trackingPlanList.find(
      (tp: TrackingPlan) => tp.id === this.trackingPlanConfig?.trackingPlanId,
    );

    // In some cases the tracking plan list might not contain the current tracking plan
    if (!currentTrackingPlan) {
      currentTrackingPlan = this.rootStore.trackingPlanListStore.getById(
        this.trackingPlanConfig?.trackingPlanId || '',
      );
    }

    // Filter out the current tracking plan from the list to avoid duplicates
    trackingPlanList = trackingPlanList.filter(
      (tp: TrackingPlan) => tp.id !== currentTrackingPlan?.id,
    );

    if (currentTrackingPlan) {
      trackingPlanList.unshift(currentTrackingPlan);
    }

    const versions = (await this.getTrackingPlanVersions({ start, regions })) || {};

    return trackingPlanList.map((tp) => {
      const tpVersions: string[] = versions[tp.id as keyof typeof versions]?.map(String) || [];
      const activeVersion =
        (tp.version && String(tp.version)) ||
        this.rootStore.trackingPlanListStore.getById(tp.id)?.version;
      // Ensure the active version is included in the list of versions
      if (activeVersion && !tpVersions.includes(activeVersion)) {
        tpVersions.push(activeVersion);
      }
      return {
        ...tp,
        versions: tpVersions,
        activeVersion,
      };
    });
  }

  // Returns the trackings plans version of the source after the given start date
  async getTrackingPlanVersions({
    start,
    regions,
  }: {
    start?: string;
    regions: Region[];
  }): Promise<TrackingPlanVersions> {
    const response = await Promise.all(
      regions.map(async (region) => {
        const { data } = await apiAuthCaller().get<TrackingPlanVersions>(
          `/sources/${this.id}/trackingPlanVersions`,
          {
            params: {
              start,
              region,
            },
          },
        );
        return data;
      }),
    );

    return merge({}, ...response);
  }

  getTotalEvents(params: EventStatParams, callback: (count: number | undefined) => void) {
    const { start } = params;
    const {
      messagesStore,
      workspaceStore: { reportingRegions },
    } = this.rootStore;
    Promise.all(
      reportingRegions.map((region) =>
        apiAuthCaller().get(`/sources/${this.id}/totalEvents`, {
          params: {
            start,
            region,
          },
        }),
      ),
    )
      .then((res) => {
        const total = res.reduce((acc, r) => acc + r.data.total, 0);
        callback(total || 0);
      })
      .catch((err) => {
        messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to get source events data'));
      });
  }

  getTrackEvents(params: TrackEventParams, callback?: () => void) {
    const isFirstCall = params.page === 0;
    if (isFirstCall) {
      this.trackEvents = undefined;
    }
    const { messagesStore } = this.rootStore;
    apiAuthCaller()
      .get(`/sources/${this.id}/events`, { params })
      .then((res) => {
        this.trackEvents = {
          ...this.trackEvents,
          hasMore: res.data.length === BATCH_SIZE,
        };
        if (res.data.length === 0 && !isFirstCall) {
          messagesStore.showErrorMessage('No more events found');
        } else {
          this.trackEvents.data = isFirstCall ? res.data : this.trackEvents.data?.concat(res.data);
        }
      })
      .catch((err) => {
        messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to get event names list'));
      })
      .finally(() => {
        callback?.();
      });
  }

  async getEventsWithViolations(
    params: EventsWithViolationParams,
  ): Promise<EventsWithViolationResponse | undefined> {
    const { messagesStore } = this.rootStore;
    try {
      const response = await apiAuthCaller().get(`/sources/${this.id}/trackingPlans/eventMetrics`, {
        params,
      });
      return response.data as EventsWithViolationResponse;
    } catch (err) {
      messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to get event names list'));
    }
    return undefined;
  }

  @computed get origin() {
    return getOrigin(this.config);
  }

  /**
   * Calculate the source type based on the source definition category and
   * source config origin
   */

  @computed get sourceCategory(): SourceCategory {
    // eslint-disable-next-line no-nested-ternary
    return SourceFactory(this.sourceDef.category).isWarehouse()
      ? {
          category: 'retl' as const,
          subcategory: coerceRetlSubcategory(this.origin),
        }
      : ['singer-protocol', 'cloud'].includes(this.sourceDef.category ?? '')
      ? { category: 'extract' as const }
      : {
          category: 'event' as const,
        };
  }
}

import { action, makeObservable, observable } from 'mobx';
import {
  DestinationTransformationStore,
  IDestinationTransformation,
  IDestinationTransformationStore,
} from './destinationTransformation';
import { IUserStore } from './user';
import { IMessageStore } from './messages';

export interface IDestinationTransformationList {
  connections: Record<string, IDestinationTransformationStore>;
  add: (
    destinationTransformation: IDestinationTransformation[] | IDestinationTransformation,
  ) => void;
  get: (
    destinationId: string,
    transformationId: string,
  ) => IDestinationTransformationStore | undefined;
  remove: (destinationId: string, transformationId: string) => void;
  removeByDestinationId: (destinationId: string) => void;
  getByDestinationId: (destinationId: string) => IDestinationTransformationStore[];
  getByTransformationId: (transformationId: string) => IDestinationTransformationStore[];
}
type RootStore = {
  userStore: IUserStore;
  messagesStore: IMessageStore;
};

export class DestinationTransformationListStore implements IDestinationTransformationList {
  @observable connections: Record<string, IDestinationTransformationStore> = {};

  @observable rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  private getMapEntry = (destinationId: string, transformationId: string) =>
    `${destinationId}-${transformationId}`;

  private addDestinationTransformation = (
    destinationTransformation: IDestinationTransformation,
  ) => {
    const mapEntry = this.getMapEntry(
      destinationTransformation.destinationId,
      destinationTransformation.transformationId,
    );
    if (!this.connections[mapEntry]) {
      this.connections[mapEntry] = new DestinationTransformationStore(
        destinationTransformation,
        this.rootStore,
      );
    }
  };

  @action.bound
  public add(
    destinationTransformations: IDestinationTransformation[] | IDestinationTransformation,
  ): void {
    if (!Array.isArray(destinationTransformations)) {
      this.addDestinationTransformation(destinationTransformations);
      return;
    }
    destinationTransformations.forEach(this.addDestinationTransformation);
  }

  @action.bound
  public remove(destinationId: string, transformationId: string): void {
    delete this.connections[this.getMapEntry(destinationId, transformationId)];
  }

  @action.bound
  public removeByDestinationId(destinationId: string): void {
    Object.keys(this.connections).forEach((key) => {
      if (this.connections[key].destinationId === destinationId) {
        delete this.connections[key];
      }
    });
  }

  public getByDestinationId(destinationId: string): IDestinationTransformationStore[] {
    return Object.values(this.connections).filter(
      (connection) => connection.destinationId === destinationId,
    );
  }

  public getByTransformationId(tranformationId: string): IDestinationTransformationStore[] {
    return Object.values(this.connections).filter(
      (connection) => connection.transformationId === tranformationId,
    );
  }

  public get(
    destinationId: string,
    transformationId: string,
  ): IDestinationTransformationStore | undefined {
    return this.connections[this.getMapEntry(destinationId, transformationId)];
  }
}

import {
  DESTINATION_DEFINITION_CATEGORY,
  OBJECT_STORAGE_DEST_DEFINITION_NAMES,
  Region,
} from '@components/common/constants';
import { getApiErrorMessage } from '@components/common/util/util';
import { IRespData, LiveEvent } from '@components/destinations/liveEvents/interface';
import { constructLiveEvents } from '@components/destinations/liveEvents/util';
import { apiAuthCaller } from '@services/apiCaller';
import { IConnectionsStore } from '@stores/connections';
import { IDestinationsListStore, ServerDestination } from '@stores/destinationsList';
import { IMessageStore } from '@stores/messages';
import { ISourcesListStore } from '@stores/sourcesList';
import { ITransformationsListStore } from '@stores/transformationsList';
import { IUserStore } from '@stores/user';
import { IWorkspaceStore } from '@stores/workspace';
import { CatchErr } from '@utils/types';
import { AxiosResponse } from 'axios';
import { action, computed, observable, makeObservable } from 'mobx';
import { IDestDefinition } from '../destinationDefsList';
import { Transformation } from '../transformation/types';
import { DestinationAccount, DestinationRegion } from './types';
import {
  DestinationTransformationStore,
  IDestinationTransformation,
  IDestinationTransformationStore,
  ITransformationConfig,
  transformationDestinationDefaultConfig,
} from '@stores/destinationTransformation';
import { ITransformationStore } from '@stores/transformation';
import { IDestinationTransformationList } from '@stores/destinationTransformationList';
import { isDeviceModeSupported } from '@components/transformations/transformationDetails/connections/utils';
import { Analytics } from '@lib/analytics';
import { ISourceStore } from '@stores/source';

export enum AuthStatus {
  // TODO: This can be removed as we are looking to put authStatus into destination config
  UNSUPPORTED = 'unsupported',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

type EventMapping = { from: string; to: string; required?: boolean };

type EventsMapping = {
  destEventName: string;
  rsEventName: string;
  eventProperties: EventMapping[];
};

type CommonMapping = EventMapping[];

export type DestinationConfig = {
  useNativeSDK?: Record<string, boolean>;
  useNativeSDKToSend?: Record<string, boolean>;
  rudderAccountId?: string;
  rudderDeleteAccountId?: string;
  authStatus?: AuthStatus;
  webhookUrl?: string;
  connectionMode?: Record<string, string>;
  commonMapping?: CommonMapping;
  propertiesMapping?: CommonMapping;
  eventsMapping?: EventsMapping[];
  [key: string]:
    | string
    | string[]
    | boolean
    | undefined
    | Record<string, unknown>
    | CommonMapping
    | EventsMapping[];
};
export type SecretConfig = Record<string, unknown>;
export interface IDestination {
  id: string;
  name: string;
  enabled: boolean;
  config: DestinationConfig;
  secretConfig: SecretConfig;
  destinationDefinition: IDestDefinition;
  sources: ISourceStore[];
  transformationConnections: IDestinationTransformationStore[];
  transformations: ITransformationStore[];
  regions: DestinationRegion[];
  configuredRegions: Region[];
  destinationTransformations: IDestinationTransformation[];
  isEventStreamSourceConnected: boolean;
  isRetlSourceConnected: boolean;
}

export interface IDestinationStore extends IDestination {
  setName(name: string): void;
  toggleEnabled(config?: DestinationConfig): void;
  updateConfig(
    config: DestinationConfig,
    hardUpdate?: boolean,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<boolean>;
  updateName(name: string): void;
  getLiveEvents(latestEventId: number, from: number): Promise<LiveEvent[]>;
  isMapped(): boolean;
  isWarehouse(): boolean;
  isObjectStorage(): boolean;
  setConfig(data: { config: DestinationConfig; secretConfig: SecretConfig }): void;
  getAccounts(): Promise<DestinationAccount[]>;
  deleteAccount(accountId: string, onSuccess: () => void): void;
  changeActiveAccount(
    account: { accountId: string; accountIdKey: string },
    onSuccess: () => void,
  ): void;
  addRegionConfig(
    region: Region,
    config: DestinationConfig,
    onSuccess: () => void,
    onError: () => void,
  ): Promise<void>;
  updateRegionConfig(
    region: Region,
    config: DestinationConfig,
    onSuccess: () => void,
    onError: () => void,
  ): Promise<void>;
  getSecretConfigForRegion(region: Region): SecretConfig;
  deleteRegionConfig(region: Region, onSuccess: () => void, onError: () => void): Promise<void>;
  getTransformationConnection(
    transformationId: string,
  ): IDestinationTransformationStore | undefined;
}

interface IUpdateDestinationBody {
  enabled: boolean;
  config?: DestinationConfig;
}

type RootStore = {
  connectionsStore: IConnectionsStore;
  destinationsListStore: IDestinationsListStore;
  transformationsListStore: ITransformationsListStore;
  workspaceStore: IWorkspaceStore;
  sourcesListStore: ISourcesListStore;
  userStore: IUserStore;
  messagesStore: IMessageStore;
  destinationTransformationsListStore: IDestinationTransformationList;
};

export class DestinationStore implements IDestinationStore {
  @observable public id: string;

  @observable public name: string;

  @observable public enabled: boolean;

  @observable public config: DestinationConfig;

  @observable public secretConfig: SecretConfig;

  @observable public destinationDefinition: IDestDefinition;

  @observable public regions: DestinationRegion[] = [];

  @observable public destinationTransformations: IDestinationTransformation[];

  @observable public rootStore: RootStore;

  constructor(destination: Omit<ServerDestination, 'permissions'>, rootStore: RootStore) {
    makeObservable(this);
    this.id = destination.id;
    this.name = destination.name;
    this.enabled = destination.enabled;
    this.config = destination.config;
    this.secretConfig = destination.secretConfig;
    this.destinationDefinition = destination.destinationDefinition;
    this.regions = destination.regions || [];
    this.rootStore = rootStore;
    this.destinationTransformations =
      destination?.destinationTransformations?.map(
        (dt) => new DestinationTransformationStore(dt, rootStore),
      ) || [];
    this.addTrasformationConnectionsToStore();
  }

  @action.bound
  public setName(name: string): void {
    this.name = name;
  }

  @computed get sources() {
    const sourceIds: string[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const key in this.rootStore.connectionsStore.connections) {
      if (this.rootStore.connectionsStore.connections[key].includes(this.id)) {
        sourceIds.push(key);
      }
    }
    return this.rootStore.sourcesListStore.sources.filter((source) =>
      sourceIds.includes(source.id),
    );
  }

  @computed get transformations() {
    return this.rootStore.transformationsListStore.transformations.filter(
      (transformation: Transformation) =>
        transformation.destinationIds && transformation.destinationIds.includes(this.id),
    );
  }

  private addTrasformationConnectionsToStore() {
    const connections: IDestinationTransformation[] = this.transformations.map((trans) => {
      const destinationTransformation = this.destinationTransformations?.find(
        (dm) => dm.transformationId === trans.id,
      );
      return (
        destinationTransformation || {
          destinationId: this.id,
          transformationId: trans.id,
          config: transformationDestinationDefaultConfig,
        }
      );
    });

    this.rootStore.destinationTransformationsListStore.add(connections);
  }

  @computed get transformationConnections() {
    return this.rootStore.destinationTransformationsListStore.getByDestinationId(this.id);
  }

  @computed get configuredRegions() {
    const {
      workspaceStore: { dataResidencyDefaultRegion: defaultRegion },
    } = this.rootStore;
    return [defaultRegion, ...this.regions.map((regionConfig) => regionConfig.region)];
  }

  @action.bound
  /**
   * @param {object} config - The configuration for the destination
   * This is a more generic object and varies from destination to destination
   * For OAuth supported destinations, there will be a field `rudderAccountId` which indicates
   * that the destination has been authorised by the account with this id.
   *
   * This object contains a property `authStatus` whose explanation is provided below
   * If the destination has been disable due to an exception such revoking of an account,
   * then when the user tries to enable the destination he/she will be asked to re-authorise.
   * After re-auth is successful we would need to change the authStatus from INACTIVE to
   * ACTIVE explicitly. This parameter indicates the authorisation status for a destination
   */
  public async toggleEnabled(config?: DestinationConfig) {
    try {
      const { messagesStore } = this.rootStore;
      if (
        this.enabled === false &&
        (!this.config || Object.keys(this.config).length === 0) &&
        (!this.secretConfig || Object.keys(this.secretConfig).length === 0)
      ) {
        messagesStore.showErrorMessage('Please update your destination settings to enable');
        return;
      }
      let updateBody: IUpdateDestinationBody = { enabled: !this.enabled };
      if (config) {
        updateBody = {
          ...updateBody,
          config,
        };
      }
      await apiAuthCaller().post(`/destinations/${this.id}`, updateBody);
      this.enabled = !this.enabled;
      if (config) {
        this.config = config;
      }
      if (!this.enabled) {
        Analytics.track({
          event: 'destinationDisabled',
          properties: {
            destinationId: this.id,
            destinationType: this.destinationDefinition.name,
          },
        });
      }
    } catch (error) {}
  }

  @action.bound
  public async updateConfig(
    config: DestinationConfig,
    hardUpdate = false,
    onSuccess: () => void = () => null,
    onError: () => void = () => null,
  ) {
    const { messagesStore } = this.rootStore;
    try {
      const updateUrl = `/destinations/${this.id}${hardUpdate ? '?hardUpdate=true' : ''}`;
      const res = await apiAuthCaller().post(updateUrl, {
        config,
      });
      this.config = res.data.config;
      this.secretConfig = res.data.secretConfig;
      onSuccess();

      if (!isDeviceModeSupported(this)) {
        Promise.all(
          this?.transformationConnections?.map((transformation) => transformation.resetConfig()),
        );
      }

      return true;
    } catch (error) {
      onError();
      messagesStore.showErrorMessage(
        getApiErrorMessage(error, 'Failed to save destination configuration'),
      );
      return false;
    }
  }

  @action.bound
  /**
   * updateName
   */
  public async updateName(name: string) {
    const { destinationsListStore, messagesStore } = this.rootStore;
    try {
      if (!destinationsListStore.isDestNameUnique(name, this.name)) {
        messagesStore.showErrorMessage('Please choose a unique destination name');
        return;
      }
      const updateUrl = `/destinations/${this.id}/updateName`;
      const res = await apiAuthCaller().post(updateUrl, {
        name,
      });
      this.name = res.data.name;
    } catch (error) {
      messagesStore.showErrorMessage(getApiErrorMessage(error, 'Failed to update name'));
    }
  }

  @action.bound
  public async disconnectTransformations() {
    try {
      const { destinationTransformationsListStore } = this.rootStore;
      await apiAuthCaller().post(`/destinations/${this.id}/disconnectAllTransformations`);
      destinationTransformationsListStore.removeByDestinationId(this.id);
    } catch (error) {}
  }

  public async getLiveEvents(latestEventId: number, timestamp: number) {
    try {
      const resp = await apiAuthCaller().get<IRespData[]>(`/eventDeliveryStatus`, {
        headers: { 'X-DESTINATION-ID': this.id },
        params: { id: latestEventId, from: timestamp },
      });
      return constructLiveEvents(resp.data);
    } catch (err) {
      return [];
    }
  }

  public isMapped() {
    return this.sources.some(
      (source) =>
        source.config?.constants?.some(
          (obj: { key: string; value: string }) =>
            obj.key === 'context.mappedToDestination' && obj.value,
        ),
    );
  }

  public isWarehouse() {
    return this.destinationDefinition.category === DESTINATION_DEFINITION_CATEGORY.WAREHOUSE;
  }

  public isObjectStorage() {
    return OBJECT_STORAGE_DEST_DEFINITION_NAMES.includes(this.destinationDefinition.name);
  }

  @action.bound
  public setConfig(data: { config: DestinationConfig; secretConfig: SecretConfig }) {
    this.config = data.config;
    this.secretConfig = data.secretConfig;
  }

  @action.bound
  async getAccounts() {
    const response: AxiosResponse<DestinationAccount[]> = await apiAuthCaller().get(
      `/destination/accounts/${
        this.destinationDefinition.config?.auth?.role ||
        this.destinationDefinition.name.toLowerCase()
      }`,
    );
    return response.data;
  }

  @action.bound
  async deleteAccount(accountId: string, onSuccess: () => void) {
    const { messagesStore } = this.rootStore;
    try {
      await apiAuthCaller().ignore400().delete(`/destination/accounts/${accountId}`);
      onSuccess();
    } catch (err) {
      messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to delete account'));
    }
  }

  @action.bound
  async changeActiveAccount(
    account: { accountId: string; accountIdKey: string },
    onSuccess: () => void,
  ) {
    const config = {
      ...this.config,
      authStatus: AuthStatus.ACTIVE,
      [account.accountIdKey]: account.accountId,
    };
    await this.updateConfig(config, false, onSuccess);
  }

  public async updateTransformationConnectionSettings(
    transformationId: string,
    config: ITransformationConfig,
  ) {
    const destinationTransformation = this.getTransformationConnection(transformationId);
    await destinationTransformation?.update(config);
  }

  async addRegionConfig(
    region: Region,
    config: DestinationConfig,
    onSuccess: () => void,
    onError: () => void,
  ): Promise<void> {
    const { messagesStore } = this.rootStore;
    try {
      const response: AxiosResponse<DestinationRegion> = await apiAuthCaller().post(
        `/destinations/${this.id}/regions`,
        {
          region,
          config,
        },
      );
      this.regions.push(response.data);
      messagesStore.showSuccessMessage('Region configuration added successfully');
      onSuccess();
    } catch (err: CatchErr) {
      onError();
      messagesStore.showErrorMessage(
        getApiErrorMessage(err, 'Failed to save destination region configuration'),
      );
    }
  }

  async updateRegionConfig(
    region: Region,
    config: DestinationConfig,
    onSuccess: () => void,
    onError: () => void,
  ): Promise<void> {
    const { messagesStore } = this.rootStore;
    try {
      const regionId = this.regions.find((r) => r.region === region)?.id;
      const response: AxiosResponse<DestinationRegion> = await apiAuthCaller().patch(
        `/destinations/${this.id}/regions/${regionId}`,
        {
          config,
        },
      );
      const regionIndex = this.regions.findIndex((r) => r.region === region);
      this.regions[regionIndex] = response.data;
      onSuccess();
    } catch (err) {
      onError();
      messagesStore.showErrorMessage(
        getApiErrorMessage(err, 'Failed to save destination region configuration'),
      );
    }
  }

  getSecretConfigForRegion(region: Region): SecretConfig {
    const {
      workspaceStore: { dataResidencyDefaultRegion: defaultRegion },
    } = this.rootStore;
    if (region === defaultRegion) {
      return this.secretConfig;
    }
    const regionConfig = this.regions.find((r) => r.region === region);
    return regionConfig?.secretConfig || {};
  }

  getTransformationConnection(
    transformationId: string,
  ): IDestinationTransformationStore | undefined {
    return this.rootStore.destinationTransformationsListStore.get(this.id, transformationId);
  }

  async deleteRegionConfig(
    region: Region,
    onSuccess: () => void,
    onError: () => void,
  ): Promise<void> {
    const { messagesStore } = this.rootStore;
    try {
      const regionId = this.regions.find((r) => r.region === region)?.id;
      if (!regionId) {
        return;
      }
      await apiAuthCaller().delete(`/destinations/${this.id}/regions/${regionId}`);
      const index = this.regions.findIndex((r) => r.region === region);
      this.regions.splice(index, 1);
      onSuccess();
      messagesStore.showSuccessMessage('Region configuration deleted successfully');
    } catch (err) {
      onError();
      messagesStore.showErrorMessage(
        getApiErrorMessage(err, 'Failed to delete destination region configuration'),
      );
    }
  }

  @computed
  get isEventStreamSourceConnected() {
    return this.sources.some((source) => source.isEventStream());
  }

  @computed
  get isRetlSourceConnected() {
    return this.sources.some((source) => source.category === 'warehouse');
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { ISource } from '@stores/source';
import { TAB_NAMES } from '@components/sources/source-view/utils';
import { DIRECTORY_TABS } from '@components/common/constants';
import {
  AccountValidator,
  ConfigurationComponentProps,
  CredentialsAuth,
  DirectoryTab,
  ResourcesComponentProps,
  SourceCategory,
} from './sourceCategory';
import { WebhookConfigure } from '@components/sources/source/webhookSource/webhookConfigure';
import React from 'react';
import {
  DestinationExperimentKeys,
  isFeatureEnabledForDestination,
} from '@lib/destinationExperiment';
import { IDestination } from '@stores/destination';

const Unimplemented = 'Method not implemented';

class EventStreamSource extends SourceCategory {
  override isNewConnectionAllowed() {
    return true;
  }

  override showGeolocationSettings() {
    return true;
  }

  override validateAccount(_: AccountValidator): Promise<string | undefined> {
    throw new Error('Method not implemented.');
  }

  override getAccountsEndpoint(_?: string): string {
    throw Error('Method not supported');
  }

  override getSourceType(sourceDef: { name: string }) {
    switch (sourceDef.name.toLowerCase()) {
      case 'javascript':
        return 'web';
      case 'android':
      case 'ios':
      case 'unity':
      case 'reactnative':
      case 'flutter':
      case 'cordova':
      case 'amp':
      case 'shopify':
        return sourceDef.name.toLowerCase();
      default:
        return 'cloud';
    }
  }

  /**
   * For event stream sources, we allow multiple sources to connect to the same destination
   * as long as the destination has only event-stream sources connected to it.
   */
  override isDestinationAllowedForConnection(destination: IDestination) {
    return destination.sources.every((source) => source.sourceCategory.category === 'event');
  }

  override handleSetup(_: boolean, __: boolean, create: () => void) {
    create();
    return true;
  }

  override isPartOf(tab: DirectoryTab) {
    return tab === DIRECTORY_TABS.EVENT_STREAMS;
  }

  override getAuthComponent(_: CredentialsAuth): JSX.Element {
    throw new Error(Unimplemented);
  }

  override getTabs(_: ISource) {
    return [TAB_NAMES.SETUP, TAB_NAMES.DESTINATIONS, TAB_NAMES.EVENTS, TAB_NAMES.SETTINGS];
  }

  override getResourcesComponent(_: ResourcesComponentProps): JSX.Element {
    throw new Error(Unimplemented);
  }

  override getConfigurationComponent(props: ConfigurationComponentProps): JSX.Element {
    const { source, onChange, disabled } = props;
    const { sourceDef, config } = source;
    return (
      <WebhookConfigure
        initialSettings={Object.keys(config || {}).length > 0 ? config : undefined}
        onSettingsChange={onChange}
        sourceDefinition={sourceDef!}
        disabled={disabled}
      />
    );
  }

  override getSyncs(source: ISource, hasSourcePermission: boolean): JSX.Element | null {
    throw new Error(Unimplemented);
  }
}

/* eslint-disable import/no-default-export */
export default EventStreamSource;

import { Button } from '@ui-library/button';
import styled from 'styled-components';

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const CreateAccountButton = styled(Button)`
  margin-top: 16px;
`;

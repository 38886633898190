import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { TimeFilterValue } from '@components/common/types';
import { Category, TableFilter } from './types';

interface TimeFilter {
  value: TimeFilterValue;
  label: string;
  dayCount: number;
}

export const timeFilters: Record<TimeFilterValue, TimeFilter> = {
  day: {
    value: TimeFilterValue.DAY,
    label: '1 day',
    dayCount: 1,
  },
  week: {
    value: TimeFilterValue.WEEK,
    label: '1 week',
    dayCount: 7,
  },
  month: {
    value: TimeFilterValue.MONTH,
    label: '1 month',
    dayCount: 30,
  },
};

type FilterBase<T> = {
  value: T;
  label: string;
  icon?: IconDefinition;
};

export type Filter = FilterBase<TableFilter['category']>;
export type SubFilter = FilterBase<TableFilter['subCategory']>;
export type StatusFilter = FilterBase<TableFilter['status']>;

type CategoryFilter = Filter & {
  defaultSubFilter: TableFilter['subCategory'];
  subFilters: SubFilter[];
  statusFilters?: StatusFilter[];
};

export const allCategories: Record<Category, CategoryFilter> = {
  eventStream: {
    label: 'Event Stream',
    value: Category.EVENT_STREAM,
    defaultSubFilter: 'all',
    subFilters: [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: 'cloud',
        label: 'Cloud',
        icon: regular('cloud'),
      },
      {
        value: 'warehouse',
        label: 'Warehouse',
        icon: regular('database'),
      },
    ],
    statusFilters: [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Failures',
        value: 'failures',
      },
    ],
  },
  retl: {
    label: 'rETL',
    value: Category.RETL,
    defaultSubFilter: '',
    subFilters: [],
  },
  trackingPlan: {
    label: 'Tracking Plans',
    value: Category.TRACKING_PLAN,
    defaultSubFilter: '',
    subFilters: [],
    statusFilters: [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Violations',
        value: 'violations',
      },
    ],
  },
};

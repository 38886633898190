import React, { useEffect, useState } from 'react';
import { ISourceDataStore } from '@stores/sourceDataStore';
import { Button } from '@ui-library/button';
import CredentialPopup from './credential-popup';
import { observer } from 'mobx-react';
import { AccountInfo } from '@components/retlWorkflow/components/setupSource/credentials/accountInfo';
import { UiConfig, ISourceDefinition } from '@stores/sourceDefinitionsList';
import { AccountFieldProps } from '@components/common/types';
import { Flex } from '@ui-library/flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { AccountInfoContainer } from './styles';
import { Skeleton } from 'antd';

// TODO: fix below implementation to exclude redundant config, sourceDef props.
interface ICredentialsProps {
  sourceDef?: ISourceDefinition;
  source?: ISourceDataStore;
  isAdmin: boolean;
}

function CredentialsV1(props: ICredentialsProps) {
  const [showCredentialPopup, setShowCredentialPopup] = useState(false);
  const [isAccountLoading, setIsAccountLoading] = useState(false);

  const { source, sourceDef, isAdmin } = props;

  const getCredentialInfo = async () => {
    setIsAccountLoading(true);
    await source!.getCredentialInfo(true);
    setIsAccountLoading(false);
  };

  useEffect(() => {
    getCredentialInfo();
  }, []);

  let modal = null;
  if (showCredentialPopup) {
    modal = <CredentialPopup onCancel={() => setShowCredentialPopup(false)} />;
  }
  const accountSummaryFields =
    ((sourceDef?.uiConfig as UiConfig[])[0]?.fields?.filter(
      (field) => field.addInAccountSummary,
    ) as AccountFieldProps[]) || [];

  return (
    <Skeleton title={{ width: 400 }} loading={isAccountLoading} active>
      <Flex gap="1.5rem" flexGrow flexDirection="column">
        {source && source.accountInfo && (
          <>
            <AccountInfoContainer notPadded>
              {modal}
              <AccountInfo
                fields={accountSummaryFields}
                accountName={source.accountInfo.name}
                info={{
                  id: source.accountInfo.id,
                  ...source.accountInfo?.options,
                  createdAt: source.accountInfo.createdAt,
                }}
              />
            </AccountInfoContainer>
            {isAdmin && (
              <Flex justifyContent="flex-end">
                <Button
                  data-testid="edit-button"
                  onClick={() => setShowCredentialPopup(true)}
                  type="default"
                  icon={<FontAwesomeIcon icon={regular('pencil')} />}
                />
              </Flex>
            )}
          </>
        )}
      </Flex>
    </Skeleton>
  );
}

/* eslint-disable import/no-default-export */
export default observer(CredentialsV1);

import { action, observable, computed, runInAction, makeObservable } from 'mobx';
import { apiAuthCaller } from '@services/apiCaller';
import { IRootStore } from '@stores/index';
import { IEvent } from '@components/transformations/liveEvents/table/payload';
import { IDestination, IDestinationStore } from '../destination';
import { LanguageType } from '@stores/transformationsList';
import { Region } from '@components/common/constants';
import {
  ErrorSample,
  EventStats,
  EventStatsResponse,
  IErrors,
  SampleErrorsParams,
  Transformation,
  TransformationEventStatParams,
} from './types';
import { getApiErrorMessage } from '@components/common/util/util';
import { CatchErr } from '@utils/types';
import { AxiosResponse } from 'axios';
import { mergeAggregatedEventsStats } from './utils';
import {
  IDestinationTransformation,
  IDestinationTransformationStore,
  ITransformationConfig,
  transformationDestinationDefaultConfig,
} from '@stores/destinationTransformation';
import { Analytics } from '@lib/analytics';

export interface ITransformationStore extends Transformation {
  code: string;
  codeVersion: '0' | '1';
  secrets: string[] | undefined;
  language: LanguageType;
  destinations: IDestinationStore[];
  destinationConnections: IDestinationTransformationStore[];
  isConnected: number;
  rootStore: IRootStore;
  loading: boolean;
  update(transformation: ITransformationStore): void;
  delete(): Promise<void>;
  addSecret(name: string, key: string): Promise<void>;
  deleteSecret(name: string): Promise<void>;
  getLiveEvents(latestId: number, from: number): Promise<IEvent[]>;
  getEventsStats(
    params: TransformationEventStatParams,
    regions: Region[],
    callback?: () => void,
  ): void;
  getSampleErrors(params: SampleErrorsParams, regions: Region[]): void;
  getDestinationConnection(destinationId: string): IDestinationTransformationStore | undefined;
  getDetails: () => Promise<void>;
}

export class TransformationStore implements ITransformationStore {
  @observable public id: string;

  @observable public name: string;

  @observable public createdBy: string;

  @observable public updatedAt: string;

  @observable public description: string;

  @observable public code: string;

  @observable public codeVersion: '0' | '1';

  @observable public secrets: string[] | undefined;

  @observable public language: LanguageType;

  @observable public destinationIds: string[];

  private destinationTransformations: IDestinationTransformation[];

  @observable public rootStore: IRootStore;

  @observable eventStats: EventStats | undefined;

  @observable errors: IErrors | undefined;

  @observable public loading = false;

  private loaded = false;

  private get isLoaded() {
    return this.loaded || !!this.code;
  }

  constructor(
    transformation: ITransformationStore & {
      destinationTransformations: IDestinationTransformation[];
    },
    rootStore: IRootStore,
  ) {
    makeObservable(this);
    this.id = transformation.id;
    this.name = transformation.name;
    this.createdBy = transformation.createdBy;
    this.updatedAt = transformation.updatedAt;
    this.description = transformation.description;
    this.code = transformation.code;
    this.secrets = transformation.secrets;
    this.codeVersion = transformation.codeVersion;
    this.language = transformation.language;
    this.destinationIds = transformation.destinations.map(
      (d: IDestination | undefined) => d?.id || '',
    );
    this.rootStore = rootStore;
    this.destinationTransformations = transformation.destinationTransformations || [];
    this.addConnectionsToStore();
  }

  @action.bound
  public async update(transformation: ITransformationStore) {
    this.id = transformation.id;
    this.name = transformation.name;
    this.description = transformation.description;
    this.code = transformation.code;
    this.secrets = transformation.secrets;
    this.codeVersion = transformation.codeVersion;
    this.language = transformation.language;
  }

  @computed get destinations() {
    return this.destinationIds
      .map(
        (destId: string) =>
          this.rootStore.destinationsListStore.destinations.find(
            (destination) => destination.id === destId,
          )!,
      )
      .filter((element) => element !== undefined); // Filters out destinations that are soft deleted
  }

  private addConnectionsToStore = () => {
    const connections: IDestinationTransformation[] = this.destinationIds.map((destinationId) => {
      const destinationTransformation = this.destinationTransformations?.find(
        (dm) => dm.destinationId === destinationId,
      );
      return (
        destinationTransformation || {
          destinationId: this.id,
          transformationId: destinationId,
          config: transformationDestinationDefaultConfig,
        }
      );
    });
    this.rootStore.destinationTransformationsListStore.add(connections);
  };

  @computed get destinationConnections() {
    return this.rootStore.destinationTransformationsListStore.getByTransformationId(this.id) || [];
  }

  @computed get isConnected() {
    return this.destinations.length;
  }

  @action.bound
  public async connectToDestination(
    destinationId: string,
    onSuccess: () => void,
    onError: () => void,
    config?: ITransformationConfig,
    showAlert = false,
  ) {
    const { transformationsListStore, messagesStore, destinationTransformationsListStore } =
      this.rootStore;
    try {
      await apiAuthCaller().post(`/transformations/${this.id}/connectToDestination`, {
        destinationId,
        config,
      });
      if (showAlert) {
        messagesStore.showSuccessMessage('Transformation added to destination successfully');
      }
      onSuccess?.();
      runInAction(() => {
        // Removing all the connections between that destination with other transformations
        // This is to always have only one transformation per destination
        // We do a similar thing in backend too.
        // When we start supporting multiple transformations per destinations,
        // we remove next block of code and similar code from backend
        transformationsListStore.transformations.forEach((t) => {
          t.destinationIds = t.destinationIds.filter((destId) => destId !== destinationId);
        });

        if (!this.destinationIds.includes(destinationId)) {
          this.destinationIds = [...this.destinationIds, destinationId];
        }
        destinationTransformationsListStore.add({
          destinationId,
          transformationId: this.id,
          config,
        });
      });
      Analytics.track({
        event: 'transformationConnectedToDestination',
        properties: {
          destinationId,
          transformationId: this.id,
          destinationMode: config?.enableForDeviceMode ? 'Device Mode' : 'Cloud Mode',
        },
      });
    } catch (error) {
      messagesStore.showErrorMessage('Failed to add transformation');
      onError?.();
    }
  }

  @action.bound
  public async delete() {
    try {
      await apiAuthCaller().delete(`/transformations/${this.id}`);
      runInAction(() => {
        this.rootStore.transformationsListStore.transformations =
          this.rootStore.transformationsListStore.transformations.filter(
            (t: ITransformationStore) => t.id !== this.id,
          );
      });
      Analytics.track({
        event: 'transformationDeleted',
        properties: {
          transformationId: this.id,
          transformationLanguageType: this.language,
          destinationMode: this.destinationConnections[0]?.config?.enableForDeviceMode
            ? 'Device Mode'
            : 'Cloud Mode',
        },
      });
    } catch (error) {}
  }

  @action.bound
  public async getDetails() {
    if (this.isLoaded) {
      return;
    }
    try {
      this.loading = true;
      const resp = await apiAuthCaller().get(`/transformations/${this.id}`);
      this.code = resp.data.code;
      this.loaded = true;
    } catch (e) {
      this.rootStore.messagesStore.showErrorMessage('Failed to get transformation');
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public async disconnectFromDestination(destinationId: string) {
    const { messagesStore, destinationTransformationsListStore } = this.rootStore;
    try {
      await apiAuthCaller().post(`/transformations/${this.id}/disconnectFromDestination`, {
        destinationId,
      });
      messagesStore.showSuccessMessage('Transformation removed successfully');
      runInAction(() => {
        this.destinationIds = this.destinationIds.filter((destId) => destId !== destinationId);
        destinationTransformationsListStore.remove(destinationId, this.id);
      });
      Analytics.track({
        event: 'transformationDisconnectedFromDestination',
        properties: {
          destinationId,
          transformationId: this.id,
        },
      });
    } catch (error) {
      messagesStore.showErrorMessage('Failed to remove transformation');
    }
  }

  public async addSecret(name: string, key: string) {
    await apiAuthCaller().post(`/transformations/${this.id}/secret`, {
      name,
      key,
    });
    if (!this.secrets) {
      this.secrets = [];
    }
    this.secrets.push(name);
  }

  public async deleteSecret(name: string) {
    await apiAuthCaller().delete(`/transformations/${this.id}/secret/${name}`);
    this.secrets = this.secrets!.filter((secret) => secret !== name);
  }

  getDestinationConnection(destinationId: string): IDestinationTransformationStore | undefined {
    return this.rootStore.destinationTransformationsListStore.get(destinationId, this.id);
  }

  public async getLiveEvents(latestId: number, timestamp: number) {
    const resp = await apiAuthCaller().get(`/eventTransformStatus`, {
      headers: { 'X-TRANSFORMATION-ID': this.id },
      params: { id: latestId, from: timestamp },
    });
    return resp.data;
  }

  public async updateConnectionSettings(destinationId: string, config: ITransformationConfig) {
    const destinationTransformation = this.getDestinationConnection(destinationId);
    await destinationTransformation?.update(config);
  }

  private getEventsStatsForRegion(
    region: Region,
    params: TransformationEventStatParams,
  ): Promise<{ data: EventStatsResponse }> {
    const { sourceId, destinationId, start, aggregationMinutes } = params;
    return apiAuthCaller().get(`transformations/${this.id}/eventFlowGraph`, {
      params: {
        region,
        sourceId,
        destinationId,
        start,
        granularityInMS: aggregationMinutes,
      },
    });
  }

  public getEventsStats(
    params: TransformationEventStatParams,
    regions: Region[],
    callback: () => void,
  ) {
    Promise.all(regions.map((region) => this.getEventsStatsForRegion(region, params)))
      .then((res) => {
        this.errors = { data: [] };
        this.eventStats = {
          graph: [],
          totalDelivered: 0,
          totalFailed: 0,
          totalFiltered: 0,
          totalProcessed: 0,
        };
        res.forEach((_regionStats, index) => {
          const { aggregates, totalDelivered, totalFailed, totalFiltered, totalProcessed, errors } =
            res[index].data;
          this.errors = {
            data: errors,
          };
          this.eventStats = {
            graph: mergeAggregatedEventsStats(this.eventStats!.graph, aggregates),
            totalDelivered: this.eventStats!.totalDelivered + totalDelivered ?? 0,
            totalFailed: this.eventStats!.totalFailed + totalFailed ?? 0,
            totalFiltered: this.eventStats!.totalFiltered + totalFiltered ?? 0,
            totalProcessed: this.eventStats!.totalProcessed + totalProcessed ?? 0,
          };
        });
      })
      .catch((err) => {
        this.rootStore.messagesStore.showErrorMessage(
          getApiErrorMessage(err, 'Failed to get transformation events data'),
        );
      })
      .finally(() => {
        callback?.();
      });
  }

  private getSampleErrorsForRegion(
    region: Region,
    params: SampleErrorsParams,
  ): Promise<AxiosResponse<ErrorSample>> {
    const { destinationId, sourceId, eventType, eventName, reportedAt, statusCode } = params;
    return apiAuthCaller().get(`/sampleError`, {
      params: {
        transformationId: this.id,
        destinationId,
        sourceId,
        eventType,
        eventName,
        region,
        reportedAt,
        statusCode,
      },
    });
  }

  public getSampleErrors(params: SampleErrorsParams, regions: Region[]) {
    this.errors = {
      ...this.errors,
      selected: undefined,
    };
    Promise.all(regions.map((region) => this.getSampleErrorsForRegion(region, params)))
      .then((res) => {
        const errors = res.reduce(
          (acc: ErrorSample[], regionErrors) => [...acc, regionErrors.data],
          [],
        );
        this.errors = {
          ...this.errors,
          selected: errors,
        };
      })
      .catch((err: CatchErr) => {
        this.rootStore.messagesStore.showErrorMessage(
          getApiErrorMessage(err, 'Failed to get sample errors'),
        );
      });
  }
}

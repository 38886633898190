import { Region } from '@components/common/constants';
import { getApiErrorMessage } from '@components/common/util/util';
import { apiAuthCaller } from '@services/apiCaller';
import { IRootStore } from '@stores/index';
import { RequestConfig } from '@utils/errorLogger';
import HttpStatusCode from '@utils/httpStatusCodes';
import { CatchErr } from '@utils/types';
import { AxiosInstance } from 'axios';
import { action, observable, makeObservable } from 'mobx';
import { ISyncStore, SyncStore } from './sync';

export interface ISyncsListStore {
  syncs: ISyncStore[];
  rootStore: IRootStore;
  pagination: IPagination;
  filters: ISyncFilters;
  canDisplaySyncs: boolean;
  currentRegion: Region;
  get(options?: IGetSyncsOptions): void;
  getDetailById(id: number): void;
  setPage(pageNumber: number): void;
  setFilter(filterName: keyof typeof DEFAULT_FILTERS, filterValue: string): void;
  isWHSyncOn(): void;
  retryUpload(id: number): void;
  removeErrorMessages(): void;
  syncUploads(destId: string): void;
  resetFilter(): void;
  retryUploads(intervalInHours: number): void;
  countUploadsToRetry(intervalInHours: number): Promise<number | undefined>;
  setRegion(region: string): void;
}

interface ISyncFilters {
  sourceId?: string;
  destinationId: string;
  status?: string;
  limit?: number;
  offset?: number;
}

interface IPagination {
  limit: number;
  offset: number;
  total?: number;
}

interface IGetSyncsOptions {
  resetPagination?: boolean;
}

const DEFAULT_PAGINATION_PAGE_SIZE = 10;
const DEFAULT_PAGINATION_OFFSET = 0;

const DEFAULT_FILTERS = {
  sourceId: 'all',
  destinationId: 'all',
  status: 'all',
};

export class SyncsListStore implements ISyncsListStore {
  @observable public syncs: ISyncStore[] = [];

  @observable public rootStore: IRootStore;

  @observable public filters: ISyncFilters = DEFAULT_FILTERS;

  @observable public pagination: IPagination = {
    limit: DEFAULT_PAGINATION_PAGE_SIZE,
    offset: DEFAULT_PAGINATION_OFFSET,
  };

  @observable public canDisplaySyncs = true;

  @observable currentRegion: Region = Region.US;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  public async get(options?: IGetSyncsOptions) {
    const { messagesStore, destinationsListStore, sourcesListStore } = this.rootStore;
    if (!this.hasWareHouseDestinations()) {
      this.syncs = [];
      return;
    }
    let res;
    try {
      if (options?.resetPagination) {
        this.filters.limit = DEFAULT_PAGINATION_PAGE_SIZE;
        this.filters.offset = DEFAULT_PAGINATION_OFFSET;
      } else {
        this.filters.limit = this.pagination.limit;
        this.filters.offset = this.pagination.offset;
      }
      let queryString = Object.entries(this.filters)
        .filter(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ([key, val]: [string, string]) => val !== null && val !== undefined && val !== 'all',
        )
        .map(([key, val]: [string, string]) => `${key}=${val}`)
        .join('&');
      queryString += `&region=${this.currentRegion}`;
      this.removeErrorMessages();
      const apiCaller = apiAuthCaller();
      this.ignoreOSErrors(apiCaller);
      res = await apiCaller.get(`/warehouse/uploads?${queryString}`);
    } catch (error: CatchErr) {
      messagesStore.showErrorMessage(getApiErrorMessage(error, 'Failed to load syncs'));
      return;
    }
    this.syncs = res.data.uploads
      ? res.data.uploads
          .filter(
            (upload: {
              sourceId: string;
              destinationId: string; // remove deleted destination/source syncs
            }) =>
              destinationsListStore.destinationById(upload.destinationId) &&
              sourcesListStore.sourceById(upload.sourceId),
          )
          .map((sync: ISyncStore) => new SyncStore(sync, this.rootStore))
      : [];
    if (!res.data.pagination.offset) {
      res.data.pagination.offset = 0;
    }
    if (!res.data.pagination.total) {
      res.data.pagination.total = 0;
    }
    this.pagination = { ...this.pagination, ...res.data.pagination };
  }

  @action.bound
  public async getDetailById(id: number) {
    const { messagesStore } = this.rootStore;
    let res;
    try {
      this.removeErrorMessages();
      const apiCaller = apiAuthCaller();
      this.ignoreOSErrors(apiCaller);
      res = await apiCaller.get(`/warehouse/uploads/${id}`, {
        params: { region: this.currentRegion },
      });
    } catch (error: CatchErr) {
      if (error.response?.status === HttpStatusCode.NotFound) {
        return;
      }
      messagesStore.showErrorMessage(getApiErrorMessage(error, 'Failed to load sync detail'));
      return;
    }

    let sync = this.syncs.find((sync) => sync.id === id.toString());
    if (sync) {
      sync.update(res.data);
    } else {
      sync = new SyncStore(res.data, this.rootStore);
      this.syncs.push(sync);
    }
  }

  @action.bound
  public setPage(pageNumber: number): void {
    this.pagination.offset = (pageNumber - 1) * this.pagination.limit;
  }

  @action.bound
  public setFilter(filterName: keyof typeof DEFAULT_FILTERS, filterValue: string): void {
    this.filters[filterName] = filterValue;
  }

  @action.bound
  public resetFilter() {
    this.filters = DEFAULT_FILTERS;
  }

  public hasWareHouseDestinations() {
    return this.rootStore.destinationsListStore.hasWareHouseDestinations();
  }

  public async isWHSyncOn() {
    const { messagesStore } = this.rootStore;
    try {
      if (!this.hasWareHouseDestinations()) {
        this.canDisplaySyncs = true;
        return;
      }
      this.removeErrorMessages();
      const apiCaller = apiAuthCaller();
      this.ignoreOSErrors(apiCaller);
      const res = await apiCaller.get('/warehouse/health', {
        params: { region: this.currentRegion },
      });
      this.canDisplaySyncs = res.data.value;
    } catch (error: CatchErr) {
      messagesStore.showErrorMessage(
        getApiErrorMessage(error, 'Failed to get sync support health status'),
      );
      this.canDisplaySyncs = false;
    }
  }

  private ignoreOSErrors(request: AxiosInstance) {
    const { featuresStore } = this.rootStore;
    if (!featuresStore.has('BILLING') && !featuresStore.has('USAGE')) {
      request.interceptors.request.use((config) => {
        (config as RequestConfig).ignore = true;
        return config;
      });
    }
  }

  public async retryUpload(uploadID: number) {
    const { messagesStore } = this.rootStore;
    try {
      this.removeErrorMessages();
      const apiCaller = apiAuthCaller();
      this.ignoreOSErrors(apiCaller);
      await apiCaller.get(`/warehouse/uploads/trigger/${uploadID}`, {
        params: { region: this.currentRegion },
      });
    } catch (error: CatchErr) {
      messagesStore.showErrorMessage(getApiErrorMessage(error, 'Failed to trigger upload'));
    }
  }

  public removeErrorMessages() {
    this.rootStore.messagesStore!.removeErrorMessage();
  }

  public async syncUploads(destId: string) {
    const { messagesStore } = this.rootStore;
    try {
      this.removeErrorMessages();
      const apiCaller = apiAuthCaller();
      this.ignoreOSErrors(apiCaller);
      const res = await apiCaller.get(`/warehouse/uploads/trigger/dest/${destId}`, {
        params: { region: this.currentRegion },
      });
      const successMessage = res.data || 'Success';
      messagesStore!.showSuccessMessage(successMessage);
    } catch (error: CatchErr) {
      messagesStore.showErrorMessage(
        getApiErrorMessage(error, `Failed to trigger uploads for destId= ${destId}`),
      );
    }
  }

  public async retryUploads(intervalInHours: number) {
    const { messagesStore } = this.rootStore;
    try {
      const { sourceId, destinationId } = this.filters;
      const body = {
        sourceId: sourceId === 'all' ? '' : sourceId,
        destinationId: destinationId === 'all' ? '' : destinationId,
        intervalInHours,
      };
      const apiCaller = apiAuthCaller();
      this.ignoreOSErrors(apiCaller);
      await apiCaller.post(`/warehouse/uploads/retry`, body, {
        params: { region: this.currentRegion },
      });
      messagesStore.showSuccessMessage('Aborted Syncs successfully retried');
    } catch (error: CatchErr) {
      messagesStore.showErrorMessage(getApiErrorMessage(error, `Failed to retry uploads`));
    }
  }

  public async countUploadsToRetry(intervalInHours: number): Promise<number | undefined> {
    if (!this.hasWareHouseDestinations()) {
      return 0;
    }
    const { messagesStore } = this.rootStore;
    try {
      const { sourceId, destinationId } = this.filters;
      const body = {
        sourceId: sourceId === 'all' ? '' : sourceId,
        destinationId: destinationId === 'all' ? '' : destinationId,
        intervalInHours,
      };
      const apiCaller = apiAuthCaller();
      this.ignoreOSErrors(apiCaller);
      const res = await apiCaller.post(`/warehouse/uploads/retry/count`, body, {
        params: { region: this.currentRegion },
      });

      const retryCount = res.data.count;
      if (retryCount === 0) {
        messagesStore.showSuccessMessage('No syncs to retry');
        return undefined;
      }

      return retryCount;
    } catch (error: CatchErr) {
      messagesStore.showErrorMessage(
        getApiErrorMessage(error, `Failed to get retry uploads count`),
      );
    }
    return undefined;
  }

  @action.bound
  public setRegion(region: string) {
    if (!region || !(region in Region)) {
      const {
        workspaceStore: { dataResidencyDefaultRegion: defaultRegion },
      } = this.rootStore;
      region = defaultRegion;
    }
    this.currentRegion = region as Region;
  }
}

import React from 'react';
import theme from '@css/theme';
import styled from 'styled-components';

const StyledSpan = styled.span`
  color: ${theme.token.colorError};
`;

export const RedAsterisk = ({ className }: { className?: string }) => (
  <StyledSpan className={className}>*</StyledSpan>
);

import { Button } from './styles';
import React from 'react';
// eslint-disable-next-line custom/no-antd-imports
import { ButtonProps } from 'antd/lib/button';
import XeroImg from '@png/xero/connect_with_xero.png';
import DisabledXeroImg from '@png/xero/disabled_connect_with_xero.png';
import GoogleImg from '@png/google/btn_google_signin_light_normal_web@2x.png';
import DisabledGoogleImg from '@png/google/btn_google_signin_light_disabled_web@2x.png';

type Props = {
  disabled?: boolean;
} & ButtonProps;

export const XeroButton = ({ disabled, ...props }: Props) => (
  <Button {...props} disabled={disabled}>
    {disabled ? (
      <img height="50px" src={DisabledXeroImg} alt="Connect with Xero" />
    ) : (
      <img height="50px" src={XeroImg} alt="Connect with Xero" />
    )}
  </Button>
);

export const GoogleButton = ({ disabled, ...props }: Props) => (
  <Button {...props} disabled={disabled}>
    {disabled ? (
      <img height="40px" src={DisabledGoogleImg} alt="Sign in with Google" />
    ) : (
      <img height="40px" src={GoogleImg} alt="Sign in with Google" />
    )}
  </Button>
);

import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Flex } from '@ui-library/flex';
import { SmallText } from '@ui-library/typography';
import { MonacoEditor } from '@components/common/codeEditor/monaco';
import { editorAutoHeight, editorDefaultFoldingLevel } from '@components/common/codeEditor/utils';
import { getErrorMessage } from '@components/destinations/destination-view/events/utils';
import { getFormattedSample } from './utils';
import { ErrorDetailsData } from './types';
import classes from './styles.module.scss';

const MAX_HEIGHT = 400;

interface Props {
  sample: string | object;
}

export const SampleError: React.FC<Props> = ({ sample: data }) => {
  const { type, sample } = getFormattedSample(data, true);

  return (
    <Flex className={classes.error_container}>
      {type === 'object' ? (
        <MonacoEditor
          onMount={(editor, monaco) => {
            editorAutoHeight(MAX_HEIGHT)(editor, monaco);
            editorDefaultFoldingLevel(2, MAX_HEIGHT)(editor, monaco);
          }}
          className={classes.error_editor}
          height={undefined}
          code={sample}
          readOnly
          language="json"
          hideLineNumber
        />
      ) : (
        sample
      )}
    </Flex>
  );
};

const StyledText = styled(SmallText).attrs(() => {
  const theme = useTheme();
  return {
    color: theme.token.colorError,
  };
})``;

export const SampleErrorWithDetails: React.FC<{ errorDetailsData: ErrorDetailsData }> = ({
  errorDetailsData,
}) => {
  const theme = useTheme();
  const { code, errorStage, module, description, payloadStage, response } = errorDetailsData;

  return (
    <Flex flexDirection="column" gap="0.75rem">
      {code && (
        <Flex alignItemsCenter gap="0.5rem">
          <FontAwesomeIcon icon={solid('circle-xmark')} color={theme.token.colorError} />
          <Flex flexDirection="column">
            <StyledText>Code: {code}</StyledText>
            <StyledText>Category: {getErrorMessage(code)}</StyledText>
            <StyledText>Module: {module}</StyledText>
            <StyledText>Error stage: {errorStage}</StyledText>
            {description && <StyledText>Description: {description}</StyledText>}
            <StyledText>Payload stage: {payloadStage}</StyledText>
          </Flex>
        </Flex>
      )}
      <SampleError sample={response} />
    </Flex>
  );
};

import React, { RefObject } from 'react';
import {
  Select as AntSelect,
  SelectProps as AntSelectProps,
  RefSelectProps,
  Tag,
  TagProps,
} from 'antd';
import type { InputStatus } from 'antd/es/_util/statusUtils';
import AntLibSelect, { SelectValue } from 'antd/lib/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled, { css } from 'styled-components';

export type { RefSelectProps } from 'antd';

export type { SelectValue } from 'antd/lib/select';

const { Option } = AntLibSelect;

export type { DefaultOptionType, SelectProps as BaseSelectProps } from 'antd/es/select';

export type SelectFieldVariant = 'badge';

export interface SelectProps<T> extends AntSelectProps<T> {
  hasError?: boolean;
  width?: string;
  variant?: SelectFieldVariant;
  inputRef?: RefObject<RefSelectProps>;
}

const variantBadgeEditableStyles = css(
  ({ theme }) => `
  width: auto!important;
  
  .ant-select-arrow {
    color: ${theme.token.colorPrimary}!important;
  }
  
  .ant-select-selection-item {
    color: ${theme.token.colorPrimary}!important;
    font-weight: 600;
   
  }
`,
);

const variantBadgeDisabledStyles = css(
  ({ theme }) => `
  width: auto!important;
  
  .ant-select-arrow {
    color: ${theme.token.colorPrimary}!important;
  }
  
  .ant-select-selection-item {
    color: ${theme.token['green.6']}!important;
    font-weight: 600;
  }
  
  .ant-select-selector {
    background-color: ${theme.token['green.1']}!important;
    border-color: ${theme.token['green.3']}!important;
  }
`,
);

const StyledSelect = styled(AntSelect)<SelectProps<unknown>>`
  --fa-animation-duration: 0.8s;

  .ant-select-selection-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-select-selection-item:has(> div) {
    display: flex;
  }

  .ant-select-arrow {
    color: ${({ theme, disabled }) =>
      disabled ? theme.token.colorTextDisabled : theme.token.colorTextBase} };
  }

  ${({ variant, disabled }) =>
    variant === 'badge' && (disabled ? variantBadgeDisabledStyles : variantBadgeEditableStyles)};
` as typeof AntSelect;

const getVariantProps = <T extends SelectValue = SelectValue>(
  variant?: SelectFieldVariant,
  status?: InputStatus,
  disabled?: boolean,
): Partial<SelectProps<T>> => {
  const variantProps: Partial<SelectProps<T>> = {};

  // eslint-disable-next-line sonarjs/no-small-switch
  switch (variant) {
    case 'badge':
      variantProps.className = `ant-select-variant-${variant}`;
      variantProps.bordered = Boolean(disabled || status);
      variantProps.suffixIcon = disabled ? null : (
        <FontAwesomeIcon icon={solid('caret-down')} size="lg" />
      );
      break;
    default:
      break;
  }

  return variantProps;
};

const Select = <T extends SelectValue = SelectValue>({
  popupMatchSelectWidth = false,
  hasError,
  size = 'large',
  status,
  width = '100%',
  variant,
  inputRef,
  ...rest
}: SelectProps<T>) => {
  const variantProps: Partial<SelectProps<T>> = getVariantProps(variant, status, rest?.disabled);

  return (
    <StyledSelect
      size={size}
      status={hasError ? 'error' : status}
      popupMatchSelectWidth={popupMatchSelectWidth}
      {...rest}
      variant={variant}
      {...variantProps}
      // Prevent style from being overwritten
      style={{ width }}
      suffixIcon={
        rest.loading ? (
          <FontAwesomeIcon icon={regular('spinner-third')} spin speed="0.1s" />
        ) : (
          <FontAwesomeIcon icon={solid('caret-down')} />
        )
      }
      ref={inputRef}
    />
  );
};

const SelectTag: React.FC<TagProps> = (props) => (
  <Tag closable closeIcon={<FontAwesomeIcon icon={regular('close')} />} {...props} />
);

export { Option, Select, SelectTag };

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ISource } from '@stores/source';
import { TAB_NAMES } from '@components/sources/source-view/utils';
import CloudSourceAuth from '@components/sources/source/cloudSource/cloudSourceAuth/cloudSourceAuth';
import CloudSourceSettings from '@components/sources/source/cloudSource/cloudSourceSettings';
import { DIRECTORY_TABS } from '@components/common/constants';
import { ISourceDataStore } from '@stores/sourceDataStore';
import { CredentialsAuth, SourceCategory, AccountValidator, DirectoryTab } from './sourceCategory';
import Credentials from '../source-view/source-settings/components/credentials';

class CloudSource extends SourceCategory {
  override isNewConnectionAllowed(source: { destinations: unknown[] }): boolean {
    return source.destinations.length === 0;
  }

  override async validateAccount({
    source,
    isNewAccountName,
    setCredentials,
    showErrorMessage,
    accountInfo,
    isOauthSource,
  }: AccountValidator) {
    if (!isOauthSource(source?.sourceDef!)) {
      if (await isNewAccountName(String(accountInfo?.options?.rudder_account_name))) {
        const skipValidation = source?.sourceDef.name === 'marketo';
        const account = await source!.createAccount({ ...accountInfo, skipValidation });
        setCredentials({ accountId: account.id, accountInfo: account, valid: true, updated: true });
        return account.id;
      }
      showErrorMessage?.(
        `Account Name ${accountInfo?.options?.rudder_account_name} already exists`,
      );
    }
    return undefined;
  }

  override handleSetup(canCreateCloud: boolean, _: boolean, create: () => void) {
    if (canCreateCloud) {
      create();
      return true;
    }
    return false;
  }

  override getAccountsEndpoint(sourceDef?: string) {
    const base = '/cloudSources/accounts';
    if (sourceDef) {
      return `${base}/${sourceDef}`;
    }
    return base;
  }

  override getSourceType(_: { name: string }) {
    return 'cloudSource';
  }

  override showTablePrefixSettings() {
    return true;
  }

  override showSyncScheduleSettings() {
    return true;
  }

  override isPartOf(tab: DirectoryTab) {
    return tab === DIRECTORY_TABS.CLOUD_EXTRACT;
  }

  override getAuthComponent({ handleChange, source, credentials }: CredentialsAuth) {
    return (
      <CloudSourceAuth
        onChange={handleChange}
        sourceDefinition={source?.sourceDef}
        selectedAccount={
          credentials?.accountId || source?.config?.rudderAccountId || source?.config?.accountId
        }
      />
    );
  }

  override getTabs(source: ISource) {
    if (CloudSourceSettings.configExists(source.sourceDef)) {
      return [
        TAB_NAMES.DESTINATIONS,
        TAB_NAMES.SYNCS,
        TAB_NAMES.CONFIGURATION,
        TAB_NAMES.RESOURCES,
        TAB_NAMES.SETTINGS,
      ];
    }
    return [TAB_NAMES.DESTINATIONS, TAB_NAMES.SYNCS, TAB_NAMES.RESOURCES, TAB_NAMES.SETTINGS];
  }

  override getCredentialSettings(
    sourceDataStore: ISourceDataStore,
    isAdmin: boolean,
  ): JSX.Element | null {
    return (
      <Credentials
        source={sourceDataStore}
        sourceDef={sourceDataStore?.sourceDef}
        config={sourceDataStore?.config}
        isAdmin={isAdmin}
      />
    );
  }
}

/* eslint-disable import/no-default-export */
export default CloudSource;

import { action, makeObservable } from 'mobx';
import { apiAuthCaller } from '@services/apiCaller';
import { FeatureFlagKeys, experiment } from '@lib/experiment';
import { IRootStore } from '@stores/index';
import { AlertDefinition, ResourceCategory, ResourceType } from './types';

export interface IAlertDefinitionsListStore {
  alertDefinitions: AlertDefinition[];
  load(): Promise<void>;
  getAlertDefinitionsByResourceCategory(
    resourceCategory: ResourceCategory,
    resourceType?: ResourceType,
  ): AlertDefinition[];
}

export class AlertDefinitionsListStore implements IAlertDefinitionsListStore {
  public rootStore: IRootStore;

  public alertDefinitions: AlertDefinition[] = [];

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @action.bound
  public async load() {
    if (this.alertDefinitions.length > 0) {
      return;
    }
    const res = await apiAuthCaller().get<AlertDefinition[]>(
      '/alertNotifications/alertDefinitions',
    );
    this.alertDefinitions = res.data;
  }

  public getAlertDefinitionsByResourceCategory = (
    resourceCategory: ResourceCategory,
    resourceType?: ResourceType,
  ) => {
    const isDelaysAlertEnabled = experiment.isFeatureEnabled(FeatureFlagKeys.delaysAlert);
    let filteredAlertDefinitions = this.alertDefinitions;
    // Remove delays alert if it is not enabled, delays alert has id 8
    if (!isDelaysAlertEnabled) {
      filteredAlertDefinitions = this.alertDefinitions.filter((def) => Number(def.id) !== 8);
    }

    return filteredAlertDefinitions.filter(
      (def) =>
        def.resourceCategory === resourceCategory &&
        (!resourceType || def.resourceType === resourceType),
    );
  };
}

import {
  CollapsibleSection,
  ConnectionModeField,
  Field,
  DestUIConfigV2,
  Group,
  NoteType,
  Section,
  ConditionalNoteType,
  RedirectField,
  OAuthField,
} from './types';
import { preRequisitesCheck } from '@components/common/formGroup/preRequisites';
import { ConfigValue, FormConfig } from '@components/common/formSchema/types';
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import { IDestinationStore } from '@stores/destination';
import { getSourceType } from '@components/common/util/util';
import { ConnectionMode, IDestDefinition } from '@stores/destinationDefsList';
import { isOauthEnabledDest } from '@components/common/util/authUtil';
import { SelectedSource } from '@components/workflows/steps/destinationSettings/util';
import { ISourceDefinition } from '@stores/sourceDefinitionsList';

const filterSections = (
  sections: Section[],
  config: FormConfig,
  availableFeatureFlags: Record<string, boolean> = {},
) =>
  sections.filter((section) =>
    preRequisitesCheck(section.preRequisites, config, availableFeatureFlags),
  );

const filterGroups = (
  groups: Group[],
  config: FormConfig,
  availableFeatureFlags: Record<string, boolean> = {},
) =>
  groups.filter((group) => preRequisitesCheck(group.preRequisites, config, availableFeatureFlags));

const filterFields = (
  fields: (Field | RedirectField | OAuthField)[],
  config: FormConfig,
  availableFeatureFlags: Record<string, boolean> = {},
) =>
  fields.filter((field) => preRequisitesCheck(field.preRequisites, config, availableFeatureFlags));

export const recalculateUiTemplate = (
  uiTemplate: CollapsibleSection[],
  config: FormConfig,
  availableFeatureFlags: Record<string, boolean> = {},
) => {
  let updatedConfig = cloneDeep(config);

  Object.keys(config).forEach((key) => {
    if (key.endsWith('-connectionMode')) {
      const [sourceType, sourceTypeKey] = key.split('-');
      const connectionModeValue = config[key] as ConnectionMode;
      set(updatedConfig, `${sourceTypeKey}.${sourceType}`, connectionModeValue);
      updatedConfig = getUpdatedConnectionModeSettingsConfig(
        updatedConfig,
        sourceType,
        connectionModeValue,
      );
    }
  });

  return uiTemplate.map((collapsibleSection) => {
    const collapsibleSectionCopy = cloneDeep(collapsibleSection);
    let filteredSections = filterSections(
      collapsibleSectionCopy.sections,
      updatedConfig,
      availableFeatureFlags,
    );

    filteredSections.forEach((section) => {
      let filteredGroups = filterGroups(section.groups, updatedConfig, availableFeatureFlags);

      filteredGroups.forEach((group) => {
        group.fields = filterFields(group.fields, updatedConfig, availableFeatureFlags);
        group.fields.forEach((field) => {
          if (typeof get(field, 'note.0.condition') === 'object') {
            const conditionalNotes: ConditionalNoteType = get(field, 'note');
            const finalNote = conditionalNotes.find((conditionalNote) =>
              preRequisitesCheck(conditionalNote.condition, updatedConfig),
            );
            if (finalNote) {
              set(field, 'note', finalNote.note);
            }
          }
        });
      });

      filteredGroups = filteredGroups.filter((group) => group.fields.length > 0);
      section.groups = filteredGroups;
    });

    filteredSections = filteredSections.filter((section) => section.groups.length > 0);
    collapsibleSectionCopy.sections = filteredSections;

    return collapsibleSectionCopy;
  });
};

const MOBILE_SOURCE_TYPES = ['android', 'ios', 'reactnative', 'flutter'];
const MOBILE_NOTE_LINK =
  'https://www.rudderstack.com/docs/destinations/streaming-destinations/amplitude/#adding-device-mode-integration';
const CONSENT_MANAGEMENT_FORM_GROUP_NOTE_LINK =
  'https://www.rudderstack.com/docs/sources/event-streams/sdks/consent-manager';

export const getSourceTypeForConnectionMode = (
  sourceDef: Pick<ISourceDefinition, 'name' | 'category'>,
) => {
  let sourceType = getSourceType(sourceDef);
  if (sourceType === 'cloud' || sourceType === 'warehouse') {
    sourceType = sourceDef.name.toLowerCase();
  }
  return sourceType;
};

export const getUniqueSourceToDisplayNameAndTypeMap = (
  selectedSources: SelectedSource[],
): Record<string, { displayName: string; type: string }> => {
  const uniqueSrcTypes: string[] = [];
  const uniqueSourceToDisplayNameAndTypeMap: Record<string, { displayName: string; type: string }> =
    {};
  selectedSources.forEach((source: SelectedSource) => {
    const uniqueSrc = getSourceTypeForConnectionMode(source.sourceDef);
    if (!uniqueSrcTypes.includes(uniqueSrc)) {
      uniqueSrcTypes.push(uniqueSrc);
      uniqueSourceToDisplayNameAndTypeMap[uniqueSrc] = {
        displayName: source.sourceDef.displayName,
        type: getSourceType(source.sourceDef),
      };
    }
  });
  return uniqueSourceToDisplayNameAndTypeMap;
};

const getSourceGroupTitle = (
  destinationDefinition: IDestDefinition,
  sourceType: string,
  displayName: string,
) => {
  let title = displayName;
  const sourceSupportedConnectionModes =
    destinationDefinition.config?.supportedConnectionModes?.[sourceType];
  if (
    sourceSupportedConnectionModes &&
    (sourceSupportedConnectionModes.includes('hybrid') ||
      sourceSupportedConnectionModes.includes('device'))
  ) {
    let titlePrefix = displayName;
    if (sourceType === 'web') {
      titlePrefix = 'Web';
    }
    title = `${titlePrefix} SDK`;
  }
  return title;
};

const getTypeToDisplayNameMap = (selectedSources: SelectedSource[]): Record<string, string> => {
  const sourceTypes: string[] = [];
  const sourceTypeToDisplayNameMap: Record<string, string> = {};
  selectedSources.forEach((source: SelectedSource) => {
    const sourceType = getSourceType(source.sourceDef);
    if (!sourceTypes.includes(sourceType)) {
      sourceTypes.push(sourceType);
      if (sourceType === 'cloud') {
        sourceTypeToDisplayNameMap[sourceType] = 'Cloud';
      } else if (sourceType === 'warehouse') {
        sourceTypeToDisplayNameMap[sourceType] = 'Warehouse';
      } else {
        sourceTypeToDisplayNameMap[sourceType] = source.sourceDef.displayName;
      }
    }
  });
  return sourceTypeToDisplayNameMap;
};

const getConnectionModeFields = (
  title: string,
  sourceType: string,
  destinationDefinition: IDestDefinition,
) => {
  const { supportedConnectionModes } = destinationDefinition.config;

  const sourceSupportedConnectionModes = supportedConnectionModes[sourceType];

  const connectionModeField: ConnectionModeField = {
    type: 'connectionMode',
    label: title,
    configKey: `${sourceType}-connectionMode`,
    value: 'cloud',
    sourceType,
    sourceSupportedConnectionModes,
    preRequisites: undefined,
  };
  return connectionModeField;
};

const getConfigTemplateFields = (
  configTemplate: Group | undefined,
  destDef: IDestDefinition,
  config: FormConfig,
  sourceType: string,
) => {
  const defaultFields: (Field | RedirectField)[] = [];
  const sourceSpecificFields: (Field | RedirectField)[] = [];

  if (!configTemplate) {
    return [];
  }

  configTemplate.fields.forEach((field) => {
    const isFormField = field.type !== 'redirect' && field.type !== 'OAuth';
    const isSourceSpecific =
      isFormField &&
      destDef.config.destConfig[sourceType] &&
      destDef.config.destConfig[sourceType].includes(field.configKey);
    const isDefault =
      isFormField &&
      destDef.config.destConfig.defaultConfig &&
      destDef.config.destConfig.defaultConfig.includes(field.configKey);

    if (isSourceSpecific) {
      const modifiedField = { ...field };
      modifiedField.configKey = `${sourceType}-${field.configKey}`;
      const value = config[field.configKey] as Record<string, ConfigValue>;

      if (value !== undefined && value[sourceType] !== undefined) {
        modifiedField.value = value[sourceType];
      }
      sourceSpecificFields.push(modifiedField);
    }

    if (isDefault) {
      const modifiedField = { ...field };
      const value = config[field.configKey] as ConfigValue;

      if (value !== undefined) {
        modifiedField.value = value;
      }
      defaultFields.push(modifiedField);
    }

    if (field.type === 'redirect') {
      const modifiedField = { ...field };
      modifiedField.sourceType = sourceType;
      sourceSpecificFields.push(modifiedField);
    }
  });

  const allFields = defaultFields.concat(sourceSpecificFields);

  return [allFields];
};

const getFormGroupsFromConsentSettingsTemplate = (
  displayName: string,
  title: string,
  groupFields: (Field | RedirectField)[] = [],
): Group[] => {
  const uiFormGroups: Group[] = [];

  if (groupFields.length > 0) {
    const defaultNote = `Configure ${displayName}’s ${title} consent management settings.`;
    const note: NoteType = [
      `${defaultNote} Then, follow `,
      {
        text: 'relevant steps ',
        link: CONSENT_MANAGEMENT_FORM_GROUP_NOTE_LINK,
      },
      `to add the desired consent management provider integration to your application.`,
    ];

    uiFormGroups.push({
      title,
      note,
      fields: groupFields,
    });
  }

  return uiFormGroups;
};

const getFormGroupsFromSdkTemplate = (
  sdkGroupFields: (Field | RedirectField)[],
  displayName: string,
  title: string,
  sourceType: string,
): Group[] => {
  const uiFormGroups: Group[] = [];

  if (sdkGroupFields.length > 0) {
    const defaultNote = `Configure ${displayName}’s native ${title}.`;
    let note: NoteType;

    if (MOBILE_SOURCE_TYPES.includes(sourceType)) {
      note = [
        `${defaultNote} Then, follow `,
        {
          text: 'these steps ',
          link: MOBILE_NOTE_LINK,
        },
        `to add the ${displayName} integration to your project`,
      ];
    } else {
      note = defaultNote;
    }

    uiFormGroups.push({
      title,
      note,
      fields: sdkGroupFields,
      preRequisites: {
        fields: [
          {
            configKey: `${sourceType}-connectionMode`,
            value: 'device',
          },
          {
            configKey: `${sourceType}-connectionMode`,
            value: 'hybrid',
          },
        ],
        condition: 'or',
      },
    });
  }

  return uiFormGroups;
};

export const buildInitialUiTemplate = (
  formTemplate: DestUIConfigV2,
  destination: IDestinationStore,
) => {
  const { baseTemplate, sdkTemplate, consentSettingsTemplate } = formTemplate;
  const uiTemplateCopy = cloneDeep(baseTemplate);
  const { config, destinationDefinition, sources, isRetlSourceConnected } = destination;
  const connectionModes: Field[] = [];
  const sdkGroups: Group[] = [];
  const consentSettingsGroups: Group[] = [];

  // Get connection mode fields config
  const uniqueSourceToDisplayNameAndTypeMap = getUniqueSourceToDisplayNameAndTypeMap(sources);
  Object.keys(uniqueSourceToDisplayNameAndTypeMap).forEach((uniqueSrc) => {
    const title = getSourceGroupTitle(
      destinationDefinition,
      uniqueSrc,
      uniqueSourceToDisplayNameAndTypeMap[uniqueSrc].displayName,
    );

    const connectionModeField = getConnectionModeFields(
      title,
      uniqueSourceToDisplayNameAndTypeMap[uniqueSrc].type,
      destinationDefinition,
    );
    connectionModes.push(connectionModeField);
  });

  // Get platform specific configurations
  const sourceTypeDisplayNameMap = getTypeToDisplayNameMap(sources);
  Object.keys(sourceTypeDisplayNameMap).forEach((sourceType) => {
    const title = getSourceGroupTitle(
      destinationDefinition,
      sourceType,
      sourceTypeDisplayNameMap[sourceType],
    );

    // Get platform specific device/hybrid mode SDK fields config
    const [sdkGroupFields] = getConfigTemplateFields(
      sdkTemplate,
      destinationDefinition,
      config,
      sourceType,
    );
    Array.prototype.push.apply(
      sdkGroups,
      getFormGroupsFromSdkTemplate(
        sdkGroupFields,
        destinationDefinition.displayName,
        title,
        sourceType,
      ),
    );

    // Get platform specific consent management fields config
    const [consentSettingsGroupFields] = getConfigTemplateFields(
      consentSettingsTemplate,
      destinationDefinition,
      config,
      sourceType,
    );
    Array.prototype.push.apply(
      consentSettingsGroups,
      getFormGroupsFromConsentSettingsTemplate(
        destinationDefinition.displayName,
        title,
        consentSettingsGroupFields,
      ),
    );
  });

  // Add OAuth fields in the UI
  const isOauthEnabled = isOauthEnabledDest(destinationDefinition);
  if (isOauthEnabled) {
    const oAuthField: OAuthField = {
      type: 'OAuth',
      label: 'Account Settings',
      configKey: 'rudderAccountId',
      destinationDefinition,
    };
    const { fields } = uiTemplateCopy[0].sections[0].groups[0];
    uiTemplateCopy[0].sections[0].groups[0].fields = [oAuthField, ...fields];
  }
  // OAuth

  // Add connection mode fields in the UI
  if (
    connectionModes.length > 0 &&
    destinationDefinition.category !== 'warehouse' &&
    !isRetlSourceConnected
  ) {
    uiTemplateCopy[0].sections[1].groups[0].fields = connectionModes;
  }

  // Add platform specific fields in UI
  const hasPlatformSpecificFields = sdkGroups.length > 0 || consentSettingsGroups.length > 0;
  if (hasPlatformSpecificFields) {
    uiTemplateCopy.forEach((collapsibleSection) => {
      if (collapsibleSection.title === 'Configuration settings') {
        collapsibleSection.sections.forEach((section) => {
          if (section.title === 'Destination settings') {
            // Add platform specific device/hybrid mode SDK fields in UI
            section.groups.push(...sdkGroups);
          } else if (section.id === 'consentSettings') {
            // Add platform specific consent management fields in UI
            section.groups.push(...consentSettingsGroups);
          }
        });
      }
    });
  }

  return uiTemplateCopy;
};

export const transformFromBEtoFE = (destination: IDestinationStore) => {
  const { config, destinationDefinition, sources } = destination;
  const transformedConfig: FormConfig = {};

  destinationDefinition.config.destConfig.defaultConfig.forEach((key) => {
    if (config[key] !== undefined) {
      transformedConfig[key] = config[key];
    }
  });

  const selectedSourceTypes: string[] = [];
  sources.forEach((source) => {
    const sourceType = source.sourceDef.type;
    if (!selectedSourceTypes.includes(sourceType)) {
      selectedSourceTypes.push(sourceType);
    }
  });

  selectedSourceTypes.forEach((sourceType) => {
    if (destinationDefinition.config.destConfig[sourceType]) {
      destinationDefinition.config.destConfig[sourceType].forEach((key) => {
        const value = get(config, `${key}.${sourceType}`);
        if (value !== undefined) {
          transformedConfig[`${sourceType}-${key}`] = value;
        }
      });
    } else {
      transformedConfig[`${sourceType}-connectionMode`] = 'cloud';
    }
  });

  return transformedConfig;
};

export const getUpdatedConnectionModeSettingsConfig = (
  config: FormConfig,
  sourceType: string,
  connectionMode: string,
) => {
  const cloud = 'connectionModes.cloud';
  const updatedConfig = config;
  if (MOBILE_SOURCE_TYPES.includes(sourceType) && connectionMode === 'device') {
    set(updatedConfig, 'connectionModes.mobileDevice', true);
  } else if (MOBILE_SOURCE_TYPES.includes(sourceType) && connectionMode === 'hybrid') {
    set(updatedConfig, cloud, true);
    set(updatedConfig, 'connectionModes.mobileDevice', true);
  } else if (sourceType === 'web' && connectionMode === 'device') {
    set(updatedConfig, 'connectionModes.webDevice', true);
  } else if (sourceType === 'web' && connectionMode === 'hybrid') {
    set(updatedConfig, cloud, true);
    set(updatedConfig, 'connectionModes.webDevice', true);
  } else {
    set(updatedConfig, cloud, true);
  }

  return updatedConfig;
};

export const setFieldValue = (field: Field, config: FormConfig, secretConfig: FormConfig) => {
  const updatedField: Field = { ...field };
  const configValue = get(config, updatedField.configKey);
  const secretConfigValue = get(secretConfig, updatedField.configKey);
  if (configValue !== undefined) {
    set(updatedField, 'value', configValue);
  } else if (
    updatedField.type === 'textInput' &&
    updatedField.secret &&
    secretConfigValue !== undefined
  ) {
    set(updatedField, 'placeholder', secretConfigValue);
  } else if (updatedField.value === undefined && updatedField.default !== undefined) {
    updatedField.value = updatedField.default;
  }
  return updatedField;
};

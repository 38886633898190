import { action, makeObservable, observable } from 'mobx';
import { IRootStore } from '@stores/index';
import { apiAuthCaller } from '@services/apiCaller';
import { getApiErrorMessage } from '@components/common/util/util';

interface CreateCredentialPayload {
  key: string;
  value: string;
  isSecret: boolean;
}

interface ICredentialStore {
  firstLoad: boolean;
  getCredentials: () => void;
  createCredential: (payload: CreateCredentialPayload) => Promise<string | undefined>;
  deleteCredential: (credentialId: string) => void;
  updateCredential: (
    credentialKey: string,
    payload: { value: string },
  ) => Promise<string | undefined>;
}

interface BaseCredential {
  id: string;
  key: string;
  workspaceId: string;
  isSecret: boolean;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
}

interface Secret extends BaseCredential {
  isSecret: true;
  secretVersion: number;
}

interface Variable extends BaseCredential {
  isSecret: false;
  value: string;
}

export type Credential = Secret | Variable;

export class CredentialStore implements ICredentialStore {
  rootStore: IRootStore;

  @observable firstLoad = false;

  @observable credentials: Credential[] = [];

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  setCredentials(credentials: Credential[]) {
    this.credentials = credentials;
  }

  @action.bound
  reset() {
    this.setCredentials([]);
    this.firstLoad = false;
  }

  @action.bound
  async getCredentials() {
    const { messagesStore } = this.rootStore;
    try {
      const {
        data: { credentials },
      } = await apiAuthCaller().get<{ credentials: Credential[] }>(`/credentials`);
      this.setCredentials(credentials);
    } catch (err) {
      messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to get Credential'));
    } finally {
      this.firstLoad = true;
    }
  }

  @action.bound
  async createCredential(payload: { key: string; value: string; isSecret: boolean }) {
    const { messagesStore } = this.rootStore;
    try {
      const { data } = await apiAuthCaller().post<Credential>(`/credentials`, { ...payload });
      this.credentials.push(data);
      messagesStore.showSuccessMessage('Credential added successfully');
      return data.id;
    } catch (err) {
      messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to create Credentials'));
      return undefined;
    }
  }

  @action.bound
  async deleteCredential(credentialId: string) {
    const { messagesStore } = this.rootStore;
    try {
      await apiAuthCaller().delete<void>(`/credentials/${credentialId}`);
      this.credentials = this.credentials.filter((credential) => credential.id !== credentialId);
      messagesStore.showSuccessMessage('Credential deleted successfully');
      return true;
    } catch (error) {
      messagesStore.showErrorMessage(getApiErrorMessage(error, 'Failed to delete credential'));
      return false;
    }
  }

  @action.bound
  async updateCredential(credentialId: string, payload: { value: string }) {
    const { messagesStore } = this.rootStore;
    try {
      const { data } = await apiAuthCaller().put<Credential>(`/credentials/${credentialId}`, {
        ...payload,
      });
      this.credentials = this.credentials.map((credential) =>
        credential.id === credentialId ? data : credential,
      );
      messagesStore.showSuccessMessage('Credential updated successfully');
      return data.id;
    } catch (error) {
      messagesStore.showErrorMessage(getApiErrorMessage(error, 'Failed to update credential'));
      return undefined;
    }
  }
}

import { action, observable, makeObservable } from 'mobx';
import { IRootStore } from '@stores/index';

export interface ISyncTable {
  name?: string;
  count?: number;
  status?: string;
  lastExecAt?: string;
  finishedAt?: string;
  duration?: string;
  error?: string;
}

export interface ISyncStore {
  update(sync: ISyncStore): void;
  id: string;
  sourceId: string;
  destinationId: string;
  destinationType: string;
  namespace: string;
  firstEventAt?: string;
  lastEventAt?: string;
  lastExecAt?: string;
  nextRetryTime?: string;
  duration?: number;
  status: string;
  tables?: ISyncTable[];
  error?: string;
  attempt?: number;
  createdAt?: string;
  isArchivedUpload: boolean;
  rootStore: IRootStore;
}

export class SyncStore implements ISyncStore {
  @observable public id: string;

  @observable public sourceId: string;

  @observable public destinationId: string;

  @observable public destinationType: string;

  @observable public namespace: string;

  @observable public firstEventAt: string | undefined;

  @observable public lastEventAt: string | undefined;

  @observable public lastExecAt: string | undefined;

  @observable public nextRetryTime: string | undefined;

  @observable public duration: number | undefined;

  @observable public status: string;

  @observable public error: string | undefined;

  @observable public attempt: number | undefined;

  @observable public createdAt: string | undefined;

  @observable public tables: ISyncTable[] | undefined;

  @observable public isArchivedUpload: boolean;

  @observable public rootStore: IRootStore;

  constructor(sync: ISyncStore, rootStore: IRootStore) {
    makeObservable(this);
    this.id = sync.id;
    this.sourceId = sync.sourceId;
    this.destinationId = sync.destinationId;
    this.destinationType = sync.destinationType;
    this.namespace = sync.namespace;
    this.firstEventAt = sync.firstEventAt;
    this.lastEventAt = sync.lastEventAt;
    this.nextRetryTime = sync.nextRetryTime;
    this.lastExecAt = sync.lastExecAt;
    this.duration = sync.duration;
    this.status = sync.status;
    this.error = sync.error;
    this.attempt = sync.attempt;
    this.createdAt = sync.createdAt;
    this.tables = sync.tables;
    this.isArchivedUpload = sync.isArchivedUpload;
    this.rootStore = rootStore;
  }

  @action.bound
  public async update(sync: ISyncStore) {
    Object.assign(this, sync);
  }
}

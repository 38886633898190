import { stores } from '@stores/index';
import useStores from '@stores/useStores';
import { experiment, FeatureFlagKeys } from '@lib/experiment';

export type LimitType = {
  limit?: number;
  currentCount?: number;
  remaining?: number;
  forceDisplay?: boolean;
  isExceeded: boolean;
  isLimitReached: boolean;
  message: string;
  description?: string;
};

type GetLimitsType = Omit<LimitType, 'message' | 'description'>;

export function getLimits(
  flag: 'TRACKING_PLANS_LIMIT' | 'TRACKING_PLAN_EVENTS_LIMIT' | 'TP_SOURCE_CONNECTIONS_LIMIT',
  options: { total: number; checkIfEnabled?: boolean },
): Required<GetLimitsType>;
export function getLimits(
  flag: 'TP_SOURCE_DROP_CONFIG',
  options?: { checkIfEnabled?: boolean },
): GetLimitsType;
export function getLimits(
  flag:
    | 'TRACKING_PLANS_LIMIT'
    | 'TRACKING_PLAN_EVENTS_LIMIT'
    | 'TP_SOURCE_DROP_CONFIG'
    | 'TP_SOURCE_CONNECTIONS_LIMIT',
  { total = 0, checkIfEnabled = true }: { total?: number; checkIfEnabled?: boolean } = {},
): GetLimitsType {
  if (checkIfEnabled) {
    const isPackagingEnabled = experiment.isFeatureEnabled(FeatureFlagKeys.enableTPPackaging);
    if (!isPackagingEnabled) {
      return {
        isLimitReached: false,
        isExceeded: false,
        forceDisplay: false,
      };
    }
  }
  const limit = stores.featuresStore.getFeatureConfiguration(flag);
  let remaining: number;
  let isLimitReached: boolean;
  let isExceeded = false;
  if (typeof limit === 'number') {
    remaining = Math.max(limit - total, 0);
    isLimitReached = remaining === 0;
    isExceeded = total > limit;
  } else {
    remaining = 0;
    isLimitReached = limit as boolean;
  }

  return {
    limit,
    currentCount: total,
    remaining,
    isLimitReached,
    isExceeded,
  } as GetLimitsType;
}

export const useTPWorkspaceLimit = () => {
  const { trackingPlanListStore, sourcesListStore } = useStores();

  const trackingPlanCount = trackingPlanListStore.plans.length;

  const connectionsCount = sourcesListStore.sources.reduce(
    (count, source) => (source.trackingPlanConfig?.trackingPlanId ? ++count : count),
    0,
  );

  const connectionLimit = getLimits('TP_SOURCE_CONNECTIONS_LIMIT', {
    total: connectionsCount,
    checkIfEnabled: false,
  });
  const trackingPlanLimit = getLimits('TRACKING_PLANS_LIMIT', { total: trackingPlanCount });
  const trackingPlanConfigurationDisabled = getLimits('TP_SOURCE_DROP_CONFIG');

  const limits: Record<
    'sourceConnectionsLimit' | 'trackingPlansLimit' | 'trackingPlanConfigurationLimit',
    LimitType
  > = {
    sourceConnectionsLimit: {
      ...connectionLimit,
      message: `${connectionLimit.currentCount} of ${connectionLimit.limit} tracking plans connected`,
      description: `You have ${connectionLimit.remaining} tracking plans remaining. Upgrade your plan to unlock unlimited tracking plans.`,
      forceDisplay: connectionLimit.limit < 200,
    },
    trackingPlansLimit: {
      ...trackingPlanLimit,
      message: `${trackingPlanLimit.currentCount} of ${trackingPlanLimit.limit} tracking plans`,
      description: `You have ${trackingPlanLimit.remaining} tracking plans remaining. Upgrade your plan to unlock unlimited tracking plans.`,
      forceDisplay:
        typeof trackingPlanLimit.forceDisplay === 'boolean'
          ? trackingPlanLimit.forceDisplay
          : trackingPlanLimit.limit < 200,
    },
    trackingPlanConfigurationLimit: {
      ...trackingPlanConfigurationDisabled,
      message: `You can’t edit violation configuration settings.`,
      description: `Upgrade your plan to unlock this feature.`,
    },
  };
  return limits;
};

export const useTrackingPlanLimit = ({ totalEvents }: { totalEvents: number }) => {
  const eventsLimit = getLimits('TRACKING_PLAN_EVENTS_LIMIT', { total: totalEvents });

  return {
    eventsLimit: {
      ...eventsLimit,
      forceDisplay:
        typeof eventsLimit.forceDisplay === 'boolean'
          ? eventsLimit.forceDisplay
          : eventsLimit.limit! < 200,
      message: `${eventsLimit.currentCount} of ${eventsLimit.limit} tracking plan events added`,
      description: `You have ${eventsLimit.remaining} tracking plan events remaining. Upgrade your plan to unlock unlimited events.`,
    },
  };
};

import { Region } from '@components/common/constants';
import { getApiErrorMessage } from '@components/common/util/util';
import { EVENT_TYPES } from '@components/profile/constants';
import { apiAuthCaller } from '@services/apiCaller';
import { IRootStore } from '@stores/index';
import formatDate from '@utils/formatDateHourly';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { action, computed, makeObservable, observable } from 'mobx';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

interface fromTo {
  startDate: number | undefined;
  endDate: number | undefined;
}

type EventsByType = {
  [p in keyof typeof EVENT_TYPES]: {
    totalEvents: number;
  };
};

interface EventTypeInfo {
  EventStream: number;
  WarehouseAction: number;
  CloudExtract: number;
  Total?: number;
}

interface ICardToken {
  id?: string;
  card?: {
    last4: string;
    brand: string;
    expiry?: string;
  };
}

interface ISetUpIntentInfo {
  clientSecret: string;
  nextAction: string;
  status: string;
}

type SetupIntentInfo = ISetUpIntentInfo | undefined;

export interface IPaymentMethod {
  id: string;
  customerId: string;
  card: {
    brand: string;
    country: string;
    expMonth: number;
    expYear: number;
    last4: string;
  };
}

interface ICardDetails {
  setDefault?: boolean;
  cardExpMonth?: number;
  cardExpYear?: number;
}

interface ISubscriptionSchedule {
  productName: string;
  startDate: number;
}

export interface IBillingStore {
  rootStore: IRootStore;
  totalEventsByCategory: EventsByType;
  billingTenure: fromTo | undefined;
  planName: ITenantInfo['planName'] | '';
  price: number;
  amountDue: number;
  paymentMethods: IPaymentMethod[];
  plansDetails: IPlanDetails[];
  cancelAtPeriodEnd: boolean;
  subscriptionSchedule: ISubscriptionSchedule | null;
  loadingPayments: boolean;
  eventTypePercent: EventTypeInfo;
  getPlanName: () => void;
  redirectToBilling: () => Promise<void>;
  updatePaymentMethod: (
    id: string,
    cardDetails: ICardDetails,
    isSuccess?: boolean,
  ) => Promise<void>;
  makeDefaultPayment: (isDefault: boolean) => Promise<void>;
  updateDefaultBillingEmail: (email: string) => void;
  updateSubscription: (stripePlanId: string) => Promise<void>;
  cancelSubscription: () => Promise<void>;
  getInvoicePreview: (stripePlanId: string) => Promise<void>;
  deletePaymentMethod: (paymentMethod: IPaymentMethod) => Promise<void>;
  getPaymentMethods: () => Promise<void>;
  getSelfServePlans: () => Promise<void>;
  getTenantDetail: () => void;
  getTotalEventsByCategory: (onSuccess?: () => void, onError?: () => void) => void;
  tenantInfo: ITenantInfo | undefined;
  firstLoad: boolean;
  nextBillingDate: number | undefined;
  currentPlan?: IPlanDetails;
  isFreeTier: boolean;
  clearBillingInfo: () => void;
}

interface IBillingInfoResponse {
  name: ITenantInfo['planName'];
  price: number;
  currentPeriodStartAt: number;
  currentPeriodEndAt: number;
  cancelAtPeriodEnd: boolean;
  metadata: { [index: string]: number | string };
  invoiceInfo: InvoiceInfoResp;
  paymentMethod: ICardToken;
  pendingSetupIntentInfo: SetupIntentInfo;
  emails: string[];
  subscriptionSchedule: ISubscriptionSchedule | null;
}

interface InvoiceInfoResp {
  name: string;
  address: {
    city: string;
    country: string;
    line1: string;
    line2: string;
    postalCode: string;
    state: string;
  };
}

export type PlanNameType = 'Enterprise' | 'Starter' | 'Free Tier' | 'Pro' | 'Open source plan';
interface ITenantInfo {
  planName: PlanNameType;
  planId: string;
  createdAt: string;
  currentPlanStartAt: number;
  contractEventVolume: number;
  invoiceEmails: string[];
  defaultPaymentMethod: IPaymentMethod;
  ownerEmail: string;
}

interface IBillingURL {
  url: string;
}

const eventsData: EventsByType = {
  CloudExtract: {
    totalEvents: 0,
  },
  WarehouseAction: {
    totalEvents: 0,
  },
  EventStream: {
    totalEvents: 0,
  },
};
export interface IPlanDetails {
  id: string;
  productName: string;
  planType: 'Starter' | 'Free Tier';
  stripePriceId: string;
  unitAmount: number;
  eventVolume: number;
}

export const billingCycleStartDate = dayjs().utc().startOf('month').unix();
const billingCycleEndDate = dayjs().add(1, 'month').utc().startOf('month').unix();

export class BillingStore implements IBillingStore {
  @observable public rootStore: IRootStore;

  @observable public planName: ITenantInfo['planName'] | '' = '';

  // used for only starter plan price in plans tab in settings page
  @observable public price = 0;

  @observable public amountDue = 0;

  @observable public paymentMethods: IPaymentMethod[] = [];

  @observable public plansDetails: IPlanDetails[] = [];

  @observable public loadingPayments = false;

  @observable public cancelAtPeriodEnd = false;

  @observable public subscriptionSchedule: ISubscriptionSchedule | null = null;

  @observable tenantInfo: ITenantInfo | undefined;

  @observable billingTenure: fromTo | undefined = {
    startDate: billingCycleStartDate * 1000,
    endDate: billingCycleEndDate * 1000,
  };

  @observable public totalEventsByCategory: EventsByType = eventsData;

  @observable firstLoad = false;

  @observable public eventTypePercent: EventTypeInfo = {
    EventStream: 0,
    WarehouseAction: 0,
    CloudExtract: 0,
  };

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action getPercentages() {
    const { EventStream, CloudExtract, WarehouseAction } = this.totalEventsByCategory;
    const denominator =
      EventStream.totalEvents + CloudExtract.totalEvents + WarehouseAction.totalEvents;
    this.eventTypePercent.EventStream = (Number(EventStream.totalEvents) / denominator) * 100 || 0;
    this.eventTypePercent.CloudExtract =
      (Number(CloudExtract.totalEvents) / denominator) * 100 || 0;
    this.eventTypePercent.WarehouseAction =
      (Number(WarehouseAction.totalEvents) / denominator) * 100 || 0;
  }

  @action.bound
  // get the plan type, the number of credits and other billing info
  public async getPlanName() {
    const { messagesStore, featuresStore } = this.rootStore;
    if (!featuresStore.has('BILLING_PAGE')) {
      return;
    }

    if (!this.tenantInfo) {
      await this.getTenantDetail();
    }
    await this.getSelfServePlans();
    if (this.isFreeTier) {
      const currentPlanStartsAt = dayjs(this.tenantInfo?.currentPlanStartAt)
        .utc()
        .unix();
      const startDate =
        billingCycleStartDate >= currentPlanStartsAt ? billingCycleStartDate : currentPlanStartsAt;
      this.updateBillingDates(startDate, billingCycleEndDate);
      return;
    }
    try {
      const resp: AxiosResponse<IBillingInfoResponse> = await apiAuthCaller({
        region: Region.US,
      }).get('/billing');
      this.updateBillingInfo(resp.data);
    } catch (error) {
      if (featuresStore.has('BILLING_PAGE')) {
        messagesStore.showErrorMessage('Failed to get billing details');
      }
    }
  }

  @action.bound
  private updateBillingInfo(subscriptionInfo: IBillingInfoResponse) {
    const {
      name,
      currentPeriodStartAt,
      currentPeriodEndAt,
      price,
      cancelAtPeriodEnd,
      subscriptionSchedule,
    } = subscriptionInfo;
    this.planName = name;
    this.price = price;
    if (this.rootStore.featuresStore.has('BILLING_PAGE')) {
      this.cancelAtPeriodEnd = cancelAtPeriodEnd;
      this.subscriptionSchedule = subscriptionSchedule;
      this.updateBillingDates(currentPeriodStartAt, currentPeriodEndAt);
    }
  }

  @action.bound
  public clearBillingInfo() {
    this.tenantInfo = undefined;
  }

  @action.bound
  public async updateDefaultBillingEmail(email: string) {
    const {
      organizationStore: { id: orgId },
      messagesStore,
    } = this.rootStore;
    try {
      await apiAuthCaller({ nonWorkspaceRoute: true, region: Region.US }).put(
        `organizations/${orgId}/billing/information`,
        {
          billingEmail: email,
        },
      );
      if (this.tenantInfo) {
        this.tenantInfo.invoiceEmails = [email];
      }
      messagesStore.showSuccessMessage('Successfully updated billing email');
    } catch (e) {
      messagesStore.showErrorMessage('Unable to update billing email');
    }
  }

  @action.bound
  public async getSelfServePlans() {
    const {
      organizationStore: { id: orgId },
      featuresStore,
      messagesStore,
    } = this.rootStore;
    try {
      if (featuresStore.has('BILLING_PAGE')) {
        const response = await apiAuthCaller({ nonWorkspaceRoute: true, region: Region.US }).get(
          `organizations/${orgId}/plans/selfServe`,
        );
        this.plansDetails = response.data.sort(
          (a: IPlanDetails, b: IPlanDetails) => a.eventVolume - b.eventVolume,
        );
      }
    } catch (e) {
      messagesStore.showErrorMessage('Unable get plans details');
    }
  }

  @action.bound
  public async getTenantDetail() {
    const { featuresStore, permissionsStore } = this.rootStore;
    if (
      (!featuresStore.has('BILLING') && !featuresStore.has('USAGE')) ||
      !permissionsStore.hasPermission({ resourceType: 'workspace', action: 'edit' })
    ) {
      this.firstLoad = true;
      return;
    }
    try {
      const resp = await apiAuthCaller({ region: Region.US })
        .ignore400()
        .get<ITenantInfo>('/tenant');
      this.tenantInfo = resp.data;
      if (featuresStore.has('USAGE_PAGE')) {
        this.updateBillingDates(billingCycleStartDate, billingCycleEndDate);
      }
    } catch (err) {
      // handle error
    } finally {
      this.firstLoad = true;
    }
  }

  private updateBillingDates(start: number, end: number) {
    this.billingTenure = { startDate: start * 1000, endDate: end * 1000 };
  }

  @action.bound
  public async redirectToBilling() {
    try {
      const resp: AxiosResponse<IBillingURL> = await apiAuthCaller({ region: Region.US }).get(
        `/billing/url?callbackUrl=${window.location.href}`,
      );
      window.location.href = resp.data.url;
    } catch (error) {
      const message = getApiErrorMessage(error, 'Failed to redirect to payment page');
      this.rootStore.messagesStore.showErrorMessage(message);
    }
  }

  @action.bound
  public async getPaymentMethods() {
    try {
      const {
        organizationStore: { id },
      } = this.rootStore;
      this.loadingPayments = true;
      const resp: AxiosResponse<IPaymentMethod[]> = await apiAuthCaller({
        nonWorkspaceRoute: true,
        region: Region.US,
      }).get(`organizations/${id}/paymentMethods`);
      this.loadingPayments = false;
      this.paymentMethods = resp.data;
    } catch (error) {
      const message = getApiErrorMessage(error, 'Failed to get Plans Details');
      this.rootStore.messagesStore.showErrorMessage(message);
    }
  }

  @action.bound
  public async updatePaymentMethod(id: string, cardDetails: ICardDetails, makeDefault?: boolean) {
    const {
      messagesStore,
      organizationStore: { id: orgId },
    } = this.rootStore;
    try {
      await apiAuthCaller({ nonWorkspaceRoute: true, region: Region.US }).patch(
        `organizations/${orgId}/paymentMethods/${id}`,
        cardDetails,
      );
      if (makeDefault) {
        messagesStore.showSuccessMessage(
          `Added card details successfully and made it as default payment method`,
        );
      } else {
        messagesStore.showSuccessMessage('Updated card details successfully');
      }
      await this.getTenantDetail();
    } catch (error) {
      const message = getApiErrorMessage(error, 'Failed to update card details');
      messagesStore.showErrorMessage(message);
    }
  }

  @action.bound
  public async makeDefaultPayment(isDefault: boolean) {
    const { messagesStore } = this.rootStore;
    try {
      await this.getPaymentMethods();
      if (isDefault || this.paymentMethods.length === 1) {
        const deafultPaymethod = this.paymentMethods[0];
        await this.updatePaymentMethod(deafultPaymethod.id, { setDefault: true }, true);
      }
    } catch (error) {
      const message = getApiErrorMessage(
        error,
        `Failed to make ${
          this.paymentMethods[this.paymentMethods.length - 1].id
        } as default payment`,
      );
      messagesStore.showErrorMessage(message);
    }
  }

  @action.bound
  public async updateSubscription(stripePriceId: string) {
    const {
      messagesStore,
      featuresStore,
      workspaceStore,
      organizationStore: { id: orgId, getEventsCountsBySourceDefinitionCategory },
    } = this.rootStore;
    try {
      let response: AxiosResponse<{ planId: string; planName: PlanNameType }>;
      if (!this.isFreeTier) {
        response = await apiAuthCaller({ nonWorkspaceRoute: true, region: Region.US }).put(
          `organizations/${orgId}/subscriptions`,
          {
            stripePriceId,
          },
        );
      } else {
        response = await apiAuthCaller({ nonWorkspaceRoute: true, region: Region.US }).post(
          `organizations/${orgId}/subscriptions`,
          {
            stripePriceId,
          },
        );
      }
      if (response.data.planId && this.tenantInfo) {
        this.tenantInfo.planId = response.data.planId;
        this.tenantInfo.planName = response.data.planName;
      } else {
        await this.getTenantDetail();
      }
      await featuresStore.getFeatures();
      await getEventsCountsBySourceDefinitionCategory(
        this.billingTenure?.startDate as number,
        workspaceStore.id,
      );
      await this.getPlanName();
      messagesStore.showSuccessMessage('Updated subscription successfully');
    } catch (error) {
      const message = getApiErrorMessage(error, 'Failed to update subscription');
      messagesStore.showErrorMessage(message);
    }
  }

  @action.bound
  public async cancelSubscription() {
    const {
      messagesStore,
      organizationStore: { id: orgId },
    } = this.rootStore;
    try {
      await apiAuthCaller({ nonWorkspaceRoute: true, region: Region.US }).put(
        `organizations/${orgId}/subscriptions/cancel`,
      );
      await this.getPlanName();
      await this.getTenantDetail();
      messagesStore.showSuccessMessage('Succefully cancelled subscription');
    } catch (error) {
      const message = getApiErrorMessage(error, 'Failed to cancel subscription');
      messagesStore.showErrorMessage(message);
    }
  }

  @action.bound
  public async getInvoicePreview(stripePriceId: string) {
    const {
      messagesStore,
      organizationStore: { id: orgId },
    } = this.rootStore;
    try {
      const response = await apiAuthCaller({ nonWorkspaceRoute: true, region: Region.US }).get(
        `organizations/${orgId}/invoices/preview`,
        { params: { stripePriceId } },
      );
      if (response.data) {
        this.amountDue = response.data.amountDue;
      }
    } catch (error) {
      messagesStore.showErrorMessage('Unable to get amount due');
    }
  }

  @action.bound
  public async deletePaymentMethod(paymentMethod: IPaymentMethod) {
    const {
      messagesStore,
      organizationStore: { id },
    } = this.rootStore;
    try {
      await apiAuthCaller({ nonWorkspaceRoute: true, region: Region.US }).delete(
        `organizations/${id}/paymentMethods/${paymentMethod.id}`,
      );
      messagesStore.showSuccessMessage(
        `You’ve deleted ${paymentMethod.card.brand} ending in ${paymentMethod.card.last4}`,
      );
    } catch (error) {
      messagesStore.showErrorMessage(
        `Failed to delete the ${paymentMethod.card.brand} ending in ${paymentMethod.card.last4}`,
      );
    }
  }

  @computed get nextBillingDate() {
    if (this.billingTenure && this.billingTenure.endDate) {
      return this.billingTenure.endDate;
    }
    return undefined;
  }

  @computed get currentPlan() {
    const { planId } = this.tenantInfo || {};
    return this.plansDetails.find((p) => p.id === planId);
  }

  @computed get isFreeTier() {
    return this.currentPlan?.unitAmount === 0;
  }

  @action.bound
  public async getTotalEventsByCategory(onSuccess?: () => void, onError?: () => void) {
    const {
      organizationStore: { workspaceRegions },
    } = this.rootStore;
    if (this.billingTenure?.startDate && this.billingTenure.endDate) {
      const dates = {
        startDate: formatDate(this.billingTenure?.startDate),
        endDate: formatDate(this.billingTenure?.endDate),
      };
      Promise.all(
        workspaceRegions.map((region) =>
          apiAuthCaller({ region }).get<EventsByType>('/totalEvents', {
            params: { ...dates, region },
          }),
        ),
      )
        .then((resp) => {
          this.totalEventsByCategory = resp.reduce(
            (acc, curr) => {
              const { EventStream, CloudExtract, WarehouseAction } = curr.data;
              acc.EventStream.totalEvents += EventStream.totalEvents;
              acc.CloudExtract.totalEvents += CloudExtract.totalEvents;
              acc.WarehouseAction.totalEvents += WarehouseAction.totalEvents;
              return acc;
            },
            {
              EventStream: { totalEvents: 0 },
              CloudExtract: { totalEvents: 0 },
              WarehouseAction: { totalEvents: 0 },
            },
          );
          this.getPercentages();
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch(() => {
          if (onError) {
            onError();
          }
        });
    }
  }
}

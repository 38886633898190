import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const isValidDate = (date: string | number) => dayjs(date).isValid();

export const fromNow = (date: string) => dayjs(date).fromNow();

export const defaultFormat = (date: string) => dayjs(date).format('DD/MM/YYYY HH:mm:ss');

/**
 * Checks if a date is after a comparison date.
 *
 * @param {string} date - The date to check.
 * @param {string} compareDate - The date to compare against.
 * @returns {boolean} Returns true if the date is after the comparison date, otherwise false.
 */
export const isAfter = (date: string, compareDate: string): boolean =>
  dayjs(date).isAfter(dayjs(compareDate));

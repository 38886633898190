import { observable, action, computed, makeObservable } from 'mobx';
import dayjs from 'dayjs';
import { IRootStore } from '@stores/index';
import { apiAuthCaller } from '@services/apiCaller';
import { getApiErrorMessage } from '@components/common/util/util';
import {
  ErrorType,
  ISampleErrorsStore,
  SyncConfigStatus,
} from '@components/sources/sourceDetails/retlSyncsV2/stores/types';
import { ISourceStore } from '@stores/source';
import { IDestinationStore } from '@stores/destination';
import { getRoundedValue } from '@stores/healthDashboard/utils';
import { SampleErrorsStore } from '@components/sources/sourceDetails/retlSyncsV2/stores/sampleErrors';

interface RawSyncData {
  sourceId: string;
  destinationId: string;
  runId: string;
  start: string;
  end: string;
  status: SyncConfigStatus.SUCCEEDED | SyncConfigStatus.FAILED | SyncConfigStatus.SYNCING;
  error?: string;
  trigger: 'manual' | 'scheduled';
  metrics: {
    total: number;
    invalid: number;
    changed: number;
    successful: number;
    failed: number;
  };
  totalRuns: {
    total: number;
    failed: number;
    aborted: number;
  };
  syncMode: string;
  sync_type: 'full' | 'incremental';
}

export interface SyncData {
  key: string;
  runId: string;
  source: ISourceStore;
  destination: IDestinationStore;
  connectionId: string;
  status:
    | SyncConfigStatus.SUCCEEDED
    | SyncConfigStatus.FAILED
    | SyncConfigStatus.ABORTED
    | SyncConfigStatus.SYNCING;
  error?: string;
  startedAt: number;
  duration: number;
  invalidCount: number;
  invalidPercent: string;
  failedCount: number;
  failedPercent: string;
  changedCount: number;
  totalCount: number;
  totalRuns: {
    total: number;
    failed: number;
    aborted: number;
  };
}

type FilterType = 'search' | 'dropdown' | null;
type Filters = {
  source: string;
  destination: string;
  status: string;
  search: string;
};

const defaultFilters = {
  source: 'all',
  destination: 'all',
  status: 'all',
  search: '',
};

export interface RETLHealth {
  syncsData: SyncData[];
  filteredSyncsData: SyncData[];
  loading: boolean;
  selectedFilter: FilterType;
  filters: Filters;
  retlSyncsCount: {
    total: number;
    failed: number;
    aborted: number;
  };
  activeFiltersCount: number;
  selectedConnection: SyncData | null;
  loadingFailures: boolean;
  sampleErrorsStore: ISampleErrorsStore | null;
  load(): void;
  refresh(): void;
  setSelectedFilter(type: FilterType): void;
  setFilters(filters: Filters): void;
  resetDropdownFilters(): void;
  setSelectedConnection(connection: SyncData | null): void;
}

export class RETLHealthStore implements RETLHealth {
  private rootStore: IRootStore;

  @observable private rawSyncsData: RawSyncData[] | null = null;

  @observable public loading = false;

  @observable public selectedFilter: FilterType = null;

  @observable public filters: Filters = defaultFilters;

  @observable public selectedConnection: SyncData | null = null;

  @observable public loadingFailures = false;

  @observable public sampleErrorsStore: ISampleErrorsStore | null = null;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  public setSelectedFilter = (type: FilterType) => {
    this.selectedFilter = type;
  };

  public setFilters = (filters: Filters) => {
    this.filters = filters;
  };

  public resetDropdownFilters = () => {
    this.filters = {
      ...defaultFilters,
      search: this.filters.search,
    };
  };

  public load = () => {
    if (!this.rawSyncsData) {
      this.getSyncsData();
    }
  };

  public refresh = () => {
    this.getSyncsData();
  };

  public setSelectedConnection = (connection: SyncData | null) => {
    this.selectedConnection = connection;
    if (connection) {
      this.sampleErrorsStore = new SampleErrorsStore(
        {
          errorType: ErrorType.FAILED,
          runId: connection.runId,
          startedAt: dayjs(connection.startedAt),
          sourceId: connection.source.id,
        },
        this.rootStore,
      );
    } else {
      this.sampleErrorsStore = null;
    }
  };

  @computed
  public get activeFiltersCount() {
    return Object.values(this.filters).filter((value) => value !== 'all' && value.trim() !== '')
      .length;
  }

  @computed
  public get syncsData() {
    const {
      destinationsListStore: { destinationById },
      sourcesListStore: { sourceById },
      retlConnectionListStore: { getConnectionsBySourceId },
    } = this.rootStore;

    if (!this.rawSyncsData || this.rawSyncsData.length === 0) {
      return [];
    }

    const mappedData = this.rawSyncsData.map((sync) => {
      const source = sourceById(sync.sourceId);
      const destination = destinationById(sync.destinationId);
      const connection = getConnectionsBySourceId(sync.sourceId).find(
        (connection) => connection.destinationId === destination?.id,
      );
      if (!source || !destination || !connection) {
        return null;
      }
      const startedAt = Number(dayjs(sync.start).valueOf());
      const finishedAt = Number(dayjs(sync.end).valueOf());
      let syncStatus: SyncData['status'] = sync.status;
      if (sync.status === SyncConfigStatus.FAILED) {
        syncStatus = SyncConfigStatus.ABORTED; // sync failed to complete = aborted
      } else if (sync.status === SyncConfigStatus.SUCCEEDED && sync.metrics.failed > 0) {
        syncStatus = SyncConfigStatus.FAILED; // sync completed but some deltas failed = failed
      }

      const syncItem: SyncData = {
        key: sync.runId,
        runId: sync.runId,
        source,
        destination,
        connectionId: connection.id,
        status: syncStatus,
        error: sync.error,
        startedAt,
        duration: ((sync.status === 'in progress' ? Date.now() : finishedAt) - startedAt) / 1000,
        invalidCount: sync.metrics.invalid,
        invalidPercent: sync.metrics.total
          ? `${getRoundedValue((sync.metrics.invalid / sync.metrics.total) * 100, 2)}%`
          : '0%',
        failedCount: sync.metrics.failed,
        failedPercent: sync.metrics.changed
          ? `${getRoundedValue((sync.metrics.failed / sync.metrics.changed) * 100, 2)}%`
          : '0%',
        changedCount: sync.metrics.changed,
        totalCount: sync.metrics.total,
        totalRuns: sync.totalRuns,
      };
      return syncItem;
    });

    return mappedData.filter((sync) => sync !== null) as SyncData[];
  }

  @computed
  public get filteredSyncsData() {
    let filteredData = this.syncsData;
    const searchValue = this.filters.search.trim().toLowerCase();
    if (searchValue.length > 0) {
      filteredData = filteredData.filter((sync) =>
        [
          sync.source.name,
          sync.destination.name,
          sync.source.sourceDef.displayName,
          sync.destination.destinationDefinition.displayName,
        ].some((value) => value.toLowerCase().includes(searchValue)),
      );
    }
    if (this.filters.source !== 'all') {
      filteredData = filteredData.filter((sync) => sync.source.id === this.filters.source);
    }
    if (this.filters.destination !== 'all') {
      filteredData = filteredData.filter(
        (sync) => sync.destination.id === this.filters.destination,
      );
    }
    if (this.filters.status !== 'all') {
      filteredData = filteredData.filter((sync) => sync.status === this.filters.status);
    }
    return filteredData;
  }

  @computed
  public get retlSyncsCount() {
    const failedSyncsCount = this.syncsData.filter((sync) => sync.status === 'failed').length;
    const abortedSyncsCount = this.syncsData.filter((sync) => sync.status === 'aborted').length;
    return {
      total: failedSyncsCount + abortedSyncsCount,
      failed: failedSyncsCount,
      aborted: abortedSyncsCount,
    };
  }

  @action.bound
  private async getSyncsData() {
    const {
      healthDashboardStore,
      messagesStore,
      workspaceStore: { defaultRegion: region },
    } = this.rootStore;
    const { periodFilter } = healthDashboardStore;

    this.loading = true;
    try {
      const { data } = await apiAuthCaller().get<RawSyncData[]>('/retlLatestSyncs', {
        params: {
          ...periodFilter.currentPeriod,
          region,
        },
      });
      this.rawSyncsData = data;
    } catch (error) {
      messagesStore.showErrorMessage(getApiErrorMessage(error, 'Failed to load rETL syncs data'));
    } finally {
      this.loading = false;
    }
  }
}

import { SOURCE_DEFINITION_CATEGORY } from '@components/common/constants';
import CloudSource from './cloudSource';
import WarehouseSource from './warehouseSource';
import EventStreamSource from './eventStreamSource';
import SingerSource from './singerSource';
import { SourceDefinitionCategory } from '@stores/types';

/* eslint-disable import/no-default-export */
export default (type: SourceDefinitionCategory | undefined) => {
  switch (type) {
    case SOURCE_DEFINITION_CATEGORY.CLOUD:
      return new CloudSource();
    case SOURCE_DEFINITION_CATEGORY.SINGER:
      return new SingerSource();
    case SOURCE_DEFINITION_CATEGORY.WAREHOUSE:
      return new WarehouseSource();
    default:
      return new EventStreamSource();
  }
};

import { action, observable, makeObservable } from 'mobx';

import { IRootStore } from '@stores/index';
import { apiAuthCaller } from '@services/apiCaller';
import { getApiErrorMessage } from '@components/common/util/util';
import { IDestinationStore } from './destination';
import ModelListStore from './modelsList';
import { IAudienceListStore } from './audiences';
import { Source } from './source/types';
import { isDeviceModeSupported } from '@components/transformations/transformationDetails/connections/utils';

type Connection = {
  id: string;
  destinations: { id: string }[];
};

export interface IConnectionsStore {
  connections: ISourceConnections;
  rootStore: IRootStore;
  setConnections(sources: Connection[]): void;
  addRETLSourceConnections(sources: { [key: string]: string[] }): void;
  removeConnections(source: Source, destination: IDestinationStore): Promise<void>;
}

interface ISourceConnections {
  [key: string]: string[];
}

const removeOriginFromWarehouseSource = (
  sourceId: string,
  audienceListStore: IAudienceListStore,
  modelListStore: ModelListStore,
) => {
  const sqlModelId: string | undefined = modelListStore.sourceModelMap[sourceId];
  const audience = audienceListStore.getAudienceBySourceId(sourceId);
  if (sqlModelId) {
    const sqlModel = modelListStore.getModelById(sqlModelId);
    sqlModel?.setSourceIds(sqlModel.sourceIds.filter((id) => id !== sourceId));
  } else if (audience) {
    audience?.disconnect(sourceId);
  }
};

export class ConnectionsStore implements IConnectionsStore {
  @observable connections: ISourceConnections = {};

  rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  public async setConnections(connections: Connection[]) {
    const mappedConnections: ISourceConnections = {};
    connections.forEach((connection) => {
      mappedConnections[connection.id] = connection.destinations.map((dest) => dest.id);
    });
    this.connections = { ...this.connections, ...mappedConnections };
  }

  @action.bound
  public async addRETLSourceConnections(sources: { [key: string]: string[] }) {
    this.connections = {
      ...this.connections,
      ...sources,
    };
  }

  @action.bound
  public async deleteRETLConnection(sourceId: string, destinationId: string) {
    const {
      retlConnectionListStore: { connections, deleteRetlConnection },
    } = this.rootStore;
    const connection = connections.find(
      (c) => c.sourceId === sourceId && c.destinationId === destinationId,
    );
    if (connection && connection.id) {
      await deleteRetlConnection(connection?.id);
    }
  }

  @action.bound
  public async removeConnections(source: Source, destination: IDestinationStore) {
    const { messagesStore, modelListStore, audienceListStore, retlSourceListStore } =
      this.rootStore;
    if (retlSourceListStore.getRetlSourceById(source.id)) {
      this.deleteRETLConnection(source.id, destination.id);
      return;
    }
    try {
      const res = await apiAuthCaller().post(`/destinations/${destination.id}/disconnect`, {
        sourceIds: [source.id],
      });
      removeOriginFromWarehouseSource(source.id, audienceListStore, modelListStore);

      if (res.data.destination) {
        destination.setConfig(res.data.destination);
      }
      messagesStore.showSuccessMessage('Connection removed successfully');
      this.updateConnections(source, destination);
      if (!isDeviceModeSupported(destination)) {
        Promise.all(
          destination?.transformationConnections?.map((transformation) =>
            transformation.resetConfig(),
          ),
        );
      }
    } catch (err) {
      messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to disconnect source'));
    }
  }

  private updateConnections(source: Source, destination: IDestinationStore) {
    const destinations = this.connections[source.id];
    const remainingDestinations = destinations.filter(
      (destId: string) => destId !== destination.id,
    );
    if (remainingDestinations.length > 0) {
      this.connections[source.id] = remainingDestinations;
    } else {
      delete this.connections[source.id];
    }
  }
}

import React from 'react';
import useStores from '@stores/useStores';
import { Loader } from '@ui-library/loader';
import { apiAuthCaller } from '@services/apiCaller';
import { CatchErr } from '@utils/types';
import AccountCredentials from '@components/common/accountCredentials';
import { getApiErrorMessage } from '@components/common/util/util';

interface IAccount {
  id: string;
  name: string;
  metadata?: { email: string };
  updatedAt?: string;
  createdAt: string;
}

interface Props {
  selectedAccount?: string;
  role: string;
  hasAccounts?: (hasAccount: boolean) => void;
  onClick: (id: string) => void;
  rudderCategory?: 'destination' | 'source';
  forceRefresh?: boolean;
  disabled?: boolean;
}

const AuthAccounts = ({
  role,
  onClick,
  selectedAccount,
  hasAccounts,
  rudderCategory = 'source',
  forceRefresh,
  disabled,
}: Props) => {
  const rudderCategoryUrl = rudderCategory === 'destination' ? 'destination' : 'cloudSources';
  const categoryRole = rudderCategory === 'destination' ? role.toLowerCase() : role;
  const { messagesStore } = useStores();
  const [accounts, setAccounts] = React.useState<IAccount[]>([]);
  const [loading, setLoading] = React.useState(false);

  const fetchAccounts = React.useCallback(() => {
    setLoading(true);
    apiAuthCaller()
      .get(`/${rudderCategoryUrl}/accounts/${categoryRole}`)
      .then((accounts) => {
        setAccounts(accounts.data);
        setLoading(false);
        if (hasAccounts) {
          hasAccounts(!!accounts.data?.length);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [role]);

  React.useEffect(() => {
    fetchAccounts();
    if (selectedAccount) {
      onClick(selectedAccount);
    }
  }, [role, forceRefresh]);
  const onDelete = React.useCallback(
    async (id: string) => {
      try {
        await apiAuthCaller().delete(`/${rudderCategoryUrl}/accounts/${id}`);
        onClick('');
        await fetchAccounts();
      } catch (err: CatchErr) {
        messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to delete account'));
      }
    },
    [role],
  );
  if (loading) {
    return <Loader type="spin" />;
  }
  if (accounts.length === 0) {
    return null;
  }

  return (
    <AccountCredentials
      accounts={accounts}
      onSelect={onClick}
      selectedAccount={selectedAccount || ''}
      onDelete={onDelete}
      isDeleteInProgress={false}
      disabled={disabled}
    />
  );
};

/* eslint-disable import/no-default-export */
export default AuthAccounts;

import { OnMount } from '@monaco-editor/react';

const LINE_HEIGHT = 18;
const GUTTER = 10;

const editorFoldingLevel =
  (level: number, maxHeight?: number): OnMount =>
  (editor) => {
    const editorFolding = `editor.foldLevel${level}`;
    setTimeout(() => {
      if (!maxHeight || editor.getContentHeight() > maxHeight) {
        editor.getAction(editorFolding)?.run();
      }
    }, 50);
  };

export const editorDefaultFoldingLevel =
  (level: number, maxHeight?: number): OnMount =>
  (editor, monaco) => {
    editorFoldingLevel(level, maxHeight)(editor, monaco);
    editor.onDidChangeModelContent(() => editorFoldingLevel(level, maxHeight)(editor, monaco));
  };

export const editorAutoHeight =
  (maxHeight: number): OnMount =>
  (editor) => {
    const el = editor.getDomNode() as HTMLDivElement;
    const codeContainer = el.getElementsByClassName('view-lines')[0] as HTMLDivElement;
    let prevLineCount = 0;
    editor.onDidChangeModelDecorations(() => {
      // wait until dom rendered
      setTimeout(() => {
        const isUnFolded = codeContainer?.childElementCount > prevLineCount;
        const height = isUnFolded
          ? codeContainer?.offsetHeight // unfold
          : codeContainer.childElementCount * LINE_HEIGHT + GUTTER; // fold
        prevLineCount = codeContainer.childElementCount;
        if (height > maxHeight) {
          el.style.height = `${maxHeight}px`;
          el.style.minHeight = `${maxHeight}px`;
        } else {
          el.style.height = `${height}px`;
          el.style.minHeight = `${height}px`;
        }
        editor.layout();
      }, 0);
    });
  };

export interface ErrorDetailsData {
  code: number;
  module: string;
  errorStage: string;
  payloadStage: string;
  description: string;
  response: string;
}

export enum Module {
  destination_filter = 'destination_filter',
  user_transformer = 'user_transformer',
  event_filter = 'event_filter',
  dest_transformer = 'dest_transformer',
  router = 'router',
  batch_router = 'batch_router',
  warehouse = 'warehouse',
}

export enum RouterSubModule {
  router_dest_transformer = 'router_dest_transformer',
  router_dest_delivery = 'router_dest_delivery',
}

export type ModuleInfo = {
  block: 'processor' | 'delivery';
  module: Module;
  moduleName: string;
  errorStage: string;
  description: string;
  payloadStage: string;
  subModules?: Record<RouterSubModule, { name: string }>;
};

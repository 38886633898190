import { css } from 'styled-components';

export const tooltipDefaultStyle = css`
  .custom_tooltip {
    --antd-arrow-background-color: ${({ theme }) => theme.token.tooltipBg};
    font-family: Inter;
    font-style: normal;
    font-weight: 500;

    .ant-tooltip-inner {
      background: ${({ theme }) => theme.token.tooltipBg};
      border-radius: 8px;
    }

    .ant-tooltip-arrow-content {
      background: ${({ theme }) => theme.token.tooltipBg};
    }
  }
`;

import { AggregatedData } from './types';

export const mergeAggregatedEventsStats = (
  aggregatedData: AggregatedData[],
  newData: AggregatedData[],
) => {
  const newDataMap: Record<string, AggregatedData> = {};
  newData.forEach((item) => {
    newDataMap[item.bucket] = item;
  });
  const aggregatedDataMap: Record<string, AggregatedData> = {};
  aggregatedData.forEach((item) => {
    aggregatedDataMap[item.bucket] = item;
  });
  Object.keys(newDataMap).forEach((key) => {
    const stat = newDataMap[key];
    if (aggregatedDataMap[key]) {
      aggregatedDataMap[key].totalFailed =
        (aggregatedDataMap[key].totalFailed || 0) + (stat.totalFailed || 0);
      aggregatedDataMap[key].totalDelivered =
        (aggregatedDataMap[key].totalDelivered || 0) + (stat.totalDelivered || 0);
      aggregatedDataMap[key].totalFiltered =
        (aggregatedDataMap[key].totalFiltered || 0) + (stat.totalFiltered || 0);
      aggregatedDataMap[key].totalProcessed =
        (aggregatedDataMap[key].totalProcessed || 0) + (stat.totalProcessed || 0);
    } else {
      aggregatedDataMap[key] = stat;
    }
  });
  return Object.values(aggregatedDataMap);
};

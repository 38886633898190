import React from 'react';
import moment from 'moment';
import { FixMe } from '@utils/types';

interface DateProps {
  date: Date | string;

  // Date format
  format?: string;

  // Display date relative to now
  ago?: false | true;

  // Should display subheader, even if empty
  subheader?: false | true | 'ago' | 'relative';

  // if subheader is 'relative' show distance of date relative to this value
  relativeTo?: Date;
  /* If you pass false, you can get the value without the suffix.
  ie 10 minutes ago, will be 10 minutes */
  relativeSuffix?: boolean;
}

interface ItemFormatProps {
  item: FixMe;
  subheader?: FixMe;
}

const ItemFormat = (props: ItemFormatProps) => (
  <span>
    {props.item && <span data-testid="item">{props.item}</span>}
    {props.subheader && (
      <span
        style={{
          fontSize: '80%',
          opacity: 0.8,
        }}
        data-testid="subHeader"
      >
        {props.subheader}
      </span>
    )}
  </span>
);

export class DateFormat extends React.Component<DateProps> {
  dateFormatted(): string {
    return moment(this.props.date).format(this.props.format);
  }

  fromNow(): string {
    return DateFormat.fromNow(this.props.date, this.props.relativeSuffix);
  }

  relativeDate(): string {
    const diff = moment(this.props.date).diff(moment(this.props.relativeTo));
    return `after ${moment.duration(diff, 'milliseconds').humanize()}`;
  }

  dateString(): string {
    if (this.props.ago) {
      return this.fromNow();
    }
    return this.dateFormatted();
  }

  subheaderString(): string {
    if (this.props.subheader === 'relative') {
      return this.relativeDate();
    }
    return this.fromNow();
  }

  static fromNow = (date: Date | string, suffix = true): string => moment(date).fromNow(!suffix);

  static from = (fromDate: Date | string, toDate: Date | string, suffix = true): string =>
    moment(fromDate).from(toDate, !suffix);

  render() {
    if (this.props.date) {
      if (this.props.subheader) {
        return (
          <ItemFormat
            item={this.dateString()}
            subheader={this.props.subheader && this.subheaderString()}
          />
        );
      }
      return <ItemFormat item={this.dateString()} />;
    }
    return <span>unknown</span>;
  }
}

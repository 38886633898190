import React from 'react';
import { AlertProps as AntAlertProps } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledAlert } from './styles';
import {
  faCircleCheck,
  faCircleInfo,
  faOctagonExclamation,
  faStars,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';

const iconMap = {
  error: <FontAwesomeIcon icon={faOctagonExclamation} />,
  success: <FontAwesomeIcon icon={faCircleCheck} />,
  warning: <FontAwesomeIcon icon={faTriangleExclamation} />,
  info: <FontAwesomeIcon icon={faCircleInfo} />,
  tip: <FontAwesomeIcon icon={faStars} />,
};

// ts-prune-ignore-next
export type AlertProps = Omit<AntAlertProps, 'type' | 'icon'> & {
  type?: 'error' | 'success' | 'warning' | 'info' | 'tip';
};

export const Alert = ({
  type = 'info',
  showIcon = true,
  closable = false,
  ...rest
}: AlertProps) => (
  <StyledAlert
    customVariant={type === 'tip' ? 'tip' : undefined}
    showIcon={showIcon}
    type={type === 'tip' ? undefined : type}
    closable={closable}
    {...rest}
    icon={iconMap[type]} // Prevent icon from being overridden
    closeIcon={<FontAwesomeIcon icon={faXmark} size="lg" />} // Prevent closeIcon from being overridden
  />
);

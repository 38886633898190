import * as React from 'react';
import styled from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';
import { InputField } from '@ui-library/input';
import { FixMe } from '@utils/types';
import { Flex } from '@ui-library/flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { LargeText } from '@ui-library/typography';
import { Button } from '@ui-library/button';

const Row = styled(Flex)`
  gap: 0.825rem;
  width: 100%;
  align-items: center;
`;

const Column = styled.div<{ basis: string }>`
  flex-basis: ${(props) => props.basis};
`;

interface IDynamicFormProps {
  field: FixMe;
  onChange: FixMe;
  disabled: boolean;
  secret: FixMe;
  hidden?: boolean;
}

interface IDynamicFormState {
  mapping: FixMe;
  count: FixMe;
  initCount: FixMe;
  secret: FixMe;
}

export class DynamicForm extends React.Component<IDynamicFormProps, IDynamicFormState> {
  constructor(props: IDynamicFormProps) {
    super(props);
    const { keyLeft } = props.field;
    const { keyRight } = props.field;
    this.state = {
      mapping: [
        {
          [keyLeft]: '',
          [keyRight]: '',
        },
      ],
      count: 1,
      initCount: 0,
      secret: [],
    };
  }

  componentDidMount() {
    const { field, onChange, secret } = this.props;
    const secretCopy = cloneDeep(secret || []);
    if (field.default) {
      this.setState(
        {
          mapping: field.default,
          count: field.default.length,
          initCount: field.default.length,
          secret: secretCopy,
        },
        () => onChange(field.value, this.state.mapping),
      );
    }
  }

  public onConfigChange = (index: number, value: string, isLeft: boolean) => {
    const { onChange, field } = this.props;
    const { keyLeft } = field;
    const { keyRight } = field;

    this.setState(
      (prevState: FixMe) => {
        const mapping = prevState.mapping.map((item: FixMe) => ({ ...item }));
        const updatedItem = {
          ...mapping[index],
          [isLeft ? keyLeft : keyRight]: value,
        };
        mapping.splice(index, 1, updatedItem);
        return {
          ...prevState,
          mapping,
        };
      },
      () => onChange(field.value, this.state.mapping),
    );
  };

  public handleDeleteRow = (index: number) => {
    const { onChange, field } = this.props;
    this.setState(
      (prevState: FixMe) => {
        const mapping = prevState.mapping.splice(0);
        const secret = prevState.secret.splice(0);
        mapping.splice(index, 1);
        if (index < secret.length) {
          secret.splice(index, 1);
        }
        const count = prevState.count - 1;
        return {
          ...prevState,
          mapping,
          count,
          secret,
        };
      },
      () => onChange(field.value, this.state.mapping),
    );
  };

  public getPlaceholderValue = (isSecret: boolean, index: number, key: string, secret: FixMe) =>
    isSecret && secret[index] && secret[index][key];

  public restrictiveMode = (index: number, option: string) => {
    const { field } = this.props;
    const { initCount } = this.state;
    const restrictiveMode =
      field.disableInputLeft || field.disableInputRight || field.disableDelete;
    const fieldRestrict = restrictiveMode && index < initCount;
    switch (option) {
      case 'inputLeft':
        return fieldRestrict && field.disableInputLeft;
      case 'inputRight':
        return fieldRestrict && field.disableInputRight;
      case 'delete':
        return fieldRestrict && field.disableDelete;
      case 'any':
        return restrictiveMode;
      default:
        return false;
    }
  };

  public renderSingleRow = (index: number) => {
    const { field, disabled } = this.props;
    const { mapping, secret } = this.state;
    const isSecret = (secret && secret.length > 0) || false;
    const { label, labelRight, keyLeft, keyRight, placeholderLeft, placeholderRight } = field;

    return (
      <Row key={index}>
        <Column basis="45%">
          <InputField
            value={mapping[index] && mapping[index][keyLeft]}
            placeholder={
              this.getPlaceholderValue(isSecret, index, keyLeft, secret) || placeholderLeft
            }
            onChange={(e: FixMe) => this.onConfigChange(index, e.target.value, true)}
            disabled={disabled || !!this.restrictiveMode(index, 'inputLeft')}
            data-testid={`${label}-${index}`}
          />
        </Column>
        <Column basis="40%">
          <InputField
            value={mapping[index] && mapping[index][keyRight]}
            placeholder={
              this.getPlaceholderValue(isSecret, index, keyRight, secret) || placeholderRight
            }
            onChange={(e: FixMe) => this.onConfigChange(index, e.target.value, false)}
            disabled={disabled || !!this.restrictiveMode(index, 'inputRight')}
            data-testid={`${labelRight}-${index}`}
          />
        </Column>
        {mapping.length > 1 && !this.restrictiveMode(index, 'delete') && !disabled && (
          <Column basis="5%">
            <Button
              type="text"
              size="middle"
              icon={<FontAwesomeIcon icon={regular('circle-minus')} />}
              onClick={() => this.handleDeleteRow(index)}
            />
          </Column>
        )}
      </Row>
    );
  };

  handleAddMore = () => {
    this.setState((prevState: IDynamicFormState) => ({
      ...prevState,
      count: prevState.count + 1,
    }));
  };

  public render() {
    const { field, hidden, disabled } = this.props;
    const { count } = this.state;
    if (hidden) {
      return null;
    }
    return (
      <div data-testid="dynamicForm">
        <Row className="m-b-md">
          <LargeText className="m-b-xs m-t-lg" data-testid="label">
            {field.label}
          </LargeText>
        </Row>

        <Row className="m-b-sm">
          <Column basis="45%" data-testid="labelLeft">
            {field.labelLeft}
          </Column>
          <Column basis="40%" data-testid="labelRight">
            {field.labelRight}
          </Column>
          <Column basis="5%" />
        </Row>

        <Flex flexDirection="column" gap=".5rem">
          {[...Array(count)].map((_v: FixMe, index: number) => this.renderSingleRow(index))}
        </Flex>

        {!disabled && (
          <Button
            noPadding
            data-testid="anchor"
            type="link"
            onClick={this.handleAddMore}
            icon={<FontAwesomeIcon icon={regular('circle-plus')} />}
          >
            Add more
          </Button>
        )}
      </div>
    );
  }
}

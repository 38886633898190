/* eslint-disable max-classes-per-file */
import React, { ReactNode } from 'react';
import { inject, observer } from 'mobx-react';
import { IUserStore } from '@stores/user';
import { RouteComponentProps } from 'react-router-dom';
import { Path } from '@components/routes';

interface IAuthenticateProps extends RouteComponentProps {
  userStore: IUserStore;
  children?: ReactNode;
}

type ComposedComponent = (props: IAuthenticateProps) => JSX.Element | null;

abstract class AuthRouteGuard extends React.Component<IAuthenticateProps> {
  abstract render(): JSX.Element;

  protected abstract hasActiveSession(): boolean;

  public componentDidMount() {
    this._checkAndRedirect();
  }

  public componentDidUpdate() {
    this._checkAndRedirect();
  }

  public _checkAndRedirect() {
    const { history } = this.props;

    if (!this.hasActiveSession()) {
      history.push(Path.LOGIN);
    }
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  protected _render(ComposedComponent: ComposedComponent) {
    return <>{this.hasActiveSession() && <ComposedComponent {...this.props} />}</>;
  }
}

/**
 * Route guard that requires User to be in a logged in state, eg have valid user store with idToken
 */
export const requiresAuth = (ComposedComponent: ComposedComponent) => {
  @inject('userStore')
  @observer
  class Authenticate extends AuthRouteGuard {
    protected hasActiveSession(): boolean {
      return this.props.userStore.loggedIn;
    }

    public render() {
      return super._render(ComposedComponent);
    }
  }
  return Authenticate;
};

/**
 * Route guard that requires User to either be logged in or have started an MFA flow,
 * such as MFA submission, or MFA set up
 */
export const requiresMfaSession = (ComposedComponent: ComposedComponent) => {
  @inject('userStore')
  @observer
  class Authenticate extends AuthRouteGuard {
    protected hasActiveSession(): boolean {
      const {
        userStore: { loggedIn, mfaData },
      } = this.props;
      return loggedIn || !!mfaData?.sessionToken;
    }

    public render() {
      return super._render(ComposedComponent);
    }
  }
  return Authenticate;
};

import styled from 'styled-components';

export interface FlexProps {
  wrap?: boolean;
  spaceBetween?: boolean;
  justifyContentCenter?: boolean;
  alignItemsCenter?: boolean;
  alignSelfCenter?: boolean;
  justifyContent?: string;
  flexDirection?: string;
  alignItems?: string;
  basis?: string;
  alignSelf?: string;
  margin?: string;
  noShrink?: boolean;
  borderRadius?: string;
  centered?: boolean;
  inline?: boolean;
  textAlignStart?: boolean;
  flexGrow?: boolean;
  hiddenOverflow?: boolean;
  gap?: string;
}

export const Flex = styled.div<FlexProps>`
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};

  ${({ gap }) => gap && `gap: ${gap};`}

  ${({ flexDirection = 'row' }) => `flex-direction: ${flexDirection};`}

  ${({ margin }) => margin && `margin: ${margin};`}

  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}

  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf};`}

  ${({ wrap }) => wrap && `flex-wrap: wrap;`}

  ${({ spaceBetween }) => spaceBetween && `justify-content: space-between;`}

  ${({ justifyContentCenter }) => justifyContentCenter && `justify-content: center;`}

  ${({ alignItemsCenter }) => alignItemsCenter && `align-items: center;`}

  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}

  ${({ basis }) => basis && `flex-basis: ${basis};`}

  ${({ centered }) =>
    centered &&
    `
    justify-content: center;
    align-items: center;
  `};

  ${({ noShrink }) =>
    noShrink &&
    `
    flex-shrink: 0;
  `};

  ${({ borderRadius }) =>
    borderRadius &&
    `
    border-radius: ${borderRadius};
  `};

  ${({ textAlignStart }) => textAlignStart && `text-align: start;`}

  ${({ flexGrow }) => flexGrow && `flex-grow: 1;`}

  ${({ hiddenOverflow }) => hiddenOverflow && `overflow: hidden;`}

  ${({ alignSelfCenter }) => alignSelfCenter && `align-self: center;`}
`;

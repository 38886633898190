import {
  ExperimentClient,
  ExperimentUser,
  Exposure,
  Variant,
  Variants,
} from '@amplitude/experiment-js-client';
import { AMPLITUDE_DEPLOYMENT_KEY, AMPLITUDE_PROXY_URL, LOCAL_FEATURE_FLAGS } from '@config';
import { Analytics } from './analytics';
import { DestinationExperimentKeys, destinationExperimentKeys } from '@lib/destinationExperiment';

type User = {
  id: string;
  isNewUser?: boolean;
  workspaceId: string;
  organizationId: string;
  planType?: string;
  environment?: string;
};

// List of experiments
export enum ExperimentKeys {
  'ONBOARDING_EXPERIMENT' = 'onboarding-questions-or-skip-vs-no-skip',
}

// List of feature flags
export enum FeatureFlagKeys {
  'deviceModeTransformations' = 'device-mode-transformations-web',
  'geoLocationEnrichment' = 'enable-geolocation-enrichment',
  'retlVdmEnableSkipPreview' = 'retl-vdm-enable-skip-preview',
  'transformerSecrets' = 'transformer-secrets',
  'rs360EnableWht' = 'rs360-enable-wht',
  'historicalUserTraits' = 'historical-user-traits',
  'retlAsyncApi' = 'retl-async-api',
  'enableDataCatalogMetrics' = 'enable-data-catalog-metrics',
  'latencyColumnOnHealthDashboard' = 'latency-column-on-health-dashboard',
  'latencyOnDestinationEventsTab' = 'latency-on-destination-events-tab',
  'delaysAlert' = 'delays-alert',
  'hideETL' = 'hide-etl',
  'text2sql' = 'text-2-sql',
  'enableTPAdvancedKeywords' = 'enable-tp-advanced-keywords',
  'enableRETLSyncSettings' = 'enable-retl-sync-settings',
  's3NewSyncsUI' = 's3-new-syncs-ui',
  'mtuUsage' = 'mtu-usage',
  'enableTPPackaging' = 'enable-tp-packaging',
  'serviceAccessToken' = 'service-access-token',
  'skipRetlSourcesValidation' = 'skip-retl-sources-validation',
  'showWarehouseLatency' = 'show-warehouse-latency',
  'asyncAPIForValidation' = 'async-api-for-validation',
  'enableCohortAudience' = 'enable-cohort-audience',
  'enableDataCatalogBundles' = 'enable-data-catalog-property-bundles',
  'enableDataCatalogCustomTypes' = 'enable-data-catalog-custom-types',
}

type AmplitudeExperiments = Record<
  ExperimentKeys,
  {
    variants: Record<string, boolean>;
    default: string;
  }
>;

// Experiments along with their variants and default variant.
const experiments: AmplitudeExperiments = {
  [ExperimentKeys.ONBOARDING_EXPERIMENT]: {
    variants: {
      'skip-onboarding': true,
      'non-skip-onboarding': false,
    },
    default: 'skip-onboarding',
  },
} as const;

const featureFlags = {
  [FeatureFlagKeys.deviceModeTransformations]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.geoLocationEnrichment]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.retlVdmEnableSkipPreview]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.transformerSecrets]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.rs360EnableWht]: {
    variants: {
      on: true,
    },
    default: LOCAL_FEATURE_FLAGS.RS360_ENABLE_WHT === 'true',
  },
  [FeatureFlagKeys.historicalUserTraits]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.retlAsyncApi]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.enableDataCatalogMetrics]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.latencyColumnOnHealthDashboard]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.latencyOnDestinationEventsTab]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.delaysAlert]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.hideETL]: {
    variants: {
      on: true,
    },
    payload: {
      date: '',
    },
    default: false,
  },
  [FeatureFlagKeys.text2sql]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.enableTPAdvancedKeywords]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.enableRETLSyncSettings]: {
    variants: {
      on: true,
    },
    default: true,
  },
  [FeatureFlagKeys.s3NewSyncsUI]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.mtuUsage]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.enableTPPackaging]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.serviceAccessToken]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.skipRetlSourcesValidation]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.showWarehouseLatency]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.asyncAPIForValidation]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.enableCohortAudience]: {
    variants: {
      on: true,
    },
    default: LOCAL_FEATURE_FLAGS.ENABLE_COHORT_AUDIENCE === 'true',
  },
  [FeatureFlagKeys.enableDataCatalogCustomTypes]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.enableDataCatalogBundles]: {
    variants: {
      on: true,
    },
    default: false,
  },
} as const;

export const flagPrefix = 'AMP_';

class AmplitudeExperiment {
  client: ExperimentClient | undefined;

  constructor() {
    if (AMPLITUDE_DEPLOYMENT_KEY) {
      import('@amplitude/experiment-js-client').then((res) => {
        this.client = res.Experiment.initialize(AMPLITUDE_DEPLOYMENT_KEY, {
          automaticExposureTracking: true,
          fetchOnStart: true,
          pollOnStart: false,
          exposureTrackingProvider: {
            track: (exposure: Exposure) => {
              Analytics.track({
                event: '$exposure',
                properties: exposure,
              });
            },
          },
          serverUrl: AMPLITUDE_PROXY_URL ? `${AMPLITUDE_PROXY_URL}/api` : undefined,
          flagsServerUrl: AMPLITUDE_PROXY_URL ? `${AMPLITUDE_PROXY_URL}/flag` : undefined,
          fetchTimeoutMillis: 30000,
          retryFetchOnFailure: false,
        });
      });
    }
  }

  async start(payload: User) {
    await this.client?.start(this.getExperimentUser(payload));
  }

  private getExperimentUser(payload: User): ExperimentUser {
    const user_properties: Record<string, string | boolean> = {
      workspace_id: payload.workspaceId,
      organization_id: payload.organizationId,
    };

    if (payload.planType) {
      user_properties.plan_type = payload.planType;
    }

    if (payload.environment) {
      user_properties.environment = payload.environment;
    }

    if (payload.isNewUser) {
      user_properties.is_new_user = payload.isNewUser;
    }

    return {
      user_id: payload.id,
      user_properties,
    };
  }

  async fetch(user: User) {
    await this.client?.fetch(this.getExperimentUser(user));
  }

  reset() {
    this.client?.clear();
    this.client?.stop();
  }

  variant(flag: ExperimentKeys): boolean {
    const defaultVariant = experiments[flag].default;
    const variant = this.client?.variant(flag, defaultVariant);

    if (variant && variant.value && variant.value in experiments[flag].variants) {
      return experiments[flag].variants[variant.value];
    }

    return experiments[flag].variants[defaultVariant];
  }

  isFeatureEnabled(flag: FeatureFlagKeys): boolean {
    const variant = this.client?.variant(flag);

    if (variant && variant.value && variant.value in featureFlags[flag].variants) {
      return featureFlags[flag].variants[variant.value as 'on'];
    }

    return featureFlags[flag].default;
  }

  getAllVariants(): Variants | undefined {
    return this.client?.all();
  }

  getAllFeatureFlags(): Record<string | FeatureFlagKeys, boolean> {
    const featureFlags: Record<string | FeatureFlagKeys, boolean> = {};
    const variants = this.getAllVariants();

    if (!variants) {
      return featureFlags;
    }

    Object.keys(variants)
      .filter(
        (variantKey) =>
          !destinationExperimentKeys.includes(variantKey as DestinationExperimentKeys),
      )
      .forEach((variantKey) => {
        featureFlags[`${flagPrefix}${variantKey}`] = variants[variantKey].value === 'on';
      });

    return featureFlags;
  }

  getFeatureFlag<T extends keyof typeof featureFlags>(flag: T) {
    return this.client?.variant(flag) as (typeof featureFlags)[T];
  }

  getVariant<T extends string | Variant>(flag: string, fallback: T) {
    return this.client?.variant(flag, fallback);
  }
}

export const experiment = new AmplitudeExperiment();

import { action, observable, makeObservable } from 'mobx';

import { getApiErrorMessage } from '@components/common/util/util';
import { apiAuthCaller } from '@services/apiCaller';
import { IRootStore } from '@stores/index';
import { AudienceStore } from './audience';
import { ApiResponse } from '@components/sources/sourceResponse';
import { IAudience, FilterSpecProps, CreatePayload } from './types';

export interface IAudienceListStore {
  audiences: AudienceStore[];
  firstLoad: boolean;
  getAudienceBySourceId: (sourceId: string) => AudienceStore | undefined;
  createAudience: (audience: CreatePayload) => Promise<string | undefined>;
  setAudiences: (audiences: AudienceStore[]) => void;
  getAudiences: () => Promise<void>;
  deleteAudience: (id: string) => Promise<boolean>;
  getAudienceById: (id: string) => AudienceStore | undefined;
  generateSqlQuery: (filterSpec: FilterSpecProps) => Promise<ApiResponse<{ sql: string }>>;
  reset: () => void;
}

export class AudienceListStore implements IAudienceListStore {
  rootStore: IRootStore;

  @observable audiences: AudienceStore[] = [];

  @observable firstLoad = false;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  setAudiences(audiences: AudienceStore[]) {
    this.audiences = audiences;
  }

  @action.bound
  async getAudiences() {
    const { messagesStore } = this.rootStore;

    try {
      const {
        data: { audiences },
      } = await apiAuthCaller().get<{
        audiences: (IAudience & { sources: { id: string }[] })[];
      }>('/audiences');
      const audienceStores = audiences.map((audience) => {
        audience.sourceIds = audience.sources.map(({ id }) => id);
        return new AudienceStore(audience, this.rootStore);
      });

      this.setAudiences(audienceStores);
    } catch (err) {
      messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to get audiences'));
    } finally {
      this.firstLoad = true;
    }
  }

  @action.bound
  async createAudience(audience: CreatePayload): Promise<string | undefined> {
    const { messagesStore } = this.rootStore;
    try {
      const response = await apiAuthCaller().post<IAudience>('/audiences', audience);

      const savedAudience = new AudienceStore(response.data, this.rootStore);

      this.setAudiences([savedAudience, ...this.audiences]);

      return savedAudience.id;
    } catch (err) {
      messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to create the audience'));
      return undefined;
    }
  }

  @action.bound
  public getAudienceById = (id: string) => this.audiences.find((audience) => audience.id === id);

  @action.bound
  async deleteAudience(id: string) {
    const { messagesStore } = this.rootStore;

    try {
      const response = await apiAuthCaller().delete<IAudience>(`/audiences/${id}`);
      this.setAudiences(this.audiences.filter((audience) => audience.id !== response.data.id));
      messagesStore.showSuccessMessage('Audience deleted successfully');
      return true;
    } catch (err) {
      messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to delete audience'));
      return false;
    }
  }

  @action.bound
  async generateSqlQuery(filterSpec: FilterSpecProps): Promise<ApiResponse<{ sql: string }>> {
    const response = await apiAuthCaller().post(
      `/cloudSources/sources/audience/query-builder/query`,
      filterSpec,
    );

    return response.data;
  }

  getAudienceBySourceId(sourceId: string) {
    return this.audiences.find(({ sourceIds }) => sourceIds.includes(sourceId));
  }

  @action.bound
  reset() {
    this.setAudiences([]);
    this.firstLoad = true;
  }
}

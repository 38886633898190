import { Flex } from '@ui-library/flex';
import styled from 'styled-components';
import theme from '@css/theme';

export const LoaderContainer = styled(Flex)`
  width: 80px;
  height: 80px;
  background-color: white;
  border: 1px solid ${theme.token.colorBorderSecondary};
  border-radius: 8px;
`;

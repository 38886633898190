import React from 'react';
import Modal from '@ui-library/modal/skeleton';
import { Button } from '@ui-library/button';
import { CopyToClipboard } from '@components/common/icons/copyToClipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Flex } from '@ui-library/flex';
import { ErrorBackButton, StyledErrorBody, StyledFlex, StyledSubtext } from './style';
import { H4 } from '@ui-library/typography';

interface Props {
  header: string;
  subHeader?: string;
  onClose: () => void;
  view: JSX.Element;
  handleBackClick?: () => void;
  showBackButton: boolean;
  clipboardContent?: string;
}

/* eslint-disable import/no-default-export */
export default ({
  header,
  subHeader,
  onClose,
  view,
  handleBackClick,
  showBackButton,
  clipboardContent,
}: Props) => (
  <Modal show modalClosed={onClose}>
    <StyledFlex flexDirection="column" onClick={(e) => e.stopPropagation()}>
      <Flex justifyContent="space-between" alignItems="center">
        <div>
          <H4 data-testid="header">{header}</H4>
          <StyledSubtext strong data-testid="subHeader">
            {subHeader}
          </StyledSubtext>
        </div>
        {!!clipboardContent && (
          <div style={{ marginLeft: 'auto', marginRight: '1rem' }} data-testid="clipboard">
            <CopyToClipboard content={clipboardContent} type="icon" />
          </div>
        )}
        <Button type="text" shape="circle" onClick={onClose} data-testid="btnCloseIcon">
          <FontAwesomeIcon icon={regular('xmark-large')} />
        </Button>
      </Flex>
      <StyledErrorBody strong>{view}</StyledErrorBody>
      <Flex justifyContent="flex-end" alignItems="center">
        <Button type="default" onClick={onClose} name="Close" data-testid="btnClose" />
        {showBackButton && (
          <ErrorBackButton
            type="default"
            onClick={handleBackClick}
            name="Back"
            data-testid="btnBack"
          />
        )}
      </Flex>
    </StyledFlex>
  </Modal>
);

import { apiAuthCaller } from '@services/apiCaller';
import { IRootStore } from '@stores/index';
import { ISource, ISourceStore, SourceConstructor, SourceStore } from '@stores/source';
import { action, computed, observable, makeObservable } from 'mobx';
import SourceFactory from '@components/sources/sourceFactory';
import { DIRECTORY_TABS, SOURCE_DEFINITION_CATEGORY } from '@components/common/constants';
import { AxiosResponse } from 'axios';
import { CreatePayload, ServerSource } from './types';
import { serverSourceToStore } from './util';
import { getPermissions } from '@stores/util';
import { Analytics } from '@lib/analytics';
import sortBy from 'lodash/sortBy';

export interface ISourcesListStore {
  sources: ISourceStore[];
  allSources: ISourceStore[];
  firstLoad: boolean;
  rootStore: IRootStore;
  setNonRETLSources(sources: ISourceStore[]): void;
  fetchSources(): void;
  createSource(source: CreatePayload): Promise<ISourceStore>;
  deleteSource(source: ISource, onSuccess: () => void, onError: () => void): void;
  sourceById(sourceId: string): ISourceStore | undefined;
  updateSourceInList(source: SourceConstructor): void;
  cloudSourcesCount: number;
  warehouseSourcesCount: number;
  deleteNonRETLSource(sourceId: string): void;
  reset: () => void;
}

export class SourcesListStore implements ISourcesListStore {
  @observable private _nonRETLSources: ISourceStore[] = [];

  @observable public rootStore: IRootStore;

  @observable public firstLoad = false;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  public setNonRETLSources(sources: ISourceStore[]): void {
    this._nonRETLSources = sources;
  }

  /* allSource will include retlSources as well */
  @computed get allSources() {
    const {
      retlSourceListStore: { sources },
    } = this.rootStore;
    const retlMappedSources = sources.map((source) => source.oldSource as ISourceStore);
    return sortBy([...retlMappedSources, ...this._nonRETLSources], 'createdAt');
  }

  @computed get sources() {
    return this.allSources;
  }

  @action.bound
  public async fetchSources() {
    const {
      userStore: { selectedWorkspaceId },
      permissionsStore,
      connectionsStore,
    } = this.rootStore;
    try {
      const filterQueryParam = `&excludeType=${SOURCE_DEFINITION_CATEGORY.WAREHOUSE}`;
      type Sources = (ServerSource & { permissions?: { id: string; isLocked: boolean } })[];
      const res: AxiosResponse<{ sources: Sources }> = await apiAuthCaller().get(
        `/sources?workspace_id=${selectedWorkspaceId}${filterQueryParam}`,
      );
      this.setNonRETLSources(
        res.data.sources.map(
          (source) => new SourceStore(serverSourceToStore(source), this.rootStore),
        ),
      );
      permissionsStore.registerResource(getPermissions(res.data.sources));
      connectionsStore.setConnections(res.data.sources);
      this.firstLoad = true;
    } catch (err) {}
  }

  @action.bound
  public async createSource(source: CreatePayload) {
    const { userStore, permissionsStore } = this.rootStore;
    const { selectedWorkspaceId } = userStore;
    const res: AxiosResponse<ServerSource> = await apiAuthCaller().post('/sources/', {
      name: source.name,
      sourceDefinitionId: source.sourceDefinitionId,
      workspaceId: selectedWorkspaceId,
      accountId: source.accountId || null,
      config: source.config || {},
      allowDuplicateNames: source.allowDuplicateNames,
    });
    const savedSource = new SourceStore(serverSourceToStore(res.data), this.rootStore);
    this._nonRETLSources.push(new SourceStore(savedSource, this.rootStore));
    Analytics.track({
      event: 'sourceCreated',
      properties: { sourceId: savedSource.id, sourceType: savedSource.sourceDef.name },
    });
    permissionsStore.addNewResource(savedSource.id);
    return savedSource;
  }

  @action.bound
  public async deleteSource(source: ISource, onSuccess: () => void, onError: () => void) {
    const { messagesStore } = this.rootStore;
    try {
      await apiAuthCaller().delete(`/sources/${source.id}`);
      messagesStore.showSuccessMessage('Source deleted successfully');
      onSuccess();
      this.deleteNonRETLSource(source.id);
      Analytics.track({
        event: 'sourceDeleted',
        properties: { sourceId: source.id, sourceType: source.sourceDef.name },
      });
    } catch (err) {
      messagesStore.showErrorMessage('Failed to delete source');
      onError();
    }
  }

  @action.bound
  public sourceById(sourceId: string): ISourceStore | undefined {
    return this.sources.find((source) => source.id === sourceId);
  }

  @action.bound
  public updateSourceInList(source: SourceConstructor) {
    const updatedSource = new SourceStore(source, this.rootStore);
    const updatesSources = this._nonRETLSources.map((s) => {
      if (s.id === source.id) {
        return updatedSource;
      }
      return s;
    });
    this.setNonRETLSources(updatesSources);
  }

  @computed get cloudSourcesCount() {
    return this.sources.filter((source) =>
      SourceFactory(source.category).isPartOf(DIRECTORY_TABS.CLOUD_EXTRACT),
    ).length;
  }

  @computed get warehouseSourcesCount() {
    return this.sources.filter((source) =>
      SourceFactory(source.category).isPartOf(DIRECTORY_TABS.WAREHOUSE_ACTIONS),
    ).length;
  }

  @action.bound
  reset() {
    this.setNonRETLSources([]);
    this.firstLoad = false;
  }

  @action.bound
  deleteNonRETLSource(sourceId: string) {
    const updatedSources = this._nonRETLSources.filter((source) => source.id !== sourceId);
    this.setNonRETLSources(updatedSources);
  }
}

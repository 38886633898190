import styled from 'styled-components';

import { Flex } from '@ui-library/flex';
import { H1 } from '@ui-library/typography';
import theme from '@css/theme';

export const PageContainer = styled(Flex)`
  height: 100vh;
  background: ${theme.token.colorPrimaryBgHover};
`;

export const CopyContainer = styled(Flex)`
  margin-top: auto;
  margin-bottom: auto;
  background: ${theme.token.colorPrimaryBgHover};
`;

export const Hero = styled(H1)`
  font-family: ${theme.text.fontFamily.tertiary};
  font-size: 4em;
`;

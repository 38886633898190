import { IDestDefinition } from '@stores/destinationDefsList';
import { ISourceStore } from '@stores/source';
import { DestinationConfig } from '@stores/destination';
import { ITransformationStore } from '@stores/transformation';
import { ParsedQuery } from 'query-string';
import SourceFactory from '@components/sources/sourceFactory';
import { CatchErr, RecursiveArg } from '@utils/types';
import { URLS, LATENCY_TOOLTIP_MESSAGES } from '../constants';
import { ISourceDefinition } from '@stores/sourceDefinitionsList';
import get from 'lodash/get';
import { Credential } from '@stores/credentialStore';

type SourceType = Pick<ISourceDefinition, 'name' | 'category'>;

const getSourceType = (sourceDef: SourceType): string =>
  SourceFactory(sourceDef.category).getSourceType(sourceDef);

const populateConnectionModeAndUseNativeSDK = (
  config: Record<string, unknown>,
  isDestUIConfigV2: boolean,
  sourceType: string,
  sourceTypeKey: string,
) => {
  let updatedConfigValue;
  if (isDestUIConfigV2) {
    const connectionMode =
      config[`${sourceType}-connectionMode`] || get(config, `connectionMode.${sourceType}`);
    if (connectionMode && sourceTypeKey === 'useNativeSDK') {
      updatedConfigValue = connectionMode !== 'cloud';
    } else if (connectionMode && sourceTypeKey === 'useNativeSDKToSend') {
      updatedConfigValue = connectionMode === 'device';
    } else if (!connectionMode && sourceTypeKey === 'connectionMode') {
      updatedConfigValue = 'cloud';
    }
  } else {
    const useNativeSDK =
      config[`${sourceType}-useNativeSDK`] !== undefined
        ? config[`${sourceType}-useNativeSDK`]
        : get(config, `useNativeSDK.${sourceType}`);
    if (sourceTypeKey === 'connectionMode') {
      updatedConfigValue = useNativeSDK === true ? 'device' : 'cloud';
    }
  }
  return updatedConfigValue;
};

// TODO: add comments on what this function is doing
const getModifiedDestinationConfig = (
  destinationDef: IDestDefinition,
  config: DestinationConfig,
  selectedSources: { sourceDef: SourceType }[] | undefined,
) => {
  if (!selectedSources) {
    return config;
  }
  const selectedSourceTypes: string[] = [];
  selectedSources.forEach((source: { sourceDef: SourceType }) => {
    const sourceType = getSourceType(source.sourceDef);
    if (!selectedSourceTypes.includes(sourceType)) {
      selectedSourceTypes.push(sourceType);
    }
  });
  const { destConfig } = destinationDef.config!;

  // TODO: add comments on what this code is doing
  selectedSourceTypes.forEach((sourceType: string) => {
    const sourceTypeKeys = destConfig && destConfig[sourceType];

    if (sourceTypeKeys) {
      sourceTypeKeys.forEach((sourceTypeKey: string) => {
        const key = `${sourceType}-${sourceTypeKey}`;
        if (config[sourceTypeKey] === undefined) {
          config[sourceTypeKey] = {};
        }

        const obj = config[sourceTypeKey] as Record<string, unknown>;

        if (typeof obj === 'object') {
          obj[sourceType] = config[key];

          const updatedConfigValue = populateConnectionModeAndUseNativeSDK(
            config,
            isDestUIConfigV2(destinationDef),
            sourceType,
            sourceTypeKey,
          );

          if (updatedConfigValue !== undefined) {
            obj[sourceType] = updatedConfigValue;
          }
        }
        delete config[key];
      });
    } else if (`${sourceType}-useNativeSDK` in config) {
      delete config[`${sourceType}-useNativeSDK`];
    }
  });

  return config;
};

const trimConfigStrings = (config: RecursiveArg) => {
  if (Array.isArray(config)) {
    return config.reduce((acc, item) => {
      acc.push(trimConfigStrings(item));
      return acc;
    }, []);
  }
  if (typeof config === 'object') {
    return Object.keys(config).reduce((acc: DestinationConfig, key) => {
      acc[key] = trimConfigStrings(config[key]);
      return acc;
    }, {});
  }
  if (typeof config === 'string') {
    return config.trim();
  }
  return config;
};

const isValidInput = (regexString: string, value: string, ignoreCase = false): boolean => {
  let regex = RegExp(regexString);
  if (ignoreCase) {
    regex = RegExp(regexString, 'i');
  }
  return regex.test(value);
};

const isSourceNameUnique = (name: string, sourcesList: ISourceStore[]): boolean => {
  const isNotUniqueName = sourcesList.some(
    (source: ISourceStore) => source.name.toLowerCase() === name.toLowerCase(),
  );
  return !isNotUniqueName;
};

const isTransformationNameUnique = (name: string, list: ITransformationStore[]): boolean => {
  const isNotUniqueName = list.some(
    (trfm: ITransformationStore) => trfm.name.toLowerCase() === name.toLowerCase(),
  );
  return !isNotUniqueName;
};

const isCredentialNameUnique = (key: string, credentialList: Credential[]): boolean => {
  const isNotUniqueName = credentialList.some((cred: Credential) => cred.key === key);
  return !isNotUniqueName;
};

const isKeyPressed = (e: React.KeyboardEvent<HTMLElement>, key: KEYS): boolean => e.keyCode === key;

enum KEYS {
  ENTER = 13,
  ESCAPE = 27,
  SPACE = 32,
}

const getParsedValue = (p: ParsedQuery, s: string): string => {
  if (!p[s]) {
    return '';
  }
  if (typeof p[s] === 'string') {
    return p[s]!.toString();
  }
  if (Array.isArray(p[s]) && p[s]!.length > 0) {
    return p[s]![0]!;
  }
  return '';
};

const isValidJson = (e: string): boolean => {
  try {
    JSON.parse(e);
    return true;
  } catch (e) {
    return false;
  }
};

const getApiErrorMessage = (error: CatchErr, fallback: string) => {
  const is4xxError = error.response?.status >= 400 && error.response?.status < 500;
  if (is4xxError && error.response?.data?.message) {
    return `${fallback} - ${error.response?.data?.message}`;
  }
  return fallback;
};

const belongsToDomain = (email: string, domains: string[]) =>
  domains.some((domain) => email.endsWith(domain));

const isRudderUser = (email: string) =>
  belongsToDomain(email, ['@rudderlabs.com', '@rudderstack.com']);

const openPricingLink = () => {
  window.open(URLS.PRICING, '_blank');
};

const isLocalhost = () => window.location.host.includes('localhost');

const isDestUIConfigV2 = (destDef: IDestDefinition) => !Array.isArray(destDef.uiConfig);

const getNoLatencyMessage = (config: {
  noData: boolean;
  insufficientData: boolean;
  disabledWhLatency?: boolean;
  disabledObjectStorageLatency?: boolean;
}): string => {
  const { noData, insufficientData, disabledWhLatency, disabledObjectStorageLatency } = config;
  if (disabledWhLatency) {
    return LATENCY_TOOLTIP_MESSAGES.DISABLED_WH_LATENCY;
  }

  if (disabledObjectStorageLatency) {
    return LATENCY_TOOLTIP_MESSAGES.DISABLED_OBJECT_STORAGE_LATENCY;
  }

  if (insufficientData) {
    return LATENCY_TOOLTIP_MESSAGES.INSUFFICIENT_DATA;
  }

  if (noData) {
    return LATENCY_TOOLTIP_MESSAGES.NO_DATA;
  }

  return '';
};

export {
  getSourceType,
  getModifiedDestinationConfig,
  trimConfigStrings,
  isValidInput,
  isSourceNameUnique,
  isTransformationNameUnique,
  isCredentialNameUnique,
  isValidJson,
  isKeyPressed,
  getApiErrorMessage,
  KEYS,
  getParsedValue,
  // ts-prune-ignore-next
  isRudderUser,
  isLocalhost,
  openPricingLink,
  isDestUIConfigV2,
  getNoLatencyMessage,
};

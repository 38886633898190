import React from 'react';
import FormGroup, { IInstruction } from '@components/common/formGroup';
import flat from 'flat';
import isString from 'lodash/isString';
import { isValidJson } from '@components/common/util/util';
import {
  getFieldPreRequisites,
  preRequisitesCheck,
} from '@components/common/formGroup/preRequisites';
import {
  Field,
  FormConfig,
  FormSecrets,
  PreRequisiteField,
  PreRequisites,
} from '@components/common/formSchema/types';
import { MetaData } from '../dynamicSelect';
import { IFeaturesStore } from '@stores/features';
import { inject, observer } from 'mobx-react';

export interface Group {
  title: string;
  fields: Field[];
  instruction?: IInstruction;
  sectionNote?: string;
  schemaAlias?: string;
  secretFields?: string[];
  nameField?: string;
  sourceDependentType?: string;
}

export type Schema = Group[];

interface IDestinationSettingsProps {
  schema: Schema;
  onChange: (settings: FormConfig, isValid: boolean) => void;
  initialSettings?: FormConfig;
  transformFields?: (fields: Field[]) => Field[];
  secretConfig?: FormSecrets;
  disabled?: boolean;
  validate?: boolean;
  metadata?: MetaData;
  className?: string;
  featuresStore?: IFeaturesStore;
  isEditMode?: boolean;
}

interface IState {
  settings: FormConfig;
}

function checkForPreRequisiteFields(
  field: Field,
  settings: FormConfig,
  availableFeatureFlags: Record<string, boolean> = {},
) {
  const preRequisites: PreRequisites = {
    ...(field.preRequisites ?? {}),
    fields: getFieldPreRequisites(field),
  };
  const hasPreRequisites = (preRequisites.fields as PreRequisiteField[]).length > 0;
  const isPreRequisitesSatisfied =
    !hasPreRequisites || preRequisitesCheck(preRequisites, settings, availableFeatureFlags);

  if (!isPreRequisitesSatisfied) {
    return true;
  }

  return !(field && field.required);
}

@inject('featuresStore')
@observer
class FormSchema extends React.Component<IDestinationSettingsProps, IState> {
  static validate = (
    settings: FormConfig,
    fields: Field[],
    availableFeatureFlags: Record<string, boolean> = {},
  ) => {
    const req = Object.entries(
      settings as Record<string, string | boolean | Record<string, string | boolean>>,
    ).map(function val([k, value]): boolean {
      const v = isString(value) ? value.trim() : value;
      if (v || typeof v === 'boolean') {
        if (typeof v === 'object' && !Array.isArray(v)) {
          return Object.entries(flat({ [k]: v }) as Record<string, string>)
            .map(val)
            .every(Boolean);
        }
        if (fields.some((field) => Object.values(field).includes(k) && field.subType === 'JSON')) {
          return isValidJson(v as string);
        }
      } else if (!v) {
        // corresponding fields is an array, when there are multiple fields with same value
        const correspondingFields = fields.filter(
          (elem: Field) => elem.value === k || k.endsWith(elem.value),
        );
        // if field has a preRequisiteField, and it's not active, do not do required validation
        return correspondingFields.every((field) =>
          checkForPreRequisiteFields(field, settings, availableFeatureFlags),
        );
      }
      return true;
    });
    return req.every(Boolean);
  };

  constructor(props: IDestinationSettingsProps) {
    super(props);
    this.state = {
      settings: {},
    };
  }

  public onSettingsChange = (settings: FormConfig, isError: boolean) => {
    const { onChange, schema, validate = true, featuresStore } = this.props;
    const availableFeatureFlags = featuresStore?.getCombinedFeatureFlagsList();

    let isValid = false;
    if (validate) {
      const fields = schema.reduce((acc: Field[], group: Group) => acc.concat(group.fields), []);
      isValid = FormSchema.validate(settings, fields, availableFeatureFlags);
    }
    onChange(settings, isError || isValid);
  };

  public onChange = (settings: FormConfig, isError: boolean) => {
    this.setState(
      (prevState: IState) => ({
        settings: {
          ...prevState.settings,
          ...settings,
        },
      }),
      () => this.onSettingsChange(this.state.settings, isError),
    );
  };

  private getFields(fields: Field[]) {
    const { transformFields } = this.props;
    if (transformFields) {
      return transformFields(fields);
    }
    return fields;
  }

  public render() {
    const { initialSettings, disabled, schema, secretConfig, metadata, className, isEditMode } =
      this.props;

    return (
      <div className={className}>
        {schema.map((group: Group) => (
          <FormGroup
            metadata={metadata}
            secretConfig={secretConfig}
            key={group.title}
            title={group.title}
            sourceDependentType={group.sourceDependentType}
            instruction={group.instruction}
            fields={this.getFields(group.fields)}
            sectionNote={group.sectionNote}
            onStateChange={this.onChange}
            initialSettings={initialSettings}
            disabled={!!disabled}
            isEditMode={isEditMode}
          />
        ))}
      </div>
    );
  }
}

/* eslint-disable import/no-default-export */
export default FormSchema;

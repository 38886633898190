import { action, makeObservable, observable } from 'mobx';
import { ITPConfig } from '@stores/source';
import { IRootStore } from '@stores/index';
import cloneDeep from 'lodash/cloneDeep';
import { getApiErrorMessage } from '@components/common/util/util';
import { getLimits } from '../trackingPlanLimitMessage/hooks';

type SourceTPConfig = Omit<ITPConfig, 'global'>;

const sourceTPCommonConfig = {
  dropOtherViolation: false,
  dropUnplannedProperties: false,
  propagateValidationErrors: true,
};

const sourceTPFreeCommonConfig = {
  dropOtherViolation: true,
  dropUnplannedProperties: true,
  propagateValidationErrors: true,
};

export const sourceConnectionDefaultConfig: SourceTPConfig = {
  track: {
    dropUnplannedEvents: false,
    ...sourceTPCommonConfig,
  },
  identify: {
    ...sourceTPCommonConfig,
  },
  page: {
    ...sourceTPCommonConfig,
  },
  screen: {
    ...sourceTPCommonConfig,
  },
  group: {
    ...sourceTPCommonConfig,
  },
};
export const sourceConnectionFreeDefaultConfig: SourceTPConfig = {
  track: {
    dropUnplannedEvents: true,
    ...sourceTPFreeCommonConfig,
  },
  identify: {
    ...sourceTPFreeCommonConfig,
  },
  page: {
    ...sourceTPFreeCommonConfig,
  },
  screen: {
    ...sourceTPFreeCommonConfig,
  },
  group: {
    ...sourceTPFreeCommonConfig,
  },
};

export class SourceConnection {
  rootStore: IRootStore;

  @observable config: SourceTPConfig;

  sourceId: string;

  get source() {
    return this.rootStore.sourcesListStore.sourceById(this.sourceId)!;
  }

  constructor(
    rootStore: IRootStore,
    sourceId: string,
    config: SourceTPConfig = cloneDeep(sourceConnectionDefaultConfig),
  ) {
    const limits = getLimits('TP_SOURCE_DROP_CONFIG');
    this.rootStore = rootStore;
    this.sourceId = sourceId;
    this.config = limits.isLimitReached ? cloneDeep(sourceConnectionFreeDefaultConfig) : config;
    makeObservable(this);
  }

  setConfig(config: SourceTPConfig) {
    this.config = config;
  }

  async updateConfig() {
    try {
      this.rootStore.sourceDataStore.setSource(this.source);
      await this.rootStore.sourceDataStore.updateTPSettings(this.config);
    } catch (e) {
      this.rootStore.messagesStore.showErrorMessage(
        getApiErrorMessage(e, 'Failed to create tracking plan'),
      );
    }
  }
}

export class TrackingPlanConnectionListStore {
  @observable sourceConnections: SourceConnection[] = [];

  constructor(
    private readonly rootStore: IRootStore,
    sourceConnections: { sourceId: string; config: SourceTPConfig }[] = [],
  ) {
    this.sourceConnections = sourceConnections.map(
      (sourceConnection) =>
        new SourceConnection(rootStore, sourceConnection.sourceId, sourceConnection.config),
    );
    makeObservable(this);
  }

  @action.bound
  public setSourceConnections(connections: { sourceId: string; config?: SourceTPConfig }[]) {
    connections.forEach((c) => this.addConnection(c));

    this.sourceConnections = this.sourceConnections.filter((connection) =>
      connections.some((c) => c.sourceId === connection.sourceId),
    );
  }

  @action.bound
  public addConnection(connection: { sourceId: string; config?: SourceTPConfig }) {
    if (!this.doesConnectionExist(connection.sourceId)) {
      this.sourceConnections = [
        ...this.sourceConnections,
        new SourceConnection(this.rootStore, connection.sourceId, connection.config),
      ];
    }
  }

  @action.bound
  public removeConnection(sourceId: string) {
    this.sourceConnections = this.sourceConnections.filter((c) => c.sourceId !== sourceId);
  }

  doesConnectionExist(sourceId: string) {
    return this.sourceConnections.some((sc) => sc.sourceId === sourceId);
  }
}

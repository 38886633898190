import get from 'lodash/get';
import set from 'lodash/set';
import {
  getFieldPreRequisites,
  getLegacyPreRequisiteFields,
  preRequisitesCheck,
} from '@components/common/formGroup/preRequisites';
import {
  Field,
  FormData,
  PreRequisiteField,
  PreRequisites,
  RowFields,
} from '@components/common/formSchema/types';

// Returns null if field prerequisites are not satisfied
export const getFieldConfigs = (
  field: Field | RowFields,
  formData: FormData,
  availableFeatureFlags: Record<string, boolean> = {},
) => {
  const preRequisites: PreRequisites = {
    ...(field.preRequisites ?? {}),
    fields: getFieldPreRequisites(field),
  };
  const hasPreRequisites = (preRequisites.fields as PreRequisiteField[]).length > 0;
  const hasPreReqFeatureFlags = preRequisites.featureFlags && preRequisites.featureFlags.length > 0;
  let forceDisabled = false;
  let preRequisiteValue;
  let preRequisiteObjectValue;

  if (hasPreRequisites || hasPreReqFeatureFlags) {
    const isPreRequisitesSatisfied = preRequisitesCheck(
      preRequisites,
      formData,
      availableFeatureFlags,
    );

    if (!isPreRequisitesSatisfied) {
      /* disabledMode
        if true this field will appear as disabled until the preRequisiteField has a value
        if false or not set the field will not appear in the ui until the preRequisiteField has a value
      */
      // eslint-disable-next-line max-depth
      if (preRequisites.disabledMode) {
        forceDisabled = true;
      } else {
        return null;
      }
    }

    // using only the first legacy preRequisiteField for reading includeValue & includeToRequest values
    const firstLegacyPreRequisiteField = getLegacyPreRequisiteFields(
      (field as Field).preRequisiteField,
    )[0];

    if (firstLegacyPreRequisiteField && firstLegacyPreRequisiteField.includeValue) {
      preRequisiteValue = get(formData, firstLegacyPreRequisiteField.name) as string | undefined;
    }

    if (firstLegacyPreRequisiteField && firstLegacyPreRequisiteField.includeToRequest) {
      const { includeToRequest } = firstLegacyPreRequisiteField;

      if (typeof includeToRequest === 'boolean') {
        preRequisiteObjectValue = set({}, firstLegacyPreRequisiteField.name, preRequisiteValue);
      } else if (typeof includeToRequest === 'object' && includeToRequest?.transformKey) {
        preRequisiteObjectValue = set({}, includeToRequest.transformKey, preRequisiteValue);
      }
    }
  }

  if ((field as Field).readOnly) {
    forceDisabled = true;
  }

  return { forceDisabled, preRequisiteValue, preRequisiteObjectValue };
};

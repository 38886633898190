import sourceFactory from '@components/sources/sourceFactory';
import { IDestinationStore } from '@stores/destination';
import { IDestinationTransformation } from '@stores/destinationTransformation';
import { ITransformationStore } from '@stores/transformation';

export const sortByTransformation =
  (currentTransformation: ITransformationStore) =>
  (
    a: { connection?: IDestinationTransformation },
    b: { connection?: IDestinationTransformation },
  ) => {
    // set first the connected destination to the current transformation
    if (
      a.connection?.transformationId === currentTransformation.id &&
      b.connection?.transformationId !== currentTransformation.id
    ) {
      return -1;
    }
    if (
      a.connection?.transformationId !== currentTransformation.id &&
      b.connection?.transformationId === currentTransformation.id
    ) {
      return 1;
    }
    // set second the destinations with a different transformation
    if (!a.connection && b.connection) {
      return 1;
    }
    if (a.connection && !b.connection) {
      return -1;
    }
    return 0;
  };

export const isDeviceModeSupported = (
  destination: Pick<IDestinationStore, 'sources' | 'config'>,
) => {
  const { sources, config } = destination;
  if (!sources || sources.length === 0) {
    return false;
  }
  const hasDeviceModeDisabled = Object.values(config?.useNativeSDK || {})
    .concat(Object.values(config?.useNativeSDKToSend || {}))
    .every((x) => !x);
  if (hasDeviceModeDisabled) {
    return false;
  }
  const hasDeviceModeSupportSource = sources
    .map((x) => sourceFactory(x.category).getSourceType(x.sourceDef))
    .some((category) => Object.keys(config?.useNativeSDK || {}).includes(category));
  return hasDeviceModeSupportSource;
};

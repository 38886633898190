export type RudderCategory = 'source' | 'destination';

/* notStarted: When the user has selected an account but hasn't pressed the Next button */
export enum ValidationState {
  loading = 'loading',
  passed = 'passed',
  failed = 'failed',
  disable = 'disable',
  hide = 'hide',
  notStarted = 'notStarted',
  list = 'list',
}

export interface AccountFieldProps {
  value: string;
  label: string;
}

export enum TimeFilterValue {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

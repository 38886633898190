import React from 'react';
import { Drawer as AntDrawer, DrawerProps as AntDrawerProps } from 'antd';
import theme from '@css/theme';

const DEFAULT_HEADER_STYLE = {
  padding: `${theme.token.paddingLG}px`,
  borderBottom: `1px solid ${theme.token.colorBorder}`,
  color: `${theme.token.colorText}`,
};

export const Drawer = ({
  title,
  open,
  onClose,
  children,
  bodyStyle = DEFAULT_HEADER_STYLE,
  placement,
  ...props
}: AntDrawerProps) => (
  <AntDrawer
    title={title}
    placement={placement}
    closable={false}
    onClose={onClose}
    open={open}
    width={480}
    headerStyle={{
      padding: `${theme.token.paddingLG}px`,
      borderBottom: `1px solid ${theme.token.colorBorder}`,
      color: `${theme.token.colorText}`,
    }}
    bodyStyle={bodyStyle}
    {...props}
  >
    {children}
  </AntDrawer>
);

export { type DrawerProps } from 'antd';

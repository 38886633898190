/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { lazy, Suspense } from 'react';
import { ISource } from '@stores/source';
import { SourceConfig } from '@stores/source/types';
import { apiAuthCaller } from '@services/apiCaller';
import { SmartRetry } from '@utils/retry';
import { ISourceDefinition } from '@stores/sourceDefinitionsList';
import { TAB_NAMES } from '../source-view/utils';
import CloudSource from './cloudSource';
import { ResourcesComponentProps, ConfigurationComponentProps } from './sourceCategory';
import { BASE_URLS } from '../constants';
import removeNullValues from '@utils/removeNullValues';
import { SingerResource } from '../source/cloudSource/cloudSourceSettings';
import {
  ApiResponse,
  isFollowingNewSourcesResponseFramework,
  isErrorFromNewSourceResponseFramework,
  getRespData,
  getApiErrorMessageDetails,
} from '../sourceResponse';
import { Skeleton } from 'antd';

const SingerSourceSettings = lazy(
  () =>
    import(
      /* webpackChunkName: "singerSource.config" */ '@components/sources/source/singerSource/singerSourceConfig'
    ),
);
const SelectResources = lazy(
  () =>
    import(
      /* webpackChunkName: "singerSource.singerResourceSelection" */ '../source/singerSource/singerResourceSelection'
    ),
);

class SingerSource extends CloudSource {
  override getTabs(source: ISource) {
    return [
      TAB_NAMES.DESTINATIONS,
      TAB_NAMES.SYNCS,
      TAB_NAMES.CONFIGURATION,
      TAB_NAMES.RESOURCES,
      TAB_NAMES.SETTINGS,
    ];
  }

  override getResourcesComponent({
    source,
    onChange,
    disabled,
    onSingleResourceOrLess,
  }: ResourcesComponentProps): JSX.Element {
    return (
      <Suspense fallback={<Skeleton active />}>
        <SelectResources
          sourceId={source.id}
          config={source.config?.config}
          secretConfig={source.secretConfig}
          configJSONSchema={source.config?.configJSONSchema}
          // when editing resources will be in form of string only.
          selectedResources={source.config?.resources as SingerResource[]}
          onSingleResourceOrLess={() => {
            if (!source.config?.resources?.length) {
              onSingleResourceOrLess()
            }
          }}
          sourceDefinition={source!.sourceDef}
          onChange={(resources) => {
            onChange({ resources });
          }}
          disabled={disabled}
        />
      </Suspense>
    );
  }

  override getCredentialSettings(_: unknown) {
    return null;
  }

  override getConfigurationComponent(props: ConfigurationComponentProps): JSX.Element {
    const { source, onChange, disabled } = props;
    const { sourceDef, config, secretConfig } = source;
    const [newConfig, setNewConfig] = React.useState(config.config);
    return (
      <Suspense fallback={<Skeleton active />}>
        <SingerSourceSettings
          configSchema={config.configJSONSchema}
          initialSettings={newConfig}
          secretConfig={secretConfig}
          onSettingsChange={(sourceConfig, schema, isValid) => {
            setNewConfig(sourceConfig);
            onChange(sourceConfig, isValid);
          }}
          sourceDefinition={sourceDef}
          disabled={disabled}
        />
      </Suspense>
    );
  }

  override transformSourceConfig(config: SourceConfig): Record<string, unknown> {
    return { config: removeNullValues(config) };
  }

  override transformResourcesConfig(config: SourceConfig): Record<string, unknown> {
    return { catalog: config.catalog, resources: config.resources };
  }

  override async validateSource(
    config: SourceConfig,
    source: { id?: string; config?: SourceConfig; sourceDef: ISourceDefinition },
  ): Promise<{ valid: boolean; reason: string }> {
    const retry = new SmartRetry<
      | { data: { valid: boolean; error: string } }
      | { data: ApiResponse<{ valid: boolean; error: string }> }
    >({ times: 2 });
    let params = {};
    if (source) {
      params = { sourceId: source.id };
    }

    let valid = false;
    let reason = '';
    await retry
      .exec(() =>
        apiAuthCaller({ timeout: 90000 }).post(
          `${BASE_URLS.SOURCES_INFO}/rudder_custom/validate`,
          {
            configJSONSchema: source?.config?.configJSONSchema,
            role: source.sourceDef.name,
            config,
            image: source.sourceDef.options!.image,
          },
          { params },
        ),
      )
      .then((resp) => {
        const { data } = resp;
        if (isFollowingNewSourcesResponseFramework(data)) {
          try {
            const respData = getRespData(data as ApiResponse<{ valid: boolean; error: string }>);
            valid = respData.valid;
            if (respData.error) {
              reason = respData.error;
            }
          } catch (err) {
            reason = getApiErrorMessageDetails(err, 'Could not validate source').message;
          }
        } else {
          const responseData = data as { valid: boolean; error: string };
          valid = Boolean(responseData.valid);
          reason = responseData.error;
        }
      })
      .catch((e) => {
        if (isErrorFromNewSourceResponseFramework(e)) {
          const errorMessage = getApiErrorMessageDetails(e, 'Unknown error');
          reason = `Failed to validate source: ${errorMessage.message}`;
        } else {
          reason = `Failed to validate source: ${e?.response?.data?.message || 'Unknown error'}`;
        }
      });

    return {
      valid,
      reason,
    };
  }
}
/* eslint-disable import/no-default-export */
export default SingerSource;

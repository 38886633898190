import { getApiErrorMessage } from '@components/common/util/util';
import { apiAuthCaller } from '@services/apiCaller';
import { IRootStore } from '@stores/index';
import { ITrackingPlanStore, TrackingPlanStore } from '@stores/trackingPlans/trackingPlan';
import { CatchErr } from '@utils/types';
import { action, observable, makeObservable } from 'mobx';
import {
  ITrackingPlan,
  ITrackingPlanInput,
  ITrackingPlanV2MigrationResponse,
  ITrackingPlanV2Response,
} from './types';
import { API_URLS } from '@components/trackingPlans/constants';

export interface ITrackingPlanListStore {
  plans: ITrackingPlanStore[];
  firstLoad: boolean;
  getPlans(): Promise<void>;
  getById(id: string): ITrackingPlanStore | undefined;
  removePlanById(id: string): void;
  unlinkTrackingPlan(
    trackingPlanId: string,
    sourceId: string,
    onSuccess?: () => void,
  ): Promise<void>;
  createTrackingPlan(config: {
    name: string;
    description?: string;
    creationType: string;
  }): Promise<TrackingPlanStore | undefined>;
  migrateTrackingPlan(id: string): Promise<ITrackingPlanV2MigrationResponse>;
  deleteTrackingPlan(id: string): Promise<boolean>;
}

export class TrackingPlansListStore implements ITrackingPlanListStore {
  @observable plans: ITrackingPlanStore[] = [];

  @observable rootStore: IRootStore;

  @observable firstLoad = false;

  constructor(root: IRootStore) {
    makeObservable(this);
    this.rootStore = root;
  }

  @action.bound
  async getPlans() {
    try {
      const response = await apiAuthCaller().get('/trackingPlans');
      this.plans = response.data.map((plan: ITrackingPlan) => this.getTrackingPlan(plan));
    } catch {}
    this.firstLoad = true;
  }

  getById(id: string) {
    return this.plans.find((plan) => plan.id === id);
  }

  removePlanById(id: string) {
    this.plans = this.plans.filter((plan) => plan.id !== id);
  }

  async unlinkTrackingPlan(trackingPlanId: string, sourceId: string, onSuccess?: () => void) {
    const { messagesStore, sourcesListStore } = this.rootStore;
    try {
      await apiAuthCaller().delete(`/trackingPlans/${trackingPlanId}/sources/${sourceId}`);
      sourcesListStore.updateSourceInList({
        ...sourcesListStore.sourceById(sourceId)!,
        trackingPlanConfig: undefined,
      });
      onSuccess?.();
    } catch (err: CatchErr) {
      messagesStore.showErrorMessage(
        getApiErrorMessage(err, 'Failed to unlink source from tracking plan'),
      );
    }
  }

  private getTrackingPlan(tp: ITrackingPlanInput) {
    return new TrackingPlanStore(tp, this.rootStore);
  }

  @action.bound
  async createTrackingPlan(tp: { name: string; description?: string; creationType: string }) {
    const config = {
      name: tp.name,
      description: tp.description,
      creationType: tp.creationType,
    };
    try {
      const { data } = await apiAuthCaller().post<ITrackingPlanV2Response>(
        API_URLS.trackingPlans,
        config,
      );
      const newTPStore = this.getTrackingPlan(data);
      this.plans = [...this.plans, newTPStore];
      return newTPStore;
    } catch (e) {
      this.rootStore.messagesStore.showErrorMessage(
        getApiErrorMessage(e, 'Failed to create tracking plan'),
      );
    }
    return undefined;
  }

  async migrateTrackingPlan(id: string) {
    try {
      const { data } = await apiAuthCaller().post<ITrackingPlanV2MigrationResponse>(
        API_URLS.migrateTrackingPlan(id),
      );
      if (!data.error) {
        const newTPStore = this.getTrackingPlan(data.trackingPlan);
        this.plans = [newTPStore, ...this.plans];
        const oldTP = this.getById(id);
        const connectedSources = oldTP?.connections.map((conn) => conn.source) || [];
        connectedSources.forEach((source) => {
          source.trackingPlanConfig!.trackingPlanId = data.trackingPlan.id;
        });
      }
      return data;
    } catch (e) {
      this.rootStore.messagesStore.showErrorMessage(
        getApiErrorMessage(e, 'Failed to migrate tracking plan'),
      );
    }
    return {} as ITrackingPlanV2MigrationResponse;
  }

  async deleteTrackingPlan(id: string) {
    try {
      const { sourcesListStore } = this.rootStore;

      const currentPlan = this.getById(id);
      const url = currentPlan?.API_V2_ENABLED
        ? API_URLS.singleTrackingPlan(id)
        : API_URLS.oldSingleTrackingPlan(id);
      await apiAuthCaller().delete(url);
      this.plans = this.plans.filter((tp) => tp.id !== id);
      const connectedSources = sourcesListStore.sources.filter(
        (source) => source.trackingPlanConfig?.trackingPlanId === id,
      );
      connectedSources.forEach((source) => {
        sourcesListStore.updateSourceInList({
          ...source,
          trackingPlanConfig: undefined,
        });
      });
      return true;
    } catch (e) {
      this.rootStore.messagesStore.showErrorMessage(
        getApiErrorMessage(e, 'Failed to delete tracking plan'),
      );
    }
    return false;
  }
}

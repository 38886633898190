import React from 'react';
import get from 'lodash/get';

import { Tooltip } from '@ui-library/tooltip';
import { AccountFieldProps } from '@components/common/types';

import { BaseText } from '@ui-library/typography';
import { Flex } from '@ui-library/flex';
import { CredentialsRow } from './styles';
import { Button } from '@ui-library/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTheme } from 'styled-components';
import { fromNow } from '@utils/dateUtils';

interface Props {
  fields: AccountFieldProps[];
  accountName: string;
  info: Record<string, string>;
  showDelete?: boolean;
  onDelete?: (id: string) => void;
  disabled?: boolean;
  isSelected?: boolean;
}

export const AccountInfo: React.FC<Props> = ({
  fields,
  accountName,
  info,
  showDelete,
  onDelete,
  disabled,
  isSelected,
}: Props) => {
  const theme = useTheme();
  const textColor = disabled ? theme.token.colorTextDisabled : theme.token.colorTextDescription;
  const titleTextColor = disabled ? theme.token.colorTextDisabled : theme.token.colorText;
  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between">
        <BaseText color={titleTextColor} fontWeight={500}>
          {accountName}
        </BaseText>
        {showDelete && onDelete && (
          <Button
            data-testId={`${info.id}-delete-account-Id`}
            onClick={() => onDelete(info.id)}
            type="text"
            size="small"
            icon={<FontAwesomeIcon icon={regular('trash')} />}
          />
        )}
      </Flex>
      <Flex flexDirection="column">
        {fields.map((field: AccountFieldProps) => {
          const fieldValue = get(info, field.value);
          return (
            <Tooltip key={`${fieldValue}-${field.value}`} placement="left" title={fieldValue}>
              <CredentialsRow fontWeight={500}>
                <BaseText color={titleTextColor} fontWeight={600}>
                  {field.label}
                </BaseText>
                {' - '}
                <BaseText color={textColor}>{fieldValue}</BaseText>
              </CredentialsRow>
            </Tooltip>
          );
        })}
        <Flex justifyContent="space-between">
          <BaseText fontWeight={500} color={textColor}>{`Created ${fromNow(
            info.createdAt,
          )}`}</BaseText>
          {isSelected && (
            <Flex justifyContent="flex-end" alignItems="flex-end">
              <FontAwesomeIcon color={theme.token.colorPrimary} icon={solid('circle-check')} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

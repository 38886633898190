import React from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SampleError } from '@components/common/sampleError';
import classes from './error-message-view.module.scss';

interface ErrorMessageViewProps {
  errorMessage: string;
  errorCode?: string;
}

const ErrorMessageView = ({ errorMessage, errorCode }: ErrorMessageViewProps) => (
  <div className={classes.error_message_container}>
    {errorCode && (
      <div className={classes.error_code}>
        <FontAwesomeIcon
          icon={solid('circle-xmark')}
          style={{ color: '#CA4139', fontSize: '1rem' }}
        />
        Error code {errorCode || ''}
      </div>
    )}
    <SampleError sample={errorMessage} />
  </div>
);

/* eslint-disable import/no-default-export */
export default ErrorMessageView;

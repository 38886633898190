interface IDataBucket {
  bucket: number;
}

export const mergeAggregatedEventsStats = <T extends IDataBucket>(
  aggregatedData: T[],
  newData: T[],
  eventsDropped?: Exclude<keyof T, 'bucket'>,
  valueKey = 'sentCount' as Exclude<keyof T, 'bucket'>,
): T[] => {
  const dataMap = new Map<number, T>();

  [...aggregatedData, ...newData].forEach((item) => {
    const existingItem = dataMap.get(item.bucket);

    if (existingItem) {
      existingItem[valueKey] = (((existingItem[valueKey] as number) || 0) +
        ((item[valueKey] as number) || 0)) as T[Exclude<keyof T, 'bucket'>];
    } else if (eventsDropped) {
      dataMap.set(item.bucket, {
        ...item,
        [valueKey]: item[valueKey] || 0,
        [eventsDropped]: item[eventsDropped] || 0,
      } as T);
    } else {
      dataMap.set(item.bucket, { ...item, [valueKey]: item[valueKey] || 0 } as T);
    }
  });

  return Array.from(dataMap.values());
};

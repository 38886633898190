import { stores } from '@stores/.';
import { ISourceDefinition } from '@stores/sourceDefinitionsList';
import { IDestDefinition } from '@stores/destinationDefsList';
import get from 'lodash/get';

interface Config {
  limit?: number | null;
  count?: number;
}

type DisplayOptions = Pick<ISourceDefinition | IDestDefinition, 'options' | 'name'>;

export const hasExhaustedLimit = ({ limit, count }: Config) => {
  if (!limit && limit !== 0) {
    return false;
  }
  if (limit < 1) {
    return true;
  }
  if (!count) {
    return false;
  }
  return count >= limit;
};

export const hideFromDirectory = ({ options }: DisplayOptions) => {
  if (typeof options?.hidden === 'object' && options?.hidden?.featureFlagName) {
    const availableFeatureFlags = stores.featuresStore.getCombinedFeatureFlagsList();
    return (
      get(availableFeatureFlags, options.hidden.featureFlagName) === options.hidden.featureFlagValue
    );
  }
  return options?.hidden;
};

export const hideFromConnectNewSource = (displayOptions: DisplayOptions) =>
  !!displayOptions.options?.hidden;

export const customMap = <T extends DisplayOptions>(all: T[]) =>
  all.filter((x) => !hideFromDirectory(x) || stores.workspaceStore.enabledSources.includes(x.name));

export const getIntegrationStatus = (icon: { preview: boolean | undefined; isBeta: boolean }) => {
  if (icon.isBeta) {
    return 'beta';
  }
  if (icon.preview) {
    return 'upcoming';
  }
  return undefined;
};

export const getExtractLimitMessages = (count: number, limit: number) => {
  if (limit === 0) {
    return {
      title: 'Cloud Extract is not available on your current plan.',
      description: 'Upgrade your plan to unlock unlimited cloud extract sources.',
      search: 'Cloud Extract is not available on your current plan.',
      isLimitReached: true,
    };
  }
  if (count === 0 || !limit) {
    return null;
  }
  return {
    title: count > limit ? '' : `${count} of ${limit} Cloud Extract sources created`,
    isLimitReached: count >= limit,
    description:
      count < limit
        ? `You have ${
            limit - count
          } sources remaining for active cloud extract sources. Upgrade your plan to unlock unlimited cloud extract sources.`
        : 'You’ve reached your limit for active cloud extract sources. Upgrade your plan to unlock unlimited cloud extract sources.',
    search: count >= limit ? 'You have reached your limit' : '',
  };
};

export const getWHActionLimitMessages = (count: number, limit: number) => {
  if (limit === 0) {
    return {
      title: 'Reverse ETL is not available on your current plan.',
      description: 'Upgrade your plan to unlock unlimited reverse ETL sources.',
      search: 'Not available on your current plan.',
      isLimitReached: true,
    };
  }
  if (!limit) {
    return null;
  }
  return {
    title: count > limit ? '' : `${count} of ${limit} reverse ETL source connected`,
    isLimitReached: count >= limit,
    description:
      count < limit
        ? `You have ${
            limit - count
          } sources remaining for active reverse ETL sources. Upgrade your plan to unlock unlimited reverse ETL sources.`
        : `You’ve reached your limit of ${limit} rETL connection${
            limit === 1 ? '' : 's'
          }. Upgrade your plan to unlock unlimited rETL connections.`,
    search:
      count >= limit
        ? `You've reached your limit of ${limit} rETL connection${limit === 1 ? '' : 's'}.`
        : '',
  };
};

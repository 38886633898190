import React, { PropsWithChildren } from 'react';
import { Flex } from '@ui-library/flex';
import classes from './loader.module.scss';
import loader from '@gif/loader.gif';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spin } from 'antd';
import theme from '@css/theme';
import { LoaderContainer } from './styles';

type Props =
  | {
      type: 'spin';
      size?: 16 | 24 | 36 | 64;
      loading?: boolean;
    }
  | {
      type: 'screen';
      loading: boolean;
    };

export const Loader: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>) => {
  if (props.type === 'spin') {
    const { size = 24, children, loading = true } = props;
    return (
      <Spin
        spinning={loading}
        indicator={
          <FontAwesomeIcon
            style={{ fontSize: size }}
            icon={regular('circle-notch')}
            className="fa-spin"
            color={theme.token.colorPrimary}
          />
        }
      >
        {children}
      </Spin>
    );
  }

  if (!props.loading) {
    return null;
  }

  return (
    <Flex centered className={classes.loader} data-testid="loader">
      <LoaderContainer centered>
        <img alt="loader" className={classes.loaderImage} src={loader} />
      </LoaderContainer>
    </Flex>
  );
};

import { action, observable, runInAction, makeObservable } from 'mobx';
import { apiAuthCaller } from '@services/apiCaller';
import { IRootStore } from '@stores/index';
import { CatchErr } from '@utils/types';
import { getApiErrorMessage } from '@components/common/util/util';
import { LanguageType } from '@stores/librariesList';

export interface ILibraryStore {
  id: string;
  name: string;
  createdBy: string;
  updatedAt: string;
  importName: string;
  description: string;
  type: string;
  code: string;
  language: LanguageType;
  rootStore: IRootStore;
  isLoading: boolean;
  update(name: string, description: string, code: string, language: LanguageType): void;
  delete(): Promise<void>;
  getDetails(): Promise<void>;
}

export class LibraryStore implements ILibraryStore {
  @observable public id: string;

  @observable public name: string;

  @observable public createdBy: string;

  @observable public updatedAt: string;

  @observable public importName: string;

  @observable public description: string;

  @observable public type: string;

  @observable public code: string;

  @observable public language: LanguageType;

  @observable public rootStore: IRootStore;

  @observable public isLoading = false;

  private loaded = false;

  private get isLoaded() {
    return this.loaded || !!this.code;
  }

  constructor(library: ILibraryStore, rootStore: IRootStore) {
    makeObservable(this);
    this.id = library.id;
    this.name = library.name;
    this.createdBy = library.createdBy;
    this.updatedAt = library.updatedAt;
    this.importName = library.importName;
    this.description = library.description;
    this.type = library.type;
    this.code = library.code;
    this.language = library.language;
    this.rootStore = rootStore;
  }

  @action.bound
  public async getDetails() {
    try {
      if (this.isLoaded) {
        return;
      }
      this.isLoading = true;
      const res = await apiAuthCaller().get(`/transformationLibraries/${this.id}`);
      this.code = res.data.code;
      this.loaded = true;
    } catch (err) {
      this.rootStore.messagesStore.showErrorMessage(
        getApiErrorMessage(err, 'Failed to get library details'),
      );
    }
    this.isLoading = false;
  }

  @action.bound
  public async update(name: string, description: string, code: string, language: LanguageType) {
    const publish = true;
    const res = await apiAuthCaller()
      .ignore400()
      .post(`/transformationLibraries/${this.id}?publish=${publish}`, {
        name,
        description,
        code,
        language,
      });
    runInAction(() => {
      this.id = res.data.id;
      this.name = res.data.name;
      this.description = res.data.description;
      this.code = res.data.code;
      this.language = res.data.language;
    });
  }

  @action.bound
  public async delete() {
    const { messagesStore, librariesListStore } = this.rootStore;
    try {
      await apiAuthCaller().ignore400().delete(`/transformationLibraries/${this.id}`);
      runInAction(() => {
        librariesListStore.libraries = librariesListStore.libraries.filter(
          (t: ILibraryStore) => t.id !== this.id,
        );
      });
    } catch (err: CatchErr) {
      messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to delete library'));
    }
  }
}

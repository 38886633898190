import theme from '@css/theme';
import styled from 'styled-components';

export const EditorContainer = styled.div`
  border: 1px solid ${theme.token.colorBorderSecondary};
  position: relative;
  height: 100%;
  .copy_button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 16px;
    z-index: 1;
  }
`;

import React from 'react';
import Bugsnag, { Event } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { IUserStore } from '@stores/user';

const onError = (event: Event, ignoreList: string[], ignoredOrigins: string[] = []) => {
  const msg = event.errors[0].errorMessage;
  const stackframes = event.errors[0].stacktrace;

  if (ignoreList.some((err) => msg.includes(err))) {
    return false;
  }

  if (ignoredOrigins.some((origin) => stackframes.some((frame) => frame.file.includes(origin)))) {
    return false;
  }

  event.context = event.errors[0].errorMessage || window.location.pathname;
  return true;
};

const ignoredOrigins = ['mutinycdn.com'];

const errorsToIgnore = [
  'status code 429',
  'status code 405',
  'status code 404',
  'status code 403',
  'status code 401',
  'ResizeObserver loop completed',
  'ResizeObserver loop limit',
  'Network Error',
  'Request aborted',
  'Unexpected token',
  "(reading 'Search')",
  "got '<'",
  'Failed to fetch',
  'unhandledrejection handler received a non-error',
  'NetworkError when attempting',
  'Illegal invocation',
  'Load failed',
  "reading 'removeEventListener'",
  "reading 'isSameNode'",
  "reading 'nodeName'",
  "Cannot read properties of undefined (reading 'first')",
  "Cannot read properties of null (reading 'right')",
  "undefined is not an object (evaluating 't.Search')",
  'Loading chunk',
  'Please call Stripe()',
  'Loading CSS chunk',
  "couldn't load module ace/theme",
  'React error #188',
  'Document is not focused',
  'Unexpected end of input',
  "Failed to execute 'insertBefore' on 'Node'",
  'Invalid or unexpected token',
  'Write permission denied',
  'Signout timeout fail',
];

const redactedKeys = [
  /config/i,
  /password/i,
  /token/i,
  /authorization/i,
  /email/i,
  /name/i,
  /phonenumber/i,
];

export const webappClient = Bugsnag.createClient({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || '00000000000000000000000000000000',
  plugins: [new BugsnagPluginReact()],
  appVersion: process.env.REACT_APP_VERSION,
  releaseStage: process.env.REACT_APP_ENV || 'development',
  redactedKeys,
  onError: (event) => onError(event, errorsToIgnore, ignoredOrigins),
});

const sourceErrorsToIgnore = ['status code 400', 'status code 409', 'status code 404'];

export const sourcesClient = Bugsnag.createClient({
  apiKey: process.env.REACT_APP_SOURCES_BUGSNAG_API_KEY || '00000000000000000000000000000000',
  plugins: [new BugsnagPluginReact()],
  appVersion: process.env.REACT_APP_VERSION,
  releaseStage: process.env.REACT_APP_ENV || 'development',
  autoDetectErrors: false,
  redactedKeys,
  onError: (event) => onError(event, sourceErrorsToIgnore, ignoredOrigins),
});

export const ErrorBoundary =
  webappClient.getPlugin('react')?.createErrorBoundary(React) || React.Component;

export const bugsnagSetUser = (user: IUserStore) => {
  const { id } = user;
  webappClient.setUser(id);
  sourcesClient.setUser(id);
};

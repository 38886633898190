// eslint-disable-next-line custom/no-antd-imports
import AntButton from 'antd/lib/button/button';
import styled from 'styled-components';

export const Button = styled(AntButton)`
  border: none;
  padding: 0;
  margin-top: 1rem;
  box-shadow: none;
  height: 40px;
`;

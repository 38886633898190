import { getApiErrorMessage } from '@components/common/util/util';
import { apiAuthCaller } from '@services/apiCaller';
import { action, computed, makeObservable, observable } from 'mobx';
import { IRootStore } from '@stores/index';
import { RetlConnectionStore } from './retlConnection';
import { CreateRETLConnectionRequest, RETLConnection } from './types';
import { assertNonNullish } from '@utils/assert';

interface IRetlConnectionListStore {
  connections: RetlConnectionStore[];
  firstLoad: boolean;
  createRetlConnection: (payload: CreateRETLConnectionRequest) => Promise<string | undefined>;
  setRetlConnections: (connections: RetlConnectionStore[]) => void;
  updateRetlSourceWithDestinationIds: () => void;
  getRetlConnections: () => Promise<void>;
  deleteRetlConnection: (id: string) => Promise<boolean>;
  getConnectionById: (id: string) => RetlConnectionStore | undefined;
  getConnectionsBySourceId: (sourceId: string) => RetlConnectionStore[];
  getConnectionsByDestinationId: (destinationId: string) => RetlConnectionStore[];
  reset: () => void;
  totalRetlConnectionsCount: number;
  retlConnectionsLimit: number | undefined;
  hasRetlConnectionLimit: boolean;
  isRetlConnectionsLimitReached: boolean;
}

export class RetlConnectionListStore implements IRetlConnectionListStore {
  rootStore: IRootStore;

  @observable connections: RetlConnectionStore[] = [];

  @observable firstLoad = false;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  setRetlConnections(connections: RetlConnectionStore[]) {
    this.connections = connections;
  }

  @action.bound
  updateRetlSourceWithDestinationIds() {
    const { retlSourceListStore, connectionsStore } = this.rootStore;
    const connectionsMapping: { [key: string]: string[] } = {};
    retlSourceListStore.sources.forEach((source) => {
      const destinationIds = (this.connections || [])
        .filter((connection) => connection.sourceId === source.id)
        .map((connection) => connection.destinationId);
      connectionsMapping[source.id] = destinationIds;
      source.updateDestinationIds(destinationIds);
    });
    connectionsStore.addRETLSourceConnections(connectionsMapping);
  }

  @action.bound
  async getRetlConnections() {
    const { messagesStore } = this.rootStore;

    try {
      const {
        data: { connections },
      } = await apiAuthCaller().get<{ connections: RETLConnection[] }>('/connections');

      const connectionStores = connections.map(
        (conn) => new RetlConnectionStore(conn, this.rootStore),
      );
      this.setRetlConnections(connectionStores);
      this.updateRetlSourceWithDestinationIds();
    } catch (err) {
      messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to get Retl-connections'));
    } finally {
      this.firstLoad = true;
    }
  }

  @action.bound
  async createRetlConnection(payload: CreateRETLConnectionRequest): Promise<string | undefined> {
    const { messagesStore, retlSourceListStore, connectionsStore } = this.rootStore;
    const { sourceId, destinationId } = payload;
    const connectionsMapping: { [key: string]: string[] } = {};
    const source = retlSourceListStore.getRetlSourceById(sourceId);
    try {
      const response = await apiAuthCaller().post<RETLConnection>('/retl-connections', payload);

      const connectionStore = new RetlConnectionStore(response.data, this.rootStore);

      this.setRetlConnections([connectionStore, ...this.connections]);
      assertNonNullish(source, 'Source cant be empty');

      const updatedDestIds = [...source.destinationIds, destinationId];

      source?.updateDestinationIds(updatedDestIds);

      connectionsMapping[sourceId] = updatedDestIds;

      connectionsStore.addRETLSourceConnections(connectionsMapping);

      return connectionStore.id;
    } catch (err) {
      messagesStore.showErrorMessage(
        getApiErrorMessage(err, 'Failed to create the Retl-connections'),
      );
      return undefined;
    }
  }

  @action.bound
  async deleteRetlConnection(id: string) {
    const { messagesStore, retlSourceListStore, connectionsStore } = this.rootStore;
    const connectionsMapping: { [key: string]: string[] } = {};

    try {
      const conn = this.connections.find((conn) => conn.id === id);
      await apiAuthCaller().delete<void>(`/retl-connections/${id}`);
      assertNonNullish(conn, 'Connection cant be empty');
      this.setRetlConnections(this.connections.filter((conn) => conn.id !== id));

      const source = retlSourceListStore.getRetlSourceById(conn.sourceId);
      assertNonNullish(source, 'Source cant be empty');

      const updatedDestIds = source.destinationIds.filter(
        (destId) => destId !== conn.destinationId,
      );

      source?.updateDestinationIds(updatedDestIds);

      connectionsMapping[source.id] = updatedDestIds;

      connectionsStore.addRETLSourceConnections(connectionsMapping);

      messagesStore.showSuccessMessage('Retl-connection deleted successfully');
      return true;
    } catch (err) {
      messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to delete Retl-connection'));
      return false;
    }
  }

  @action.bound
  getConnectionById(id: string) {
    return this.connections.find((conn) => conn.id === id);
  }

  @action.bound
  getConnectionsBySourceId(sourceId: string) {
    return this.connections.filter((conn) => conn.sourceId === sourceId);
  }

  @action.bound
  getConnectionsByDestinationId(destinationId: string) {
    return this.connections.filter((conn) => conn.destinationId === destinationId);
  }

  @action.bound
  reset() {
    this.setRetlConnections([]);
    this.firstLoad = false;
  }

  @computed
  public get totalRetlConnectionsCount(): number {
    const {
      retlSourceListStore: { sources: retlSources },
    } = this.rootStore;
    return this.connections.filter((connection) =>
      retlSources.some((source) => source.id === connection.sourceId),
    ).length;
  }

  @computed
  public get retlConnectionsLimit(): number | undefined {
    const { featuresStore } = this.rootStore;
    return featuresStore.getFeatureConfiguration('RETL_CONNECTIONS_LIMIT') as number | undefined;
  }

  @computed
  public get hasRetlConnectionLimit(): boolean {
    return !!this.retlConnectionsLimit;
  }

  @computed
  public get isRetlConnectionsLimitReached(): boolean {
    if (!this.hasRetlConnectionLimit) {
      return false;
    }
    return this.totalRetlConnectionsCount >= (this.retlConnectionsLimit as number);
  }
}

import React, { useEffect, useState, lazy, Suspense } from 'react';
import { ValidationState } from '@components/common/types';
import { ISourceDefinition } from '@stores/sourceDefinitionsList';
import { IAccount } from '@stores/accounts';
import { Skeleton } from 'antd';
import { RadioGroupProps } from '@ui-library/radio';
import { WarehouseSourceOrigin } from '@stores/types';
import { ISourceConfig } from '@components/sources/visualizer/mappingScreens/visualDataMappingProvider';
import { stores } from '@stores/index';
import { ISourceDataStore } from '@stores/sourceDataStore';
import { ValidateAccountInfoProps } from './warehouseSettings/validateSource';
import useStores from '@stores/useStores';
import CredentialsV1 from '@components/sources/source-view/source-settings/components/credentials/credentialsV1';

type ITableConfig = { name?: string; schema?: string };

const WarehouseAccounts = lazy(
  () =>
    import(
      /* webpackChunkName: "warehouseFactory.dataWarehouseAuth" */ '@components/common/warehouseAccounts'
    ),
);

const SchemaValidation = lazy(
  () =>
    import(
      /* webpackChunkName: "originHelpers.schemaValidation" */ './warehouseSettings/validateSource'
    ),
);

interface Props {
  isValidationLoading?: ValidationState;
  isValidationDisabled?: boolean;
  sourceDefinition: ISourceDefinition;
  accountId?: string;
  accountInfo?: ValidateAccountInfoProps;
  checkValid: (val: ValidationState, isAccountDeleted?: boolean) => void;
  alterValidation: () => void;
  initialOrigin?: WarehouseSourceOrigin;
  handleSettingsChange: (
    val: {
      accountId?: string;
      accountInfo?: Partial<IAccount>;
      rudderAccountId?: undefined;
      origin: WarehouseSourceOrigin;
    },
    enableNext: boolean,
    cl?: (previousSettings?: { accountId?: string }) => void,
  ) => void;
  hideSchemaValidation: () => void;
}

const useSourceOrigin = ({
  initialOrigin,
  accountId,
  handleSettingsChange,
  isValidationLoading,
  checkValid,
  sourceDefinition,
  alterValidation,
  isValidationDisabled,
  accountInfo,
  hideSchemaValidation,
}: Props) => {
  let description;
  const [isAccountProcessing, setAccountProcessing] = useState(false);
  const [origin, setOrigin] = useState<WarehouseSourceOrigin>(
    initialOrigin || WarehouseSourceOrigin.TABLE,
  );

  useEffect(() => {
    if (origin === WarehouseSourceOrigin.TABLE) {
      setAccountProcessing(Boolean(accountId));
    }
  }, [origin]);

  const { featuresStore } = useStores();

  const isAudienceEnabled = featuresStore.has('AUDIENCES');
  const isSqlModelEnabled = featuresStore.has('MODELS');
  const onAccountSettingsChange = (
    newAccountId: string,
    newAccountInfo: Record<string, unknown>,
    isValid: boolean,
  ) => {
    handleSettingsChange(
      {
        accountId: newAccountId,
        accountInfo: isValid ? newAccountInfo : undefined,
        origin: WarehouseSourceOrigin.TABLE,
      },
      isValid,
      (settings?: { accountId?: string }) => {
        /* when the selected accountId changes, make the validation
      run again when pressed on NEXT. */
        if (
          isValidationLoading === ValidationState.passed ||
          isValidationLoading === ValidationState.failed ||
          isValidationLoading === ValidationState.notStarted
        ) {
          checkValid(ValidationState.notStarted, Boolean(settings?.accountId && !newAccountId));
        }
        setAccountProcessing(false);
      },
    );
  };
  let component;
  if (origin === WarehouseSourceOrigin.TABLE) {
    component = (
      <>
        <Suspense fallback={<Skeleton active />}>
          <WarehouseAccounts
            selectedAccount={accountId}
            onChange={onAccountSettingsChange}
            sourceDefinition={sourceDefinition}
            checkValid={checkValid}
            alterValidation={alterValidation}
            isValidationLoading={isValidationLoading}
            isValidationDisabled={isValidationDisabled}
          />
        </Suspense>
        {!isValidationDisabled && isValidationLoading !== ValidationState.disable && (
          <Suspense fallback={<Skeleton active />}>
            <SchemaValidation
              status={isValidationLoading || ValidationState.hide}
              checkValid={checkValid}
              sName={sourceDefinition.name}
              accountId={accountId || ''}
              accountInfo={accountInfo}
              sourceDisplayName={sourceDefinition.displayName}
            />
          </Suspense>
        )}
      </>
    );
  }

  const onSourceOriginClick: RadioGroupProps['onChange'] = (e) => {
    const { value } = e.target;
    let isValid;
    let callback;
    let config;

    switch (value) {
      case WarehouseSourceOrigin.MODEL:
      case WarehouseSourceOrigin.AUDIENCE:
        config = {
          accountId: undefined,
          rudderAccountId: undefined,
          origin: value,
        };
        isValid = true;
        callback = hideSchemaValidation;
        break;
      default:
        config = {
          origin: WarehouseSourceOrigin.TABLE,
        };
        isValid = false;
        break;
    }
    handleSettingsChange(config, isValid, callback);
    setOrigin(value);
  };

  const originOptions = [{ id: WarehouseSourceOrigin.TABLE, element: 'Table' }];
  if (isSqlModelEnabled && sourceDefinition.options?.isSqlModelSupported) {
    originOptions.push({ id: WarehouseSourceOrigin.MODEL, element: 'Model' });
    description = 'Use this source to sync either a table, or model.';
  }
  if (isAudienceEnabled && sourceDefinition.options?.isAudienceSupported) {
    originOptions.push({ id: WarehouseSourceOrigin.AUDIENCE, element: 'Audience' });
    description = 'Use this source to sync either a table, model, or audience.';
  }
  return {
    origin,
    description,
    component,
    originOptions,
    onSourceOriginClick,
    isAccountProcessing,
    setAccountProcessing,
  };
};

const isNextEnabledOnMount = (origin?: WarehouseSourceOrigin, accountId?: string) =>
  (origin && origin !== WarehouseSourceOrigin.TABLE) || Boolean(accountId);

const getOriginHelpers = (origin: WarehouseSourceOrigin) => {
  let getSourceConfig: (settings: {
    origin: WarehouseSourceOrigin;
    audienceId?: string;
    sqlModelId?: string;
    tableConfig?: ITableConfig;
    bucketConfig?: { bucketName?: string; objectPrefix?: string };
  }) => ISourceConfig;

  let getOriginFinalSourceConfig: (settings: {
    columns: string[];
    tableConfig?: ITableConfig;
    audienceId?: string;
    sqlModelId?: string;
  }) => {
    query: { columns: string[]; table?: ITableConfig };
    sqlModelId?: string;
    audienceId?: string;
    resources: string[];
  };

  let getOriginAccountId: (settings: {
    sqlModelId?: string;
    audienceId?: string;
    sourceConfigAccId?: string;
  }) => string;

  switch (origin) {
    case WarehouseSourceOrigin.AUDIENCE:
      getSourceConfig = ({ audienceId }) => ({
        audienceId,
      });
      getOriginFinalSourceConfig = ({ columns, audienceId }) => ({
        resources: ['audience'],
        audienceId,
        query: {
          columns,
        },
      });
      getOriginAccountId = ({ audienceId }) =>
        stores.audienceListStore.getAudienceById(audienceId || '')?.account.id || '';

      break;
    case WarehouseSourceOrigin.MODEL:
      getSourceConfig = ({ sqlModelId }) => ({
        sqlModelId,
      });
      getOriginFinalSourceConfig = ({ columns, sqlModelId }) => ({
        query: {
          columns,
        },
        sqlModelId,
        resources: ['sql_model'],
      });
      getOriginAccountId = ({ sqlModelId }) =>
        stores.modelListStore.getModelById(sqlModelId || '')?.account.id || '';

      break;
    default:
      getSourceConfig = ({ tableConfig, bucketConfig }) => ({
        table: {
          name: tableConfig?.name,
          schema: tableConfig?.schema,
          bucketName: bucketConfig?.bucketName,
          objectPrefix: bucketConfig?.objectPrefix,
        },
      });
      getOriginFinalSourceConfig = ({ columns, tableConfig }) => ({
        query: {
          table: tableConfig,
          columns,
        },
        resources: [tableConfig?.name || ''],
      });
      getOriginAccountId = ({ sourceConfigAccId }) => sourceConfigAccId || '';
  }
  const getCredentialSettings: (
    sourceDataStore: ISourceDataStore,
    isAdmin: boolean,
  ) => JSX.Element = (sourceDataStore: ISourceDataStore, isAdmin: boolean) => {
    const { sourceDef } = sourceDataStore;

    return <CredentialsV1 isAdmin={isAdmin} source={sourceDataStore} sourceDef={sourceDef} />;
  };

  return {
    getSourceConfig,
    getOriginFinalSourceConfig,
    getOriginAccountId,
    getCredentialSettings,
  };
};

export { useSourceOrigin, isNextEnabledOnMount, getOriginHelpers };

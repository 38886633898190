import { getApiErrorMessage } from '@components/common/util/util';
import { apiAuthCaller } from '@services/apiCaller';
import { IRootStore } from '@stores/index';
import { ServerSourceTPConfig } from '@stores/sourcesList/types';
import { CatchErr } from '@utils/types';
import { serverSourceToStore } from '../sourcesList/util';
import {
  ITrackingPlan,
  ITrackingPlanInput,
  ITrackingPlanUpdateInput,
  TrackingPlanSourceConnection,
} from '@stores/trackingPlans/types';
import { computed, makeObservable, observable } from 'mobx';
import {
  sourceConnectionDefaultConfig,
  sourceConnectionFreeDefaultConfig,
} from '@components/trackingPlans/stores/trackingPlanConnectionList';
import { SourceTPConfig } from '@components/trackingPlans/stores/trackingPlanEventsList';
import { parseTrackingPlanConnections } from './utils';
import { RudderTyperAnalytics } from '@rudderlabs/rudder-typer-webapp';
import { TrackingPlanEventPatchParams } from '@components/trackingPlans/createTrackingPlans/types';
import { AxiosError } from 'axios';
import { API_URLS } from '@components/trackingPlans/constants';
import { getLimits } from '@components/trackingPlans/trackingPlanLimitMessage/hooks';

export interface ITrackingPlanStore extends ITrackingPlan {
  connectSourceToTP(sourceIds: string[], onSuccess?: () => void): Promise<void>;
  connectSources(connections: { sourceId: string; config: SourceTPConfig }[]): Promise<boolean>;
  update(input: ITrackingPlanUpdateInput): Promise<boolean>;
  patchEvents(events: TrackingPlanEventPatchParams[]): Promise<void>;
}

export class TrackingPlanStore implements ITrackingPlanStore {
  id = '';

  description = '';

  name = '';

  creationType = '';

  createdAt = '';

  updatedAt = '';

  version = '';

  get API_V2_ENABLED() {
    return !!this.creationType;
  }

  @computed get connections(): TrackingPlanSourceConnection[] {
    return this.rootStore.sourcesListStore.sources
      .filter((source) => source.trackingPlanConfig?.trackingPlanId === this.id)
      .map((source) => ({
        source,
        config: source.trackingPlanConfig!.config || sourceConnectionDefaultConfig,
      }));
  }

  private readonly rootStore: IRootStore;

  constructor(plan: ITrackingPlanInput, rootStore: IRootStore) {
    this.setProps(plan);
    this.rootStore = rootStore;
    makeObservable(this);
  }

  async connectSourceToTP(sourceIds: string[], onSuccess?: () => void) {
    const { messagesStore, sourcesListStore } = this.rootStore;
    try {
      if (this.API_V2_ENABLED) {
        const limits = getLimits('TP_SOURCE_DROP_CONFIG');

        this.connectSources(
          sourceIds.map((sourceId) => ({
            sourceId,
            config: limits.isLimitReached
              ? sourceConnectionFreeDefaultConfig
              : sourceConnectionDefaultConfig,
          })),
        );
      } else {
        const response = await apiAuthCaller().post(`trackingPlans/${this.id}/connections`, {
          sourceIds,
        });
        const sourceConfigs = response.data;
        sourceConfigs.forEach(
          (config: { sourceId: string; trackingPlanId: string; config: ServerSourceTPConfig }) => {
            sourcesListStore.updateSourceInList(
              serverSourceToStore({
                ...sourcesListStore.sourceById(config.sourceId)!,
                dgSourceTrackingPlanConfig: config,
              }),
            );
          },
        );
      }
      onSuccess?.();
    } catch (err: CatchErr) {
      messagesStore.showErrorMessage(
        getApiErrorMessage(err, 'Failed to link sources to tracking plan'),
      );
    }
  }

  async connectSources(connections: { sourceId: string; config: SourceTPConfig }[]) {
    try {
      if (connections.length === 0) {
        return true;
      }
      const { sourcesListStore } = this.rootStore;

      await apiAuthCaller().post(
        `trackingPlans/${this.id}/connectionsConfig`,
        parseTrackingPlanConnections(connections),
      );

      connections.forEach(({ sourceId, config }) => {
        sourcesListStore.updateSourceInList({
          ...sourcesListStore.sourceById(sourceId)!,
          trackingPlanConfig: {
            trackingPlanId: this.id,
            config: {
              ...config,
              global: {},
            },
          },
        });
      });

      RudderTyperAnalytics.trackingPlanConnected({
        trackingPlanId: this.id,
        trackingPlanName: this.name,
        sourceId: connections.map((sourceConnection) => sourceConnection.sourceId),
        sourceName: connections.map(
          (sourceConnection) => sourcesListStore.sourceById(sourceConnection.sourceId)?.name,
        ),
      });

      return true;
    } catch (e) {
      this.rootStore.messagesStore.showErrorMessage(
        getApiErrorMessage(e, 'Failed to link sources to tracking plan'),
      );
    }
    return false;
  }

  private setProps(plan: ITrackingPlanInput) {
    this.id = plan.id || this.id;
    this.name = plan.name || this.name;
    this.description = plan.description || this.description;
    this.creationType = plan.creationType || this.creationType;
    this.version = plan.version || this.version;
    this.updatedAt = plan.updatedAt || this.updatedAt;
    this.createdAt = plan.createdAt || this.createdAt;
  }

  async update(input: ITrackingPlanUpdateInput) {
    try {
      const resp = await apiAuthCaller().put(API_URLS.singleTrackingPlan(this.id), {
        name: input.name,
        description: input.description,
      });
      this.setProps(resp.data);
      return true;
    } catch (e) {
      this.rootStore.messagesStore.showErrorMessage(
        getApiErrorMessage(e, 'Failed to update tracking plan'),
      );
    }
    return false;
  }

  @observable failedEventUpdates: { error: string; events: TrackingPlanEventPatchParams[] }[] = [];

  async retryEventUpdate() {
    if (this.failedEventUpdates.length === 0) {
      return 0;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const failure of this.failedEventUpdates) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await this.patchEvents(failure.events);
        this.failedEventUpdates = this.failedEventUpdates.filter((e) => e !== failure);
        return 0;
      } catch (err) {
        failure.error = err instanceof AxiosError ? err.response?.data.message : '';
      }
    }
    return this.failedEventUpdates.length;
  }

  async patchEvents(events: TrackingPlanEventPatchParams[]) {
    const { data } = await apiAuthCaller().patch(API_URLS.trackingPlanEvents(this.id), { events });
    return data;
  }
}

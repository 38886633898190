import { apiAuthCaller } from '@services/apiCaller';
import { action, computed, observable, makeObservable } from 'mobx';
import moment from 'moment';
import { IRootStore } from '@stores/index';
import { LogType } from '@components/profile/auditLogs';

type LogCategory = (typeof LogType)[keyof typeof LogType];

export interface ParsedLog {
  id: string;
  userName?: string;
  email?: string;
  action: string;
  defName: string;
  targetType?: Exclude<LogCategory, 'team'> | 'Teammate';
  target: string;
  payload: {
    from: Record<string, unknown>;
    to: Record<string, unknown>;
  };
  createdAt: string;
}

interface Log {
  id: string;
  targetType: LogCategory;
  payload: {
    def: string;
    name: string;
    email: string;
    from: Record<string, unknown>;
    to: Record<string, unknown>;
  };
  user?: {
    name: string;
    email: string;
  };
  action: string;
  createdAt: string;
}

interface Params {
  skip: number;
  limit: number;
  target?: string | undefined;
  targetType?: LogCategory;
}

export interface IAuditLogsStore {
  logs: Log[];
  getLogs(type: LogCategory): void;
  getMore(): void;
  setTarget(target: string | undefined): void;
  parsedLogs: ParsedLog[];
  loading: string;
  target: string | undefined;
}

export class AuditLogsStore implements IAuditLogsStore {
  @observable logs: Log[] = [];

  @observable loading = '';

  @observable target: string | undefined = undefined;

  type = '' as LogCategory;

  total = 0;

  limit = 20;

  rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  setTarget(target: string | undefined) {
    this.target = target;
  }

  @action.bound
  async getLogs(type: LogCategory) {
    if (this.loading) {
      return;
    }
    this.loading = 'Loading logs...';
    this.logs = [];
    this.total = 0;

    this.type = type;
    let params: Params = { skip: 0, limit: this.limit };
    if (this.type !== 'all') {
      params = {
        ...params,
        targetType: this.type,
      };
    }
    if (this.target) {
      params = { ...params, target: this.target };
    }
    try {
      const response = await apiAuthCaller().get('/auditlog', {
        params,
      });
      this.logs = response.data.logs;
      this.total = response.data.count;
    } catch (e) {
      const { messagesStore } = this.rootStore;
      messagesStore.showErrorMessage('Unable to fetch more logs. Please try again later');
    }
    this.loading = '';
  }

  @action.bound
  async getMore() {
    if (!this.canLoadMore || this.loading) {
      return;
    }
    this.loading = 'Loading more logs...';
    let params: Params = { skip: this.logs.length, limit: this.limit };
    if (this.type !== 'all') {
      params = {
        ...params,
        targetType: this.type,
      };
    }
    if (this.target) {
      params = { ...params, target: this.target };
    }
    try {
      const response = await apiAuthCaller().get('/auditlog', {
        params,
      });
      this.logs = [...this.logs, ...response.data.logs];
    } catch (e) {
      const { messagesStore } = this.rootStore;
      messagesStore.showErrorMessage('Unable to fetch more logs. Please try again later');
    }
    this.loading = '';
  }

  @computed get canLoadMore() {
    return this.logs.length < this.total;
  }

  @computed public get parsedLogs() {
    const { sourceDefinitionsListStore, destinationDefsListStore } = this.rootStore;
    return this.logs.map((log) => {
      let defName = '';
      if (log.targetType === 'source') {
        defName = sourceDefinitionsListStore.getById(log.payload.def)?.name || '';
      } else if (log.targetType === 'destination') {
        defName = destinationDefsListStore.getDestinationDef(log.payload.def)?.name || '';
      }
      return {
        id: log.id,
        userName: log.user?.name,
        email: log.user?.email,
        action: log.action.replaceAll('_', ' '),
        target: log.payload.name || log.payload.email,
        payload: log.payload,
        defName,
        targetType: log.targetType === 'team' ? ('Teammate' as const) : log.targetType,
        createdAt: moment(log.createdAt).format('MM-DD-YYYY HH:mm A'),
      };
    });
  }
}

import { createGlobalStyle } from 'styled-components';

import { styles } from '@ui-library/styles';

const globalStyles = createGlobalStyle`
h1,
h2,
h3,
h4,
h5,
p,
pre,
a {
  margin: 0;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Inter;
}

fieldset {
  border: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100vh;
}

.App {
  font-family: Inter;
  min-height: 100vh;

}

${styles}

`;

/* eslint-disable import/no-default-export */
export default globalStyles;

import * as React from 'react';
import { FixMe } from '@utils/types';
import TextInputField from '@components/common/textInput/textInput';
import { SwitchInput } from './switchInput';
import { DynamicForm } from './dynamicForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { SingleSelect } from '@components/common/formGroup/singleSelect';
import { getFieldConfigs } from '@components/common/formGroup/utils';
import { LargeText } from '@ui-library/typography';
import { Button } from '@ui-library/button';

interface IDynamicCustomFormProps {
  field: FixMe;
  onChange: FixMe;
  disabled: boolean;
}
interface IDynamicCustomFormState {
  mapping: FixMe;
  count: FixMe;
}

const getDefaultValue = (field: FixMe) => {
  const fieldType = field.type;
  switch (fieldType) {
    case 'singleSelect':
    case 'textInput':
    case 'textareaInput':
      return '';
    case 'checkbox':
      return false;
    case 'dynamicForm': {
      const { keyLeft } = field;
      const { keyRight } = field;
      return [
        {
          [keyLeft]: '',
          [keyRight]: '',
        },
      ];
    }
    case 'dynamicCustomForm':
      return [];
  }
  return undefined;
};

/* eslint-disable import/no-default-export */
export default class DynamicCustomForm extends React.Component<
  IDynamicCustomFormProps,
  IDynamicCustomFormState
> {
  constructor(props: IDynamicCustomFormProps) {
    super(props);
    const fields = props.field.customFields;
    const fieldData = new Map();
    fields.forEach((field: FixMe) => {
      const key: string = field.value;
      fieldData.set(key, field.default ? field.default : getDefaultValue(field));
    });
    const obj = Object.fromEntries(fieldData);
    this.state = {
      mapping: [{ ...obj }],
      count: 1,
    };
  }

  componentDidMount() {
    const { field, onChange } = this.props;
    if (field.default && field.default?.length > 0) {
      this.setState({ mapping: [...field.default], count: field.default.length }, () =>
        onChange(field.value, this.state.mapping),
      );
    }
  }

  public onConfigChange = (index: number, value: string, keyValue: string) => {
    const { onChange, field } = this.props;
    this.setState(
      (prevState: FixMe) => {
        const mapping = prevState.mapping.splice(0);
        const updatedItem = {
          ...mapping[index],
          [keyValue]: value,
        };
        mapping.splice(index, 1, updatedItem);
        return {
          ...prevState,
          mapping,
        };
      },
      () => {
        const { mapping } = this.state;
        const trimmedMapping = mapping.filter(
          (mapItem: { [key: string]: string | unknown }) =>
            !(Object.keys(mapItem).length === 1 && mapItem[keyValue] === ''),
        );
        onChange(field.value, trimmedMapping);
      },
    );
  };

  private renderSingleRow = (index: number) => {
    const { field } = this.props;

    return (
      <div key={`singleRow${index}`}>
        {field.customFields.map((customField: FixMe) =>
          this.renderField({ ...customField }, index),
        )}
      </div>
    );
  };

  handleAddMore = () => {
    this.setState({ count: this.state.count + 1 });
  };

  public render() {
    const { field, disabled } = this.props;
    const { count } = this.state;
    return (
      <div data-testid="dynamicCustomForm">
        {field.label && <LargeText>{field.label}</LargeText>}
        {[...Array(count)].map((_v: FixMe, index: number) => this.renderSingleRow(index))}
        {!disabled && (
          <Button
            noPadding
            type="link"
            onClick={this.handleAddMore}
            icon={<FontAwesomeIcon icon={regular('circle-plus')} />}
          >
            Add more
          </Button>
        )}
      </div>
    );
  }

  private renderField = (customField: FixMe, index: FixMe) => {
    const { field, disabled } = this.props;
    const formRowData = this.state.mapping[index];

    const fieldConfig = getFieldConfigs(customField, formRowData);
    if (fieldConfig === null) {
      return null;
    }

    if (field.default && field.default[index]) {
      const defaultValue = field.default[index][customField.value];
      customField.default = defaultValue;
    } else {
      customField.default = getDefaultValue(customField);
    }

    switch (customField.type) {
      case 'textInput':
      case 'textareaInput':
        return (
          <div className="p-b-sm" key={customField.label + index}>
            <TextInputField
              field={customField}
              onChange={(label, value) => {
                this.onConfigChange(index, value, label);
              }}
              type={customField.type === 'textInput' ? 'input' : 'textarea'}
              disabled={disabled}
            />
            {customField.footerNote && (
              <div className="p-t-sm p-b-sm">{customField.footerNote}</div>
            )}
          </div>
        );

      case 'checkbox':
        return (
          <div className="p-b-sm" key={customField.label + index}>
            <SwitchInput
              field={customField}
              hidden={false}
              onChange={(label, value) => {
                this.onConfigChange(index, value, label);
              }}
              disabled={disabled}
            />
            {customField.footerNote && (
              <div className="p-t-sm p-b-sm">{customField.footerNote}</div>
            )}
          </div>
        );

      case 'dynamicForm':
        return (
          <div className="p-b-sm" key={customField.label + index}>
            <DynamicForm
              field={customField}
              onChange={(label: FixMe, value: FixMe) => {
                this.onConfigChange(index, value, label);
              }}
              disabled={disabled}
              secret={[]}
            />
            {customField.footerNote && (
              <div className="p-t-sm p-b-sm">{customField.footerNote}</div>
            )}
          </div>
        );

      case 'singleSelect':
        return (
          <div
            className="p-b-sm form_group__select_container"
            data-testid="fgsingleSelect"
            key={customField.label + index}
          >
            <SingleSelect
              field={customField}
              onChange={(label, value) => {
                this.onConfigChange(index, value, label);
              }}
              disabled={disabled}
            />
            {customField.footerNote && (
              <div className="p-t-sm p-b-sm">{customField.footerNote}</div>
            )}
          </div>
        );

      case 'dynamicCustomForm':
        return (
          <div
            className="p-l-lg"
            data-testid="nestedDynamicCustomForm"
            key={customField.label + index}
          >
            <DynamicCustomForm
              field={customField}
              onChange={(label: string, value: string) => {
                this.onConfigChange(index, value, label);
              }}
              disabled={disabled}
            />
            {customField.footerNote && (
              <div className="p-t-sm p-b-sm">{customField.footerNote}</div>
            )}
          </div>
        );

      default:
        break;
    }

    return null;
  };
}

import { action, makeObservable, observable, reaction } from 'mobx';
import { WhtProjectServerResponse } from '@components/profiles/stores/types';
import { IRootStore } from '@stores/index';
import { apiAuthCaller } from '@services/apiCaller';
import { getApiErrorMessage } from '@components/common/util/util';

export interface IProfilesListStore {
  loading: boolean;
  profilesList: WhtProjectServerResponse[];
  loadProfilesList: () => Promise<void>;
  getProfileById: (profileId: string) => WhtProjectServerResponse | undefined;
}

export class ProfilesListStore implements IProfilesListStore {
  public rootStore: IRootStore;

  @observable public profilesList: WhtProjectServerResponse[] = [];

  @observable public loading = false;

  @observable private listLoaded = false;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    reaction(
      () => this.rootStore.workspaceStore.id,
      () => {
        this.setListLoaded(false);
      },
    );
  }

  @action.bound
  private setProfilesList(profilesList: WhtProjectServerResponse[]) {
    this.profilesList = profilesList;
  }

  @action.bound
  private setLoading(loading: boolean) {
    this.loading = loading;
  }

  @action.bound
  private setListLoaded(listLoaded: boolean) {
    this.listLoaded = listLoaded;
  }

  @action.bound
  public async loadProfilesList() {
    if (this.listLoaded) {
      return;
    }

    const { messagesStore } = this.rootStore;
    this.setLoading(true);

    try {
      const { data } = await apiAuthCaller().get<WhtProjectServerResponse[]>('/whtProjects');

      this.setProfilesList(data);
      this.setListLoaded(true);
    } catch (err) {
      messagesStore.showErrorMessage(
        getApiErrorMessage(err, 'Profiles details could not be fetched.'),
      );
    } finally {
      this.setLoading(false);
    }
  }

  @action.bound
  public getProfileById(profileId: string) {
    return this.profilesList.find((profile) => profile.id === profileId);
  }
}

import { apiAuthCaller } from '@services/apiCaller';
import { action, observable, makeObservable, computed } from 'mobx';
import { IRootStore } from '@stores/index';
import { ISourceStore } from '../source';
import { constructTrackingPlanPeriodData, transformTrackingPlansData } from './utils';
import { getApiErrorMessage } from '@components/common/util/util';
import { TrackingPlanData, TrackingPlanPeriodData, TrackingPlanResponseData } from './types';

interface ITrackingPlanHealthStore {
  loadingCurrentPeriod: boolean;
  tableLoading: boolean;
  trackingPlanData: TrackingPlanPeriodData[];
  trackingPlanOverviewData: TrackingPlanOverviewData;
  selectedSource?: ISourceStore | null;
  setSelectedSource: (id: string) => void;
}

interface TrackingPlanOverviewData {
  total: number;
  violationCount: number;
  sourcesCount: number;
}

const defaultTrackingPlanOverviewData: TrackingPlanOverviewData = {
  total: 0,
  violationCount: 0,
  sourcesCount: 0,
};

export class TrackingPlanHealthStore implements ITrackingPlanHealthStore {
  protected rootStore: IRootStore;

  @observable public trackingPlanOverviewData: TrackingPlanOverviewData =
    defaultTrackingPlanOverviewData;

  @observable private trackingPlanCurrentData: TrackingPlanData[] | null = null;

  @observable private trackingPlanPrevData: TrackingPlanData[] | null = null;

  @observable private loadingPrevPeriod = false;

  @observable public loadingCurrentPeriod = false;

  @observable public selectedSource: ISourceStore | null = null;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @computed get tableLoading() {
    return this.loadingPrevPeriod || this.loadingCurrentPeriod;
  }

  @computed
  public get trackingPlanData(): TrackingPlanPeriodData[] {
    const {
      healthDashboardStore: { tableFilter },
    } = this.rootStore;

    let data = constructTrackingPlanPeriodData(
      this.trackingPlanCurrentData || [],
      this.trackingPlanPrevData || [],
    );
    data.sort((a, b) => b.eventsWithViolations - a.eventsWithViolations);

    if (tableFilter.status === 'violations') {
      data = data.filter((item) => item.eventsWithViolations > 0);
    }

    return data;
  }

  @action.bound
  public async setSelectedSource(id: string | null) {
    const { sourcesListStore } = this.rootStore;
    if (id) {
      const source = sourcesListStore.sourceById(id);
      this.selectedSource = source || null;
      return;
    }
    this.selectedSource = null;
  }

  private async getTrackingPlanData(timeRange: { start: string; end: string }) {
    const {
      workspaceStore: { defaultRegion: region },
    } = this.rootStore;
    const tpData = await apiAuthCaller().get<TrackingPlanResponseData>('/trackingPlanHealth', {
      params: {
        ...timeRange,
        region,
      },
    });
    const data = transformTrackingPlansData(tpData.data, this.rootStore);
    return data;
  }

  @action.bound
  public async load() {
    const { healthDashboardStore, messagesStore } = this.rootStore;
    try {
      this.loadingCurrentPeriod = true;
      const data = await this.getTrackingPlanData(healthDashboardStore.periodFilter.currentPeriod);
      this.trackingPlanCurrentData = data.sources;
      this.trackingPlanOverviewData = data.overview;
    } catch (e) {
      messagesStore.showErrorMessage(
        getApiErrorMessage(e, 'Failed to fetch current period tracking plan data'),
      );
    }
    this.loadingCurrentPeriod = false;
  }

  @action.bound
  public async refresh() {
    this.trackingPlanCurrentData = null;
    this.trackingPlanPrevData = null;
    this.trackingPlanOverviewData = defaultTrackingPlanOverviewData;
    await this.load();
  }

  @action.bound
  public async getPrevPeriodData() {
    const { healthDashboardStore, messagesStore } = this.rootStore;
    if (!this.trackingPlanPrevData) {
      try {
        this.loadingPrevPeriod = true;
        const prevData = await this.getTrackingPlanData(
          healthDashboardStore.periodFilter.prevPeriod,
        );
        this.trackingPlanPrevData = prevData.sources;
      } catch (e) {
        messagesStore.showErrorMessage(
          getApiErrorMessage(e, 'Failed to fetch previous period tracking plan data'),
        );
      }
      this.loadingPrevPeriod = false;
    }
  }
}

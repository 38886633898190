import { FixMe, CatchErr } from './types';

const delay = (ms = 1000) =>
  new Promise((res) => {
    setTimeout(res, ms);
  });
interface IRetryOptions {
  times?: number;
  delayTime?: number | ((timeLeft: number) => number);
}

const retry = async <RESP>(
  func: (time: number) => Promise<FixMe>,
  { times = 2, delayTime = 1000 }: IRetryOptions = {},
): Promise<RESP> => {
  try {
    return await func(times);
  } catch (err) {
    if (times < 0) {
      throw err;
    }
    await delay(typeof delayTime === 'function' ? delayTime(times) : delayTime);
    return retry(func, { times: times - 1, delayTime });
  }
};

/* eslint-disable import/no-default-export */
export default retry;

export class SmartRetry<RESP> {
  private inProgress = false;

  private func: (times?: number) => FixMe = () => null;

  private cancelMethod: () => void = () => null;

  static defaults: Required<IRetryOptions> = {
    times: 2,
    delayTime: 1000,
  };

  private definedOptions: IRetryOptions = SmartRetry.defaults;

  private options: Required<IRetryOptions> = SmartRetry.defaults;

  constructor(options: IRetryOptions = {}) {
    this.setOptions(options);
  }

  setOptions = (options: IRetryOptions) => {
    this.definedOptions = options;
    this.options = { ...SmartRetry.defaults, ...options };
  };

  setCancelMethod = (func: () => FixMe) => {
    this.cancelMethod = func;
  };

  private setFunc = (func: (times?: number) => FixMe) => {
    this.func = func;
  };

  exec = async (func: (time?: number) => FixMe): Promise<RESP> => {
    this.setFunc(func);
    return this.execute();
  };

  cancel = () => {
    if (this.inProgress) {
      try {
        this.cancelMethod();
      } catch (err) {}
      this.setOptions(this.definedOptions);
    }
  };

  private retryIndex = 0;

  private execute = async (reqNumber?: number, loop = false): Promise<RESP> => {
    let retryIndex = reqNumber;
    if (typeof reqNumber !== 'number') {
      this.retryIndex += 1;
      retryIndex = this.retryIndex;
    } else if (reqNumber !== this.retryIndex) {
      throw {
        constructor: {
          name: 'Cancel',
        },
      };
    }
    if (!loop) {
      this.cancel();
    }
    const { times, delayTime } = this.options;
    this.inProgress = true;
    try {
      const res = await this.func(times);
      return res;
    } catch (err: CatchErr) {
      if (err.constructor.name === 'Cancel') {
        throw err;
      }

      if (times <= 0) {
        throw err;
      }
      this.options.times -= 1;
      await delay(typeof delayTime === 'function' ? delayTime(times) : delayTime);
      return this.execute(retryIndex, true);
    }
  };
}

import { isSourceNameUnique, isValidInput } from '@components/common/util/util';
import { REGEX_STRING } from '@components/common/constants';
import { stores } from '@stores/index';
import setupDocs from './source-settings/components/sourceSetupDocs.json';

export const TAB_NAMES = {
  DESTINATIONS: 'Overview',
  SETUP: 'Setup',
  SYNCS: 'Syncs',
  SCHEMA: 'Schema',
  SETTINGS: 'Settings',
  EVENTS: 'Events',
  AUDIT_LOGS: 'Audit Logs',
  RESOURCES: 'Resources',
  CONFIGURATION: 'Configuration',
  TRACKING_PLANS: 'Tracking Plans',
  PERMISSIONS: 'Permissions',
  RETL_CONFIGURATION: 'Configuration',
};

export const validateSourceName = (name: string, flow?: string) => {
  if (!isValidInput(REGEX_STRING.SOURCE_NAME, name.trim())) {
    return `Please enter a valid ${flow || 'source'} name`;
  }
  if (!isSourceNameUnique(name, stores.sourcesListStore.sources)) {
    return `Please choose a unique ${flow || 'source'} name : ${name}`;
  }
  return '';
};

export const disabledLiveEventMsg = (isEnabled: boolean, isLocked: boolean) => {
  if (!isEnabled) {
    return 'Source is disabled';
  }
  if (isLocked) {
    return "You don't have permissions to view live events";
  }
  return '';
};

export const getSourceSetupLink = (sourceDefName: string) =>
  setupDocs.find((d) => d.name.toLowerCase() === sourceDefName.toLowerCase())?.docs;

import styled from 'styled-components';
import theme from '@css/theme';

const colorStyle = ({ color }: { color?: string }) => `
  color: ${color || theme.token.colorText};
`;

export const Title1 = styled.div<{ color?: string }>`
  font-family: ${theme.text.fontFamily.tertiary};
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 4.5rem;
  ${colorStyle};
`;

export const Title2 = styled.div<{ color?: string }>`
  font-family: ${theme.text.fontFamily.tertiary};
  font-style: normal;
  font-weight: 600;
  font-size: 2.625rem;
  line-height: 4rem;
  ${colorStyle};
`;

export const Title3 = styled.div<{ color?: string }>`
  font-family: ${theme.text.fontFamily.tertiary};
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  ${colorStyle};
`;

export const Title4 = styled.div<{ color?: string }>`
  font-family: ${theme.text.fontFamily.tertiary};
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  ${colorStyle};
`;

export const H1 = styled.h1<{ color?: string }>`
  font-family: ${theme.text.fontFamily.secondary};
  font-style: normal;
  font-weight: 500;
  font-size: ${theme.token.fontSizeHeading1}px;
  line-height: 2.875rem;
  ${colorStyle};
`;

export const H2 = styled.h2<{ color?: string }>`
  font-family: ${theme.text.fontFamily.secondary};
  font-weight: 400;
  font-size: ${theme.token.fontSizeHeading2}px;
  line-height: 2.5rem;
  ${colorStyle};
`;

export const H3 = styled.h3<{ color?: string }>`
  font-family: ${theme.text.fontFamily.secondary};
  font-weight: 500;
  font-size: ${theme.token.fontSizeHeading3}px;
  line-height: 2rem;
  ${colorStyle};
`;

export const H4 = styled.h4<{ color?: string }>`
  font-family: ${theme.text.fontFamily.secondary};
  font-weight: 400;
  font-size: ${theme.token.fontSizeHeading4}px;
  line-height: 1.875rem;
  ${colorStyle};
`;

export const H5 = styled.h5<{ color?: string }>`
  font-family: ${theme.text.fontFamily.secondary};
  font-weight: 500;
  font-size: ${theme.token.fontSizeHeading5}px;
  line-height: 1.75rem;
  ${colorStyle};
`;

export const BaseText = styled.span<{ fontWeight?: 600 | 500; color?: string }>`
  display: inline-block;
  font-family: ${theme.text.fontFamily.primary};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  font-size: ${theme.token.fontSize}px;
  color: ${({ color }) => color || theme.token.colorText};
  line-height: 1.375rem;
`;

export const SmallText = styled.span<{ fontWeight?: 600 | 500; color?: string }>`
  display: inline-block;
  font-family: ${theme.text.fontFamily.primary};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  font-size: ${theme.token.fontSizeSM}px;
  color: ${({ color }) => color || theme.token.colorText};
  line-height: 1.25rem;
`;

export const LargeText = styled.span<{ strong?: boolean; color?: string }>`
  display: inline-block;
  font-family: ${theme.text.fontFamily.primary};
  font-weight: ${({ strong }) => (strong ? 600 : 400)};
  font-size: ${theme.token.fontSizeLG}px;
  color: ${({ color }) => color || theme.token.colorText};
  line-height: 1.5rem;
`;

export const XLargeText = styled.span<{ strong?: boolean; color?: string }>`
  display: inline-block;
  font-family: ${theme.text.fontFamily.primary};
  font-weight: ${({ strong }) => (strong ? 600 : 400)};
  font-size: ${theme.token.fontSizeXL}px;
  color: ${({ color }) => color || theme.token.colorText};
  line-height: 1.625rem;
`;

export const XXLargeText = styled(XLargeText)<{ strong?: boolean; color?: string }>`
  font-size: ${theme.token.fontSizeHeading4}px;
`;

/** @deprecated - Use BaseText */
export const Body = styled.p<{
  secondary?: boolean;
  color?: string;
  strong?: boolean;
}>`
  font-family: ${theme.text.fontFamily.primary};
  font-weight: ${({ strong }) => (strong ? 600 : 400)};
  color: ${({ secondary, color }) => getColor(false, secondary, color)};
  font-size: ${theme.token.fontSizeLG}px;
  line-height: 1.5rem;
`;

const tableStyles = `
  color: ${theme.token.colorText};
  font-size: 0.875rem;
  font-family: ${theme.text.fontFamily.primary};
`;

// TODO move to the table component
/** @deprecated - No direct imports */
export const TableBody = styled.div`
  font-weight: normal;
  ${tableStyles}
`;

// TODO move to the table component
/** @deprecated - No direct imports */
export const TableHeader = styled.div`
  font-weight: bold;
  ${tableStyles}
`;

const getColor = (error?: boolean, secondary?: boolean, color?: string) => {
  if (error) {
    return theme.token.colorErrorBase;
  }
  if (secondary) {
    return theme.text.color.secondary;
  }
  return color || theme.token.colorText;
};

type fontWeightType = 600;

/** @deprecated - Use SmallText */
export const Subtext = styled.div<{
  error?: boolean;
  secondary?: boolean;
  color?: string;
  strong?: boolean;
  fontWeight?: fontWeightType;
}>`
  font-family: ${theme.text.fontFamily.primary};
  font-weight: ${({ strong, fontWeight }) => fontWeight || (strong ? 500 : 400)};
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: ${({ error, secondary, color }) => getColor(error, secondary, color)};
`;

/** @deprecated - Use SmallText */
export const Caption = styled.div<{
  color?: string;
  strong?: boolean;
  fontWeight?: fontWeightType;
}>`
  font-family: ${theme.text.fontFamily.primary};
  font-weight: ${({ strong, fontWeight }) => fontWeight || (strong ? 500 : 400)};
  font-size: ${theme.token.fontSizeSM}px;
  line-height: 1.125rem;
  color: ${({ color }) => color || theme.text.color.primary};
`;

const linkStyle = `
  font-family: ${theme.text.fontFamily.primary};
  font-weight: normal;
  color: ${theme.token.colorPrimary};
  &:hover, :active, :focus {
    color: ${theme.token.colorPrimary};
    text-decoration: underline;
  }
`;

export const Link1 = styled.a`
  ${linkStyle}
  font-size: ${theme.token.fontSize}px;
  line-height: 1.5rem;
`;

export const Link2 = styled.a`
  ${linkStyle}
  font-size: 1rem;
  line-height: 1.375rem;
`;

export const Link3 = styled.a`
  ${linkStyle}
  font-size: ${theme.token.fontSizeSM}px;
  line-height: 1.125rem;
`;

import React from 'react';
import { Switch } from '@ui-library/switch';
import { FixMe } from '@utils/types';
import { Flex } from '@ui-library/flex';
import { Subtext } from '@ui-library/typography';

interface ISwitchInputProps {
  field: FixMe;
  hidden?: boolean;
  disabled?: boolean;
  onChange: (label: string, value: FixMe) => void;
}

export class SwitchInput extends React.Component<ISwitchInputProps> {
  componentDidMount() {
    const { field, onChange } = this.props;
    onChange(field.value, field.default || false);
  }

  public render() {
    const { field, onChange, hidden, disabled } = this.props;
    if (!hidden) {
      return (
        <Flex
          style={
            (field.divider !== false && {
              padding: '26px 0px 0px 0px',
              marginTop: '5px',
            }) ||
            undefined
          }
          spaceBetween
          className={(field.divider !== false && 'm-t-xs b-t-grey') || undefined}
          data-testid="switchInput"
        >
          {field.label && (
            <Subtext>
              {field.label}
              {field.required && (
                <span style={{ color: 'red' }} data-testid="siRequired">
                  {' '}
                  *
                </span>
              )}
            </Subtext>
          )}
          <Switch
            defaultChecked={field.default}
            onChange={(checked: FixMe) => onChange(field.value, checked)}
            disabled={disabled}
            data-testid="switchInputSwitch"
          />
        </Flex>
      );
    }
    return null;
  }
}

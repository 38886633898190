import { ISourceStore } from '@stores/source';

export interface TrackingPlanData {
  id: string;
  source: ISourceStore;
  trackingPlanName: string;
  trackingPlanId: string;
  trackingPlanVersion: string;
  eventsValidated: number;
  violationCount: number;
  eventsWithViolations: number;
  isActive: boolean;
}

export interface TrackingPlanPeriodData extends TrackingPlanData {
  prevEventsValidated: number;
  prevEventsWithViolations: number;
}

export type TrackingPlanResponseData = {
  sourceId: string;
  trackingPlanId: string;
  eventsValidated: number;
  violationCount: number;
  eventsWithViolations: number;
}[];

export enum Category {
  EVENT_STREAM = 'eventStream',
  RETL = 'retl',
  TRACKING_PLAN = 'trackingPlan',
}

export type SubCategories = {
  eventStream: 'all' | 'cloud' | 'warehouse';
  retl: '';
  trackingPlan: '';
};

type StatusFilterValue = {
  eventStream: 'all' | 'failures';
  retl: 'all';
  trackingPlan: 'all' | 'violations';
};

export interface TableFilter {
  category: Category;
  subCategory: SubCategories[Category];
  status: StatusFilterValue[Category];
}

export interface OverviewData {
  key: Category;
  title: string;
  description: string;
  total: number;
  loading: boolean;
  categories: {
    label: string;
    value: number;
  }[];
  alerts: ActiveAlertsOverview | null;
}

export interface ActiveAlertsOverview {
  enabled: boolean;
  alertCount: number;
  resourceCount: number;
  loading: boolean;
  title: string;
  resourceType: string;
}

import styled, { css } from 'styled-components';
import React from 'react';
import { Flex, FlexProps } from '@ui-library/flex';
import { Subtext } from '@ui-library/typography';
import theme from '@css/theme';
import {
  createPaddingVariant,
  createBorderRadiusVariant,
  PaddingVariant,
  BorderRadiusVariant,
} from '@ui-library/tokens/utils';

interface CardProp {
  ghost?: boolean;
  notPadded?: boolean;
  fitContent?: boolean;
  borderColor?: string;
  highlighted?: boolean;
  padding?: PaddingVariant;
  borderRadius?: BorderRadiusVariant;
}

const baseCard = css<CardProp>`
  overflow: hidden;
  background-color: ${theme.token.colorBgBase};
  border: 1px solid ${({ borderColor }) => borderColor || theme.token.colorBorder};

  ${createBorderRadiusVariant('borderRadiusLG')}

  ${({ notPadded }) => !notPadded && createPaddingVariant('paddingLG')}

  ${({ fitContent }) => fitContent && 'width: fit-content;'}

  ${({ highlighted, theme }) =>
    highlighted &&
    `
    background-color: ${theme.token.colorFillAlter};
    border: 1px solid ${theme.token.colorBorderSecondary};
  `}

  ${({ ghost = false }) => ghost && 'border: none;'}
`;

export const Card = styled.div<CardProp>`
  ${baseCard}
`;

type FlexCardProps = CardProp &
  FlexProps & {
    stretch?: boolean;
  };

export const FlexCard = styled(Flex)<FlexCardProps>`
  ${baseCard}
  ${({ stretch }) => stretch && 'flex: 1;'}
`;

export const CardContent = styled.div`
  padding: ${theme.token.paddingLG}px ${theme.token.paddingXL}px;
`;

const Header = styled(Flex)`
  background: ${theme.token.controlItemBgHover};
  border-radius: ${theme.token.borderRadiusLG}px ${theme.token.borderRadiusLG}px 0 0;
  padding: ${theme.token.padding}px ${theme.token.paddingXL}px;
`;

interface IProps {
  children: React.ReactNode;
  title: string;
  tag?: string;
  noContentPadding?: boolean;
}

export const HeaderCard = ({ children, title, tag, noContentPadding }: IProps) => (
  <Card notPadded data-testid="card">
    <Header spaceBetween>
      <Subtext strong data-testid="title">
        {title}
      </Subtext>
      {tag && <Subtext data-testid="tag">{tag}</Subtext>}
    </Header>
    {noContentPadding ? children : <CardContent>{children}</CardContent>}
  </Card>
);

import React from 'react';
import dayjs from 'dayjs';
import { action, observable, makeObservable, computed } from 'mobx';
import { apiAuthCaller } from '@services/apiCaller';
import { IRootStore } from '@stores/index';
import { ISource } from '@stores/source';
import { IDestination } from '@stores/destination';
import { ResourceCategory, ResourceType } from '@stores/alertDefinitionsList/types';
import sourceFactory from '@components/sources/sourceFactory';
import { readableThreshold } from '../utils';

const ALERTS_API = '/alertNotifications/alerts/active';

interface RawActiveAlert {
  sourceId?: string;
  destinationId?: string;
  trackingPlanId?: string;
  jobId?: string;
  alertDefinitionName: string;
  activeAt: string;
  threshold?: number;
}

export interface ActiveAlert extends Omit<RawActiveAlert, 'activeAt' | 'threshold'> {
  resourceCategory: ResourceCategory;
  resourceType: ResourceType;
  alertDisplayName: string;
  alertMessage: string;
  activeAt: dayjs.Dayjs;
  threshold: string;
  sourceName?: string;
  destinationName?: string;
  trackingPlanName?: string;
}

type AlertConfigurationStatus =
  | {
      hasEventStreamDestinationAlerts: boolean;
      hasRETLAlerts: boolean;
      hasTrackingPlanAlerts: boolean;
    }
  | {
      hasAlerts: boolean;
    };

interface RawActiveAlertsData {
  activeAlerts: RawActiveAlert[];
  alertConfigurationStatus: AlertConfigurationStatus;
}

export type ResourceDetails =
  | {
      resource: ISource;
      resourceType: ResourceType.SOURCE;
      resourceCategory: ResourceCategory.EVENT_STREAM | ResourceCategory.RETL;
    }
  | {
      resource: IDestination;
      resourceType: ResourceType.CLOUD_DESTINATION | ResourceType.WAREHOUSE_DESTINATION;
      resourceCategory: ResourceCategory.EVENT_STREAM | ResourceCategory.RETL;
    };

export interface IActiveAlertsStore {
  resourceDetails: ResourceDetails | undefined;
  activeAlerts: ActiveAlert[];
  alertConfigurationStatus: AlertConfigurationStatus | null;
  resourceAlertsConfigured: boolean;
  activeAlertsLoading: boolean;
  showActiveAlerts: boolean;
  load(): Promise<void>;
  getResourceDetails(resource: ResourceDetails['resource']): ResourceDetails;
}

export class ActiveAlertsStore implements IActiveAlertsStore {
  protected rootStore: IRootStore;

  private resource: ResourceDetails['resource'] | undefined;

  @observable public activeAlerts: ActiveAlert[] = [];

  @observable public alertConfigurationStatus: AlertConfigurationStatus | null = null;

  @observable public activeAlertsLoading = false;

  @observable private errorInLoadingActiveAlerts = false;

  constructor(rootStore: IRootStore, resource?: ResourceDetails['resource']) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.resource = resource;
  }

  @computed get resourceDetails() {
    return this.resource ? this.getResourceDetails(this.resource) : undefined;
  }

  public getResourceDetails = (resource: ResourceDetails['resource']) => {
    let resourceDetails: ResourceDetails;

    if (resource && 'sourceDef' in resource) {
      resourceDetails = {
        resource,
        resourceType: ResourceType.SOURCE,
        resourceCategory: sourceFactory(resource.sourceDef.category).isWarehouse()
          ? ResourceCategory.RETL
          : ResourceCategory.EVENT_STREAM,
      };
    } else {
      resourceDetails = {
        resource,
        resourceType:
          resource.destinationDefinition.category === 'warehouse'
            ? ResourceType.WAREHOUSE_DESTINATION
            : ResourceType.CLOUD_DESTINATION,
        resourceCategory:
          resource.sources.length > 0 &&
          resource.sources.every((source) => source.sourceCategory.category === 'retl')
            ? ResourceCategory.RETL
            : ResourceCategory.EVENT_STREAM,
      } as ResourceDetails;
    }

    return resourceDetails;
  };

  @computed
  private get canFetchActiveAlerts() {
    const isDestination =
      this.resourceDetails?.resourceType === ResourceType.CLOUD_DESTINATION ||
      this.resourceDetails?.resourceType === ResourceType.WAREHOUSE_DESTINATION;
    const isRetlDestination =
      isDestination && this.resourceDetails?.resourceCategory === ResourceCategory.RETL;

    return this.rootStore.featuresStore.has('NOTIFICATION_SUBSCRIPTIONS') && !isRetlDestination;
  }

  @computed
  public get showActiveAlerts() {
    return this.canFetchActiveAlerts && !this.errorInLoadingActiveAlerts;
  }

  @computed
  public get resourceAlertsConfigured() {
    if (
      this.alertConfigurationStatus &&
      typeof this.alertConfigurationStatus === 'object' &&
      'hasAlerts' in this.alertConfigurationStatus
    ) {
      return this.alertConfigurationStatus.hasAlerts;
    }
    return false;
  }

  @action.bound
  public async load() {
    this.errorInLoadingActiveAlerts = false;

    if (!this.canFetchActiveAlerts) {
      return;
    }
    const { messagesStore, alertDefinitionsListStore } = this.rootStore;
    this.activeAlertsLoading = true;
    const { resource, resourceType, resourceCategory } = this.resourceDetails || {};

    try {
      const resp = await Promise.all([
        apiAuthCaller().get<RawActiveAlertsData>(ALERTS_API, {
          params: {
            resourceId: resource?.id,
            resourceType,
            resourceCategory,
          },
        }),
        alertDefinitionsListStore.load(),
      ]);
      const { data } = resp[0];

      this.activeAlerts = this.constructActiveAlerts(data.activeAlerts);

      this.alertConfigurationStatus = data.alertConfigurationStatus;
    } catch (e) {
      messagesStore.showErrorMessage('Failed to load active alerts');
      this.errorInLoadingActiveAlerts = true;
    } finally {
      this.activeAlertsLoading = false;
    }
  }

  @action
  private constructActiveAlerts = (rawActiveAlerts: RawActiveAlert[]) => {
    const {
      alertDefinitionsListStore: { alertDefinitions },
      sourcesListStore: { sourceById },
      destinationsListStore: { destinationById },
      retlConnectionListStore: { getConnectionById },
      trackingPlanListStore,
    } = this.rootStore;

    const alerts = rawActiveAlerts.map((a) => {
      const alertDef = alertDefinitions.find((alertDef) => alertDef.name === a.alertDefinitionName);

      if (!alertDef) {
        return null;
      }

      const source = sourceById(a?.sourceId || '');
      const destination = destinationById(a.destinationId || '');
      const connection = getConnectionById(a.jobId || '');
      const connectionDestination = destinationById(connection?.destinationId || '');
      const trackingPlan = trackingPlanListStore.getById(a.trackingPlanId || '');

      return {
        ...a,
        activeAt: dayjs(a.activeAt),
        alertMessage: alertDef.messageTemplate,
        alertDisplayName: alertDef.displayName,
        resourceCategory: alertDef.resourceCategory,
        resourceType: alertDef.resourceType,
        threshold: readableThreshold(a.threshold, alertDef?.thresholdMetadata?.unit),
        sourceName: source?.name || '',
        destinationName: destination?.name || connectionDestination?.name || '',
        trackingPlanName: trackingPlan?.name || '',
      };
    });

    return alerts.filter((alert) => alert !== null) as ActiveAlert[];
  };
}

export const ActiveAlertsStoreCtx = React.createContext<IActiveAlertsStore | undefined>(undefined);

export const useActiveAlertsStore = () =>
  React.useContext(ActiveAlertsStoreCtx) as IActiveAlertsStore;

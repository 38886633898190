import { AxiosError } from 'axios';

/* eslint-disable import/no-default-export */
export default (err: AxiosError | Error) => {
  if (!err) {
    return null;
  }
  const errorData = (err as AxiosError).response?.data;
  const message = errorData
    ? (errorData as { message: string }).message || (errorData as string)
    : err.message;

  return {
    status: (err as AxiosError).response?.status,
    message:
      typeof message === 'object'
        ? (message as { error?: { message: string } }).error?.message! ||
          (message as { error: string }).error ||
          message
        : message,
  };
};
